import React, {
	ComponentProps,
	ForwardedRef,
	forwardRef,
	useCallback,
} from 'react';
import { StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { PlayerType } from '../../contexts/PlayerContext';
import {
	useCurrentEpisode,
	useDuration,
	useSubscriptions,
} from '../../hooks/api';
import { useI18n } from '../../hooks/useI18n';
import { usePlayer } from '../../hooks/usePlayer';
import { AssetType } from '../../models/AssetType';
import { Details } from '../../models/Details';
import {
	USER_INTERACTION_CONTEXT,
	USER_INTERACTION_FEATURES,
	USER_INTERACTION_TYPE,
} from '../../services/event-tracker/UserInterActionEvent';
import { getScaledValue, getResponsiveScaling } from '../../utils/scale';
import Button from '../button/button-flex';
import Icon from '../icon';
import { IMAGE_WIDTH } from '../tile/tileBase';

const styles = StyleSheet.create({
	button: {
		marginTop: getScaledValue(10),
		marginRight: getScaledValue(10),
		width: getResponsiveScaling((IMAGE_WIDTH + 2) * 2).width,
	},
});

type MovieButtonProps = {
	asset: Details;
	onFocus: () => void;
} & ComponentProps<typeof Button>;

const MovieButton = forwardRef(
	(
		{ asset, onFocus, parentContext, focusOptions }: MovieButtonProps,
		ref: ForwardedRef<MovieButtonProps>
	) => {
		const {
			setShowDetails,
			setPlaying,
			setCurrentTime,
			playerType,
			setIsEnd,
			isEnd,
			setPlayerType,
			setShowPlayer,
			setAsset,
		} = usePlayer();
		const { translate } = useI18n();
		const assetToPlay =
			(asset.asset_type === AssetType.tvshow && useCurrentEpisode(asset)) ||
			asset;
		const { duration } = useDuration(assetToPlay);
		const { reactivatableSubscription, activeSubscription } =
			useSubscriptions();

		const showReactivateSubscription =
			!activeSubscription && reactivatableSubscription;

		const labelMovie =
			duration > 0
				? translate('continuewatching.button')
				: translate(
						assetToPlay.asset_type === AssetType.episode ||
							asset.asset_type === AssetType.tvshow
							? 'button.play.episode'
							: 'button.play.movie'
				  );

		const { navigate } = useNavigation();

		const handleClick = useCallback(() => {
			if (playerType === PlayerType.Trailer) {
				setCurrentTime(0);
			}

			if (isEnd) {
				setCurrentTime(0);
				setIsEnd(false);
			}

			setAsset(assetToPlay);
			setPlayerType(PlayerType.Video);
			setPlaying(true);
			setShowDetails(false);
			setShowPlayer(true);
		}, [
			assetToPlay,
			isEnd,
			playerType,
			setAsset,
			setCurrentTime,
			setIsEnd,
			setPlayerType,
			setPlaying,
			setShowDetails,
			setShowPlayer,
		]);

		return (
			<Button
				ref={ref}
				parentContext={parentContext}
				style={[styles.button]}
				label={
					showReactivateSubscription
						? translate('subscription_reactivation.calltoaction')
						: labelMovie
				}
				leftIcon={<Icon type="play" />}
				onFocus={onFocus}
				onPress={
					showReactivateSubscription
						? () => navigate('ReactivateSubscription')
						: handleClick
				}
				focusOptions={focusOptions}
				track={{
					feature: USER_INTERACTION_FEATURES.ASSET_DETAIL,
					context: USER_INTERACTION_CONTEXT.BUTTON_ROW,
					type: USER_INTERACTION_TYPE.BUTTON,
					name: showReactivateSubscription
						? 'reactivate_subscription'
						: 'movie_play',
				}}
			/>
		);
	}
);

MovieButton.displayName = 'TrailerButton';

export default MovieButton;
