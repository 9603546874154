import React, {
	createContext,
	useEffect,
	useCallback,
	useState,
	ReactNode,
} from 'react';
import * as RNLocalize from 'react-native-localize';
import { nl, sv, nb, enGB } from 'date-fns/locale';

import config from '../../platformAssets/runtime/config';
import { files } from '../../platformAssets/runtime/translations';
import { Locale } from 'date-fns';
import { useAuth } from '../providers/auth';
import { useConfig } from '../hooks/useConfig';

type Props = {
	children?: ReactNode;
};

const I18nContext = createContext<
	| {
			translate: (
				key: string,
				interpolation?: { [key: string]: any }
			) => string;
			language: string | undefined;
			countryCode: string | undefined;
			country: string | undefined;
			dateFnsLocale: Locale;
	  }
	| undefined
>(undefined);

const I18nProvider = ({ children }: Props) => {
	const { countryCode, country } = useConfig();

	const [currentFile, setCurrentFile] = useState<{ [key: string]: any }>();
	const [language, setLanguage] = useState<string | undefined>();
	const [dateFnsLocale, setDateFnsLocale] = useState<Locale>(enGB);
	const { userSettings } = useAuth();

	useEffect(() => {
		if (userSettings) {
			const preferredLanguage = userSettings?.find(
				(setting) => setting.key === 'preferred_language'
			);

			if (preferredLanguage) {
				if (files[preferredLanguage.value]) {
					setCurrentFile(files[preferredLanguage.value]);
					setLanguage(preferredLanguage.value);
				}
			}
		}
	}, [userSettings]);

	useEffect(() => {
		function handleLocalizationChange() {
			const locale = RNLocalize.getLocales()?.[0];
			const lang = locale?.languageTag;
			setLanguage(locale.languageCode);
			setCurrentFile(
				files[lang]
					? files[lang]
					: files[config.localisation.default.languageTag]
			);
		}

		handleLocalizationChange();
		RNLocalize.addEventListener('change', handleLocalizationChange);
		return () =>
			RNLocalize.removeEventListener('change', handleLocalizationChange);
	}, []);

	useEffect(() => {
		if (language) {
			switch (language) {
				case 'nl':
					setDateFnsLocale(nl);
					break;
				case 'sv':
					setDateFnsLocale(sv);
					break;
				case 'nb':
					setDateFnsLocale(nb);
					break;
				default:
					setDateFnsLocale(enGB);
					break;
			}
		}
	}, [language]);

	const translate = useCallback(
		(key, interpolation) => {
			if (currentFile?.[key]) {
				let translation = currentFile[key];

				if (interpolation) {
					Object.keys(interpolation).forEach((interpolationKey) => {
						const r = `\\{${interpolationKey}\\}`;
						const re = new RegExp(r, 'g');
						translation = translation.replace(
							re,
							interpolation[interpolationKey]
						);
					});
				}

				return translation;
			}

			return process.env.CONFIG_DEV ? 'MISSING: ' + key : key;
		},
		[currentFile]
	);

	return (
		<I18nContext.Provider
			value={{ translate, language, countryCode, country, dateFnsLocale }}
		>
			{children}
		</I18nContext.Provider>
	);
};

export { I18nContext, I18nProvider };
