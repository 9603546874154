import edit from '../platformAssets/runtime/icon/add.png';
import favorites from '../platformAssets/runtime/icon/favorites.png';
import favorites_active from '../platformAssets/runtime/icon/favorites_active.png';
import play from '../platformAssets/runtime/icon/play.png';
import profile from '../platformAssets/runtime/icon/profile.png';
import restart from '../platformAssets/runtime/icon/restart.png';
import subtitles from '../platformAssets/runtime/icon/subtitles.png';
import trailer from '../platformAssets/runtime/icon/trailer.png';
import audio from '../platformAssets/runtime/icons/audio.png';
import christmas from '../platformAssets/runtime/icons/christmas.png';
import audioActive from '../platformAssets/runtime/icons/audio_selected.png';
import christmasActive from '../platformAssets/runtime/icons/christmas_selected.png';
import cross from '../platformAssets/runtime/icons/cross.png';
import crossActive from '../platformAssets/runtime/icons/cross_selected.png';
import easter from '../platformAssets/runtime/icons/easter.png';
import easterActive from '../platformAssets/runtime/icons/easter_selected.png';
import femaleDetective from '../platformAssets/runtime/icons/female_detective.png';
import femaleDetectiveActive from '../platformAssets/runtime/icons/female_detective_selected.png';
import genres from '../platformAssets/runtime/icons/genres.png';
import genresActive from '../platformAssets/runtime/icons/genres_selected.png';
import home from '../platformAssets/runtime/icons/home.png';
import homeActive from '../platformAssets/runtime/icons/home_selected.png';
import kids from '../platformAssets/runtime/icons/kids.png';
import kidsActive from '../platformAssets/runtime/icons/kids_selected.png';
import movies from '../platformAssets/runtime/icons/movies.png';
import moviesActive from '../platformAssets/runtime/icons/movies_selected.png';
import newIcon from '../platformAssets/runtime/icons/new.png';
import newIconActive from '../platformAssets/runtime/icons/new_selected.png';
import original from '../platformAssets/runtime/icons/original.png';
import originalActive from '../platformAssets/runtime/icons/original_selected.png';
import search from '../platformAssets/runtime/icons/search.png';
import searchActive from '../platformAssets/runtime/icons/search_selected.png';
import series from '../platformAssets/runtime/icons/series.png';
import seriesActive from '../platformAssets/runtime/icons/series_selected.png';
import user from '../platformAssets/runtime/icons/user.png';
import userActive from '../platformAssets/runtime/icons/user_selected.png';
import pen from '../platformAssets/runtime/icon/pen.png';
import bullet from '../platformAssets/runtime/icons/bullet.png';

export const iconsActive = {
	home: homeActive,
	search: searchActive,
	user: userActive,
	favorites: favorites_active,
	genres: genresActive,
	movies: moviesActive,
	series: seriesActive,
	kids: kidsActive,
	original: originalActive,
	new: newIconActive,
	cross: crossActive,
	femaleDetective: femaleDetectiveActive,
	easter: easterActive,
	audio: audioActive,
	christmas: christmasActive,
};

export default {
	genres,
	home,
	homeActive,
	search,
	searchActive,
	user,
	userActive,
	favorites,
	favorites_active,
	play,
	profile,
	subtitles,
	trailer,
	restart,
	movies,
	series,
	kids,
	original,
	new: newIcon,
	cross,
	femaleDetective,
	easter,
	audio,
	christmas,
	edit,
	pen,
	bullet,
};
