export const pad = (
	string: string,
	length: number,
	padChar: string,
	inFront: boolean
) => {
	const numPadChars = Math.max(0, length - string.length);
	return inFront
		? padChar.repeat(numPadChars) + string
		: string + padChar.repeat(numPadChars);
};
