import { CoreManager, View } from '@flexn/sdk';
import * as Sentry from '@sentry/browser';
import {
	Player,
	PlayerAPI,
	PlayerConfig,
	PlayerEvent,
	PlayerEventCallback,
	StreamType,
	SubtitleTrack,
	PlayerType as BMPlayerType,
	AudioTrack as BMPlayerAudioTrack,
} from 'bitmovin-player';
import { SubtitleOverlay, UIContainer, UIManager } from 'bitmovin-player-ui';
import AbrModule from 'bitmovin-player/modules/bitmovinplayer-abr';
import Mp4Module from 'bitmovin-player/modules/bitmovinplayer-container-mp4';
import CryptoModule from 'bitmovin-player/modules/bitmovinplayer-crypto';
import DashModule from 'bitmovin-player/modules/bitmovinplayer-dash';
import DrmModule from 'bitmovin-player/modules/bitmovinplayer-drm';
import BitmovinEngine from 'bitmovin-player/modules/bitmovinplayer-engine-bitmovin';
import MserendererModule from 'bitmovin-player/modules/bitmovinplayer-mserenderer';
import PolyFillModule from 'bitmovin-player/modules/bitmovinplayer-polyfill';
import StyleModule from 'bitmovin-player/modules/bitmovinplayer-style';
import SubtitlesModule from 'bitmovin-player/modules/bitmovinplayer-subtitles';
import SubtitlesNativeModule from 'bitmovin-player/modules/bitmovinplayer-subtitles-native';
import TizenModule from 'bitmovin-player/modules/bitmovinplayer-tizen';
import WebOSModule from 'bitmovin-player/modules/bitmovinplayer-webos';
import XmlModule from 'bitmovin-player/modules/bitmovinplayer-xml';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { StyleSheet } from 'react-native';
import * as RNLocalize from 'react-native-localize';

import config from '../../../platformAssets/runtime/config';
import { PlayerType, Subtitles } from '../../contexts/PlayerContext';
import { useDuration } from '../../hooks/api';
import { useKeyHandler } from '../../hooks/useKeyHandler';
import { usePlayer } from '../../hooks/usePlayer';
import { AssetType } from '../../models/AssetType';
import {
	DIRECTION_DOWN,
	DIRECTION_LEFT,
	DIRECTION_RIGHT,
	DIRECTION_UP,
	KEY_ENTER,
	KEY_PLAYPAUSE,
	MEDIA_FASTFORWARD,
	MEDIA_PAUSE,
	MEDIA_PLAY,
	MEDIA_PLAYPAUSE,
	MEDIA_REWIND,
	MEDIA_STOP,
	MEDIA_TRACK_NEXT,
	MEDIA_TRACK_PREVIOUS,
} from '../../utils/keymap/index';
import parseTimeFormat from '../../utils/parseTimeFormat';
import { getScaledValue } from '../../utils/scale';
import { Storage } from '../../utils/storage';
import AppLoader from '../app-loader';
import NoActiveSubscription from '../no-active-subscription';

import PlayerControls from './player-controls';
import UserInterActionEvent, {
	USER_INTERACTION_CONTEXT,
	USER_INTERACTION_FEATURES,
	USER_INTERACTION_TYPE,
} from '../../services/event-tracker/UserInterActionEvent';
import ClientTracker from '../../services/event-tracker/ClientTracker';
import { isWebos } from '@rnv/renative';
import { useAuth } from '../../providers/auth';
import { AudioTrack } from 'bitmovin-player-react-native';
import { preferredSubtitleLanguageStorageKey } from '../../utils/storageKeys';

export const DEFAULT_CONFIGURATION: PlayerConfig = {
	key: config.BitmovinPlayerLicenseKey,
	ui: false,
	playback: {
		autoplay: true,
		preferredTech: [
			{
				player: BMPlayerType.Html5,
				streaming: StreamType.Dash,
			},
		],
		// muted: true,
	},
	tweaks: {
		file_protocol: true,
		app_id: config.appId,
		DWORD_BASE_MEDIA_DECODE_TIMESTAMPS: true,
		BACKWARD_BUFFER_PURGE_INTERVAL: 10,
	},
	buffer: {
		video: {
			forwardduration: 30,
			backwardduration: 10,
		},
		audio: {
			forwardduration: 30,
			backwardduration: 10,
		},
	},
};

Player.addModule(SubtitlesModule);
Player.addModule(SubtitlesNativeModule);
Player.addModule(TizenModule);
Player.addModule(PolyFillModule);
Player.addModule(MserendererModule);
Player.addModule(Mp4Module);
Player.addModule(BitmovinEngine);
Player.addModule(CryptoModule);
Player.addModule(DashModule);
Player.addModule(XmlModule);
Player.addModule(DrmModule);
Player.addModule(AbrModule);
Player.addModule(StyleModule);
Player.addModule(WebOSModule);

let playerInstance: PlayerAPI | null = null;
let uiInstance: UIManager | null = null;

const axinomPlayreadyUrl = 'https://drm-playready-licensing.axprod.net';
const axinomWidevineURl = 'https://drm-widevine-licensing.axprod.net';

const BitmovinPlayer = ({ goBackFocusKey }) => {
	const {
		trailer,
		video,
		asset,
		showPlayer,
		setPlaying,
		setIsEnd,
		setShowDetails,
		currentUrl,
		setCurrentUrl,
		playerType,
		setShowControls,
		showDetails,
		showControls,
		setCurrentTime,
		setDuration,
		playing,
		playpause,
		setSelectedSubtitle,
		selectedSubtitle,
		setSubtitles,
		currentTime,
		setPlayerWeb,
		startOver,
		setStartOver,
		setShowSkipIntro,
		showSkipIntro,
		setShowNextEpisode,
		showNextEpisode,
		setSeekTime,
		clearPlayerState,
		duration,
		setAudioTracks,
		selectedAudioTrack,
	} = usePlayer();
	const { user } = useAuth();

	const playerRef = useRef(null);
	const { duration: watchDuration } = useDuration(asset);
	const [prevCurrentUrl, setPrevCurrentUrl] = useState<string>('');
	const [loaded, setLoaded] = useState<boolean>(false);
	const timer = useRef<number | undefined>();
	const [noSubscription, setNoSubscription] = useState(false);

	const handlePlaybackFinished = useCallback(() => {
		setPlaying(false);
		setIsEnd(true);
		setShowDetails(true);
	}, [setPlaying, setIsEnd, setShowDetails]);

	const onProgress: PlayerEventCallback = useCallback(
		// @note: type PlaybackEvent is not working properly here due to an issue in the bitmovin library
		({ time }: any) => {
			setCurrentTime(time);

			if (asset && asset.intro_start) {
				const skipIntroStart = asset.intro_start
					? parseTimeFormat(asset.intro_start)
					: 0;
				const skipIntroEnd = parseTimeFormat(asset.intro_end || '0');
				const showSkipIntro = time >= skipIntroStart && time < skipIntroEnd;

				setShowSkipIntro(showSkipIntro);
			}

			if (asset && asset.duration && asset.asset_type === AssetType.episode) {
				const creditsStart =
					asset?.credits_start_time && asset.credits_start_time !== '00:00:00'
						? parseTimeFormat(asset.credits_start_time)
						: asset.duration - 30;
				const creditEnd = asset.duration;
				const showNextEpisode = time >= creditsStart && time < creditEnd;
				setShowNextEpisode(showNextEpisode);
			}
		},
		[setCurrentTime, asset]
	);

	const onSeek = useCallback(() => {
		setSeekTime(null);
		playerInstance && setCurrentTime(playerInstance.getCurrentTime());
	}, [setSeekTime]);

	const onLoad = useCallback(() => {
		setCurrentTime(0);

		const duration = playerInstance?.getDuration();
		setDuration(duration);

		const subtitles = playerInstance?.subtitles.list();
		const subtitlesMapping: Subtitles =
			subtitles?.map((sub: SubtitleTrack) => {
				return {
					id: sub.id,
					title: sub.label,
					language: sub.lang,
					type: 'subtitle',
				};
			}) || [];

		setSubtitles(subtitlesMapping);

		// Set subtile
		const langs = RNLocalize.getLocales();
		Storage.getItem(preferredSubtitleLanguageStorageKey).then((lang) => {
			const foundSub =
				subtitlesMapping.find((s) => s.language === lang) ||
				subtitlesMapping.find(
					(s) => s.language === langs[0].countryCode.toLowerCase()
				);
			foundSub &&
				foundSub.language &&
				setSelectedSubtitle(foundSub.language.toLowerCase());
		});

		// Audio tracks
		const audioTracksPlayer = playerInstance?.getAvailableAudio();

		const audioTrackMapping: AudioTrack[] =
			audioTracksPlayer?.map((track: BMPlayerAudioTrack, index) => {
				return {
					identifier: track.id,
					label: track.label,
					language: track.lang,
					isDefault: index === 0,
				};
			}) || [];

		setAudioTracks(audioTrackMapping);

		if (watchDuration && playerType === PlayerType.Video && !startOver) {
			if (
				duration &&
				watchDuration > 0 &&
				(watchDuration / duration) * 100 >= 99
			) {
				playerInstance?.seek(watchDuration - 60);
			} else {
				playerInstance?.seek(watchDuration);
			}
		} else if (playerType === PlayerType.Video) {
			playerInstance?.seek(0);
			setStartOver(false);
		}

		setTimeout(() => {
			setLoaded(true);
		}, 1000);

		const userInterActionEvent = new UserInterActionEvent();
		userInterActionEvent.setFeature(USER_INTERACTION_FEATURES.PLAYER);
		userInterActionEvent.setContext(USER_INTERACTION_CONTEXT.PLAYER);
		userInterActionEvent.setType(USER_INTERACTION_TYPE.SUCCESS);
		userInterActionEvent.setName(`player_load`);

		ClientTracker.sendUserInterActionEvent(userInterActionEvent);

		setPlaying(true);
	}, [
		playerType,
		setCurrentTime,
		setDuration,
		setPlaying,
		setSelectedSubtitle,
		setStartOver,
		setSubtitles,
		startOver,
		watchDuration,
	]);

	const initializePlayer = useCallback(() => {
		if (!playerRef.current) return null;
		setLoaded(false);

		if (playerInstance) {
			playerInstance.destroy();
			setPlayerWeb(null);

			if (uiInstance) {
				uiInstance.release();
			}
		}

		playerInstance = new Player(playerRef.current, {
			...DEFAULT_CONFIGURATION,
			...(isWebos() && {
				analytics: {
					key: config.bitmovinAnalyticsLicenseKey,
					videoId: asset?.id,
					customData1: user?.id,
					customData2: ClientTracker._devicePlatform,
				},
			}),
		});

		if (playerInstance === null) return;

		setPlayerWeb(playerInstance);

		playerInstance.on(PlayerEvent.SourceLoaded, onLoad);
		playerInstance.on(PlayerEvent.PlaybackFinished, handlePlaybackFinished);
		playerInstance.on(PlayerEvent.TimeChanged, onProgress);
		playerInstance.on(PlayerEvent.Seeked, onSeek);

		const subtitleUI = new UIContainer({
			components: [new SubtitleOverlay()],
		});

		// Launch the UI
		uiInstance = new UIManager(playerInstance, subtitleUI);

		void playerInstance.load({
			dash: currentUrl,
			poster: `${config.resourceUrl}/${asset?.id}/${
				asset?.image.clean_cover ? 'clean_' : ''
			}cover/1280x720/${asset?.image.clean_cover || asset?.image.cover}.jpeg`,
			drm: video.drmToken
				? {
						// playready: {
						// 	LA_URL: `${axinomPlayreadyUrl}/AcquireLicense`,
						// 	utf8message: true,
						// 	plaintextChallenge: true,
						// 	headers: {
						// 		'X-AxDRM-Message': video.drmToken,
						// 		'Content-Type': 'text/xml',
						// 	},
						// },
						widevine: {
							LA_URL: `${axinomWidevineURl}/AcquireLicense`,
							headers: {
								'X-AxDRM-Message': video.drmToken,
							},
						},
				  }
				: {},
		});

		playerInstance.on(PlayerEvent.Error, (event) => {
			const userInterActionEvent = new UserInterActionEvent();
			userInterActionEvent.setFeature(USER_INTERACTION_FEATURES.PLAYER);
			userInterActionEvent.setContext(USER_INTERACTION_CONTEXT.PLAYER);
			userInterActionEvent.setType(USER_INTERACTION_TYPE.ERROR);
			userInterActionEvent.setName(`player_error`);
			userInterActionEvent.setBody({
				error: JSON.stringify(event),
			});

			ClientTracker.sendUserInterActionEvent(userInterActionEvent);

			Sentry.captureMessage(`${JSON.stringify(event)}`);

			alert(JSON.stringify(event));
		});
	}, [
		asset?.id,
		asset?.image.clean_cover,
		asset?.image.cover,
		currentUrl,
		handlePlaybackFinished,
		onLoad,
		onProgress,
		playerType,
		setPlayerWeb,
		video.drmToken,
	]);

	useEffect(() => {
		if (currentUrl && prevCurrentUrl !== currentUrl) {
			setPrevCurrentUrl(currentUrl);
			initializePlayer();
		}
	}, [currentUrl, initializePlayer, prevCurrentUrl]);

	useEffect(() => {
		if (playerType === PlayerType.Video) {
			setNoSubscription(video.subscriptionNotFound && !showDetails);
		} else {
			setNoSubscription(false);
		}
	}, [playerType, showDetails, video.subscriptionNotFound]);

	useKeyHandler(
		[
			DIRECTION_UP,
			DIRECTION_RIGHT,
			DIRECTION_DOWN,
			DIRECTION_LEFT,
			KEY_ENTER,
			KEY_PLAYPAUSE,
			MEDIA_PLAYPAUSE,
			MEDIA_REWIND,
			MEDIA_FASTFORWARD,
			MEDIA_PLAY,
			MEDIA_PAUSE,
			MEDIA_STOP,
			MEDIA_TRACK_PREVIOUS,
			MEDIA_TRACK_NEXT,
		],
		(key) => {
			if (showDetails) return true;

			if (timer.current) {
				clearTimeout(timer.current);
			}

			if (currentTime < 1) return;

			if (
				key === KEY_ENTER &&
				playing &&
				!showControls &&
				showSkipIntro &&
				showNextEpisode
			) {
				return;
			}

			// trigger fastforward/rewind when controls are hidden
			if (
				(key === MEDIA_FASTFORWARD || key === MEDIA_REWIND) &&
				!showControls
			) {
				if (duration) {
					const seekStap = playerType === PlayerType.Video ? 30 : 5;
					let newTime = 0;

					if (key === MEDIA_FASTFORWARD && currentTime + seekStap < duration) {
						newTime = currentTime + seekStap;
					}
					if (key === MEDIA_REWIND && currentTime - seekStap > 0) {
						newTime = currentTime - seekStap;
					}

					if (newTime > 0) setSeekTime(newTime);
				}
				setTimeout(() => {
					CoreManager.focusElementByFocusKey('progress-indicator');
				}, 100);
			}

			setShowControls(true);

			if (key === MEDIA_PLAYPAUSE) {
				playpause();
			}

			if (key === MEDIA_PLAY) {
				setShowControls(false);
				setPlaying(true);
			}

			if (key === MEDIA_PAUSE) {
				setPlaying(false);
			}

			if (key === MEDIA_STOP) {
				setShowControls(false);
				setShowDetails(true);
				setPlaying(false);
			}

			if (key === KEY_ENTER && playing && !showControls) {
				playpause();
			}

			timer.current = setTimeout(() => {
				CoreManager.focusElementByFocusKey('play-button');
				setShowControls(false);
			}, 5000) as unknown as number;
		}
	);

	const setupCurrentUrl = useCallback(() => {
		setCurrentUrl('');
		if (asset) {
			clearPlayerState();

			if (playerType === PlayerType.Video) {
				video.url && setCurrentUrl(video.url);
			} else {
				trailer.url && setCurrentUrl(trailer.url);
			}
		}
	}, [asset, trailer.url, video.url, playerType]);
	useEffect(() => {
		setupCurrentUrl();
	}, [setupCurrentUrl]);

	// Player Effects
	//  Set Playing
	useEffect(() => {
		if (playing && loaded) {
			playerInstance?.play();
		} else {
			playerInstance?.pause();
		}
	}, [playing, loaded]);

	//  Set subtile
	useEffect(() => {
		if (selectedSubtitle && playerInstance) {
			const subtitles = playerInstance?.subtitles?.list();

			if (subtitles && loaded) {
				playerInstance?.subtitles.enable(
					subtitles.find((s) => s.lang === selectedSubtitle)?.id || ''
				);
			}
		}
	}, [loaded, playing, selectedSubtitle, showPlayer]);

	// Set Audio Track
	useEffect(() => {
		if (selectedAudioTrack && playerInstance) {
			const audioTrackList = playerInstance.getAvailableAudio();

			if (audioTrackList && loaded) {
				playerInstance.setAudio(
					audioTrackList.find((s) => s.lang === selectedAudioTrack)?.id || ''
				);
			}
		}
	}, [selectedAudioTrack, playerInstance]);

	useEffect(() => {
		return () => {
			if (playerInstance) {
				playerInstance.destroy();
				playerInstance = null;
				setLoaded(false);
			}
		};
	}, []);

	if (noSubscription) {
		return <NoActiveSubscription />;
	}

	return (
		<>
			<Helmet>
				<link
					rel="stylesheet"
					href="https://cdn.dutchchannels.com/ctv/playerWeb.css"
				/>
			</Helmet>

			<div
				ref={playerRef}
				style={styles.videoPlayer}
			/>
			{loaded && !showDetails && (
				<View style={styles.videoPlayer}>
					<PlayerControls showControls={showControls} />
				</View>
			)}
			<AppLoader show={!loaded} />
		</>
	);
};

const styles = StyleSheet.create({
	videoPlayer: {
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
	},

	logoContainer: {
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
	},
	logo: {
		height: getScaledValue(62),
		width: getScaledValue(110),
	},

	indicator: {
		marginTop: getScaledValue(8),
	},
});

export default BitmovinPlayer;
