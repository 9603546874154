import RecyclableList, {
	RecyclableListDataProvider,
	RecyclableListLayoutProvider,
} from '@flexn/sdk/lib/components/RecyclableList';
import View from '@flexn/sdk/lib/components/View';
import {
	Context,
	RecyclableListFocusOptions,
} from '@flexn/sdk/lib/focusManager/types';
import React, { useEffect, useRef, useState } from 'react';
import { Dimensions, StyleProp, TextStyle, ViewStyle } from 'react-native';

import { ShelfAsset as ShelfAssetModel } from '../../models/Asset';
import { Shelf as ShelfModel } from '../../models/Shelf';
import ContinueWatchingShelf from '../shelf/continue-watching-shelf';
import FavoritesShelf from '../shelf/favorites-shelf';
import Shelf from '../shelf/shelf-flexn';
import {
	TILE_HEIGHT,
	TILE_LANDSCAPE_HEIGHT,
	TILE_LANDSCAPE_WIDTH,
	TILE_WIDTH,
} from '../tile/tileBase';
import { getResponsiveScaling } from '../../utils/scale';

interface ListProps {
	parentContext?: Context;
	focusOptions?: RecyclableListFocusOptions;
	animatorOptions?: any;
	itemsInViewport?: number;
	style?: StyleProp<ViewStyle>;
	cardStyle?: StyleProp<ViewStyle> | StyleProp<TextStyle>;
	titleStyle?: StyleProp<TextStyle>;
	onFocus?(data: any): void;
	onBlur?(data: any): void;
	onPress?(data: any): void;
	renderCard?(
		data: any,
		_repeatContext: any,
		dimensions: any,
		_renderProps: any
	): JSX.Element | JSX.Element[] | null;
	items: ShelfModel<ShelfAssetModel>[];
	itemDimensions?: { height: number };
	itemSpacing?: number;
	verticalItemSpacing?: number;
	horizontalItemSpacing?: number;
	initialXOffset?: number;
	rowHeight?: number;
	rerenderData?: any;
	disableItemContainer?: boolean;
	showDetail?: boolean;
	showProgressBar?: boolean;
}

const ShelfList = ({
	parentContext,
	items,
	itemsInViewport = 6,
	style = {},
	cardStyle = {},
	titleStyle = {},
	rerenderData,
	focusOptions,
	animatorOptions,
	itemSpacing = 20,
	verticalItemSpacing = 0,
	horizontalItemSpacing = 0,
	itemDimensions,
	onPress,
	onFocus,
	onBlur,
	renderCard,
	initialXOffset = 0,
	rowHeight,
	disableItemContainer = false,
	showDetail = false,
}: ListProps) => {
	const ref: any = useRef();
	const layoutProvider: any = useRef();
	const [rowRendererData, setRowRendererData] = useState();
	const dataProviderInstance = useRef(
		new RecyclableListDataProvider((r1, r2) => r1 !== r2)
	).current;
	const [dataProvider, setDataProvider] = useState(
		dataProviderInstance.cloneWithRows(items)
	);
	const showDetailHeight = showDetail ? 10 : 0;

	useEffect(() => {
		setLayoutProvider();
		setDataProvider(dataProviderInstance.cloneWithRows(items));
		setRowRendererData(rerenderData);
	}, [dataProviderInstance, JSON.stringify(items), rerenderData]);

	const setLayoutProvider = () => {
		layoutProvider.current = new RecyclableListLayoutProvider(
			// @ts-ignore
			// @note: support for custom types in Flexn is not supported
			(index) => items[index],
			(shelf: ShelfModel, dim: { width: number; height: number }) => {
				dim.width = Dimensions.get('screen').width;
				dim.height =
					getResponsiveScaling(
						(shelf?.isHorizontal ? TILE_LANDSCAPE_HEIGHT : TILE_HEIGHT) + 5
					).width +
					getResponsiveScaling(shelf?.isHorizontal ? showDetailHeight : 0)
						.width;
			}
		);
	};

	const renderRow = ({ index, data, repeatContext }: any) => {
		let ShelfComponent;

		switch (data.type) {
			case 'continueWatching':
				ShelfComponent = ContinueWatchingShelf;
				break;

			case 'favorites':
				ShelfComponent = FavoritesShelf;
				break;

			default:
				ShelfComponent = Shelf;
				break;
		}

		return (
			<ShelfComponent
				key={index}
				shelf={data}
				items={[...data.items, { type: 'footer' }]}
				title={data.title}
				onPress={onPress}
				onFocus={onFocus}
				onBlur={onBlur}
				renderCard={renderCard}
				repeatContext={repeatContext}
				style={{
					width: Dimensions.get('screen').width,
					height: rowHeight,
				}}
				cardStyle={cardStyle}
				titleStyle={titleStyle}
				itemDimensions={{
					height: data.isHorizontal
						? getResponsiveScaling(TILE_LANDSCAPE_HEIGHT).width
						: getResponsiveScaling(TILE_HEIGHT).width,
					width: data.isHorizontal
						? getResponsiveScaling(TILE_LANDSCAPE_WIDTH).width
						: getResponsiveScaling(TILE_WIDTH).width,
				}}
				itemSpacing={data.isHorizontal ? 30 : 20}
				initialXOffset={initialXOffset}
				animatorOptions={animatorOptions}
				disableItemContainer={disableItemContainer}
				// TODO: This should be not needed eventually
				focusOptions={{
					nextFocusLeft: focusOptions?.nextFocusLeft,
					nextFocusRight: focusOptions?.nextFocusRight,
				}}
				rerenderData={rowRendererData}
				showDetail={showDetail}
				isTopShelf={data.isTopShelf}
			/>
		);
	};

	const renderRecycler = () => {
		return (
			<RecyclableList
				renderAheadOffset={
					getResponsiveScaling(TILE_LANDSCAPE_HEIGHT).width * 2
				}
				parentContext={parentContext}
				type="list"
				isHorizontal={false}
				scrollViewProps={{
					showsVerticalScrollIndicator: false,
				}}
				style={{
					height: Dimensions.get('screen').height,
				}}
				contentContainerStyle={{
					paddingTop: getResponsiveScaling(showDetail ? 2 : 0).height,
					paddingBottom: getResponsiveScaling(TILE_HEIGHT * 4).height,
				}}
				dataProvider={dataProvider}
				layoutProvider={layoutProvider.current}
				rowRenderer={(
					_type: string | number,
					rowData: any,
					index: number,
					repeatContext: any
				) => {
					return renderRow({
						index,
						data: rowData,
						title: rowData.rowTitle,
						nestedParentContext: repeatContext?.parentContext,
						repeatContext: repeatContext,
					});
				}}
				focusOptions={focusOptions}
				renderFooter={() => (
					<View
						style={{
							height: getResponsiveScaling(
								TILE_HEIGHT +
									(items[items.length - 2]?.isHorizontal
										? TILE_HEIGHT * 1.2
										: TILE_HEIGHT)
							).width,
						}}
					/>
				)}
			/>
		);
	};

	return (
		<View
			testID="shelf-list"
			ref={ref}
			parentContext={parentContext}
			style={{
				height: Dimensions.get('screen').height,
			}}
		>
			{renderRecycler()}
		</View>
	);
};

export default ShelfList;
