import React from 'react';
import { View, Image, StyleSheet } from 'react-native';
import config from '../../../platformAssets/runtime/config';
import logo from '../../../platformAssets/runtime/icons/logo.png';
import { useI18n } from '../../hooks/useI18n';
import Title from '../title';
import { getScaledValue } from '../../utils/scale';

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
	},

	logo: {
		height: getScaledValue(62),
		width: getScaledValue(110),
		marginBottom: getScaledValue(24),
	},
});

const CountryError = () => {
	const { country } = useI18n();

	return (
		<View style={styles.container}>
			<Image
				source={logo}
				resizeMode={'contain'}
				style={styles.logo}
			/>
			<Title style={{ fontSize: getScaledValue(18) }}>
				Unfortunately, {config.platformName} is not available in {country}
			</Title>
		</View>
	);
};

export default CountryError;
