import { View } from '@flexn/sdk';
import useStyleFlattener from '@flexn/sdk/lib/hooks/useStyleFlattener';
import { isWebos } from '@rnv/renative';
import React, { useRef, useState } from 'react';
import { Text } from 'react-native';

import isNative from '../../utils/isNative';
import { getScaledValue, getResponsiveScaling } from '../../utils/scale';
import Pressable from '../pressable';

import { IMAGE_WIDTH, MemoizedDetails, MemoizedTileContent } from './tileBase';
import fonts from '../../../platformAssets/runtime/fontnames';
import colors from '../../../platformAssets/runtime/colors';

export const NUMBER_WIDTH = 51;

const TileFlexn = React.forwardRef<any, any>(
	(
		{
			src,
			title = '',
			resizeMode = 'cover',
			style = {},
			onFocus,
			onPress,
			onBlur,
			parentContext,
			repeatContext,
			focusOptions,
			renderProps,
			asset,
			renderImage = true,
			showDetail = false,
			number,
			testID = null,
			showProgressBar = false,
		},
		ref
	) => {
		const styles = useStyleFlattener(style, ['width', 'height']);
		const [softFocus, setSoftFocus] = useState(false);

		const posterStyles = {
			borderRadius: styles.borderWidth ? 0 : styles.borderRadius,
		};

		const borderProps = {
			borderWidth: styles.borderWidth,
			borderLeftWidth: styles.borderLeftWidth,
			borderRightWidth: styles.borderRightWidth,
			borderTopWidth: styles.borderTopWidth,
			borderBottomWidth: styles.borderBottomWidth,
			borderStartWidth: styles.borderStartWidth,
			borderEndWidth: styles.borderEndWidth,
			borderColor: styles.borderColor,
		};

		const containerStyle = [
			baseStyles.card,
			styles,
			borderProps,
			{
				marginLeft: number
					? getScaledValue(number >= 10 ? NUMBER_WIDTH * 2 : NUMBER_WIDTH)
					: 0,
			},
		];

		delete styles.borderWidth;
		delete styles.borderLeftWidth;
		delete styles.borderRightWidth;
		delete styles.borderTopWidth;
		delete styles.borderBottomWidth;
		delete styles.borderStartWidth;
		delete styles.borderEndWidth;
		delete styles.borderColor;
		delete styles.borderRadius;

		const [showTrailer, setShowTrailer] = useState(false);
		const timer = useRef<number | undefined>();

		const handleFocus = () => {
			if (timer.current) {
				clearTimeout(timer.current);
			}

			if (isNative) {
				timer.current = setTimeout(() => {
					setShowTrailer(true);
				}, 2000) as unknown as number;
			}

			setSoftFocus(true);

			onFocus();
		};

		const handleBlur = () => {
			if (timer.current) {
				clearTimeout(timer.current);
			}

			setShowTrailer(false);
			setSoftFocus(false);

			onBlur();
		};

		return (
			<>
				<Pressable
					testID={testID}
					ref={ref}
					style={
						isWebos()
							? [
									containerStyle,
									softFocus &&
										!isNative && {
											borderColor: '#FFFFFF',
											borderWidth: getScaledValue(2),
										},
							  ]
							: containerStyle
					}
					parentContext={parentContext}
					repeatContext={repeatContext}
					onFocus={handleFocus}
					onBlur={handleBlur}
					onPress={onPress}
					focusOptions={focusOptions}
				>
					<MemoizedTileContent
						asset={asset}
						renderImage={renderImage}
						renderTrailer={showTrailer}
						showProgressBar={showProgressBar}
					/>
				</Pressable>
				{number && (
					<View
						style={{
							position: 'absolute',
							bottom: getResponsiveScaling(13).width,
						}}
					>
						<Text
							style={{
								color: colors.primary,
								position: 'absolute',
								fontSize: getResponsiveScaling(IMAGE_WIDTH - 1).width,
								fontFamily: fonts.secondaryBold,
								letterSpacing: getScaledValue(number >= 10 ? -13 : 0),
							}}
						>
							{number}
						</Text>
						<Text
							style={{
								color: '#FFFFFF',
								position: 'absolute',
								left: getScaledValue(5),
								top: getScaledValue(5),
								fontSize: getResponsiveScaling(IMAGE_WIDTH - 1).width,
								fontFamily: fonts.secondaryBold,
								letterSpacing: getScaledValue(number >= 10 ? -13 : 0),
							}}
						>
							{number}
						</Text>
					</View>
				)}
				{showDetail && <MemoizedDetails asset={asset} />}
			</>
		);
	}
);

const baseStyles = {
	card: {
		borderWidth: 5,
		borderRadius: getScaledValue(12),
		borderColor: '#FFFFFF',
		overflow: 'hidden',
	},
	poster: {},
};

TileFlexn.displayName = 'Card';

export default TileFlexn;
