import {
	RecyclableList,
	RecyclableListDataProvider,
	RecyclableListLayoutProvider,
} from '@flexn/sdk';
import { useEffect, useRef, useState } from 'react';
import { StyleSheet, Image, Dimensions } from 'react-native';
import { AudioTrack } from 'bitmovin-player-react-native';
import { usePlayer } from '../../hooks/usePlayer';
import * as RNLocalize from 'react-native-localize';
import Button from '../button/button-flex';
import { Storage } from '../../utils/storage';
import { useI18n } from '../../hooks/useI18n';
import { getResponsiveScaling, getScaledValue } from '../../utils/scale';
import config from '../../../platformAssets/runtime/config';
import isNative from '../../utils/isNative';
import audioIcon from '../../../platformAssets/runtime/icon/audio.png';
import {
	USER_INTERACTION_CONTEXT,
	USER_INTERACTION_FEATURES,
	USER_INTERACTION_TYPE,
} from '../../services/event-tracker/UserInterActionEvent';
import { preferredAudioLanguageStorageKey } from '../../utils/storageKeys';

const AudioTrackList = ({ parentContext }) => {
	const {
		audioTracks,
		selectedAudioTrack,
		setSelectedAudioTrack,
		setShowSettingsDialog,
	} = usePlayer();

	const { translate } = useI18n();

	const layoutProvider: any = useRef();

	const dataProviderInstance = useRef(
		new RecyclableListDataProvider((r1, r2) => r1 !== r2)
	).current;

	const [dataProvider, setDataProvider] = useState(
		dataProviderInstance.cloneWithRows([audioTracks])
	);

	const setLayoutProvider = () => {
		layoutProvider.current = new RecyclableListLayoutProvider(
			// @ts-ignore
			// @note: support for custom types in Flexn is not supported
			(index) => index,
			(item, dim: { width: number; height: number }) => {
				dim.width = Dimensions.get('screen').width;
				dim.height = getResponsiveScaling(11).height;
			}
		);
	};

	const selectAudioTrack = (audioTrack: AudioTrack) => {
		setSelectedAudioTrack(audioTrack.language || '');
		Storage.setItem(
			preferredAudioLanguageStorageKey,
			audioTrack.language || ''
		);
		setShowSettingsDialog(false);
	};

	const getAudioTrackTitleForLanguage = (audioTrack: AudioTrack) => {
		const language = audioTrack?.language;

		const fallback = audioTrack?.label || audioTrack?.language;

		const lokaliseValue = translate(`subtitle.${language}`);

		return lokaliseValue !== `subtitle.${language}` ? lokaliseValue : fallback;
	};

	useEffect(() => {
		const langs = RNLocalize.getLocales();

		setLayoutProvider();

		if (audioTracks) {
			setDataProvider(
				dataProviderInstance.cloneWithRows([
					...audioTracks
						.filter((s) => s.language || s.lang)
						.sort((a: AudioTrack, b: AudioTrack) => {
							if (a.language === langs[0].countryCode.toLowerCase()) {
								return -1; // own language comes first
							} else if (a.language === 'en' && b.language !== 'nl') {
								return -1; // 'english' comes second
							} else {
								return a?.language?.localeCompare(b?.language);
							}
						}),
				])
			);
		}
	}, [dataProviderInstance, audioTracks]);

	return (
		<RecyclableList
			parentContext={parentContext}
			renderAheadOffset={10}
			type="list"
			isHorizontal={false}
			scrollViewProps={{
				showsVerticalScrollIndicator: false,
			}}
			style={{
				height: Dimensions.get('screen').height * 0.6,
			}}
			dataProvider={dataProvider}
			layoutProvider={layoutProvider.current}
			rowRenderer={(
				_type: string | number,
				audioTracks: any,
				index: number,
				repeatContext: any
			) => {
				return (
					<Button
						onPress={() => selectAudioTrack(audioTracks)}
						style={styles.subtileButton}
						key={isNative ? audioTracks.index : audioTracks.id}
						label={getAudioTrackTitleForLanguage(audioTracks)}
						rightIcon={
							selectedAudioTrack === audioTracks.language ? (
								<Image
									source={audioIcon}
									style={styles.iconSmall}
								/>
							) : null
						}
						repeatContext={repeatContext}
						track={{
							feature: USER_INTERACTION_FEATURES.PLAYER,
							context: USER_INTERACTION_CONTEXT.PLAYER_CONTROLS,
							type: USER_INTERACTION_TYPE.BUTTON,
							name: `subtile_${
								audioTracks.language ||
								audioTracks.lang ||
								config.localisation.default.language
							}`,
						}}
						focusOptions={{
							...(index === audioTracks.length - 1
								? { forbiddenFocusDirections: ['down'] }
								: {}),
						}}
					/>
				);
			}}
		/>
	);
};

const styles = StyleSheet.create({
	iconSmall: {
		width: getScaledValue(15),
		height: getScaledValue(15),
	},

	subtileButton: {
		marginBottom: getScaledValue(10),
	},
});

export default AudioTrackList;
