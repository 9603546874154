export const DIRECTION_UP = 'up';
export const DIRECTION_DOWN = 'down';
export const DIRECTION_LEFT = 'left';
export const DIRECTION_RIGHT = 'right';
export const KEY_ENTER = 'enter';
export const KEY_BACK = 'back';
export const KEY_PLAYPAUSE = 'playpause';
export const DONE = 'done';
export const MEDIA_PLAYPAUSE = 'mediaplaypause';
export const MEDIA_REWIND = 'mediarewind';
export const MEDIA_FASTFORWARD = 'mediafastforward';
export const MEDIA_PLAY = 'mediaplay';
export const MEDIA_PAUSE = 'mediapause';
export const MEDIA_STOP = 'mediastop';
export const MEDIA_TRACK_PREVIOUS = 'mediatrackprevious';
export const MEDIA_TRACK_NEXT = 'mediatracknext';
export const INPUT_EXIT = 'XF86Back';

export const keymap = {
	'38': DIRECTION_UP,
	'40': DIRECTION_DOWN,
	'37': DIRECTION_LEFT,
	'39': DIRECTION_RIGHT,
	'13': KEY_ENTER,
	'10009': KEY_BACK,
	// '27': KEY_BACK,
	'65376': DONE,
	'10252': MEDIA_PLAYPAUSE,
	'412': MEDIA_REWIND,
	'417': MEDIA_FASTFORWARD,
	'415': MEDIA_PLAY,
	'19': MEDIA_PAUSE,
	'413': MEDIA_STOP,
	'10232': MEDIA_TRACK_PREVIOUS,
	'10233': MEDIA_TRACK_NEXT,
	'10182': INPUT_EXIT,
};
