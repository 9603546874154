import { Asset, Collection, Season } from './Asset';
import { AssetType } from './AssetType';

export type CollectionResponse = Collection & Pagination;

type Pagination = {
	total: number;
	page: number;
	page_size: number;
};

export function isCollection(asset: Asset): asset is Collection {
	return asset.asset_type === AssetType.collection;
}

export function isNotEmptyCollection(asset: Asset): asset is Collection {
	if (!isNotContinueWatching(asset) || !isNotFavorites(asset)) {
		return true;
	}

	return isCollection(asset) && asset.items.length > 0;
}

export function isNotCollectionOrSeason(
	asset: Asset
): asset is Exclude<Asset, Collection | Season> {
	return (
		asset.asset_type !== AssetType.collection &&
		asset.asset_type !== AssetType.season
	);
}

export function isJumbotron(asset: Asset): boolean {
	return asset.id.includes('jumbotron');
}

export function isNotJumbotron(asset: Asset): boolean {
	return !asset.id.includes('jumbotron');
}

export function isNotContinueWatching(asset: Asset): boolean {
	return asset?.extended?.custom?.type !== 'continueWatching';
}

export function isNotFavorites(asset: Asset): boolean {
	return asset?.extended?.custom?.type !== 'favorites';
}
