import { pad } from './pad';

export const parseSeconds = (s: number) => {
	const hours = Math.floor(s / 3600);
	const minutes = Math.floor(s / 60);
	return { hours, minutes };
};

export const convertSeconds = (s: number) => {
	const hours = Math.floor(s / 3600);
	const minutes = Math.floor((s % 3600) / 60);
	return { hours, minutes, seconds: Math.floor(s % 60) };
};

export const toTimeString = (time: {
	hours: number;
	minutes: number;
	seconds: number;
}) => {
	return `${time.hours ? time.hours + ':' : ''}${
		time.hours ? pad(time.minutes.toString(), 2, '0', true) : time.minutes
	}:${pad(time.seconds.toString(), 2, '0', true)}`;
};
