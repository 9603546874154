export default {
	primary: '#D80625',
	primaryText: '#FFFFFF',
	secondaryText: '#B5B5B5',
	muted: '#7D7A7A',
	menuBackground: 'transparent',
	overlay: '#000000',
	warning: '#D80625',
	inactive: '#8181813F',
	buttonFocus: '#FFFFFF33',
	textInputFocus: '#FFFFFF66',
	profileAvatars: [
		'#28394E',
		'#DF1121',
		'#7F22FD',
		'#00696B',
		'#2C008A',
		'#0A72B2',
		'#FF4D00',
		'#CC4B00',
		'#377A00',
		'#377A00',
		'#000000',
	],
};
