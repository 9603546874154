import { useEffect, useRef } from 'react';

import TVEvents from '../utils/TVEvents';


export function useKeyHandler(keys: string[], cb: (key: string) => void, delay: number = 300) {
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        const listener = TVEvents.addEventListener((eventType) => {
            if (keys.includes(eventType)) {
                // Clear previous timeout if another event happens within the delay period
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }

                // Set a new timeout to call the callback after the delay period
                timeoutRef.current = setTimeout(() => {
                    cb(eventType);
                }, delay);
            }
        });

        return listener.remove;
    }, [cb, keys, delay]);

    // Cleanup timeout on unmount
    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);
}
