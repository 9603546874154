import { Context } from '@flexn/sdk/lib/focusManager/types';
import React from 'react';
import {
	ImageBackground as RNImageBackground,
	ImageBackgroundProps,
} from 'react-native';

interface ImageBackgroundPropsExtended extends ImageBackgroundProps {
	parentContext?: Context;
	children?: React.ReactNode;
}

const ImageBackground = ({
	parentContext,
	children,
	source,
	...props
}: ImageBackgroundPropsExtended) => {
	const childrenWithProps = React.Children.map(children, (child) => {
		if (React.isValidElement(child)) {
			return React.cloneElement(
				child as React.ReactElement<ImageBackgroundPropsExtended>,
				{ parentContext }
			);
		}
		return child;
	});

	return (
		<RNImageBackground
			source={source}
			{...props}
		>
			{childrenWithProps}
		</RNImageBackground>
	);
};

ImageBackground.displayName = 'ImageBackground';

export default ImageBackground;
