import { TILE_HEIGHT, TILE_LANDSCAPE_HEIGHT } from '../tile/tileBase';

export const SHELF_LIST_BORDER_WIDTH = 0;
export const SHELF_TITLE_HEIGHT = 0; //28
export const SHELF_MARGIN_BOTTOM = 0; //12
export const SHELF_HEIGHT =
	SHELF_TITLE_HEIGHT +
	TILE_HEIGHT +
	SHELF_LIST_BORDER_WIDTH * 2 +
	SHELF_MARGIN_BOTTOM;

export const SHELF_LANDSCAPE_HEIGHT =
	SHELF_TITLE_HEIGHT +
	TILE_LANDSCAPE_HEIGHT +
	SHELF_LIST_BORDER_WIDTH * 4 +
	SHELF_MARGIN_BOTTOM;

export const INCENTIVE_BANNER_BORDER_WIDTH = 2;
export const INCENTIVE_BANNER_MARGIN_TOP = 8;
export const INCENTIVE_BANNER_MARGIN_BOTTOM = 12;
export const INCENTIVE_BANNER_TILE_HEIGHT = 180;
export const INCENTIVE_BANNER_HEIGHT =
	INCENTIVE_BANNER_MARGIN_TOP +
	INCENTIVE_BANNER_TILE_HEIGHT +
	INCENTIVE_BANNER_MARGIN_BOTTOM +
	INCENTIVE_BANNER_BORDER_WIDTH * 2;
