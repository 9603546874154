import { isTizen } from '@rnv/renative';
import { useEffect, useState } from 'react';

const checkOnlineStatus = async (callback) => {
	try {
		const res = await fetch(
			`https://connectivitycheck.gstatic.com/generate_204`,
			{
				method: 'GET',
			}
		);

		if (!res.ok) {
			callback(false);
		}
		callback(true);
	} catch (e) {
		callback(false);
	}
};

export function useNetworkConnection() {
	const [isConnected, setIsConnected] = useState(true);

	useEffect(() => {
		if (isTizen()) {
			setInterval(() => {
				checkOnlineStatus(setIsConnected);
			}, 2000);
		}
	}, []);

	return isConnected;
}
