import '@babel/polyfill';

import 'promise-polyfill/src/polyfill';
import 'unfetch/polyfill';
import 'abortcontroller-polyfill';

import 'resize-observer-polyfill/dist/ResizeObserver.global';
import 'url-search-params-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './src/app';
import config from './platformAssets/runtime/config';
import { View } from 'react-native';

if (!Object.fromEntries) {
	Object.fromEntries = function (entries) {
		if (!entries || !entries[Symbol.iterator]) {
			throw new Error(
				'Object.fromEntries() requires a single iterable argument'
			);
		}
		let obj = {};
		for (let [key, value] of entries) {
			obj[key] = value;
		}
		return obj;
	};
}

Object.defineProperty(Array.prototype, 'flat', {
	value: function (depth = 1) {
		return this.reduce(function (flat, toFlatten) {
			return flat.concat(
				Array.isArray(toFlatten) && depth > 1
					? toFlatten.flat(depth - 1)
					: toFlatten
			);
		}, []);
	},
});

if (!(ArrayBuffer.prototype.slice && ArrayBuffer.prototype.slice[0])) {
	//Returns a new ArrayBuffer whose contents are a copy of this ArrayBuffer's
	//bytes from `begin`, inclusive, up to `end`, exclusive
	ArrayBuffer.prototype.slice = function (begin, end) {
		//If `begin` is unspecified, Chrome assumes 0, so we do the same
		if (begin === void 0) {
			begin = 0;
		}

		//If `end` is unspecified, the new ArrayBuffer contains all
		//bytes from `begin` to the end of this ArrayBuffer.
		if (end === void 0) {
			end = this.byteLength;
		}

		//Chrome converts the values to integers via flooring
		begin = Math.floor(begin);
		end = Math.floor(end);

		//If either `begin` or `end` is negative, it refers to an
		//index from the end of the array, as opposed to from the beginning.
		if (begin < 0) {
			begin += this.byteLength;
		}
		if (end < 0) {
			end += this.byteLength;
		}

		//The range specified by the `begin` and `end` values is clamped to the
		//valid index range for the current array.
		begin = Math.min(Math.max(0, begin), this.byteLength);
		end = Math.min(Math.max(0, end), this.byteLength);

		//If the computed length of the new ArrayBuffer would be negative, it
		//is clamped to zero.
		if (end - begin <= 0) {
			return new ArrayBuffer(0);
		}

		var result = new ArrayBuffer(end - begin);
		var resultBytes = new Uint8Array(result);
		var sourceBytes = new Uint8Array(this, begin, end - begin);

		resultBytes.set(sourceBytes);

		return result;
	};
}

// Update Resource and Media URL for WebOS 3.0
var request = webOS?.service.request(
	'luna://com.webos.service.tv.systemproperty',
	{
		method: 'getSystemInfo',
		parameters: {
			keys: ['sdkVersion'],
		},
		onComplete: function (inResponse) {
			if (parseFloat(inResponse?.sdkVersion) < 4) {
				config.resourceUrl = config.resourceUrl.replace('https', 'http');
				config.mediaUrl = config.mediaUrl.replace('https', 'http');
			}
		},
	}
);

ReactDOM.render(React.createElement(App), document.getElementById('root'));
