import { Image, View } from '@flexn/sdk';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, Text } from 'react-native';
import { isWebos } from '@rnv/renative';

import colors from '../../platformAssets/runtime/colors';
import logo from '../../platformAssets/runtime/icons/logo.png';
import bottom_logo_shadow from '../../platformAssets/runtime/img/bottom_logo_shadow.png';
import config from '../../platformAssets/runtime/config';
import fonts from '../../platformAssets/runtime/fontnames';
import BackgroundPoster from '../components/background-poster';
import DeviceInfoTrigger from '../components/device-info-trigger';
import Screen from '../components/screen';
import { useRegistrationBackground } from '../hooks/api';
import { useI18n } from '../hooks/useI18n';
import { NavigatorParameterList } from '../navigators/main-screen-navigator';
import { useAuth } from '../providers/auth';
import { getScaledValue } from '../utils/scale';
import UserInterActionEvent, {
	USER_INTERACTION_CONTEXT,
	USER_INTERACTION_FEATURES,
	USER_INTERACTION_TYPE,
} from '../services/event-tracker/UserInterActionEvent';
import ClientTracker from '../services/event-tracker/ClientTracker';
import Title from '../components/title';
import { getPlatformName } from '../utils/getPlatform';

const styles = StyleSheet.create({
	title: {
		color: colors.primaryText,
		marginBottom: getScaledValue(8),
	},

	screenContainer: {
		flex: 1,
		padding: getScaledValue(26),
	},
	form: {
		width: '50%',
		flexDirection: 'column',
		justifyContent: 'center',
		margin: getScaledValue(16),
	},
	qrCodeContainer: {
		paddingLeft: getScaledValue(16),
		marginLeft: getScaledValue(16),
		flex: 1,
		justifyContent: 'center',
	},
	qrCodeTitle: {
		color: colors.primaryText,
		fontSize: getScaledValue(22),
		marginBottom: getScaledValue(16),
	},
	qrImage: {
		height: getScaledValue(100),
		width: getScaledValue(100),
		marginTop: getScaledValue(8),
		borderRadius: getScaledValue(4),
	},
	qrCodeLabel: {
		borderWidth: getScaledValue(1),
		borderColor: colors.primaryText,
		marginRight: getScaledValue(20),
		borderRadius: getScaledValue(4),
		paddingVertical: getScaledValue(4),
		paddingHorizontal: getScaledValue(8),
		height: getScaledValue(24),
		textAlign: 'center',
	},
	qrCodeNumiric: {
		justifyContent: 'center',
		height: getScaledValue(48),
		borderWidth: getScaledValue(1),
		borderColor: colors.primaryText,
		borderRadius: getScaledValue(4),
		marginTop: getScaledValue(16),
	},
	qrCodeLabelText: {
		color: colors.primaryText,
		fontSize: getScaledValue(10),
		fontFamily: fonts.secondaryBold,
	},
	qrcodeOptionContainer: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: getScaledValue(20),
	},
	qrcodeOptionTitle: {
		color: colors.secondaryText,
		fontSize: getScaledValue(16),
	},
	qrcodeOptionText: {
		color: colors.primaryText,
		fontSize: getScaledValue(26),
		fontFamily: fonts.secondarySemiBold,
	},
	logoContainer: {
		position: 'absolute',
		zIndex: 100,
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
		bottom: getScaledValue(16),
		right: getScaledValue(16),
	},
	logo: {
		margin: 0,
		width: getScaledValue(config.dimensions.logo.width),
		height: getScaledValue(config.dimensions.logo.height),
	},
	logoShadow: {
		zIndex: 99,
		bottom: 0,
		right: getScaledValue(-50),
		height: getScaledValue(260),
		width: getScaledValue(425),
		position: 'absolute',
		opacity: 0.9,
	},
	numiricText: {
		letterSpacing: getScaledValue(8),
		textAlign: 'center',
	},
});

type RegisterCodeScreenProps = NativeStackScreenProps<
	NavigatorParameterList,
	'RegisterCode'
>;
const REGISTER_CODE_CHECKER_TIMEOUT = 3000;

const RegisterCodeScreen: React.FC<RegisterCodeScreenProps> = ({
	navigation,
}) => {
	const { translate } = useI18n();

	const { loginCode, user } = useAuth();
	const [authorizationCode, setAuthorizationCode] = useState(0);
	const timer = useRef<number | undefined>();
	const { data: background } = useRegistrationBackground();

	const loginCodeChecker = useCallback(async () => {
		clearTimeout(timer.current);

		if (authorizationCode === 0 || user) return;

		try {
			await loginCode(authorizationCode);

			const userInterActionEvent = new UserInterActionEvent();
			userInterActionEvent.setFeature(USER_INTERACTION_FEATURES.REGISTER);
			userInterActionEvent.setContext(USER_INTERACTION_CONTEXT.REGISTER_QRCODE);
			userInterActionEvent.setType(USER_INTERACTION_TYPE.REQUEST);
			userInterActionEvent.setName(`/v2/user/logincode/activate`);

			ClientTracker.sendUserInterActionEvent(userInterActionEvent);

			navigation.navigate('SwitchProfile');
		} catch (error: any) {
			timer.current = setTimeout(() => {
				loginCodeChecker();
			}, REGISTER_CODE_CHECKER_TIMEOUT) as unknown as number;
		}
	}, [authorizationCode, loginCode, navigation, user]);

	useEffect(() => {
		setAuthorizationCode(Math.floor(100000 + Math.random() * 900000));
	}, []);

	useFocusEffect(
		useCallback(() => {
			loginCodeChecker();

			return () => {
				clearTimeout(timer.current);
			};
		}, [loginCodeChecker])
	);

	const isFocused = useIsFocused();

	return (
		<Screen stealFocus={true}>
			<BackgroundPoster poster={background}>
				<View style={styles.screenContainer}>
					<DeviceInfoTrigger />
					<View
						style={{
							display: 'flex',
							flexDirection: 'row',
							flex: 1,
						}}
					>
						<View style={styles.form}>
							<View style={styles.qrCodeContainer}>
								<Text style={styles.qrCodeTitle}>
									{translate('registerCode.title')}
								</Text>

								<View style={styles.qrcodeOptionContainer}>
									<View style={styles.qrCodeLabel}>
										<Text style={styles.qrCodeLabelText}>
											{translate('login.code.step1.label')}
										</Text>
									</View>
									<View>
										<Text style={styles.qrcodeOptionTitle}>
											{translate('registerCode.step1.title')}
										</Text>
										<Text style={styles.qrcodeOptionText}>
											{config.platformUrl + getPlatformName()}
										</Text>

										<Image
											source={{
												uri: `http://api.qrserver.com/v1/create-qr-code/?color=000000&bgcolor=FFFFFF&data=${encodeURIComponent(
													config.platformUrl +
														getPlatformName() +
														'?registrationCode=' +
														authorizationCode
												)}&qzone=1&margin=0&size=400x400&ecc=L`,
											}}
											style={[styles.qrImage]}
										/>
									</View>
								</View>

								<View style={styles.qrcodeOptionContainer}>
									<View style={styles.qrCodeLabel}>
										<Text style={styles.qrCodeLabelText}>
											{translate('login.code.step2.label')}
										</Text>
									</View>
									<View>
										<Text style={styles.qrcodeOptionTitle}>
											{translate('registerCode.step2.title')}
										</Text>
									</View>
								</View>

								<View style={styles.qrcodeOptionContainer}>
									<View style={styles.qrCodeLabel}>
										<Text style={styles.qrCodeLabelText}>
											{translate('login.code.step3.label')}
										</Text>
									</View>
									<View>
										<Text style={styles.qrcodeOptionTitle}>
											{translate('registerCode.step3.title')}
										</Text>

										<View style={[styles.qrCodeNumiric]}>
											<Text
												style={[styles.qrcodeOptionText, styles.numiricText]}
											>
												{authorizationCode}
											</Text>
										</View>
									</View>
								</View>
							</View>
						</View>
					</View>
				</View>
			</BackgroundPoster>
			{!isWebos() && (
				<Image
					source={bottom_logo_shadow}
					resizeMode={'contain'}
					style={styles.logoShadow}
				/>
			)}
			<View style={styles.logoContainer}>
				<Image
					style={styles.logo}
					source={logo}
					resizeMode="contain"
				/>
			</View>
		</Screen>
	);
};

export default RegisterCodeScreen;
