import { useEffect, useState } from 'react';
import { getDeviceId, getDeviceName } from 'react-native-device-info';
import useFetch from 'use-http';

import config from '../../platformAssets/runtime/config';
import { AssetDetails, EpisodeDetails, MovieDetails } from '../models/Details';
import { EntitlementProvider } from '../models/EntitlementProvider';
import { objectIdentifierMap } from '../models/ObjectIdentifier';
import { RequestType } from '../models/RequestType';
import { Storage } from '../utils/storage';
import streamType from '../utils/streamType';
import UserInterActionEvent, {
	USER_INTERACTION_CONTEXT,
	USER_INTERACTION_FEATURES,
	USER_INTERACTION_TYPE,
} from '../services/event-tracker/UserInterActionEvent';
import ClientTracker from '../services/event-tracker/ClientTracker';
import { useConfig } from './useConfig';
import { authTokenStorageKey } from '../utils/storageKeys';

enum StatusCodes {
	GEO_ERROR = 3803,
	MAX_DEVICES = 3817,
	SUBSCRIPTION_NOT_FOUND = 3804,
}

export function useVideo(
	asset: MovieDetails | EpisodeDetails | undefined,
	provider: EntitlementProvider
) {
	const { endpoints } = useConfig();
	const [request] = useFetch(endpoints?.['subscription-backend']);
	const [url, setUrl] = useState('');
	const [drmToken, setDrmToken] = useState('');
	const [maxStream, setMaxStream] = useState(false);
	const [subscriptionNotFound, setSubscriptionNotFound] = useState(false);

	useEffect(() => {
		setUrl('');
		setDrmToken('');
		setMaxStream(false);
		setSubscriptionNotFound(false);
	}, [asset]);

	useEffect(() => {
		if (!asset?.[objectIdentifierMap[provider]]?.[streamType]) {
			setUrl('');
			return;
		}

		let cancelled = false;

		const effect = async () => {
			const newUrl = asset?.[objectIdentifierMap[provider]]?.[
				streamType
			].replace('media', config.mediaUrl);

			if (!newUrl) return;

			if (provider === EntitlementProvider.trailer) {
				if (!cancelled) {
					setUrl(newUrl);
				}
				return;
			}

			const token = await Storage.getItem(authTokenStorageKey);
			if (provider === EntitlementProvider.internal && !token) return;

			if (cancelled) return;

			const getDrm = async () => {
				if (asset.video.is_drm) {
					try {
						const res = await request.post('/v3/entitlement', {
							asset_id: asset.id,
							entitlement_provider: provider,
							request_type: RequestType.drm,
							key_id: asset.video.drm_key_id,
							token,
							persistent: false,
							device_id: getDeviceId(),
							device_name: await getDeviceName(),
						});

						if (res?.drm) {
							return res.drm;
						} else if (res?.code === StatusCodes.MAX_DEVICES) {
							const userInterActionEvent = new UserInterActionEvent();
							userInterActionEvent.setFeature(USER_INTERACTION_FEATURES.PLAYER);
							userInterActionEvent.setContext(
								USER_INTERACTION_CONTEXT.ENTITLEMENT
							);
							userInterActionEvent.setType(USER_INTERACTION_TYPE.ERROR);
							userInterActionEvent.setName(`drm_max_devices`);

							ClientTracker.sendUserInterActionEvent(userInterActionEvent);

							setMaxStream(true);
						} else if (res?.code === StatusCodes.SUBSCRIPTION_NOT_FOUND) {
							const userInterActionEvent = new UserInterActionEvent();
							userInterActionEvent.setFeature(USER_INTERACTION_FEATURES.PLAYER);
							userInterActionEvent.setContext(
								USER_INTERACTION_CONTEXT.ENTITLEMENT
							);
							userInterActionEvent.setType(USER_INTERACTION_TYPE.ERROR);
							userInterActionEvent.setName(`drm_subscription_not_found`);

							ClientTracker.sendUserInterActionEvent(userInterActionEvent);

							setSubscriptionNotFound(true);
						}
						return null;
					} catch (err) {
						const userInterActionEvent = new UserInterActionEvent();
						userInterActionEvent.setFeature(USER_INTERACTION_FEATURES.PLAYER);
						userInterActionEvent.setContext(
							USER_INTERACTION_CONTEXT.ENTITLEMENT
						);
						userInterActionEvent.setType(USER_INTERACTION_TYPE.ERROR);
						userInterActionEvent.setName(`drm`);
						userInterActionEvent.setBody({
							error: JSON.stringify(err),
						});

						ClientTracker.sendUserInterActionEvent(userInterActionEvent);

						console.log(err);
					}
				}

				return null;
			};

			const drm = await getDrm();
			if (drm) {
				const userInterActionEvent = new UserInterActionEvent();
				userInterActionEvent.setFeature(USER_INTERACTION_FEATURES.PLAYER);
				userInterActionEvent.setContext(USER_INTERACTION_CONTEXT.ENTITLEMENT);
				userInterActionEvent.setType(USER_INTERACTION_TYPE.REQUEST);
				userInterActionEvent.setName(`drm`);

				ClientTracker.sendUserInterActionEvent(userInterActionEvent);

				setDrmToken(drm);
			}

			setUrl(newUrl);
		};

		effect().catch((err) => {
			const userInterActionEvent = new UserInterActionEvent();
			userInterActionEvent.setFeature(USER_INTERACTION_FEATURES.PLAYER);
			userInterActionEvent.setContext(USER_INTERACTION_CONTEXT.ENTITLEMENT);
			userInterActionEvent.setType(USER_INTERACTION_TYPE.ERROR);
			userInterActionEvent.setName(`player_drm`);
			userInterActionEvent.setBody({
				error: JSON.stringify(err),
			});

			ClientTracker.sendUserInterActionEvent(userInterActionEvent);

			console.log('useVideo', err);
		});

		return () => {
			cancelled = true;
		};
	}, [asset, provider, request]);

	return {
		url,
		drmToken,
		maxStream,
		subscriptionNotFound,
	};
}
