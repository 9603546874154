const isDevelopment = process.env.CONFIG_DEV;

const configDevelopment = {
	BitmovinPlayerLicenseKey: 'cb1b84a0-8bd9-4f46-acea-e1733f289d31',
	bitmovinAnalyticsLicenseKey: '2dd0ca6b-d3e3-4970-9a22-fb593049f9ae',
	fairplayCertificateUrl:
		'https://cdn.newfaithnetwork.com/license/fairplay/fairplay.cer',
	userServiceUrl: 'https://dc-nfn-ms-user.axprod.net',
	subscriptionServiceUrl: 'https://dc-nfn-ms-subscription.axprod.net',
	catalogServiceUrl: 'https://dc-nfn-cdn-catalog.newfaithnetwork.com',
	// catalogServiceUrl: 'https://dc-nfn-ml-catalog.axprod.net',
	mediaUrl: 'https://dc-nfn-cdn-media.newfaithnetwork.com',
	resourceUrl: 'https://dc-nfn-cdn-resources.newfaithnetwork.com',
	searchUrl: 'https://search.newfaithnetwork.com/es/nfn-movie,nfn-series',
	webappCdnUrl: 'https://cdn.newfaithnetwork.nl',
	facebookApiUrl: 'https://graph.facebook.com',
	facebookAppId: '1699379590106340',
	facebookClientToken: '2d8b53378254059b83f5741205383a1b',
	hermesApiUrl: 'https://api-tst.newfaithnetwork.com',
	sentryDNS: undefined,
	collections: {
		home: '0-8-home-',
		menu: '0-8-nfn-',
		noResults: '0-8-no-search-results-',
	},
	appId: 'nl.newfaithnetwork.app',
	appName: 'nfn',
	platformName: 'New Faith Network',
	dimensions: {
		logo: {
			width: 60,
			height: (372 / 396) * 60,
		},
	},
	hygraph: {
		hygraphUrl:
			'https://eu-central-1-shared-euc1-02.cdn.hygraph.com/content/clf0vb2mr2bvb01um6dtc799d/master',
		hygraphToken:
			'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2ODUxMDgwMTgsImF1ZCI6WyJodHRwczovL2FwaS1ldS1jZW50cmFsLTEtc2hhcmVkLWV1YzEtMDIuaHlncmFwaC5jb20vdjIvY2xmMHZiMm1yMmJ2YjAxdW02ZHRjNzk5ZC9tYXN0ZXIiLCJtYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiMjZmZGEyMTgtMGE1Yi00ZTQ2LTljY2UtZGM3NzM1NTJiMTQ0IiwianRpIjoiY2xpNGx0MHhmMmVsNjAxdDc2MDM2MmRxNCJ9.US3Ecr2Sai-ES2iJablNXIhrExDkR0rd8WI2MkPaY2EJMuT6nCr_emaFfmKHEoQRezqMorVbhh9zkuObkV_aC8tD9ibiE13Wr6pjSTqJeXZ-zXzVcASfVUyPFq4iJeZoALA0Q46J3zGsy1l9WVwJhmg4eHAzifzS77y5HMtdPwFgUIzXjx9XYfGRv2v-TQU7objTdY4UrB6lynW_bE3Mp0eETjpLoMKqTkKDJc--REFNKDGqoPnecTxjlzo1RVTmGET0RWT9NwsouaR9XvT5rLF6AJ0WN5kA3utkyj5gtSoVFSlGnsa1wjEocEMgXcIldqnEgMM5k5W_zri_5OVotfFQalsNxcvl_LaMQueJJY1a4xTUpSnt-mzujstVrCbbrkNEmwvpTQARqH-boLC92deiSqphZ4pFJohy5kcLXhlkK117GvUty3cOfY15tAVKkfJSRA-KRSzinviraiDnG90mXGIL77PqgJnr-qnWvniqWcQ08UBHTsIfvenLImEcc-i1ghTDOPHdVuLcvQBG74iTuwd9CvVPIq1oYvjJD7XiSRu2KihTjfvYBvpA5yqhr6tNVrHEXYcxLgDM3SKzRhNdWPyYgGBjpj_f0P7zn36rlHikEc5gBNbY7d0_Y1-v_lP2OuqodjjSGtNatKYM69qAdYsQXq9qmmPSOExsuEw',
		genreId: 'cli4ejlhjflsn0aw7fkfej7a9',
		registrationBackground: 'ctv-register-background-nfn',
		availableLocales: ['en', 'nl', 'nb', 'sv'],
	},
	search: {
		assetKeys: {
			movie: 'nfn-movie',
			series: 'nfn-series',
		},
	},
	localisation: {
		default: {
			language: 'en',
			countryCode: 'GB',
			languageTag: 'en-GB',
		},
		languageMap: {
			nl: 'Nederlands',
			en: 'English',
			sv: 'Svenska',
			nb: 'Norsk bokmål',
		},
	},
	cdnType: 'akamai',
	trackingUrl: 'https://dctracking-dev.dutchchannels.com',
	analyticsChannel: 'E2D4D4FA738754EEBD7430C858B93D4D',
	qrCodePage: 'https://newfaithnetwork.com/tv',
	platformUrl: 'https://newfaithnetwork.com/',
	mux: {
		env: 'tj1eikr0vcivc8ur07ep74r6d',
	},
	dev: {
		email: process.env.USER_EMAIL_NFN_DEV,
		password: process.env.USER_PASSWORD_NFN_DEV,
	},
};

const configProduction = {
	BitmovinPlayerLicenseKey: 'cb1b84a0-8bd9-4f46-acea-e1733f289d31',
	bitmovinAnalyticsLicenseKey: '2dd0ca6b-d3e3-4970-9a22-fb593049f9ae',
	fairplayCertificateUrl:
		'https://cdn.newfaithnetwork.com/license/fairplay/fairplay.cer',
	userServiceUrl: 'https://dc-nfn-ms-user.axprod.net',
	subscriptionServiceUrl: 'https://dc-nfn-ms-subscription.axprod.net',
	catalogServiceUrl: 'https://dc-nfn-cdn-catalog.newfaithnetwork.com',
	// catalogServiceUrl: 'https://dc-nfn-ml-catalog.axprod.net',
	mediaUrl: 'https://dc-nfn-cdn-media.newfaithnetwork.com',
	resourceUrl: 'https://dc-nfn-cdn-resources.newfaithnetwork.com',
	searchUrl: 'https://search.newfaithnetwork.com/es/nfn-movie,nfn-series',
	webappCdnUrl: 'https://cdn.newfaithnetwork.nl',
	facebookApiUrl: 'https://graph.facebook.com',
	facebookAppId: '1699379590106340',
	facebookClientToken: '2d8b53378254059b83f5741205383a1b',
	hermesApiUrl: 'https://api.newfaithnetwork.com',
	sentryDNS: !isDevelopment
		? 'https://b943f52f325942b5bfb5522b6f047e2f@o201346.ingest.sentry.io/6398984'
		: undefined,
	collections: {
		home: '0-8-home-',
		menu: '0-8-nfn-',
		noResults: '0-8-no-search-results-',
	},
	appId: 'nl.newfaithnetwork.app',
	appName: 'nfn',
	platformName: 'New Faith Network',
	dimensions: {
		logo: {
			width: 60,
			height: (372 / 396) * 60,
		},
	},
	hygraph: {
		hygraphUrl:
			'https://eu-central-1-shared-euc1-02.cdn.hygraph.com/content/clf0vb2mr2bvb01um6dtc799d/master',
		hygraphToken:
			'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2ODUxMDgwMTgsImF1ZCI6WyJodHRwczovL2FwaS1ldS1jZW50cmFsLTEtc2hhcmVkLWV1YzEtMDIuaHlncmFwaC5jb20vdjIvY2xmMHZiMm1yMmJ2YjAxdW02ZHRjNzk5ZC9tYXN0ZXIiLCJtYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiMjZmZGEyMTgtMGE1Yi00ZTQ2LTljY2UtZGM3NzM1NTJiMTQ0IiwianRpIjoiY2xpNGx0MHhmMmVsNjAxdDc2MDM2MmRxNCJ9.US3Ecr2Sai-ES2iJablNXIhrExDkR0rd8WI2MkPaY2EJMuT6nCr_emaFfmKHEoQRezqMorVbhh9zkuObkV_aC8tD9ibiE13Wr6pjSTqJeXZ-zXzVcASfVUyPFq4iJeZoALA0Q46J3zGsy1l9WVwJhmg4eHAzifzS77y5HMtdPwFgUIzXjx9XYfGRv2v-TQU7objTdY4UrB6lynW_bE3Mp0eETjpLoMKqTkKDJc--REFNKDGqoPnecTxjlzo1RVTmGET0RWT9NwsouaR9XvT5rLF6AJ0WN5kA3utkyj5gtSoVFSlGnsa1wjEocEMgXcIldqnEgMM5k5W_zri_5OVotfFQalsNxcvl_LaMQueJJY1a4xTUpSnt-mzujstVrCbbrkNEmwvpTQARqH-boLC92deiSqphZ4pFJohy5kcLXhlkK117GvUty3cOfY15tAVKkfJSRA-KRSzinviraiDnG90mXGIL77PqgJnr-qnWvniqWcQ08UBHTsIfvenLImEcc-i1ghTDOPHdVuLcvQBG74iTuwd9CvVPIq1oYvjJD7XiSRu2KihTjfvYBvpA5yqhr6tNVrHEXYcxLgDM3SKzRhNdWPyYgGBjpj_f0P7zn36rlHikEc5gBNbY7d0_Y1-v_lP2OuqodjjSGtNatKYM69qAdYsQXq9qmmPSOExsuEw',
		genreId: 'cli4ejlhjflsn0aw7fkfej7a9',
		registrationBackground: 'ctv-register-background-nfn',
		availableLocales: ['en', 'nl', 'nb', 'sv'],
	},
	search: {
		assetKeys: {
			movie: 'nfn-movie',
			series: 'nfn-series',
		},
	},
	localisation: {
		default: {
			language: 'en',
			countryCode: 'GB',
			languageTag: 'en-GB',
		},
		languageMap: {
			nl: 'Nederlands',
			en: 'English',
			sv: 'Svenska',
			nb: 'Norsk bokmål',
		},
	},
	cdnType: 'akamai',
	trackingUrl: 'https://dctracking.dutchchannels.com',
	analyticsChannel: 'E2D4D4FA738754EEBD7430C858B93D4D',
	qrCodePage: 'https://newfaithnetwork.com/tv',
	platformUrl: 'https://newfaithnetwork.com/',
	mux: {
		env: 'lhghf1nmhhbd8le94lg1bq3l2',
	},
	dev: {
		email: process.env.USER_EMAIL_NFN_PRD,
		password: process.env.USER_PASSWORD_NFN_PRD,
	},
};

const config = isDevelopment
	? {
			...configProduction,
			...configDevelopment,
	  }
	: configProduction;

export default config;
