export const files = {
	'en-GB': require('./en_GB.json'),
	'en-IE': require('./en_IE.json'),
	'nb-NO': require('./nb_NO.json'),
	'nl-NL': require('./nl_NL.json'),
	'sv-SE': require('./sv_SE.json'),
	'ro-RO': require('./ro_RO.json'),
	'fi-FI': require('./fi_FI.json'),
	'da-DK': require('./da_DK.json'),
	'de-DE': require('./de_DE.json'),
	'fr-FR': require('./fr_FR.json'),
	'es-ES': require('./es_ES.json'),
	'pt-PT': require('./pt_PT.json'),
	'hu-HU': require('./hu_HU.json'),
	'pl-PL': require('./pl_PL.json'),
	'el-GR': require('./el_GR.json'),
	'it-IT': require('./it_IT.json'),
	'gb': require('./en_GB.json'),
	'ie': require('./en_IE.json'),
	'nb': require('./nb_NO.json'),
	'nl': require('./nl_NL.json'),
	'sv': require('./sv_SE.json'),
	'ro': require('./ro_RO.json'),
	'fi': require('./fi_FI.json'),
	'da': require('./da_DK.json'),
	'de': require('./de_DE.json'),
	'fr': require('./fr_FR.json'),
	'es': require('./es_ES.json'),
	'pt': require('./pt_PT.json'),
	'hu': require('./hu_HU.json'),
	'pl': require('./pl_PL.json'),
	'el': require('./el_GR.json'),
	'it': require('./it_IT.json')
};
