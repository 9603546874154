import React from 'react';
import { Image, StyleSheet } from 'react-native';

import icons from '../icons';
import { getScaledValue } from '../utils/scale';

const styles = StyleSheet.create({
	icon: {
		width: getScaledValue(24),
		height: getScaledValue(24),
	},
});

const Icon = ({ type, style }: { type: string; style?: any }) => {
	let source;

	switch (type) {
		case 'play':
			source = icons.play;
			break;
		case 'trailer':
			source = icons.trailer;
			break;
		case 'add':
			source = icons.favorites;
			break;
		case 'edit':
			source = icons.edit;
			break;
		case 'remove':
			source = icons.favorites_active;
			break;
		case 'subtitles':
			source = icons.subtitles;
			break;
		case 'profile':
			source = icons.profile;
			break;
		case 'restart':
			source = icons.restart;
			break;
		case 'pen':
			source = icons.pen;
			break;
		default:
			throw new Error(`Unknown icon type: ${type}`);
	}

	return (
		<Image
			source={source}
			resizeMode="cover"
			style={[styles.icon, style]}
		/>
	);
};

export default Icon;
