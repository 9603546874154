import styled from '@emotion/native';
import React, { useState } from 'react';
import { StyleSheet } from 'react-native';

import colors from '../../platformAssets/runtime/colors';
import fontnames from '../../platformAssets/runtime/fontnames';
import Pressable from '../components/pressable';
import {
	USER_INTERACTION_CONTEXT,
	USER_INTERACTION_FEATURES,
	USER_INTERACTION_TYPE,
} from '../services/event-tracker/UserInterActionEvent';
import { getScaledValue } from '../utils/scale';
import { scale } from '../utils/scaling';

const styles = StyleSheet.create({
	avatar: {
		width: getScaledValue(120),
		height: getScaledValue(120),
		borderRadius: 9999,
		borderColor: 'white',
		borderWidth: 0,
		alignItems: 'center',
		justifyContent: 'center',
		overflow: 'hidden',
	},
});

const AvatarText = styled.Text`
	font-family: ${fontnames.secondaryBold};
	font-size: ${scale('80')}px;
	margin: ${scale('20')}px 0;
	color: ${colors.primaryText};
`;

type AvatarProps = {
	parentContext?: any;
	name: string;
	color: string;
	onPress: () => void;
	onFocus?: () => void;
	hasFocus: boolean;
};

const Avatar: React.FC<AvatarProps> = ({
	parentContext,
	name,
	color,
	onPress,
	hasFocus,
	onFocus,
}) => {
	const abbr = name.toUpperCase().substring(0, 2);
	const [selected, setSelected] = useState(hasFocus);

	return (
		<Pressable
			parentContext={parentContext}
			style={[
				styles.avatar,
				{ backgroundColor: color, borderWidth: selected ? 4 : 0 },
			]}
			focusOptions={{
				hasInitialFocus: selected,
			}}
			onPress={onPress}
			onFocus={() => {
				setSelected(true);

				if (onFocus) {
					onFocus();
				}
			}}
			onBlur={() => setSelected(false)}
			track={{
				feature: USER_INTERACTION_FEATURES.SWITCH_PROFILE,
				context: USER_INTERACTION_CONTEXT.AVATARS,
				type: USER_INTERACTION_TYPE.BUTTON,
				name: 'profile_avatar',
			}}
		>
			<AvatarText>{abbr}</AvatarText>
		</Pressable>
	);
};

export default Avatar;
