import * as Sentry from '@sentry/browser';
import { useContext } from 'react';

import { UIContext } from '../contexts/UIContext';

export function useUI() {
	const context = useContext(UIContext);

	if (context === undefined) {
		Sentry.captureMessage(`useUI must be within UIProvider`);
		throw new Error('useUI must be within UIProvider');
	}

	return context;
}
