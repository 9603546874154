export default [{
                              fontFamily: 'AveriaSansLibre-Bold',
                              file: require('/home/runner/work/connected-tv-apps/connected-tv-apps/appConfigs/base/fonts/AveriaSansLibre-Bold.ttf'),
                          },{
                              fontFamily: 'BrandonGrotesque-Bold',
                              file: require('/home/runner/work/connected-tv-apps/connected-tv-apps/appConfigs/base/fonts/BrandonGrotesque-Bold.ttf'),
                          },{
                              fontFamily: 'FontAwesome',
                              file: require('/home/runner/work/connected-tv-apps/connected-tv-apps/appConfigs/base/fonts/FontAwesome.ttf'),
                          },{
                              fontFamily: 'FontAwesome5_Brands',
                              file: require('/home/runner/work/connected-tv-apps/connected-tv-apps/appConfigs/base/fonts/FontAwesome5_Brands.ttf'),
                          },{
                              fontFamily: 'FontAwesome5_Regular',
                              file: require('/home/runner/work/connected-tv-apps/connected-tv-apps/appConfigs/base/fonts/FontAwesome5_Regular.ttf'),
                          },{
                              fontFamily: 'FontAwesome5_Solid',
                              file: require('/home/runner/work/connected-tv-apps/connected-tv-apps/appConfigs/base/fonts/FontAwesome5_Solid.ttf'),
                          },{
                              fontFamily: 'Ionicons',
                              file: require('/home/runner/work/connected-tv-apps/connected-tv-apps/appConfigs/base/fonts/Ionicons.ttf'),
                          },{
                              fontFamily: 'Montserrat-Bold',
                              file: require('/home/runner/work/connected-tv-apps/connected-tv-apps/appConfigs/base/fonts/Montserrat-Bold.ttf'),
                          },{
                              fontFamily: 'OpenSans-Bold',
                              file: require('/home/runner/work/connected-tv-apps/connected-tv-apps/appConfigs/base/fonts/OpenSans-Bold.ttf'),
                          },{
                              fontFamily: 'OpenSans-Light',
                              file: require('/home/runner/work/connected-tv-apps/connected-tv-apps/appConfigs/base/fonts/OpenSans-Light.ttf'),
                          },{
                              fontFamily: 'OpenSans-Regular',
                              file: require('/home/runner/work/connected-tv-apps/connected-tv-apps/appConfigs/base/fonts/OpenSans-Regular.ttf'),
                          },{
                              fontFamily: 'OpenSans-SemiBold',
                              file: require('/home/runner/work/connected-tv-apps/connected-tv-apps/appConfigs/base/fonts/OpenSans-SemiBold.ttf'),
                          },{
                              fontFamily: 'AntDesign',
                              file: require('/home/runner/work/connected-tv-apps/connected-tv-apps/node_modules/react-native-vector-icons/Fonts/AntDesign.ttf'),
                          },{
                              fontFamily: 'Entypo',
                              file: require('/home/runner/work/connected-tv-apps/connected-tv-apps/node_modules/react-native-vector-icons/Fonts/Entypo.ttf'),
                          },{
                              fontFamily: 'EvilIcons',
                              file: require('/home/runner/work/connected-tv-apps/connected-tv-apps/node_modules/react-native-vector-icons/Fonts/EvilIcons.ttf'),
                          },{
                              fontFamily: 'Feather',
                              file: require('/home/runner/work/connected-tv-apps/connected-tv-apps/node_modules/react-native-vector-icons/Fonts/Feather.ttf'),
                          },{
                              fontFamily: 'FontAwesome6_Brands',
                              file: require('/home/runner/work/connected-tv-apps/connected-tv-apps/node_modules/react-native-vector-icons/Fonts/FontAwesome6_Brands.ttf'),
                          },{
                              fontFamily: 'FontAwesome6_Regular',
                              file: require('/home/runner/work/connected-tv-apps/connected-tv-apps/node_modules/react-native-vector-icons/Fonts/FontAwesome6_Regular.ttf'),
                          },{
                              fontFamily: 'FontAwesome6_Solid',
                              file: require('/home/runner/work/connected-tv-apps/connected-tv-apps/node_modules/react-native-vector-icons/Fonts/FontAwesome6_Solid.ttf'),
                          },{
                              fontFamily: 'Fontisto',
                              file: require('/home/runner/work/connected-tv-apps/connected-tv-apps/node_modules/react-native-vector-icons/Fonts/Fontisto.ttf'),
                          },{
                              fontFamily: 'Foundation',
                              file: require('/home/runner/work/connected-tv-apps/connected-tv-apps/node_modules/react-native-vector-icons/Fonts/Foundation.ttf'),
                          },{
                              fontFamily: 'MaterialCommunityIcons',
                              file: require('/home/runner/work/connected-tv-apps/connected-tv-apps/node_modules/react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'),
                          },{
                              fontFamily: 'MaterialIcons',
                              file: require('/home/runner/work/connected-tv-apps/connected-tv-apps/node_modules/react-native-vector-icons/Fonts/MaterialIcons.ttf'),
                          },{
                              fontFamily: 'Octicons',
                              file: require('/home/runner/work/connected-tv-apps/connected-tv-apps/node_modules/react-native-vector-icons/Fonts/Octicons.ttf'),
                          },{
                              fontFamily: 'SimpleLineIcons',
                              file: require('/home/runner/work/connected-tv-apps/connected-tv-apps/node_modules/react-native-vector-icons/Fonts/SimpleLineIcons.ttf'),
                          },{
                              fontFamily: 'Zocial',
                              file: require('/home/runner/work/connected-tv-apps/connected-tv-apps/node_modules/react-native-vector-icons/Fonts/Zocial.ttf'),
                          },];