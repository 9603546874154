import { isWebBased } from '@rnv/renative';
import React from 'react';
import {
	ActivityIndicator,
	Animated,
	Image,
	StyleSheet,
	View,
} from 'react-native';

import colors from '../../../platformAssets/runtime/colors';
import config from '../../../platformAssets/runtime/config';
import logo from '../../../platformAssets/runtime/icons/logo.png';
import { getScaledValue } from '../../utils/scale';
import { Gradients } from '../gradients';

const AppLoader = ({
	show,
	hideLogo = false,
}: {
	show: boolean;
	hideLogo?: boolean;
}) => {
	if (isWebBased || !show) {
		return null;
	}

	return (
		<Animated.View style={styles.logoContainer}>
			<Gradients>
				<View style={styles.logoContainer}>
					{!hideLogo && (
						<Image
							source={logo}
							resizeMode={'contain'}
							style={styles.logo}
						/>
					)}
					<ActivityIndicator
						color={colors.primary}
						style={styles.indicator}
					/>
				</View>
			</Gradients>
		</Animated.View>
	);
};

const styles = StyleSheet.create({
	logoContainer: {
		backgroundColor: 'rgba(0.0.0,0.95)',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
	},
	logo: {
		height: getScaledValue(config.dimensions.logo.height * 2),
		width: getScaledValue(config.dimensions.logo.width * 2),
	},

	indicator: {
		marginTop: getScaledValue(16),
	},
});

export default AppLoader;
