import React, { useEffect, useState } from 'react';
import { StyleSheet, Text } from 'react-native';

import { getScaledValue } from '../../utils/scale';

type Props = {
	textInputFocused: string;
	keyboardData: Array<{ text: string }>;
};

const styles = StyleSheet.create({
	underscore: {
		color: 'white',
		fontSize: getScaledValue(12),
	},
});

export const Cursor = ({ textInputFocused, keyboardData }: Props) => {
	const [showCursor, setShowCursor] = useState(false);
	useEffect(() => {
		const interval = setInterval(() => {
			setShowCursor(!showCursor);
		}, 500);

		return () => clearInterval(interval);
	}, [showCursor]);

	return (
		<Text
			style={[
				styles.underscore,
				{
					display: keyboardData
						.map((key) => key.text)
						.includes(textInputFocused)
						? showCursor
							? 'none'
							: 'flex'
						: 'none',
				},
			]}
		>
			_
		</Text>
	);
};

Cursor.displayName = 'Cursor';
