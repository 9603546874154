import {
	RecyclableList,
	RecyclableListDataProvider,
	RecyclableListLayoutProvider,
	View,
} from '@flexn/sdk';
import React from 'react';
import { StyleSheet, Dimensions } from 'react-native';

import { ShelfAsset } from '../../models/Asset';
import { Shelf } from '../../models/Shelf';
import isNative from '../../utils/isNative';
import { getScaledValue, getResponsiveScaling } from '../../utils/scale';
import { TILE_HEIGHT, TILE_WIDTH } from '../tile/tileBase';

import { SearchResult } from './searchResult';

type Props = {
	parentContext?: any;
	shelves: Shelf[];
	onPress: (asset: ShelfAsset) => void;
	onFocus: (asset: ShelfAsset) => void;
	numberOfCollumns: number;
	style?: any;
};

const styles = StyleSheet.create({
	keyboard: {},
	title: {
		fontSize: getScaledValue(18),
		paddingLeft: getScaledValue(12),
		paddingTop: getScaledValue(12),
	},
});

export const SearchResults = ({
	parentContext,
	onPress,
	onFocus,
	shelves,
	numberOfCollumns,
	style = {},
}: Props) => {
	const [dataProvider] = React.useState(
		new RecyclableListDataProvider((r1, r2) => r1 !== r2).cloneWithRows(
			shelves[0].items
		)
	);

	const layoutProvider = React.useRef(
		new RecyclableListLayoutProvider(
			() => '_',
			(_: string | number, dim: { width: number; height: number }) => {
				dim.width = getResponsiveScaling(TILE_WIDTH).width;
				dim.height = getResponsiveScaling(TILE_HEIGHT).width;
			}
		)
	).current;

	return (
		<RecyclableList
			dataProvider={dataProvider}
			layoutProvider={layoutProvider}
			parentContext={parentContext}
			rowRenderer={(
				_type: string | number,
				data: any,
				index: number,
				repeatContext: any
			) => {
				return (
					<SearchResult
						asset={data}
						onPress={onPress}
						onFocus={(asset) => {
							onFocus(asset);
						}}
						repeatContext={repeatContext}
						focusOptions={{
							animatorOptions: {
								type: isNative ? 'scale_with_border' : 'border',
								scale: 1.1,
							},

							focusKey: `search-${index}`,
							nextFocusUp: `search-${index - numberOfCollumns}`,
							nextFocusDown: `search-${index + numberOfCollumns}`,
							nextFocusRight:
								index !== dataProvider.getSize() - 1 && `search-${index + 1}`,

							nextFocusLeft:
								index === 0 || index % numberOfCollumns === 0
									? `keyboard`
									: `search-${index - 1}`,
							forbiddenFocusDirections:
								index === dataProvider.getSize() - 1
									? ['right', 'down']
									: index > dataProvider.getSize() - 1 - numberOfCollumns
									? ['down']
									: [],
						}}
					/>
				);
			}}
			isHorizontal={false}
			style={[
				styles.keyboard,
				{ height: Dimensions.get('screen').height },
				style && style,
			]}
			scrollViewProps={{
				showsVerticalScrollIndicator: false,
			}}
			type={'grid'}
			renderFooter={() => (
				<View
					style={{
						height: getResponsiveScaling(TILE_HEIGHT * 3).width,
					}}
				/>
			)}
		/>
	);
};

SearchResults.displayName = 'SearchResults';
