import { Animated, View } from '@flexn/sdk';
import { isTvos } from '@rnv/renative';
import React, { memo } from 'react';
import { Image, StyleSheet, Text } from 'react-native';

import colors from '../../platformAssets/runtime/colors';
import fonts from '../../platformAssets/runtime/fontnames';
import { ratingFileMap } from '../age-rating';
import { useAsset, useAssetDetails } from '../hooks/api';
import { useI18n } from '../hooks/useI18n';
import { ShelfAsset } from '../models/Asset';
import { AssetType } from '../models/AssetType';
import { AssetDetails } from '../models/Details';
import {
	USER_INTERACTION_CONTEXT,
	USER_INTERACTION_FEATURES,
	USER_INTERACTION_TYPE,
} from '../services/event-tracker/UserInterActionEvent';
import { getScaledValue, getResponsiveScaling } from '../utils/scale';

import Button from './button/button-flex';
import Icon from './icon';
import { TILE_WIDTH } from './tile/tileBase';
import Title from './title';

const JUMBOTRON_HEIGHT = 50;
const PREVIEW_HEIGHT = 42;

const styles = StyleSheet.create({
	previewContainer: {
		paddingTop: getScaledValue(12),
		height: getResponsiveScaling(PREVIEW_HEIGHT).height,
	},
	jumbotronContainer: {
		display: 'flex',
		justifyContent: 'center',
		height: getResponsiveScaling(JUMBOTRON_HEIGHT).height,
	},
	informationDot: {
		height: getResponsiveScaling(0.5).width,
		width: getResponsiveScaling(0.5).width,
		backgroundColor: colors.muted,
		borderRadius: getScaledValue(3),
		marginRight: getScaledValue(4),
	},
	description: {
		width: '40%',
		fontFamily: fonts.secondary,
		fontSize: getResponsiveScaling(1.5).width,
		color: colors.secondaryText,
		marginTop: getScaledValue(8),
		marginBottom: getScaledValue(8),
	},
	information: {
		marginRight: getScaledValue(4),
		color: colors.muted,
		fontFamily: fonts.secondarySemiBold,
		fontSize: getResponsiveScaling(1.5).width,
	},
	ratingView: {
		backgroundColor: 'rgba(255,255,255,0.5)',
		borderRadius: getResponsiveScaling(2).width,
	},
	ratingImage: {
		width: getResponsiveScaling(1.5).width,
		height: getResponsiveScaling(1.5).width,
		opacity: 0.9,
	},
	playButton: {
		marginTop: getScaledValue(8),
		height: 'auto',
		width: getResponsiveScaling(TILE_WIDTH * 2).width,
	},
	playButtonHidden: {
		opacity: 0, // does work in tvos :P
		height: getScaledValue(16),
	},

	jumbotronLabel: {
		color: '#FFFFFF',
		fontFamily: fonts.secondarySemiBold,
		fontSize: getScaledValue(12),
	},
});

type AssetPreviewProps = {
	parentContext?: any;
	isJumbotron?: boolean;
	onPlayButtonPress?: (asset: ShelfAsset) => void;
	onPlayButtonFocus?: (asset: ShelfAsset) => void;
};

const AssetPreview: React.FC<AssetPreviewProps> = ({
	parentContext,
	isJumbotron = false,
	onPlayButtonPress,
	onPlayButtonFocus,
}) => {
	const { translate, language } = useI18n();

	const fadeAnimation = 1;

	const { data: asset } = useAsset();
	const { data: assetDetails } =
		useAssetDetails({ asset, enabled: !!asset?.id });

	const handlePlayButtonPress = () => {
		asset && onPlayButtonPress && onPlayButtonPress(asset);
	};

	const handlePlayButtonFocus = () => {
		asset && onPlayButtonFocus && onPlayButtonFocus(asset);
	};

	if (!asset) {
		return null;
	}

	return (
		<Animated.View
			style={[
				isJumbotron ? styles.jumbotronContainer : styles.previewContainer,
				{ opacity: fadeAnimation },
			]}
		>
			<>
				{isJumbotron &&
					language &&
					asset.extended?.custom?.jumbotronLabel &&
					asset.extended?.custom?.jumbotronLabel[
						language?.toLocaleLowerCase()
					] && (
						<Text style={styles.jumbotronLabel}>
							{
								asset.extended?.custom?.jumbotronLabel[
									language?.toLocaleLowerCase()
								]
							}
						</Text>
					)}
				<Title
					fontSize={
						isJumbotron
							? getResponsiveScaling(2).width
							: getResponsiveScaling(2).width
					}
					style={{
						marginBottom: isTvos() ? getScaledValue(0) : 0,
						marginTop: isTvos() ? getScaledValue(0) : 0,
					}}
				>
					{asset?.title.toUpperCase()}
				</Title>

				{!!assetDetails && <AssetInfo asset={assetDetails} />}

				{onPlayButtonPress && (
					<Button
						testID="asset-preview-button"
						parentContext={parentContext}
						style={[
							styles.playButton,
							!asset.isJumbotron && styles.playButtonHidden,
						]}
						label={translate(
							`button.play.${asset.asset_subtype.toLocaleLowerCase()}`
						)}
						leftIcon={<Icon type="play" />}
						focusOptions={{
							focusKey: 'asset-preview-play-button',
							hasInitialFocus: true,
							nextFocusLeft: 'tab-bar',
							nextFocusDown: 'shelf-0-0-0',
						}}
						onPress={handlePlayButtonPress}
						onFocus={handlePlayButtonFocus}
						track={{
							feature: USER_INTERACTION_FEATURES.BROWSE,
							context: USER_INTERACTION_CONTEXT.ASSET_PREVIEW,
							type: USER_INTERACTION_TYPE.BUTTON,
							name: 'play_button',
						}}
					/>
				)}
			</>
		</Animated.View>
	);
};

const AssetInfo = ({ asset }: { asset: AssetDetails }) => {
	const { translate } = useI18n();

	return (
		<>
			<View style={{ flexDirection: 'row', alignItems: 'center' }}>
				{asset.asset_type === AssetType.tvshow && (
					<>
						<Text style={styles.information}>Serie</Text>
						<View style={styles.informationDot} />
					</>
				)}
				{!!asset.release_date && (
					<Text style={styles.information}>
						{asset.release_date.substring(0, 4)}
					</Text>
				)}
				{!!asset.release_date && !!asset.duration && (
					<View style={styles.informationDot} />
				)}
				{!!asset.duration && (
					<Text style={styles.information}>
						{`${Math.round(asset.duration / 60)} ${
							Math.round(asset.duration / 60) === 1
								? translate('time.minute')
								: translate('time.minutes')
						}`}
					</Text>
				)}
				{!!asset.age_rating && <View style={styles.informationDot} />}
				{!!asset.age_rating && (
					<>
						<View style={styles.ratingView}>
							<Image
								style={styles.ratingImage}
								source={ratingFileMap[asset.age_rating]}
								resizeMode={'contain'}
							/>
						</View>
					</>
				)}
			</View>
			<Text
				style={styles.description}
				numberOfLines={4}
			>
				{asset?.short_description}
			</Text>
		</>
	);
};

export default memo(AssetPreview, (p, n) => p.isJumbotron === n.isJumbotron);
