import { Pressable, View } from '@flexn/sdk';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Text } from 'react-native';

import colors from '../../../platformAssets/runtime/colors';
import fonts from '../../../platformAssets/runtime/fontManager';
import { useFavorites } from '../../hooks/api';
import { useI18n } from '../../hooks/useI18n';
import { useAuth } from '../../providers/auth';
import { getScaledValue } from '../../utils/scale';
import Button from '../button/button-flex';

import Shelf from './shelf-flexn';

const FavoritesShelf: React.FC = ({ parentContext, ...props }) => {
	const { data: favorites, isLoading: isFavoritesLoading } = useFavorites();
	const { user } = useAuth();
	const { translate } = useI18n();
	const { navigate } = useNavigation();

	if (isFavoritesLoading) return null;

	const isEmpty = !favorites || favorites?.length === 0;

	const emptyItems = [
		{
			isIncentive: true,
		},
	];

	const renderEmptyTile = ({ repeatContext }) => {
		return (
			<Pressable
				onPress={() => undefined}
				parentContext={parentContext}
				repeatContext={repeatContext}
			>
				<Text
					style={{
						fontFamily: fonts.secondary,
						fontSize: getScaledValue(14),
						color: colors.secondaryText,
					}}
				>
					{translate('shelves.favorites.empty')}
				</Text>
			</Pressable>
		);
	};

	const renderEmptyLoginTile = ({ repeatContext }) => {
		return (
			<>
				<Text
					style={{
						fontFamily: fonts.secondary,
						fontSize: getScaledValue(14),
						color: colors.secondaryText,
					}}
				>
					{translate('shelves.favorites.empty.login')}
				</Text>
				<Button
					repeatContext={repeatContext}
					onPress={() => navigate('Register')}
					label={translate('login.button.text')}
					style={{ height: getScaledValue(1), opacity: 0.1 }}
				/>
			</>
		);
	};

	const renderEmpty = ({ cardStyle, repeatContext, itemDimensions }) => {
		return (
			<View
				style={[
					cardStyle,
					{
						flex: 1,
						justifyContent: 'space-between',
						width: itemDimensions.width,
						height: itemDimensions.height,
						padding: getScaledValue(16),
					},
				]}
			>
				{!user && renderEmptyLoginTile({ repeatContext })}
				{user && renderEmptyTile({ repeatContext })}
			</View>
		);
	};

	return (
		<Shelf
			{...props}
			isEmpty={isEmpty}
			renderEmpty={renderEmpty}
			items={isEmpty ? emptyItems : favorites}
		/>
	);
};

export default FavoritesShelf;
