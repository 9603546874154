import { useMemo } from 'react';

import { ShelfAsset } from '../models/Asset';
import { AssetType } from '../models/AssetType';
import { EpisodeDetails, MovieDetails, TVShowDetails } from '../models/Details';
import { Shelf } from '../models/Shelf';

import { useTVShow } from './api';
import { useSeasonsShelves } from './shelves';
import { usePlayer } from './usePlayer';

export function useNextEpisode() {
	const { asset } = usePlayer();

	const { data: tvShow } = useTVShow(
		asset?.asset_type === AssetType.episode ? asset.tvshow.id : undefined
	);

	const seasons = useSeasonsShelves(tvShow);

	const memoNextEpisode = useMemo(() => {
		return getNextEpisode(seasons.shelves, asset);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [seasons.shelves, asset]);

	return memoNextEpisode;
}

const getNextEpisode = (
	shelves: Shelf<ShelfAsset>[],
	asset: MovieDetails | EpisodeDetails | undefined
): { next: ShelfAsset | undefined; nextSeason: boolean } => {
	let next: ShelfAsset | undefined = undefined;
	let nextSeason = false;
	shelves.map((s, index) =>
		s.items.forEach((episode, i) => {
			if (episode.id === asset?.id && i + 1 <= s.items.length - 1) {
				next = s.items[i + 1];
			}
			if (
				episode.id === asset?.id &&
				i + 1 > s.items.length - 1 &&
				index + 1 <= shelves.length - 1
			) {
				next = shelves[index + 1].items[0];
				nextSeason = true;
			}
		})
	);
	return { next: next, nextSeason: nextSeason };
};
