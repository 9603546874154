import { CoreManager, View } from '@flexn/sdk';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { ActivityIndicator, StyleSheet, Text } from 'react-native';

import colors from '../../../platformAssets/runtime/colors';
import fontnames from '../../../platformAssets/runtime/fontnames';
import { useAssetDetails } from '../../hooks/api';
import { useNextEpisode } from '../../hooks/nextEpisode';
import { useI18n } from '../../hooks/useI18n';
import { usePlayer } from '../../hooks/usePlayer';
import { isPlayable } from '../../models/Asset';
import {
	USER_INTERACTION_CONTEXT,
	USER_INTERACTION_FEATURES,
	USER_INTERACTION_TYPE,
} from '../../services/event-tracker/UserInterActionEvent';
import { getScaledValue } from '../../utils/scale';
import Button from '../button/button-flex';

export const NEXT_EPISODE_FOCUS_KEY = 'PLAYER-NEXT-EPISODE';

type NextEpisodeProps = {
	parentContext?: any;
};

const NextEpisode: React.FC<NextEpisodeProps> = ({ parentContext }) => {
	const {
		showNextEpisode,
		setAsset,
		setCurrentTime,
		buffering,
		currentTime,
		duration,
		setStartOver,
	} = usePlayer();
	const { translate } = useI18n();

	const [focusAlreadySet, setFocusAlreadySet] = useState<boolean>(false);

	const nextEpisode = useNextEpisode();

	const { data: selectedAssetDetails } = useAssetDetails({
		asset: nextEpisode.next,
		enabled: !!nextEpisode.next?.id,
	});

	const handlePress = () => {
		if (selectedAssetDetails && isPlayable(selectedAssetDetails)) {
			setCurrentTime(0);
			setStartOver(true);
			setAsset(selectedAssetDetails);
		}
	};

	useLayoutEffect(() => {
		if (!focusAlreadySet && showNextEpisode) {
			setFocusAlreadySet(true);
			setTimeout(() => {
				CoreManager.focusElementByFocusKey('play-controls-action-button');
			}, 500);
		}
	}, [focusAlreadySet, showNextEpisode]);

	useEffect(() => {
		if (
			selectedAssetDetails &&
			isPlayable(selectedAssetDetails) &&
			showNextEpisode &&
			duration &&
			currentTime > duration - 1
		) {
			setCurrentTime(0);
			setStartOver(true);
			setAsset(selectedAssetDetails);
		}
	}, [
		showNextEpisode,
		selectedAssetDetails,
		currentTime,
		duration,
		setStartOver,
		setCurrentTime,
		setAsset,
	]);

	if (!nextEpisode || !selectedAssetDetails || !duration) {
		return null;
	}

	return (
		<View parentContext={parentContext}>
			<Button
				onPress={handlePress}
				focusOptions={{ focusKey: 'play-controls-action-button' }}
				{...(duration && {
					loadingPercentage: ((30 - (duration - currentTime)) / 29) * 100,
				})}
				track={{
					feature: USER_INTERACTION_FEATURES.PLAYER,
					context: USER_INTERACTION_CONTEXT.PLAYER_CONTROLS,
					type: USER_INTERACTION_TYPE.BUTTON,
					name: 'next_episode',
				}}
			>
				<>
					{buffering && <ActivityIndicator color="white" />}
					<Text
						numberOfLines={1}
						style={styles.buttonText}
					>
						{translate(
							nextEpisode.nextSeason
								? 'player.next_season'
								: 'player.next_episode'
						)}
					</Text>
				</>
			</Button>
		</View>
	);
};

const styles = StyleSheet.create({
	button: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingHorizontal: getScaledValue(12),
		borderRadius: getScaledValue(8),
		backgroundColor: '#ffffff20',
	},
	buttonText: {
		marginLeft: getScaledValue(4),
		marginRight: getScaledValue(4),
		marginTop: getScaledValue(16),
		marginBottom: getScaledValue(16),
		fontSize: getScaledValue(16),
		fontFamily: fontnames.secondarySemiBold,
		color: 'white',
	},

	focusedButton: {
		backgroundColor: colors.primary,
	},
});

export default NextEpisode;
