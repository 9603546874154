import { View } from '@flexn/sdk';
import { isTvos } from '@rnv/renative';
import React, { useState } from 'react';
import { StyleSheet, Text } from 'react-native';

import colors from '../../../platformAssets/runtime/colors';
import config from '../../../platformAssets/runtime/config';
import {
	default as fontnames,
	default as fonts,
} from '../../../platformAssets/runtime/fontnames';
import { useAssetDetails, useDuration } from '../../hooks/api';
import { useI18n } from '../../hooks/useI18n';
import { ShelfAsset } from '../../models/Asset';
import { AssetType } from '../../models/AssetType';
import {
	getScaledValue,
	getResponsiveScaling
} from '../../utils/scale';
import Image from '../image';
import Video from '../video';

const TILE_SPACING_HORIZONTAL = 0;
const TILE_SPACING_TOP = 0;
const TILE_SPACING_BOTTOM = 0;

export const IMAGE_ASPECT_RATIO = 9 / 13;

export const IMAGE_WIDTH = 12;
export const IMAGE_HEIGHT = IMAGE_WIDTH / IMAGE_ASPECT_RATIO;

export const TILE_WIDTH = 12 + TILE_SPACING_HORIZONTAL * 2;
export const TILE_HEIGHT =
	IMAGE_HEIGHT + TILE_SPACING_TOP + TILE_SPACING_BOTTOM;

export const IMAGE_LANDSCAPE_ASPECT_RATIO = 16 / 9;
export const IMAGE_LANDSCAPE_WIDTH = 20;
export const IMAGE_LANDSCAPE_HEIGHT =
	IMAGE_LANDSCAPE_WIDTH / IMAGE_LANDSCAPE_ASPECT_RATIO;

export const TILE_LANDSCAPE_WIDTH = 20 + TILE_SPACING_HORIZONTAL * 2;
export const TILE_LANDSCAPE_HEIGHT =
	IMAGE_LANDSCAPE_HEIGHT + TILE_SPACING_TOP + TILE_SPACING_BOTTOM;

const styles = StyleSheet.create({
	tileContainer: {
		justifyContent: 'center',
		alignItems: 'center',
		paddingTop: getScaledValue(TILE_SPACING_TOP),
		paddingBottom: getScaledValue(TILE_SPACING_BOTTOM),
		backgroundColor: 'transparent',
		borderRadius: getScaledValue(12),
		borderColor: '#0A74E6',
		borderWidth: 4,
	},
	tileContainerFocus: {},
	imageContainer: {
		width: '100%',
		height: '100%',
		borderRadius: getScaledValue(10),
		borderColor: 'transparent',
		overflow: 'hidden',
	},
	imageContainerFocus: {
		borderColor: 'white',
	},
	imageContainerHover: {
		borderColor: 'rgba(255,255,255,0.7)',
	},
	imageContainerLandscape: {
		width: '100%',
		height: '100%',
		borderRadius: getScaledValue(12),
		borderColor: 'transparent',
		overflow: 'hidden',
	},
	image: {
		flex: 1,
		borderRadius: getScaledValue(8),
		overflow: 'hidden',
	},
	fastImage: {
		position: 'absolute',
		zIndex: -1,
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
	},
	imageFocus: {},

	tagContainerAligner: {
		display: 'flex',
		flexDirection: 'row',
		flex: 1,
		alignItems: 'flex-end',
		paddingBottom: getScaledValue(7),
		paddingHorizontal: getScaledValue(5),
		width: '100%',
	},

	tagContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		width: '100%',
	},

	tag: {
		padding: getScaledValue(3),
		marginLeft: getScaledValue(4),
		borderRadius: getScaledValue(2),
		marginTop: getScaledValue(2),
		backgroundColor: 'rgba(0, 0, 0, 0.7)',
	},

	tagText: {
		textTransform: 'uppercase',
		fontSize: getResponsiveScaling(1).width,
		fontFamily: fontnames.secondaryBold,
		color: 'white',
	},

	progress: {
		position: 'absolute',
		bottom: getScaledValue(2),
		left: getScaledValue(10),
		right: getScaledValue(10),
		height: getScaledValue(3),
		borderRadius: getScaledValue(2),
		backgroundColor: '#ffffff40',
	},

	progressHidden: {
		display: 'none',
	},

	fill: {
		position: 'absolute',
		left: 0,
		top: 0,
		height: getScaledValue(3),
		borderRadius: getScaledValue(2),
		backgroundColor: colors.primary,
	},
	detailTitle: {
		flex: 10,
		fontFamily: fonts.primary,
		fontSize: getScaledValue(14),
		color: colors.primaryText,
		marginRight: getScaledValue(4),
	},
	detailTime: {
		flex: 0,
		fontFamily: fonts.secondarySemiBold,
		fontSize: getScaledValue(12),
		color: colors.muted,
	},
	detailDescription: {
		fontFamily: fonts.secondary,
		fontSize: getScaledValue(12),
		color: colors.secondaryText,
	},
});

const TileContent = ({
	asset,
	selected = false,
	hover = false,
	renderImage = true,
	renderTrailer = false,
	showProgressBar = false,
}) => {

	const style = [
		asset.isHorizontal ? styles.imageContainerLandscape : styles.imageContainer,
		selected ? styles.imageContainerFocus : undefined,
		hover ? styles.imageContainerHover : undefined,
	];

	return (
		<View style={style}>
			<MemoizedTileImage
				asset={asset}
				uri={asset.image.uri}
				renderImage={renderImage}
				renderTrailer={renderTrailer}
			>
				<MemoizedLabels asset={asset} />
				{showProgressBar && <MemoizedProgress asset={asset} />}
			</MemoizedTileImage>
		</View>
	);
};

export const MemoizedTileContent = React.memo(
	TileContent,
	(p, n) =>
		p.selected === n.selected &&
		p.renderImage === n.renderImage &&
		p.hover === n.hover &&
		p.renderTrailer === n.renderTrailer &&
		p.showProgressBar === n.showProgressBar
);

const TileImage = ({
	uri,
	renderImage = true,
	children,
	asset,
	renderTrailer,
}: {
	uri: string;
	renderImage?: boolean;
	children: React.ReactNode;
	asset: ShelfAsset;
	renderTrailer: boolean;
}) => {
	const source = { uri: renderImage ? uri : '' };
	const [isVideoEnd, setIsVideoEnd] = useState(false);
	const showTrailer =
		asset.isHorizontal &&
		renderTrailer &&
		asset.asset_type !== AssetType.episode;
	const { data: assetDetails } = useAssetDetails({
		asset,
		enabled: showTrailer,
	});

	return (
		<View style={styles.image}>
			<Image
				source={source}
				style={styles.fastImage}
			></Image>
			{showTrailer && assetDetails?.trailer && !isVideoEnd && (
				<Video
					onEnd={() => setIsVideoEnd(true)}
					media={
						isTvos()
							? assetDetails.trailer.hls_url.replace('media', config.mediaUrl)
							: assetDetails.trailer.dash_url.replace('media', config.mediaUrl)
					}
					style={{
						backgroundColor: 'black',
						height: '100%',
						width: '100%',
						position: 'absolute',
						zIndex: -1,
						left: 0,
						right: 0,
						top: 0,
						bottom: 0,
					}}
				/>
			)}

			{children}
		</View>
	);
};

const MemoizedTileImage = React.memo(
	TileImage,
	(p, n) =>
		p.uri === n.uri &&
		p.renderImage === n.renderImage &&
		p.renderTrailer === n.renderTrailer
);

const Progress = ({ asset }: { asset: ShelfAsset }) => {
	const { progress } = useDuration(asset);

	if (progress < 1) {
		return null;
	}

	return (
		<View
			style={[styles.progress, progress < 1 ? styles.progressHidden : null]}
		>
			<View style={[styles.fill, { width: `${progress}%` }]} />
		</View>
	);
};

const MemoizedProgress = React.memo(Progress, () => true);

const Labels = ({ asset }: { asset: ShelfAsset }) => {
	const { language } = useI18n();

	const EpisodeTag = () => {
		const season = asset.id.match(/s[\d][\d]/g);
		const episode = asset.id.match(/e[\d][\d]/g);

		if (!season || !episode) return null;

		return (
			<View style={styles.tag}>
				<Text style={styles.tagText}>
					{season[0]}:{episode[0]}
				</Text>
			</View>
		);
	};

	return (
		<View style={styles.tagContainerAligner}>
			<View style={styles.tagContainer}>
				{asset.asset_type === AssetType.tvshow && config.appName !== 'lov' && (
					<View style={styles.tag}>
						<Text style={styles.tagText}>SERIE</Text>
					</View>
				)}
				{asset.asset_type === AssetType.episode && <EpisodeTag />}
				{asset.extended?.custom?.tags &&
					language &&
					asset.extended?.custom?.tags[language.toLocaleLowerCase()] &&
					asset.extended?.custom?.tags[language.toLocaleLowerCase()].map(
						(tag) => (
							<View
								key={tag.text}
								style={[
									styles.tag,
									{
										backgroundColor:
											tag?.backgroundColor?.[0] === '#'
												? tag?.backgroundColor
												: '#' + tag?.backgroundColor,
									},
								]}
							>
								<Text
									style={[
										styles.tagText,
										{
											color: tag?.textColor
												? tag?.textColor?.[0] === '#'
													? tag?.textColor
													: '#' + tag?.textColor
												: 'white',
										},
									]}
								>
									{tag?.text}
								</Text>
							</View>
						)
					)}
			</View>
		</View>
	);
};

const MemoizedLabels = React.memo(Labels, () => true);

const Details = ({ asset }: { asset: ShelfAsset }) => {
	return (
		<View
			style={{
				width: getResponsiveScaling(
					asset.isHorizontal ? TILE_LANDSCAPE_WIDTH : TILE_WIDTH - 10
				).width,
				minHeight: getResponsiveScaling(20).width,
				marginTop: getScaledValue(18),
			}}
		>
			<View
				style={{
					flexWrap: 'nowrap',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				{asset.title && (
					<Text
						style={styles.detailTitle}
						numberOfLines={1}
					>
						{asset.title}
					</Text>
				)}
				{!!asset.duration && asset.duration > 0 && (
					<Text
						style={styles.detailTime}
						numberOfLines={1}
					>
						{`${Math.round(asset.duration / 60)} ${
							Math.round(asset.duration / 60) === 1 ? 'minute' : 'minutes'
						}`}
					</Text>
				)}
			</View>
			{!!asset.description && (
				<Text
					style={styles.detailDescription}
					numberOfLines={2}
				>
					{asset.description}
				</Text>
			)}
		</View>
	);
};

export const MemoizedDetails = React.memo(Details, () => true);
