import { View } from '@flexn/sdk';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { isTvos } from '@rnv/renative';
import React, { useCallback, useEffect, useRef } from 'react';
import { ActivityIndicator, BackHandler, StyleSheet, Text } from 'react-native';

import colors from '../../platformAssets/runtime/colors';
import BackgroundPoster from '../components/background-poster';
import NoResults from '../components/no-results';
import Screen from '../components/screen';
import { SearchResults } from '../components/search-input/searchResults';
import Title from '../components/title';
import { useAsset, useSetAsset } from '../hooks/api';
import { useGenresShelves, useSearchGenreShelves } from '../hooks/shelves';
import { useI18n } from '../hooks/useI18n';
import { useKeyHandler } from '../hooks/useKeyHandler';
import { ShelfAsset } from '../models/Asset';
import { NavigatorParameterList } from '../navigators/main-screen-navigator';
import ClientTracker from '../services/event-tracker/ClientTracker';
import UserInterActionEvent, {
	USER_INTERACTION_CONTEXT,
	USER_INTERACTION_FEATURES,
	USER_INTERACTION_TYPE,
} from '../services/event-tracker/UserInterActionEvent';
import isNative from '../utils/isNative';
import { KEY_BACK } from '../utils/keymap';
import { getScaledValue, getResponsiveScaling } from '../utils/scale';
import TvEventControl from '../utils/TvEventControl';
import config from '../../platformAssets/runtime/config';
import Image from '../components/image';
import {
	IMAGE_LANDSCAPE_HEIGHT,
	IMAGE_LANDSCAPE_WIDTH,
} from '../components/tile/tileBase';

type GenreScreenProps = NativeStackScreenProps<NavigatorParameterList, 'Genre'>;

const styles = StyleSheet.create({
	screenContainer: {
		backgroundColor: 'black',
		flex: 1,
	},

	noResults: {
		flex: 1,
		alignItems: 'center',
		color: colors.secondaryText,
		fontSize: getScaledValue(18),
		marginBottom: getScaledValue(8),
	},
	title: {
		color: colors.primaryText,
		paddingLeft: getScaledValue(40),
		marginBottom: getScaledValue(20),
		marginLeft: getScaledValue(40),
		marginTop: getScaledValue(40),
	},
	titleImage: {
		color: colors.primaryText,
		width: getResponsiveScaling(IMAGE_LANDSCAPE_WIDTH).width,
		height: getResponsiveScaling(IMAGE_LANDSCAPE_HEIGHT).width,
		paddingLeft: getScaledValue(40),
		marginBottom: getScaledValue(20),
		marginLeft: getScaledValue(40),
		marginTop: getScaledValue(40),
	},
});

const GenreScreen: React.FC<GenreScreenProps> = ({
	navigation,
	route: {
		params: { genre },
	},
}: GenreScreenProps) => {
	const timeoutRef = useRef<any | undefined>(undefined);
	const { data: selectedAsset } = useAsset();
	const { mutate: setAsset } = useSetAsset();

	const { translate } = useI18n();
	const { genres, genresLoading } = useGenresShelves();

	const handleTilePress = useCallback(
		(asset: ShelfAsset) => {
			const userInterActionEvent = new UserInterActionEvent();
			userInterActionEvent.setFeature(USER_INTERACTION_FEATURES.SEARCH);
			userInterActionEvent.setContext(USER_INTERACTION_CONTEXT.SHELF_LIST);
			userInterActionEvent.setType(USER_INTERACTION_TYPE.SHELF_TILE);
			userInterActionEvent.setName(`shelf_${asset.shelfId}_asset_${asset.id}`);
			userInterActionEvent.setBody({
				shelf: asset.shelfId,
				asset: asset.id,
			});

			ClientTracker.sendUserInterActionEvent(userInterActionEvent);
			navigation.navigate('AssetDetail', { asset });
		},
		[navigation]
	);

	const handleTileFocus = useCallback(
		(asset: ShelfAsset) => {
			console.log('-------------- focused on', asset.id);

			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}

			timeoutRef.current = setTimeout(() => {
				setAsset(asset);
			}, 250);
		},
		[setAsset]
	);

	useEffect(() => {
		if (isTvos()) {
			TvEventControl.enableTVMenuKey();
		}

		const handleBackButtonClick = () => {
			return false;
		};
		BackHandler.addEventListener('hardwareBackPress', handleBackButtonClick);
		return () => {
			if (isTvos()) {
				TvEventControl.disableTVMenuKey();
			}

			BackHandler.removeEventListener(
				'hardwareBackPress',
				handleBackButtonClick
			);
		};
	}, []);

	useKeyHandler([KEY_BACK], (key) => {
		if (!isNative) {
			if (key === KEY_BACK) {
				navigation.goBack();
			}
		}
	});

	const { shelves, shelvesError, shelvesLoading } = useSearchGenreShelves(
		genre,
		true
	);

	const getGenreItemBackground = () => {
		const item = genres.filter(
			(g) =>
				g.slug.replace(`-${config.appName}`, '') === genre.toLocaleLowerCase()
		)[0]?.image?.url;
		return item || selectedAsset?.background.uri;
	};

	const getGenreTitle = () => {
		const item = genres.filter(
			(g) =>
				g.slug.replace(`-${config.appName}`, '') === genre.toLocaleLowerCase()
		)[0]?.titleImage?.url;
		if (item) {
			return (
				<Image
					style={styles.titleImage}
					source={{ uri: item }}
					resizeMode="contain"
				/>
			);
		}
		return <Title style={styles.title}>{genre}</Title>;
	};

	return (
		<Screen
			style={styles.screenContainer}
			stealFocus={true}
			focusOptions={{
				horizontalViewportOffset: getScaledValue(60),
				verticalViewportOffset: getScaledValue(20),
			}}
		>
			<BackgroundPoster
				poster={getGenreItemBackground()}
				blur={!selectedAsset}
			>
				{getGenreTitle()}
				{shelvesError && <Text>An error has occurred.</Text>}

				{shelvesLoading ? (
					<ActivityIndicator
						size="large"
						color={colors.warning}
					/>
				) : (
					<View style={{ flex: 1 }}>
						{shelves.length === 0 && (
							<NoResults
								style={styles.noResults}
								title={translate('search.noresults')}
							/>
						)}

						{shelves.length !== 0 && (
							<View
								style={{
									paddingHorizontal: getScaledValue(30),
								}}
							>
								<SearchResults
									shelves={shelves}
									onPress={handleTilePress}
									onFocus={handleTileFocus}
									numberOfCollumns={7}
									style={{
										paddingLeft: getScaledValue(20),
									}}
								/>
							</View>
						)}
					</View>
				)}
			</BackgroundPoster>
		</Screen>
	);
};

export default GenreScreen;
