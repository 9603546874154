import { Image, View } from '@flexn/sdk';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, Text } from 'react-native';

import colors from '../../platformAssets/runtime/colors';
import logo from '../../platformAssets/runtime/icons/logo.png';
import config from '../../platformAssets/runtime/config';
import fonts from '../../platformAssets/runtime/fontnames';
import BackgroundPoster from '../components/background-poster';
import Button from '../components/button/button-flex';
import DeviceInfoTrigger from '../components/device-info-trigger';
import Screen from '../components/screen';
import { useRegistrationBackground } from '../hooks/api';
import { useI18n } from '../hooks/useI18n';
import { NavigatorParameterList } from '../navigators/main-screen-navigator';
import { useAuth } from '../providers/auth';
import { getResponsiveScaling, getScaledValue } from '../utils/scale';
import UserInterActionEvent, {
	USER_INTERACTION_CONTEXT,
	USER_INTERACTION_FEATURES,
	USER_INTERACTION_TYPE,
} from '../services/event-tracker/UserInterActionEvent';
import ClientTracker from '../services/event-tracker/ClientTracker';
import icons from '../icons';
import { useKeyHandler } from '../hooks/useKeyHandler';
import isNative from '../utils/isNative';
import { KEY_BACK } from '../utils/keymap';
import { useConfig } from '../hooks/useConfig';

const styles = StyleSheet.create({
	icon: {
		width: getScaledValue(14),
		height: getScaledValue(14),
		color: colors.primaryText,
	},
	screenContainer: {
		flex: 1,
		paddingTop: getScaledValue(26),
		paddingLeft: getScaledValue(60),
	},
	form: {
		width: '40%',
		flexDirection: 'column',
		justifyContent: 'center',
		gap: getScaledValue(16),
	},
	qrCodeTitle: {
		color: colors.primaryText,
		fontSize: getScaledValue(24),
		marginBottom: getScaledValue(16),
		marginTop: getScaledValue(16),
	},
	qrCodeLabelText: {
		color: colors.primaryText,
		fontSize: getResponsiveScaling(1.5).width,
		fontFamily: fonts.secondaryBold,
		marginLeft: getScaledValue(12),
	},
	logoContainer: {
		zIndex: 100,
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
	},
	logo: {
		margin: 0,
		width: getScaledValue(config.dimensions.logo.width),
		height: getScaledValue(config.dimensions.logo.height),
	},
	logoShadow: {
		zIndex: 99,
		bottom: 0,
		right: getScaledValue(-50),
		height: getScaledValue(260),
		width: getScaledValue(425),
		position: 'absolute',
		opacity: 0.9,
	},
	listItem: {
		display: 'flex',
		flexDirection: 'row',
		marginBottom: getScaledValue(12),
		alignItems: 'center',
	},
	bottomText: {
		display: 'flex',
		justifyContent: 'flex-end',
		flexDirection: 'column',
		flex: 1,
		marginBottom: getScaledValue(24),
	},
});

type RegisterScreenProps = NativeStackScreenProps<
	NavigatorParameterList,
	'Register'
>;
const LOGIN_CODE_CHECKER_TIMEOUT = 3000;

const RegisterScreen: React.FC<RegisterScreenProps> = ({ navigation }) => {
	const { translate } = useI18n();

	const { loginCode, user } = useAuth();
	const { onboardingFlow } = useConfig();
	const [authorizationCode, setAuthorizationCode] = useState(0);
	const { data: background } = useRegistrationBackground();

	const timer = useRef<number | undefined>();

	const handleRegisterPress = async () => {
		navigation.navigate('RegisterCode');
	};
	const handleLogInPress = async () => {
		navigation.navigate('Login');
	};

	const loginCodeChecker = useCallback(async () => {
		clearTimeout(timer.current);

		if (authorizationCode === 0 || user) return;

		try {
			await loginCode(authorizationCode);

			const userInterActionEvent = new UserInterActionEvent();
			userInterActionEvent.setFeature(USER_INTERACTION_FEATURES.LOGIN);
			userInterActionEvent.setContext(USER_INTERACTION_CONTEXT.LOGIN_QRCODE);
			userInterActionEvent.setType(USER_INTERACTION_TYPE.REQUEST);
			userInterActionEvent.setName(`/v2/user/logincode/activate`);

			ClientTracker.sendUserInterActionEvent(userInterActionEvent);

			navigation.navigate('SwitchProfile');
		} catch (error: any) {
			timer.current = setTimeout(() => {
				loginCodeChecker();
			}, LOGIN_CODE_CHECKER_TIMEOUT) as unknown as number;
		}
	}, [authorizationCode, loginCode, navigation, user]);

	useEffect(() => {
		setAuthorizationCode(Math.floor(100000 + Math.random() * 900000));
	}, []);

	useFocusEffect(
		useCallback(() => {
			loginCodeChecker();

			return () => {
				clearTimeout(timer.current);
			};
		}, [loginCodeChecker])
	);

	const isFocused = useIsFocused();

	return (
		<Screen stealFocus={true}>
			<BackgroundPoster
				poster={background}
				animated={true}
			>
				<View style={styles.screenContainer}>
					<DeviceInfoTrigger />

					<View style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
						<View style={styles.form}>
							<View style={styles.logoContainer}>
								<Image
									style={styles.logo}
									source={logo}
									resizeMode="contain"
								/>
							</View>
							<Text style={styles.qrCodeTitle}>
								{translate('register.title')}
							</Text>
							{onboardingFlow ? (
								<Button
									style={{ marginBottom: getScaledValue(16), width: '100%' }}
									withBorder={true}
									onPress={handleRegisterPress}
									hasTVPreferredFocus={navigation.isFocused()}
									label={translate('register.button')}
									track={{
										feature: USER_INTERACTION_FEATURES.LOGIN,
										context: USER_INTERACTION_CONTEXT.LOGIN_EMAIL_PASSWORD,
										type: USER_INTERACTION_TYPE.BUTTON,
										name: 'submit',
									}}
								/>
							) : (
								<Button
									style={{ width: '100%' }}
									withBorder={true}
									onPress={handleLogInPress}
									hasTVPreferredFocus={navigation.isFocused()}
									focusOptions={{
										forbiddenFocusDirections: ['down'],
									}}
									label={translate('register.loginbutton')}
									track={{
										feature: USER_INTERACTION_FEATURES.LOGIN,
										context: USER_INTERACTION_CONTEXT.LOGIN_EMAIL_PASSWORD,
										type: USER_INTERACTION_TYPE.BUTTON,
										name: 'submit',
									}}
								/>
							)}
							<View style={styles.listItem}>
								<Image
									style={styles.icon}
									source={icons.bullet}
									resizeMode="contain"
								/>
								<Text style={styles.qrCodeLabelText}>
									{translate('register.bullet1')}
								</Text>
							</View>
							<View style={styles.listItem}>
								<Image
									style={styles.icon}
									source={icons.bullet}
									resizeMode="contain"
								/>
								<Text style={styles.qrCodeLabelText}>
									{translate('register.bullet2')}
								</Text>
							</View>
							<View style={styles.listItem}>
								<Image
									style={styles.icon}
									source={icons.bullet}
									resizeMode="contain"
								/>
								<Text style={styles.qrCodeLabelText}>
									{translate('register.bullet3')}
								</Text>
							</View>

							<View style={styles.bottomText}>
								{onboardingFlow && (
									<Button
										style={{ width: '100%' }}
										withBorder={true}
										onPress={handleLogInPress}
										hasTVPreferredFocus={navigation.isFocused()}
										focusOptions={{
											forbiddenFocusDirections: ['down'],
										}}
										label={translate('register.loginbutton')}
										track={{
											feature: USER_INTERACTION_FEATURES.LOGIN,
											context: USER_INTERACTION_CONTEXT.LOGIN_EMAIL_PASSWORD,
											type: USER_INTERACTION_TYPE.BUTTON,
											name: 'submit',
										}}
									/>
								)}
							</View>
						</View>
					</View>
				</View>
			</BackgroundPoster>
		</Screen>
	);
};

export default RegisterScreen;
