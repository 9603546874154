import { View } from '@flexn/sdk';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { isWebBased } from '@rnv/renative';
import React, { useCallback, useEffect, useRef } from 'react';
import { Dimensions, StyleSheet, Text } from 'react-native';
import { getReadableVersion } from 'react-native-device-info';

import packageFile from '../../package.json';
import colors from '../../platformAssets/runtime/colors';
import BackgroundPoster from '../components/background-poster';
import Button from '../components/button/button-flex';
import DeviceInfoTrigger from '../components/device-info-trigger';
import Screen from '../components/screen';
import ShelfList from '../components/shelf-list/shelf-list-flexn';
import Title from '../components/title';
import { useAsset, useJumbotron, useSetAsset } from '../hooks/api';
import { useProfileShelves } from '../hooks/shelves';
import { useI18n } from '../hooks/useI18n';
import { ShelfAsset } from '../models/Asset';
import { NavigatorParameterList } from '../navigators/main-screen-navigator';
import { useAuth } from '../providers/auth';
import ClientTracker from '../services/event-tracker/ClientTracker';
import UserInterActionEvent, {
	USER_INTERACTION_CONTEXT,
	USER_INTERACTION_FEATURES,
	USER_INTERACTION_TYPE,
} from '../services/event-tracker/UserInterActionEvent';
import isNative from '../utils/isNative';
import { getScaledValue } from '../utils/scale';

const styles = StyleSheet.create({
	screenContainer: {
		flex: 1,
	},
	contentContainer: {
		paddingTop: getScaledValue(20),
		paddingLeft: getScaledValue(100),
	},
	actionMenu: {
		flexDirection: 'row',
	},
	shelvesContainer: {
		flex: 1,
	},
	button: {
		width: getScaledValue(256),
		marginBottom: getScaledValue(16),
		marginRight: getScaledValue(8),
	},
	version: {
		position: 'absolute',
		zIndex: 1,
		right: getScaledValue(8),
		top: getScaledValue(8),
		color: colors.primaryText,
		fontSize: getScaledValue(9),
	},
});

type ProfileScreenProps = NativeStackScreenProps<
	NavigatorParameterList,
	'Profile'
>;

const ProfileScreen: React.FC<ProfileScreenProps> = ({ navigation }) => {
	console.log('--- profile screen render');

	const timeoutRef = useRef<any | undefined>(undefined);
	const { data: jumbotron } = useJumbotron();

	const { profile, logout, user } = useAuth();
	const { shelves, shelvesLoading } = useProfileShelves();
	const { translate } = useI18n();

	const { data: selectedAsset } = useAsset();
	const { mutate: setAsset } = useSetAsset();

	const handleSwitchProfilePress = () => {
		navigation.navigate('SwitchProfile');
	};

	const handleLogOutPress = () => {
		logout();
	};

	const handleTilePress = useCallback(
		(asset: ShelfAsset) => {
			const userInterActionEvent = new UserInterActionEvent();
			userInterActionEvent.setFeature(USER_INTERACTION_FEATURES.PROFILE_PAGE);
			userInterActionEvent.setContext(USER_INTERACTION_CONTEXT.SHELF_LIST);
			userInterActionEvent.setType(USER_INTERACTION_TYPE.SHELF_TILE);
			userInterActionEvent.setName(`shelf_${asset.shelfId}_asset_${asset.id}`);
			userInterActionEvent.setBody({
				shelf: asset.shelfId,
				asset: asset.id,
			});

			ClientTracker.sendUserInterActionEvent(userInterActionEvent);

			navigation.navigate('AssetDetail', { asset });
		},
		[navigation]
	);

	const handleButtonFocus = useCallback(() => {
		jumbotron && setAsset(jumbotron);
	}, [jumbotron, setAsset]);

	const handleTileFocus = useCallback(
		(asset: ShelfAsset) => {
			console.log('-------------- focused on', asset.id);

			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}

			timeoutRef.current = setTimeout(() => {
				setAsset(asset);
			}, 250);
		},
		[setAsset]
	);

	useEffect(() => {
		if (!user) {
			navigation.navigate('Register');
		}
	}, [user]);

	return (
		<Screen
			style={styles.screenContainer}
			stealFocus={true}
			focusOptions={{
				horizontalViewportOffset: getScaledValue(60),
				verticalViewportOffset: getScaledValue(20),
			}}
		>
			<BackgroundPoster poster={selectedAsset?.background.uri}>
				<View style={styles.contentContainer}>
					<Title>{translate('user.welcome', { name: profile?.name })}</Title>
					<Text style={styles.version}>
						© {translate('app.name').toUpperCase()} {new Date().getFullYear()} •{' '}
						{isWebBased ? packageFile?.version : getReadableVersion()}
					</Text>

					<DeviceInfoTrigger />

					<View style={styles.actionMenu}>
						<Button
							style={styles.button}
							onPress={handleSwitchProfilePress}
							onFocus={handleButtonFocus}
							label={translate('user.profile.switch')}
							track={{
								feature: USER_INTERACTION_FEATURES.PROFILE_PAGE,
								context: USER_INTERACTION_CONTEXT.BUTTON_ROW,
								type: USER_INTERACTION_TYPE.BUTTON,
								name: 'profile.switch',
							}}
						/>

						<Button
							style={styles.button}
							onPress={handleLogOutPress}
							onFocus={handleButtonFocus}
							label={translate('user.logout')}
							track={{
								feature: USER_INTERACTION_FEATURES.PROFILE_PAGE,
								context: USER_INTERACTION_CONTEXT.BUTTON_ROW,
								type: USER_INTERACTION_TYPE.BUTTON,
								name: 'user.logout',
							}}
						/>
					</View>
				</View>

				{!shelvesLoading && (
					<View style={styles.shelvesContainer}>
						<ShelfList
							style={{
								height: Dimensions.get('screen').height,
							}}
							key={profile?.id}
							items={shelves}
							onPress={handleTilePress}
							onFocus={handleTileFocus}
							initialXOffset={getScaledValue(40)}
							animatorOptions={{
								type: isNative ? 'scale_with_border' : 'border',
								scale: 1.1,
							}}
							focusOptions={{
								forbiddenFocusDirections: ['right'],
								nextFocusLeft: 'tab-bar',
							}}
							titleStyle={{
								paddingLeft: getScaledValue(100),
							}}
							cardStyle={{
								borderWidth: isNative ? getScaledValue(2) : 0,
								borderColor: '#FFFFFF',
								borderRadius: getScaledValue(16),
							}}
						/>
					</View>
				)}
			</BackgroundPoster>
		</Screen>
	);
};

export default ProfileScreen;
