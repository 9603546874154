import { AssetType } from './AssetType';
import { BusinessType } from './BusinessType';

export type SubscriptionResponse = Subscription[];

export type Subscription = {
	id: string;
	user_id: string;
	identifier: string;
	can_be_reactivated: boolean;
	subscription_plan: {
		id: string;
		asset_type: AssetType;
		subscription_plan_type: SubscriptionPlanType;
		title: string;
		original_title: string;
		system: string;
		description: string;
		billing_cycle_type: BillingCycleType;
		billing_frequency: number;
		price: number;
		currency: string;
		country: string;
		countries: string[];
		start: Date;
		end: Date;
		only_available_with_promotion: boolean;
		recurring: boolean;
		payment_providers: {
			name: string;
			product_reference: string;
		}[];
		promotions: [
			{
				title: string;
				code: string;
				start_date: Date;
				end_date: Date;
				discount: number;
				discount_type: DiscountType;
			}
		];
		asset_types: AssetType[];
		asset_ids: string[];
		free_trial: number;
		business_type: BusinessType;
		only_available_for_logged_in_users: boolean;
		is_downloadable: boolean;
		catch_up_hours: number;
		number_of_supported_devices: number;
		number_of_supported_profiles: number;
		tier: string;
		movie_audio_languages: string[];
		tv_show_audio_languages: string[];
		channel_audio_languages: string[];
		duration_text: string;
		pause_min_days: number;
		pause_max_days: number;
	};
	new_plan_id: string;
	subscription_start: Date;
	subscription_end: Date;
	state: SubscriptionState;
	recurring_enabled: boolean;
	payment_provider: string;
	free_trial: number;
	create_date: Date;
	ip_address: string;
	country: string;
	region: string;
	discount_amount: number;
	subscription_resumable_days: number;
	is_pausable: boolean;
	is_resumable: boolean;
};

export enum SubscriptionState {
	ACTIVE = 'active',
	PAUSED = 'paused',
	PENDING = 'pending',
}
type DiscountType = 'currency';
type BillingCycleType = 'hours';
type SubscriptionPlanType = 'SVOD';
