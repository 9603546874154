import React from 'react';
import { StyleSheet, FlatList, Text } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome5';

import colors from '../../../platformAssets/runtime/colors';
import Pressable from '../../components/pressable';
import { getScaledValue, getResponsiveScaling } from '../../utils/scale';

import keyboardDataEng from './eng.json';

type Props = {
	parentContext?: any;

	setTextInputFocused: (text: string) => void;
	searchUpdate: (text: string) => void;
	textInputFocused: string;
};

const styles = StyleSheet.create({
	keyboard: {
		width: getResponsiveScaling(20).width,
	},
	pressable: {
		width: getResponsiveScaling(2).width,
		height: getResponsiveScaling(2).width,
		color: ' white',
		margin: getScaledValue(3),
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: getScaledValue(4),
	},
	pressableFocused: {
		backgroundColor: colors.primary,
	},
	letter: {
		color: 'white',
		fontWeight: '700',
		fontSize: getResponsiveScaling(1.5).width,
	},
});

export type TKeyboardAdditionalLocales = Array<{
	langSwitchButton: {
		text: string;
	};
	switchSpecButton: {
		text: string;
	};
	letters: Array<{ text: string }>;
}>;

export const SearchInput = ({
	parentContext,
	setTextInputFocused,
	searchUpdate,
	textInputFocused,
}: Props) => {
	const keyboardDataLocale: TKeyboardAdditionalLocales = [keyboardDataEng];
	const keyboardData = [...keyboardDataLocale[0].letters];

	return (
		<FlatList
			style={styles.keyboard}
			data={keyboardData}
			keyExtractor={({ text }) => text}
			numColumns={5}
			showsHorizontalScrollIndicator={false}
			showsVerticalScrollIndicator={false}
			renderItem={({ item, index }) => (
				<Pressable
					focusOptions={{
						focusKey: index === 0 ? 'keyboard' : undefined,
						forbiddenFocusDirections:
							index > keyboardData.length - 1 - 5 ? ['down'] : [],
					}}
					onFocus={() => {
						setTextInputFocused(item.text);
					}}
					onBlur={() => {
						setTextInputFocused('');
					}}
					onPress={() => {
						searchUpdate(item.text);
					}}
					style={[
						styles.pressable,
						textInputFocused === item.text && styles.pressableFocused,
						(item.text === 'backspace' || item.text === 'window-minimize') && {
							backgroundColor: 'transparent',
						},
					]}
					parentContext={parentContext}
				>
					{item.text === 'backspace' || item.text === 'window-minimize' ? (
						<Icon
							name={item.text}
							size={getResponsiveScaling(1.5).width}
							color={textInputFocused === item.text ? colors.primary : 'white'}
						/>
					) : (
						<Text style={styles.letter}>{item.text}</Text>
					)}
				</Pressable>
			)}
		/>
	);
};

SearchInput.displayName = 'SearchInput';
