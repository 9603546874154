import { isTizen, isTvos, isWeb, isWebos } from '@rnv/renative';
import { PixelRatio, Dimensions } from 'react-native';
import {
	widthPercentageToDP as wp,
	heightPercentageToDP as hp,
} from 'react-native-responsive-screen';

export const smallScreenType = () => {
	const width = PixelRatio.roundToNearestPixel(
		Dimensions.get('window').width * PixelRatio.get()
	);

	return width <= 1400;
};

export const bigScreenCaculator = (v: number) => {
	if (isWebos()) {
		if (PixelRatio.get() >= 3) {
			return v * 1.3;
		}

		return v * 2;
	}

	return v * 2;
};

export const getScaledValue = (v: number) => {
	if (isWebos() || isTizen()) {
		return smallScreenType() ? v : bigScreenCaculator(v);
	}

	if (isTvos()) {
		return v * 2;
	}

	return v;
};

export const getResponsiveScaling = (v: number) => {
	return { width: wp(v), height: hp(v) };
};
