import { View } from '@flexn/sdk';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useState } from 'react';
import { StyleSheet, Image, Text } from 'react-native';

import config from '../../platformAssets/runtime/config';
import logo from '../../platformAssets/runtime/icons/logo.png';
import BackgroundPoster from '../components/background-poster';
import Button from '../components/button/button-flex';
import Screen from '../components/screen';
import Title from '../components/title';
import { useAsset, useSubscriptions } from '../hooks/api';
import { useI18n } from '../hooks/useI18n';
import { NavigatorParameterList } from '../navigators/main-screen-navigator';
import { getResponsiveScaling, getScaledValue } from '../utils/scale';
import {
	USER_INTERACTION_CONTEXT,
	USER_INTERACTION_FEATURES,
	USER_INTERACTION_TYPE,
} from '../services/event-tracker/UserInterActionEvent';
import colors from '../../platformAssets/runtime/colors';
import fonts from '../../platformAssets/runtime/fontManager';
import { addDays, format } from 'date-fns';
import { useConfig } from '../hooks/useConfig';

const subscriptionReactivation = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'rgba(0,0,0,0.4)',
	},

	buttonContainer: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: getScaledValue(24),
	},
	button: {
		width: getScaledValue(110),
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: getScaledValue(8),
	},

	information: {
		color: 'white',
		fontSize: getScaledValue(18),
	},

	logoContainer: {
		position: 'absolute',
		zIndex: 100,
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
		bottom: getScaledValue(16),
		right: getScaledValue(16),
	},
	logo: {
		margin: 0,
		width: getScaledValue(config.dimensions.logo.width),
		height: getScaledValue(config.dimensions.logo.height),
	},

	description: {
		width: '40%',
		fontFamily: fonts.secondary,
		fontSize: getResponsiveScaling(1.5).width,
		color: colors.secondaryText,
		marginTop: getScaledValue(8),
		marginBottom: getScaledValue(8),
		textAlign: 'center',
	},
});

type SubscriptionReactivationScreenProps = NativeStackScreenProps<
	NavigatorParameterList,
	'ReactivateSubscription'
>;

const SubscriptionReactivationScreen = ({
	navigation,
}: SubscriptionReactivationScreenProps) => {
	const { data: selectedAsset } = useAsset();
	const { freeTrialDays } = useConfig();
	const { reactivateSubscription, reactivatableSubscription } =
		useSubscriptions();

	const { translate, dateFnsLocale } = useI18n();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const handleReactiveClick = async () => {
		setLoading(true);

		try {
			await reactivateSubscription(reactivatableSubscription);

			navigation.goBack();
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Screen stealFocus={true}>
			<BackgroundPoster poster={selectedAsset?.background.uri}>
				<View style={[subscriptionReactivation.container]}>
					<Title style={[subscriptionReactivation.information]}>
						{translate('subscription_reactivation.title')}
					</Title>

					<Text style={subscriptionReactivation.description}>
						{translate('subscription_reactivation.description', {
							date: format(addDays(new Date(), freeTrialDays), 'PP', {
								locale: dateFnsLocale,
							}),
						})}
					</Text>

					{error && (
						<Text
							style={[
								subscriptionReactivation.description,
								{ color: colors.warning },
							]}
						>
							{error}
						</Text>
					)}

					<View style={subscriptionReactivation.buttonContainer}>
						<Button
							centeredText
							style={subscriptionReactivation.button}
							label={translate('subscription_reactivation.reactive')}
							focusOptions={{
								focusKey: 'reactivate',
								hasInitialFocus: true,
								nextFocusLeft: 'exit',
							}}
							onPress={handleReactiveClick}
							track={{
								feature: USER_INTERACTION_FEATURES.SUBSCRIPTION_REACTIVE,
								context: USER_INTERACTION_CONTEXT.BUTTON_ROW,
								type: USER_INTERACTION_TYPE.BUTTON,
								name: 'reactivate',
							}}
							processing={loading}
						/>
						<Button
							centeredText
							style={subscriptionReactivation.button}
							label={translate('subscription_reactivation.exit')}
							focusOptions={{
								focusKey: 'exit',
								nextFocusRight: 'reactivate',
							}}
							onPress={() => {
								navigation.goBack();
							}}
							track={{
								feature: USER_INTERACTION_FEATURES.SUBSCRIPTION_REACTIVE,
								context: USER_INTERACTION_CONTEXT.BUTTON_ROW,
								type: USER_INTERACTION_TYPE.BUTTON,
								name: 'exit',
							}}
						/>
					</View>
				</View>
				<View style={subscriptionReactivation.logoContainer}>
					<Image
						style={subscriptionReactivation.logo}
						source={logo}
						resizeMode="contain"
					/>
				</View>
			</BackgroundPoster>
		</Screen>
	);
};

SubscriptionReactivationScreen.displayName = 'ReactivateSubscriptionScreen';

export default SubscriptionReactivationScreen;
