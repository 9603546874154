import { ThemeContext } from '@emotion/react';
import { CoreManager, Screen } from '@flexn/sdk';
import { BottomTabBarProps } from '@react-navigation/bottom-tabs';
import { isWebos } from '@rnv/renative';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Animated, StyleSheet, BackHandler } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import { useMenu } from '../../hooks/api';
import { useI18n } from '../../hooks/useI18n';
import { useKeyHandler } from '../../hooks/useKeyHandler';
import icons, { iconsActive } from '../../icons';
import { useAuth } from '../../providers/auth';
import isNative from '../../utils/isNative';
import { KEY_BACK } from '../../utils/keymap';
import TvEventControl from '../../utils/TvEventControl';

import TabBarItem from './tab-bar-item';
import { getScaledValue, getResponsiveScaling } from '../../utils/scale';
import {
	USER_INTERACTION_CONTEXT,
	USER_INTERACTION_FEATURES,
	USER_INTERACTION_TYPE,
} from '../../services/event-tracker/UserInterActionEvent';
import { useUI } from '../../hooks/useUI';

const TRANSLATE_VAL_HIDDEN = getScaledValue(-300);

const tabBarStyles = StyleSheet.create({
	gradient: {
		position: 'absolute',
		zIndex: 1,
		left: 0,
		top: 0,
		bottom: 0,
		width: getScaledValue(100),
		flex: 1,
	},
	gradientFull: {
		width: '100%',
	},

	container: {
		position: 'absolute',
		zIndex: 2,
		width: getScaledValue(100),
		height: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		pointerEvents: 'box-none',
		backgroundColor: 'transparent',
	},
	menuContainer: {
		position: 'absolute',
		zIndex: 2,
		left: 0,
		paddingTop: getScaledValue(40),
		backgroundColor: 'transparent',
		justifyContent: 'center',
		flexDirection: 'column',
		width: getResponsiveScaling(10).width,
		height: getResponsiveScaling(100).height,
		pointerEvents: 'box-none',
	},
	sideMenuContainerAnimation: {
		zIndex: 3,
		width: getResponsiveScaling(25).width,
		position: 'absolute',
		left: 0,
		top: 0,
		right: '50%',
		bottom: 0,
	},
	item: {
		margin: getScaledValue(20),
	},

	profile: {
		position: 'absolute',
		top: getScaledValue(10),
	},
});

const TabBar = memo<React.FC<BottomTabBarProps>>(
	({ navigation, state }: BottomTabBarProps) => {
		const currentRoute = state.routeNames[state.index];
		const currentRouteHref = state.routes[state.index].params?.href;
		const [focus, setFocus] = useState(false);
		const { translate } = useI18n();
		const { setIsMenuFocused } = useUI();
		const { user } = useAuth();
		const { configCollection } = useUI();
		const { data: menuItems, isLoading: isLoadingMenuItems } = useMenu({
			id: configCollection,
		});
		const translateBgAnim = useRef(
			new Animated.Value(TRANSLATE_VAL_HIDDEN)
		).current;

		const timer = useRef<number | undefined>();

		console.log('>> rendering tab bar', { currentRoute, currentRouteHref });

		const timing = (
			object: Animated.AnimatedValue,
			toValue: number,
			duration = 200
		): Animated.CompositeAnimation => {
			return Animated.timing(object, {
				toValue,
				duration,
				useNativeDriver: true,
			});
		};

		const opacityAnim = [
			useRef(new Animated.Value(0)).current,
			useRef(new Animated.Value(0)).current,
			useRef(new Animated.Value(0)).current,
		];
		const translateTextAnim = [
			useRef(new Animated.Value(TRANSLATE_VAL_HIDDEN)).current,
			useRef(new Animated.Value(TRANSLATE_VAL_HIDDEN)).current,
			useRef(new Animated.Value(TRANSLATE_VAL_HIDDEN)).current,
		];

		const onFocus = useCallback(() => {
			if (timer.current) {
				clearTimeout(timer.current);
			}

			Animated.parallel([
				timing(translateBgAnim, 0),
				timing(opacityAnim[0], 1, 800),
				timing(opacityAnim[1], 1, 800),
				timing(opacityAnim[2], 1, 800),
				timing(translateTextAnim[0], getScaledValue(60)),
				timing(translateTextAnim[1], getScaledValue(60)),
				timing(translateTextAnim[2], getScaledValue(60)),
			]).start();
			setIsMenuFocused(true);
			setFocus(true);
		}, [opacityAnim, translateBgAnim, translateTextAnim]);

		const onBlur = useCallback(() => {
			timer.current = setTimeout(() => {
				Animated.parallel([
					timing(translateBgAnim, TRANSLATE_VAL_HIDDEN),
					timing(opacityAnim[0], 0, 100),
					timing(opacityAnim[1], 0, 100),
					timing(opacityAnim[2], 0, 100),
					timing(translateTextAnim[0], TRANSLATE_VAL_HIDDEN),
					timing(translateTextAnim[1], TRANSLATE_VAL_HIDDEN),
					timing(translateTextAnim[2], TRANSLATE_VAL_HIDDEN),
				]).start();
				setFocus(false);
				setIsMenuFocused(false);
			}, 100) as unknown as number;
		}, [opacityAnim, translateBgAnim, translateTextAnim]);

		useEffect(() => {
			const isInMenu = menuItems.find((item) => item.title === currentRoute);
			if (
				(currentRoute === 'Home' ||
					currentRoute === 'Search' ||
					currentRoute === 'Profile' ||
					currentRoute === 'SwitchProfile' ||
					currentRoute === 'EditProfile' ||
					isInMenu ||
					currentRoute === 'ExitFlow') &&
				isNative
			) {
				const backAction = () => {
					if (
						state.history[state.history.length - 2]?.key.includes('Register') &&
						currentRoute !== 'RegisterCode' &&
						currentRoute !== 'Login'
					) {
						navigation.navigate('ExitFlow');
						onBlur();
						return true;
					}

					if (currentRoute === 'RegisterCode' || currentRoute === 'Login') {
						navigation.navigate('Register');
						return true;
					}

					if (currentRoute === 'ExitFlow') {
						navigation.goBack();

						return true;
					}
					if (!focus) {
						CoreManager.focusElementByFocusKey('tab-bar');
						return true;
					}

					navigation.navigate('ExitFlow');
					onBlur();

					return true;
				};

				TvEventControl.enableTVMenuKey();
				BackHandler.addEventListener('hardwareBackPress', backAction);

				return () => {
					TvEventControl.disableTVMenuKey();
					BackHandler.removeEventListener('hardwareBackPress', backAction);
				};
			}
			if (currentRoute === 'AssetDetail' && isNative) {
				const backAction = () => {
					if (!navigation.canGoBack()) {
						navigation.navigate('Home');
						onBlur();

						return true;
					}

					return false;
				};

				TvEventControl.enableTVMenuKey();
				BackHandler.addEventListener('hardwareBackPress', backAction);

				return () => {
					TvEventControl.disableTVMenuKey();
					BackHandler.removeEventListener('hardwareBackPress', backAction);
				};
			}
			if (isNative) {
				const backAction = () => {
					if (
						state.history[state.history.length - 2]?.key.includes('Register') &&
						currentRoute !== 'RegisterCode' &&
						currentRoute !== 'Login'
					) {
						navigation.navigate('ExitFlow');
						onBlur();
						return true;
					}

					if (currentRoute === 'Register') {
						navigation.navigate('ExitFlow');
						onBlur();
						return true;
					}

					if (currentRoute === 'RegisterCode' || currentRoute === 'Login') {
						navigation.navigate('Register');
						return true;
					}

					if (currentRoute === 'ExitFlow') {
						navigation.goBack();

						return true;
					}
					if (!focus) {
						CoreManager.focusElementByFocusKey('tab-bar');
						return true;
					}

					navigation.navigate('ExitFlow');
					onBlur();

					return true;
				};

				TvEventControl.enableTVMenuKey();
				BackHandler.addEventListener('hardwareBackPress', backAction);

				return () => {
					TvEventControl.disableTVMenuKey();
					BackHandler.removeEventListener('hardwareBackPress', backAction);
				};
			}
		}, [currentRoute, focus, navigation, onBlur]);

		useKeyHandler([KEY_BACK], (key) => {
			if (!isNative) {
				if (key === KEY_BACK) {
					const isInMenu = menuItems.find(
						(item) => item.title === currentRoute
					);
					if (
						state.history[state.history.length - 2]?.key.includes('Register') &&
						currentRoute !== 'RegisterCode' &&
						currentRoute !== 'Login'
					) {
						return;
					}
					if (currentRoute === 'RegisterCode' || currentRoute === 'Login') {
						navigation.navigate('Register');
						return;
					}

					if (currentRoute === 'Register') {
						navigation.navigate('ExitFlow');
						onBlur();
						return;
					}

					if (
						currentRoute === 'Home' ||
						currentRoute === 'Search' ||
						currentRoute === 'Profile' ||
						currentRoute === 'SwitchProfile' ||
						currentRoute === 'EditProfile' ||
						isInMenu ||
						currentRoute === 'ExitFlow' ||
						currentRoute === 'ReactivateSubscription'
					) {
						if (currentRoute === 'ExitFlow') {
							navigation.navigate('Home');
							return;
						}
						if (!focus) {
							CoreManager.focusElementByFocusKey('tab-bar');
							return;
						}

						navigation.navigate('ExitFlow');
						onBlur();
					}
				}
			}
		});

		const onPress = (navigateTo: string, params = {}) => {
			console.log('onPress', params);

			navigation.navigate(navigateTo, params);

			Animated.parallel([
				timing(translateBgAnim, TRANSLATE_VAL_HIDDEN),
				timing(opacityAnim[0], 0, 100),
				timing(opacityAnim[1], 0, 100),
				timing(opacityAnim[2], 0, 100),
				timing(translateTextAnim[0], TRANSLATE_VAL_HIDDEN),
				timing(translateTextAnim[1], TRANSLATE_VAL_HIDDEN),
				timing(translateTextAnim[2], TRANSLATE_VAL_HIDDEN),
			]).start();
			setFocus(false);
		};

		useEffect(() => {
			if (isWebos()) {
				const handleWindowMouseMove = (event) => {
					if (getScaledValue(event.screenX) < getScaledValue(150)) {
						setFocus(true);
						onFocus();
					}
					if (getScaledValue(event.screenX) > getScaledValue(400)) {
						setFocus(false);
						onBlur();
					}
				};
				window.addEventListener('mousemove', handleWindowMouseMove);

				return () => {
					window.removeEventListener('mousemove', handleWindowMouseMove);
				};
			}
		}, [onBlur, onFocus]);

		if (
			[
				'AssetDetail',
				'SwitchProfile',
				'ExitFlow',
				'EditProfile',
				'ReactivateSubscription',
				'Genre',
				'Register',
				'RegisterCode',
				'Login',
			].includes(currentRoute)
		) {
			return null;
		}

		if (isLoadingMenuItems) return null;

		return (
			<Screen
				style={[
					tabBarStyles.menuContainer,
					focus && {
						width: '100%',
					},
				]}
				stealFocus={false}
				onFocus={onFocus}
				onBlur={onBlur}
				focusOptions={{
					focusKey: 'tab-bar',
				}}
			>
				<LinearGradient
					colors={['black', 'transparent']}
					start={{ x: 0, y: 0 }}
					end={{ x: 1, y: 0 }}
					style={[tabBarStyles.gradient, focus && tabBarStyles.gradientFull]}
				/>
				<Animated.View
					style={[
						tabBarStyles.sideMenuContainerAnimation,
						{
							transform: [{ translateX: translateBgAnim }],
						},
					]}
				/>
				{user && focus && (
					<TabBarItem
						style={tabBarStyles.profile}
						isProfile
						opacityAnim={opacityAnim[0]}
						transformAnim={translateTextAnim[0]}
						icon={icons.home}
						iconActive={iconsActive.home}
						isActive={currentRoute === 'SwitchProfile'}
						onPress={() => onPress('SwitchProfile')}
						onFocus={onFocus}
						onBlur={onBlur}
						focusOptions={{
							focusKey: 'tab-bar-switch',
							nextFocusDown: 'tab-bar-home',
							forbiddenFocusDirections: ['left', 'up', 'right'],
						}}
						track={{
							feature: USER_INTERACTION_FEATURES.TAB_BAR,
							context: USER_INTERACTION_CONTEXT.TAB_BAR_ITEM,
							type: USER_INTERACTION_TYPE.BUTTON,
							name: `switch_profile`,
						}}
					/>
				)}
				{menuItems?.map((menuItem, index) => {
					return (
						<TabBarItem
							key={`tab-bar-item-${index}`}
							text={menuItem.title}
							opacityAnim={opacityAnim[0]}
							transformAnim={translateTextAnim[0]}
							icon={icons[menuItem?.icon] || icons.genres}
							iconActive={iconsActive[menuItem?.icon] || icons.genres}
							isActive={currentRouteHref === menuItem.href}
							onPress={() =>
								onPress(menuItem.title, {
									href: menuItem.href,
									collectionId: menuItem.href.split('/')[2],
								})
							}
							onFocus={onFocus}
							onBlur={onBlur}
							focusOptions={{
								hasInitialFocus: index === 0,
								focusKey: `tab-bar-item-${menuItem.href}`,
								nextFocusUp:
									index === 0
										? 'tab-bar-switch'
										: `tab-bar-item-${menuItems[index - 1].href}`,
								nextFocusDown:
									menuItems[index + 1] > menuItems.length - 1
										? 'tab-bar-search'
										: `tab-bar-item-${menuItems[index + 1]?.href}`,
								forbiddenFocusDirections: ['left'],
								animatorOptions: {
									type: 'scale',
									scale: 1.0,
								},
							}}
							track={{
								feature: USER_INTERACTION_FEATURES.BROWSE,
								context: USER_INTERACTION_CONTEXT.TAB_BAR_ITEM,
								type: USER_INTERACTION_TYPE.BUTTON,
								name: menuItem.title,
							}}
						/>
					);
				})}
				<TabBarItem
					text={translate('menu.search')}
					opacityAnim={opacityAnim[0]}
					transformAnim={translateTextAnim[0]}
					icon={icons.search}
					iconActive={iconsActive.search}
					isActive={currentRoute === 'Search'}
					onPress={() => onPress('Search')}
					onFocus={onFocus}
					onBlur={onBlur}
					focusOptions={{
						focusKey: 'tab-bar-search',
						nextFocusDown: 'tab-bar-profile',
						nextFocusUp: `tab-bar-${menuItems[menuItems.length - 1]?.href}`,
						forbiddenFocusDirections: ['left'],
						animatorOptions: {
							type: 'scale',
							scale: 1.0,
						},
					}}
					track={{
						feature: USER_INTERACTION_FEATURES.BROWSE,
						context: USER_INTERACTION_CONTEXT.TAB_BAR_ITEM,
						type: USER_INTERACTION_TYPE.BUTTON,
						name: `search`,
					}}
				/>
				<TabBarItem
					testID="tab-bar-profile"
					text={translate('menu.profile')}
					opacityAnim={opacityAnim[0]}
					transformAnim={translateTextAnim[0]}
					icon={icons.user}
					iconActive={iconsActive.user}
					isActive={currentRoute === 'Profile'}
					onPress={() => onPress('Profile')}
					onFocus={onFocus}
					onBlur={onBlur}
					focusOptions={{
						focusKey: 'tab-bar-profile',
						nextFocusUp: 'tab-bar-search',
						forbiddenFocusDirections: ['left', 'down'],
						animatorOptions: {
							type: 'scale',
							scale: 1.0,
						},
					}}
					track={{
						feature: USER_INTERACTION_FEATURES.BROWSE,
						context: USER_INTERACTION_CONTEXT.TAB_BAR_ITEM,
						type: USER_INTERACTION_TYPE.BUTTON,
						name: 'profile',
					}}
				/>
			</Screen>
		);
	},
	(prevProps, nextProps) => {
		return false;
	}
);

TabBar.displayName = 'TabBar';

export default TabBar;
