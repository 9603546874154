import { View, Modal, ScrollView, FlatList } from '@flexn/sdk';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
// import * as Sentry from '@sentry/browser';
import { isTizen, isWebos } from '@rnv/renative';
import React, { useEffect, useRef, useState } from 'react';
import { Image, StyleSheet, Text, TextInput } from 'react-native';

import colors from '../../platformAssets/runtime/colors';
import config from '../../platformAssets/runtime/config';
import fontnames from '../../platformAssets/runtime/fontnames';
import logo from '../../platformAssets/runtime/icons/logo.png';
import Avatar from '../components/avatar';
import Button from '../components/button/button-flex';
import ColorSelect from '../components/color-select/color-select';
import Input from '../components/input';
import Pressable from '../components/pressable';
import Screen from '../components/screen';
import Title from '../components/title';
import { useI18n } from '../hooks/useI18n';
import { useKeyHandler } from '../hooks/useKeyHandler';
import { Profile } from '../models/Profile';
import { NavigatorParameterList } from '../navigators/main-screen-navigator';
import { useAuth } from '../providers/auth';
import isNative from '../utils/isNative';
import { KEY_BACK } from '../utils/keymap';
import { INPUT_EXIT } from '../utils/keymap/index.tizen';
import { getScaledValue } from '../utils/scale';

const styles = StyleSheet.create({
	screenContainer: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'black',
	},
	profileContainer: {
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
	},
	profileList: {
		flexDirection: 'row',
		justifyContent: 'center',
		width: '100%',
	},
	profileCard: {
		alignItems: 'center',
		justifyContent: 'center',
		margin: getScaledValue(20),
		width: getScaledValue(150),
	},
	profileName: {
		fontFamily: fontnames.secondary,
		fontSize: getScaledValue(16),
		margin: getScaledValue(12),
		color: colors.primaryText,
	},

	textInput: {
		paddingHorizontal: getScaledValue(16),
		paddingVertical: getScaledValue(16),
		borderWidth: 0,
		borderRadius: getScaledValue(8),
		overflow: 'hidden',
		fontSize: getScaledValue(16),
		color: colors.secondaryText,
		backgroundColor: colors.inactive,
		justifyContent: 'center',
		width: getScaledValue(256),
		padding: getScaledValue(16),
	},
	textInputFocused: {
		backgroundColor: colors.textInputFocus,
		// color: colors.overlay,
	},
	button: {
		width: getScaledValue(120),
		marginBottom: getScaledValue(16),
		marginRight: getScaledValue(8),
	},
	buttonDelete: {
		width: getScaledValue(200),
		marginBottom: getScaledValue(16),
		marginRight: getScaledValue(8),
	},
	logoContainer: {
		position: 'absolute',
		zIndex: 100,
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
		bottom: getScaledValue(16),
		right: getScaledValue(16),
	},
	logo: {
		margin: 0,
		width: getScaledValue(config.dimensions.logo.width),
		height: getScaledValue(config.dimensions.logo.height),
	},
	errorText: {
		color: 'tomato',
		fontSize: getScaledValue(16),
		marginBottom: getScaledValue(16),
	},
});

type EditProfileScreenProps = NativeStackScreenProps<
	NavigatorParameterList,
	'EditProfile'
>;

const colorinos = [
	'#28394E',
	'#DF1121',
	'#7F22FD',
	'#00696B',
	'#2C008A',
	'#0A72B2',
	'#FF4D00',
	'#CC4B00',
	'#377A00',
	'#377A00',
	'#000000',
];

const EditProfileScreen: React.FC<EditProfileScreenProps> = ({
	route: {
		params: { profile },
	},
	navigation,
}) => {
	const { translate } = useI18n();
	const { updateProfile, createProfile, deleteProfile, profiles } = useAuth();
	const [modalVisible, setModalVisible] = useState(false);
	const inputRef = useRef<TextInput | null>(null);
	const [name, setName] = useState<string>(profile?.name || '');
	const [initial, setInitial] = useState<boolean>(!!profile?.name || true);
	const [newProfile, setNewProfile] = useState<boolean>(false);
	const [textInputFocused, setTextInputFocused] = useState(false);
	const [color, setColor] = useState<string>(
		profile?.image_url
			? colorinos[parseInt(profile?.image_url)]
			: colors.primary
	);
	const [colorIndex, setColorIndex] = useState<string | undefined>(undefined);
	const [keyboard, setKeyboard] = useState(false);

	useEffect(() => {
		if (!profile?.image_url) {
			setColorIndex(undefined);
		}
		if (!profile) {
			setNewProfile(true);
		}
	}, [profile]);

	useKeyHandler([KEY_BACK], (key) => {
		if (!isNative) {
			if (key === KEY_BACK && inputRef.current?.isFocused()) {
				inputRef.current?.blur();
				setTextInputFocused(false);
			}
		}
	});

	const handleColorChange = (color?: number) => {
		setColorIndex(color?.toString());
		setColor(color ? colorinos[color] : colors.primary);
		setModalVisible(false);
	};

	const handleChanges = () => {
		if (newProfile) {
			createProfile({ name, image_url: colorIndex });
		} else if (name && color && profile?.id) {
			updateProfile({ ...profile, name, image_url: colorIndex });
		}

		navigation.navigate('Profile');
	};

	const handleDelete = () => {
		if (profile) {
			deleteProfile(profile);
		}

		navigation.navigate('Profile');
	};

	//function to block onpress for 1 seconden after keyboard is closed
	const onPressBlock = () => {
		setTimeout(() => {
			setKeyboard(false);
		}, 1000);
	};

	return (
		<Screen
			style={styles.screenContainer}
			stealFocus={true}
		>
			{!modalVisible && (
				<Title style={{ marginTop: getScaledValue(24) }}>
					{translate('user.edit', { name: profile?.name || '' })}
				</Title>
			)}
			{modalVisible && (
				<View style={{ flex: 1 }}>
					<ColorSelect
						name={name === '' ? '?' : name}
						items={colorinos}
						onPress={(i) => handleColorChange(i)}
						numberOfCollumns={3}
						selectedColor={color}
					/>
				</View>
			)}
			{!modalVisible && (
				<View style={styles.profileContainer}>
					<View style={styles.profileCard}>
						<Avatar
							name={name === '' ? '?' : name}
							color={color}
							onPress={() => setModalVisible(true)}
							hasFocus={false}
						/>
						<View style={{ marginTop: getScaledValue(32) }}>
							<Pressable
								onPress={() => {
									isNative && inputRef.current?.focus();

									if ((isWebos() || isTizen()) && !keyboard) {
										inputRef.current?.focus();
										setKeyboard(true);
									}

									setTextInputFocused(true);
								}}
								onFocus={() => {
									setTextInputFocused(true);
								}}
								onBlur={() => {
									inputRef.current?.blur();
									onPressBlock();
									setTextInputFocused(false);
								}}
								focusOptions={{
									animatorOptions: {
										type: 'scale',
										scale: 1,
									},
								}}
							>
								<TextInput
									ref={inputRef}
									style={[
										styles.textInput,
										textInputFocused && styles.textInputFocused,
									]}
									placeholderTextColor={colors.secondaryText}
									autoCapitalize="none"
									autoFocus={false}
									placeholder={'Add your name'}
									onChangeText={(text) => {
										setInitial(false);
										setName(text);
									}}
									value={name}
									onKeyPress={(event: any) => {
										// note: Tizen does not support onSubmitEditing so we use this instead
										if (event.key === 'Select') {
											setName(event.nativeEvent?.text || name);
										}
										if (!isNative && event.key === INPUT_EXIT) {
											inputRef.current?.blur();
											setTextInputFocused(false);
										}
									}}
								/>
							</Pressable>

							{!initial && (!name || name.length < 2) && (
								<Text style={styles.errorText}>{translate('user.short')}</Text>
							)}
						</View>
					</View>
					<View
						style={{
							flexDirection: 'column',
							width: '100%',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<View
							style={{
								flexDirection: 'row',
								width: '100%',
								justifyContent: 'center',
							}}
						>
							{(profile?.name !== name ||
								profile?.image_url !==
									colorinos.findIndex((c) => c === color).toString()) &&
								name.length >= 2 && (
									<Button
										style={styles.button}
										onPress={handleChanges}
										focusOptions={{
											forbiddenFocusDirections: ['left'],
										}}
										centeredText
										label={translate('user.save')}
									/>
								)}
							<Button
								style={styles.button}
								onPress={() => navigation.goBack()}
								focusOptions={{
									forbiddenFocusDirections: ['right'],
								}}
								centeredText
								label={translate('user.cancel')}
							/>
						</View>
						{profiles && profiles.length > 1 && (
							<Button
								style={styles.button}
								onPress={handleDelete}
								focusOptions={{
									forbiddenFocusDirections: ['down', 'right', 'left'],
								}}
								centeredText
								label={translate('user.delete')}
							/>
						)}
					</View>
				</View>
			)}

			<View style={styles.logoContainer}>
				<Image
					style={styles.logo}
					source={logo}
					resizeMode="contain"
				/>
			</View>
		</Screen>
	);
};

export default EditProfileScreen;
