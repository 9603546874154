import React, {
	ComponentProps,
	ForwardedRef,
	forwardRef,
	useCallback,
} from 'react';
import { StyleSheet } from 'react-native';

import { PlayerType } from '../../contexts/PlayerContext';
import { useCurrentEpisode, useDuration } from '../../hooks/api';
import { useI18n } from '../../hooks/useI18n';
import { usePlayer } from '../../hooks/usePlayer';
import { AssetType } from '../../models/AssetType';
import { Details } from '../../models/Details';
import {
	USER_INTERACTION_CONTEXT,
	USER_INTERACTION_FEATURES,
	USER_INTERACTION_TYPE,
} from '../../services/event-tracker/UserInterActionEvent';
import isNative from '../../utils/isNative';
import { getScaledValue, getResponsiveScaling } from '../../utils/scale';
import Button from '../button/button-flex';
import Icon from '../icon';
import { IMAGE_WIDTH } from '../tile/tileBase';

const styles = StyleSheet.create({
	button: {
		marginTop: getScaledValue(10),
		marginRight: getScaledValue(10),
		width: getResponsiveScaling((IMAGE_WIDTH + 2) * 2).width,
	},
});

type StartOverButtonProps = {
	asset: Details;
	onFocus: () => void;
} & ComponentProps<typeof Button>;

const StartOverButton = forwardRef(
	(
		{
			asset,
			onFocus,
			nextFocusDown,
			parentContext,
			focusOptions,
		}: StartOverButtonProps,
		ref: ForwardedRef<StartOverButtonProps>
	) => {
		const {
			setShowDetails,
			setPlaying,
			setPlayerType,
			setShowPlayer,
			setAsset,
			setStartOver,
			player,
			currentTime,
			fireDidSeek,
			playerWeb,
			setIsEnd,
			setCurrentTime,
		} = usePlayer();
		const { translate } = useI18n();

		const assetToPlay =
			(asset.asset_type === AssetType.tvshow && useCurrentEpisode(asset)) ||
			asset;
		const { duration } = useDuration(assetToPlay);

		const handleClick = useCallback(() => {
			console.log('press startOver', assetToPlay?.id);

			setStartOver(true);

			if (currentTime > 9) {
				const playerRef = isNative ? player.current : playerWeb;

				playerRef?.seek(0);
				fireDidSeek({});
				setPlaying(true);
			}
			setCurrentTime(0);
			setIsEnd(false);
			setAsset(assetToPlay);
			setPlayerType(PlayerType.Video);
			setPlaying(true);
			setShowDetails(false);
			setShowPlayer(true);
		}, [
			assetToPlay,
			currentTime,
			fireDidSeek,
			player,
			playerWeb,
			setAsset,
			setPlayerType,
			setPlaying,
			setShowDetails,
			setShowPlayer,
			setStartOver,
			setCurrentTime,
			setIsEnd,
		]);

		if (assetToPlay.asset_type === AssetType.tvshow || duration < 10) {
			return null;
		}

		return (
			<Button
				testID={'asset-detail-button-start-over'}
				ref={ref}
				parentContext={parentContext}
				hasTVPreferredFocus={assetToPlay.asset_type === AssetType.tvshow}
				style={[styles.button]}
				label={translate('startover.button')}
				leftIcon={<Icon type="restart" />}
				onFocus={onFocus}
				onPress={handleClick}
				nextFocusDown={nextFocusDown}
				focusOptions={focusOptions}
				track={{
					feature: USER_INTERACTION_FEATURES.ASSET_DETAIL,
					context: USER_INTERACTION_CONTEXT.BUTTON_ROW,
					type: USER_INTERACTION_TYPE.BUTTON,
					name: `button_startover`,
				}}
			/>
		);
	}
);

StartOverButton.displayName = 'StartOverButtonButton';

export default StartOverButton;
