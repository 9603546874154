import { useNetInfo } from '@react-native-community/netinfo';
import {
	NavigationContainer,
	useNavigationContainerRef,
} from '@react-navigation/native';
import { isAndroidtv, isTizen } from '@rnv/renative';
import React, { useEffect } from 'react';
import SplashScreen from 'react-native-bootsplash';
import * as Sentry from '@sentry/browser';

import config from '../../platformAssets/runtime/config';
import CountryError from '../components/country-error';
import InternetError from '../components/internet-error';
import { I18nProvider } from '../contexts/I18nContext';
import { UIProvider } from '../contexts/UIContext';
import { useClientTracker } from '../hooks/useClientTracker';
import { useI18n } from '../hooks/useI18n';
import { useNetworkConnection } from '../hooks/useNetworkConnection';
import { useUI } from '../hooks/useUI';
import { AuthProvider, useAuth } from '../providers/auth';
import ClientTracker from '../services/event-tracker/ClientTracker';
import UserInterActionEvent, {
	USER_INTERACTION_CONTEXT,
	USER_INTERACTION_FEATURES,
	USER_INTERACTION_TYPE,
} from '../services/event-tracker/UserInterActionEvent';
import isNative from '../utils/isNative';

import MainScreenNavigator from './main-screen-navigator';

const linking = {
	prefixes: [`${config.appName}://`, `https://${config.appId}`],
	config: {
		screens: {
			Home: 'home/:id',
			Profile: 'profile',
			AssetDetail: {
				path: 'assetDetail/:id/:type/:play',
				parse: {
					id: String,
					type: Number,
					play: Number,
				},
			},
		},
	},
};

const Content = () => {
	const { countryCode } = useI18n();
	const netInfo = useNetInfo();
	const tizenNetInfo = useNetworkConnection();
	const { token, isLoading } = useAuth();
	const { testingCollection, isCountryActive, collectionSetupLoading } =
		useUI();
	const navigationRef = useNavigationContainerRef();

	useClientTracker();

	if (typeof token !== 'string') {
		console.log('getting token from storage');

		return null;
	}

	if (isNative && !netInfo.isConnected) {
		console.log('no internet connection');
		return <InternetError />;
	}

	// TODO - uncomment when tizen is implemented
	// if (isTizen() && !tizenNetInfo) {
	// 	console.log('no internet connection');
	// 	return <InternetError />;
	// }

	if (!countryCode) {
		console.log('fetching country code');

		return null;
	}

	if (collectionSetupLoading) {
		console.log('loading collection setup');

		return null;
	}

	if (!isCountryActive) {
		if (!testingCollection) {
			Sentry.captureMessage(`country error screen for ${countryCode}`);

			return <CountryError />;
		}
	}

	if (token !== '' && isLoading) {
		console.log('loading authentication user and profiles');

		return null;
	}

	return (
		<NavigationContainer
			ref={navigationRef}
			{...(isNative && { linking })}
			onStateChange={() => {
				if (navigationRef) {
					const userInterActionEvent = new UserInterActionEvent();
					userInterActionEvent.setFeature(USER_INTERACTION_FEATURES.APP);
					userInterActionEvent.setContext(
						USER_INTERACTION_CONTEXT.APP_NAVIGATOR
					);
					userInterActionEvent.setType(USER_INTERACTION_TYPE.ROUTE);
					userInterActionEvent.setName(
						navigationRef.getCurrentRoute()?.name || 'navigation'
					);
					userInterActionEvent.setBody({
						currentRouteName: navigationRef.getCurrentRoute()?.name,
					});

					ClientTracker.sendUserInterActionEvent(userInterActionEvent);
				}
			}}
		>
			<MainScreenNavigator />
		</NavigationContainer>
	);
};

console.log('SpashScreen', SplashScreen);

const AppNavigator: React.FC = () => {
	if (isAndroidtv()) {
		SplashScreen.hide();
	}

	return (
		<AuthProvider>
			<I18nProvider>
				<UIProvider>
					<Content />
				</UIProvider>
			</I18nProvider>
		</AuthProvider>
	);
};

export default AppNavigator;
