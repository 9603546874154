import styled from '@emotion/native';
import { Text, View } from '@flexn/sdk';
import React, { ComponentProps, useState } from 'react';
import { Animated, Image, ImageSourcePropType, StyleSheet } from 'react-native';

import colors from '../../../platformAssets/runtime/colors';
import fontnames from '../../../platformAssets/runtime/fontnames';
import { useI18n } from '../../hooks/useI18n';
import { useAuth } from '../../providers/auth';
import { getResponsiveScaling, getScaledValue } from '../../utils/scale';
import { scale } from '../../utils/scaling';
import Pressable from '../pressable';

import { useConfig } from '../../hooks/useConfig';

const AvatarText = styled.Text`
	font-family: ${fontnames.secondaryBold};
	font-size: ${scale('40')}px;
	margin: ${scale('10')}px 0;
	color: ${colors.primaryText};
`;

const styles = StyleSheet.create({
	iconContainerHover: {
		backgroundColor: '#000000',
	},

	iconContainerActive: {
		backgroundColor: colors.inactive,
	},

	iconContainer: {
		justifyContent: 'center',
		alignItems: 'center',
		width: getResponsiveScaling(4).width,
		height: getResponsiveScaling(4).width,
		borderRadius: getResponsiveScaling(2).width,
		opacity: 0.8,
		position: 'relative',
	},
	icon: {
		width: getResponsiveScaling(2).width,
		height: getResponsiveScaling(2).width,
	},
	menuButton: {
		alignSelf: 'flex-start',
		alignItems: 'center',
		maxWidth: getScaledValue(400),
		minWidth: getScaledValue(50),
		flexDirection: 'row',
		padding: getScaledValue(4),
		paddingLeft: getScaledValue(30),
		marginRight: getScaledValue(20),
		zIndex: 5,
	},

	menuButtonHover: {
		backgroundColor: '#000000',
	},

	menuButtonActive: {
		backgroundColor: colors.inactive,
	},
	menuProfileText: {
		position: 'absolute',
		marginLeft: getResponsiveScaling(2).width,
		display: 'flex',
		flexDirection: 'column',
		width: getScaledValue(200),
	},

	buttonText: {
		fontSize: getResponsiveScaling(2).width,
		color: colors.primaryText,
	},

	buttonUnderText: {
		fontSize: getResponsiveScaling(1).width,
		color: colors.secondaryText,
		width: getScaledValue(200),
	},

	shine: {
		position: 'absolute',
		width: getScaledValue(18),
		height: getScaledValue(18),
		top: getScaledValue(5),
		right: getScaledValue(5),
		borderRadius: getScaledValue(44),
		backgroundColor: 'white',
		opacity: 0.2,
	},
	ring: {
		position: 'absolute',
		width: getScaledValue(12),
		height: getScaledValue(12),
		borderRadius: getScaledValue(24),
		backgroundColor: 'transparent',
		borderWidth: getScaledValue(1),
		borderColor: 'gold',
		top: getScaledValue(-11),
		left: getScaledValue(-1),
	},
	block: {
		position: 'absolute',
		width: getScaledValue(12),
		height: getScaledValue(6),
		borderRadius: getScaledValue(2),
		backgroundColor: 'gold',
		top: getScaledValue(-1),
		left: getScaledValue(3),
		transform: [{ rotate: '-30deg' }],
	},
});

type Props = {
	icon: ImageSourcePropType;
	iconActive: ImageSourcePropType;
	isActive: boolean;
	text?: string;
	transformAnim: any;
	opacityAnim: any;
	onPress?: () => void;
	onFocus?: () => void;
	onBlur?: () => void;
	style?: any;
	isProfile?: boolean;
	testID?: string;
} & ComponentProps<typeof Pressable>;

const TabBarItem = React.forwardRef<typeof Pressable, Props>(
	(
		{
			icon,
			iconActive,
			isActive,
			onPress,
			onFocus,
			onBlur,
			style,
			text,
			transformAnim,
			opacityAnim,
			isProfile,
			...props
		},
		ref
	) => {
		const [hasFocus, setHasFocus] = useState(false);
		const { translate } = useI18n();
		const { profile } = useAuth();

		const handleFocus = () => {
			setHasFocus(true);
			onFocus && onFocus();
		};

		const handleBlur = () => {
			setHasFocus(false);
			onBlur && onBlur();
		};

		return (
			<Pressable
				ref={ref}
				onPress={onPress}
				onFocus={handleFocus}
				onBlur={handleBlur}
				style={[
					styles.menuButton,
					!isProfile && { opacity: hasFocus ? 1 : 0.5 },
					style,
				]}
				{...props}
			>
				<Animated.View
					style={[
						styles.menuProfileText,
						{
							transform: [{ translateX: transformAnim }],
							opacity: opacityAnim,
						},
						profile &&
							isProfile &&
							hasFocus && {
								marginBottom: getScaledValue(5),
							},
					]}
				>
					<Text
						numberOfLines={1}
						style={[styles.buttonText]}
					>
						{profile && isProfile && hasFocus ? profile.name : text}
					</Text>
					{profile && isProfile && hasFocus && (
						<Text style={[styles.buttonUnderText]}>
							{translate('menu.switch-profile')}
						</Text>
					)}
				</Animated.View>

				<View
					style={[
						styles.iconContainer,
						isActive && styles.iconContainerActive,
						hasFocus && styles.iconContainerHover,
						profile &&
							isProfile && {
								backgroundColor:
									colors.profileAvatars[profile.image_url] || colors.primary,
							},
					]}
				>
					{profile && isProfile ? (
						<ProfileIcon name={profile.name} />
					) : (
						<Image
							style={styles.icon}
							source={hasFocus ? iconActive : icon}
						/>
					)}
				</View>
			</Pressable>
		);
	}
);

const ProfileIcon = ({ name }: { name: string }) => {
	const { theme } = useConfig();

	if (theme === 'christmas') {
		return (
			<>
				<View style={styles.ring} />
				<View style={styles.block} />
				<View style={styles.shine} />

				<AvatarText>{name.toUpperCase().substring(0, 2)}</AvatarText>
			</>
		);
	}
	return <AvatarText>{name.toUpperCase().substring(0, 2)}</AvatarText>;
};

TabBarItem.displayName = 'TabBarItem';

export default TabBarItem;
