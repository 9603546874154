import { isAndroidtv, isTizen, isTvos, isWebos } from '@rnv/renative';

export const getPlatformName = () => {
	switch (true) {
		case isWebos():
			return 'webos';
		case isAndroidtv():
			return 'androidtv';
		case isTizen():
			return 'tizen';
		case isTvos():
			return 'tvos';
		default:
			return 'start';
	}
};
