import {
	RecyclableList,
	RecyclableListDataProvider,
	RecyclableListLayoutProvider,
	View,
} from '@flexn/sdk';
import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, Dimensions } from 'react-native';

import { Genre as GenreType } from '../../hooks/shelves';
import isNative from '../../utils/isNative';
import { getScaledValue, getResponsiveScaling } from '../../utils/scale';
import {
	TILE_HEIGHT,
	TILE_LANDSCAPE_HEIGHT,
	TILE_LANDSCAPE_WIDTH,
} from '../tile/tileBase';

import { Genre } from './genre';

type Props = {
	parentContext?: any;
	setGenre: (text: string) => void;
	search?: string;
	genres: GenreType[];
};

const styles = StyleSheet.create({
	keyboard: {
		paddingLeft: getScaledValue(8),
		paddingRight: getScaledValue(40),
	},
});

export const Genres = ({ parentContext, setGenre, search, genres }: Props) => {
	const layoutProvider: any = useRef();
	const numberOfCollumns = 3;

	const dataProviderInstance = useRef(
		new RecyclableListDataProvider((r1, r2) => r1 !== r2)
	).current;

	const [dataProvider, setDataProvider] = useState(
		dataProviderInstance.cloneWithRows(genres || [])
	);

	const setLayoutProvider = () => {
		layoutProvider.current = new RecyclableListLayoutProvider(
			() => '_',
			(_: string | number, dim: { width: number; height: number }) => {
				dim.width = getResponsiveScaling(TILE_LANDSCAPE_WIDTH).width;
				dim.height = getResponsiveScaling(TILE_LANDSCAPE_HEIGHT).width;
			}
		);
	};

	useEffect(() => {
		setLayoutProvider();
		setDataProvider(dataProviderInstance.cloneWithRows(genres || []));
	}, [dataProviderInstance, genres]);

	return (
		<RecyclableList
			dataProvider={dataProvider}
			layoutProvider={layoutProvider.current}
			parentContext={parentContext}
			numColumns={numberOfCollumns}
			rowRenderer={(
				_type: string | number,
				data: any,
				index: number,
				repeatContext: any
			) => {
				return (
					<Genre
						genre={data}
						setGenre={setGenre}
						repeatContext={repeatContext}
						focusOptions={{
							animatorOptions: {
								type: isNative ? 'scale_with_border' : 'border',
								scale: 1.1,
							},

							focusKey: `genre-${index}`,
							nextFocusUp: `genre-${index - numberOfCollumns}`,
							nextFocusDown: `genre-${index + numberOfCollumns}`,
							nextFocusRight:
								index !== dataProvider.getSize() - 1 && `genre-${index + 1}`,

							nextFocusLeft:
								index === 0 || index % numberOfCollumns === 0
									? `keyboard`
									: `genre-${index - 1}`,
							forbiddenFocusDirections:
								index === dataProvider.getSize() - 1
									? ['right', 'down']
									: index > dataProvider.getSize() - 1 - numberOfCollumns
									? ['down']
									: [],
						}}
					/>
				);
			}}
			isHorizontal={false}
			style={[
				styles.keyboard,
				{
					height: Dimensions.get('screen').height,
					display: search ? 'none' : 'flex',
				},
			]}
			scrollViewProps={{
				showsVerticalScrollIndicator: false,
			}}
			type={'grid'}
			contentContainerStyle={{
				paddingLeft: getScaledValue(8),
			}}
			renderFooter={() => (
				<View
					style={{
						height: getResponsiveScaling(TILE_HEIGHT * 2).width,
					}}
				/>
			)}
		/>
	);
};

Genres.displayName = 'Genres';
