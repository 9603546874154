import { View } from '@flexn/sdk';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useCallback, useRef, useState } from 'react';
import { ActivityIndicator, StyleSheet, Text } from 'react-native';

import colors from '../../platformAssets/runtime/colors';
import NoResults from '../components/no-results';
import Screen from '../components/screen';
import { SearchInput } from '../components/search-input';
import { Cursor } from '../components/search-input/Cursor';
import keyboardDataEng from '../components/search-input/eng.json';
import { Genres } from '../components/search-input/genres';
import { TKeyboardAdditionalLocales } from '../components/search-input/search-input';
import { SearchResults } from '../components/search-input/searchResults';
import { useSetAsset } from '../hooks/api';
import { useGenresShelves, useSearchShelves } from '../hooks/shelves';
import { useI18n } from '../hooks/useI18n';
import { ShelfAsset } from '../models/Asset';
import { NavigatorParameterList } from '../navigators/main-screen-navigator';
import ClientTracker from '../services/event-tracker/ClientTracker';
import UserInterActionEvent, {
	USER_INTERACTION_CONTEXT,
	USER_INTERACTION_FEATURES,
	USER_INTERACTION_TYPE,
} from '../services/event-tracker/UserInterActionEvent';
import { getScaledValue, getResponsiveScaling } from '../utils/scale';

const styles = StyleSheet.create({
	screenContainer: {
		backgroundColor: 'black',
		flex: 1,
	},

	shelvesContainer: {
		flex: 1,
		marginTop: getScaledValue(10),
		paddingBottom: getScaledValue(10),
	},
	searchInputContainer: {
		paddingTop: getScaledValue(40),
		paddingLeft: getScaledValue(100),
		flexDirection: 'row',
		flex: 1,
	},
	noResults: {
		flex: 1,
		alignItems: 'center',
		color: colors.secondaryText,
		fontSize: getScaledValue(18),
		marginBottom: getScaledValue(8),
	},

	textInput: {
		marginRight: getScaledValue(16),
		marginLeft: getScaledValue(14),
		paddingHorizontal: getScaledValue(16),
		paddingVertical: getScaledValue(16),
		borderWidth: 0,
		borderRadius: getScaledValue(8),
		overflow: 'hidden',
		fontSize: getScaledValue(16),
		backgroundColor: colors.inactive,
		width: getResponsiveScaling(60).width,
		height: getScaledValue(48),
		padding: getScaledValue(16),
		flexDirection: 'row',
		justifyContent: 'flex-start',
	},
	value: {
		color: 'white',
		fontSize: getScaledValue(12),
	},
	content: {
		width: getResponsiveScaling(70).width,
	},
});

type SearchScreenProps = NativeStackScreenProps<
	NavigatorParameterList,
	'Search'
>;

const SearchScreen: React.FC<SearchScreenProps> = ({ navigation }) => {
	console.log('--- search screen render');
	const keyboardDataLocale: TKeyboardAdditionalLocales = [keyboardDataEng];
	const keyboardData = [...keyboardDataLocale[0].letters];

	const { translate } = useI18n();
	const timeoutRef = useRef<any | undefined>(undefined);

	const { mutate: setAsset } = useSetAsset();

	const [search, setSearch] = useState('');
	const [getSearchValue, setGetSearchValue] = useState('');
	const { shelves, shelvesError, shelvesLoading } = useSearchShelves(
		getSearchValue,
		true
	);

	const [textInputFocused, setTextInputFocused] = useState('');

	const { genres, genresLoading } = useGenresShelves();

	const handleTilePress = useCallback(
		(asset: ShelfAsset) => {
			const userInterActionEvent = new UserInterActionEvent();
			userInterActionEvent.setFeature(USER_INTERACTION_FEATURES.SEARCH);
			userInterActionEvent.setContext(USER_INTERACTION_CONTEXT.SHELF_LIST);
			userInterActionEvent.setType(USER_INTERACTION_TYPE.SHELF_TILE);
			userInterActionEvent.setName(`shelf_${asset.shelfId}_asset_${asset.id}`);
			userInterActionEvent.setBody({
				shelf: asset.shelfId,
				asset: asset.id,
			});

			ClientTracker.sendUserInterActionEvent(userInterActionEvent);
			navigation.navigate('AssetDetail', { asset });
		},
		[navigation]
	);

	const handleTileFocus = useCallback(
		(asset: ShelfAsset) => {
			console.log('-------------- focused on', asset.id);

			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}

			timeoutRef.current = setTimeout(() => {
				setAsset(asset);
			}, 250);
		},
		[setAsset]
	);

	const searchUpdate = (value: string) => {
		let newValue = '';
		if (value === 'window-minimize') {
			newValue = search + ' ';
		} else if (value === 'backspace') {
			newValue = search.slice(0, -1);
		} else {
			newValue = search + value;
		}

		setSearch(newValue);
		if (newValue.length !== 1) {
			setGetSearchValue(newValue);
		}
	};

	return (
		<Screen
			style={styles.screenContainer}
			stealFocus={true}
			focusOptions={{
				horizontalViewportOffset: getScaledValue(60),
				verticalViewportOffset: getScaledValue(20),
			}}
		>
			<View style={styles.searchInputContainer}>
				<SearchInput
					setTextInputFocused={(text: string) => {
						setTextInputFocused(text);
					}}
					searchUpdate={(text: string) => {
						searchUpdate(text);
					}}
					textInputFocused={textInputFocused}
				/>

				<View style={styles.content}>
					<View style={styles.textInput}>
						<Text style={styles.value}>{search}</Text>
						<Cursor
							textInputFocused={textInputFocused}
							keyboardData={keyboardData}
						/>
					</View>

					<View style={styles.shelvesContainer}>
						{shelvesError && <Text>An error has occurred.</Text>}

						{shelvesLoading ? (
							<ActivityIndicator
								size="large"
								color={colors.warning}
							/>
						) : (
							<View style={{ flex: 1 }}>
								{search.length === 0 &&
									(genresLoading ? (
										<ActivityIndicator
											size="large"
											color={colors.warning}
										/>
									) : (
										<Genres
											genres={genres}
											search={search}
											setGenre={(g) => {
												navigation.navigate('Genre', { genre: g });
											}}
										/>
									))}

								{shelves.length === 0 && search !== '' && (
									<NoResults
										style={styles.noResults}
										title={translate('search.noresults')}
									/>
								)}

								{shelves.length !== 0 && search !== '' && (
									<SearchResults
										shelves={shelves}
										onPress={handleTilePress}
										onFocus={handleTileFocus}
										numberOfCollumns={5}
										style={{
											paddingLeft: getScaledValue(8),
											paddingRight: getScaledValue(40),
										}}
									/>
								)}
							</View>
						)}
					</View>
				</View>
			</View>
		</Screen>
	);
};

export default SearchScreen;
