import { ScreenProps as FlexnScreenProps } from '@flexn/sdk';
import { useIsFocused } from '@react-navigation/native';
import React from 'react';

import { Screen as FMScreen } from '../../flexn';

type ScreenProps = {
	forceScreenState?: 'foreground' | 'background';
} & FlexnScreenProps;

const Screen = ({
	children,
	stealFocus,
	focusOptions,
	style,
	forceScreenState,
	...rest
}: ScreenProps) => {
	const isFocused = useIsFocused();

	return (
		<FMScreen
			screenState={forceScreenState || isFocused ? 'foreground' : 'background'}
			stealFocus={stealFocus}
			focusOptions={focusOptions}
			style={style}
			{...rest}
		>
			{children}
		</FMScreen>
	);
};

export default Screen;
