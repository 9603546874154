import React, { createContext, useState, ReactNode, useEffect } from 'react';
import useFetch from 'use-http';
import config from '../../platformAssets/runtime/config';
import * as Sentry from '@sentry/browser';
import { isTizen } from '@rnv/renative';
import { set } from 'date-fns';
import { getPlatformName } from '../utils/getPlatform';

type Props = {
	children?: ReactNode;
};

type endpointsType = {
	'subscription-backend': string;
	'user-backend': string;
};

const ConfigContext = createContext<
	| {
			countryCode: string | undefined;
			country: string | undefined;
			isCountryActive: boolean;
			configCollection: string;
			homeCollection: string;
			noResultsCollection: string;
			collectionSetupLoading: boolean;
			theme: string;
			freeTrialDays: number;
			endpoints: endpointsType | null;
			testingCollection: boolean;
			onboardingFlow: boolean;
	  }
	| undefined
>(undefined);

const ConfigProvider = ({ children }: Props) => {
	const [request] = useFetch(config.subscriptionServiceUrl);

	const [testingCollection, setTestingCollection] = useState(false);
	const [countryCode, setCountryCode] = useState();
	const [country, setCountry] = useState();
	const [theme, setTheme] = useState('default');
	const [collectionSetupLoading, setCollectionSetupLoading] = useState(true);
	const [configCollection, setConfigCollection] = useState('');
	const [homeCollection, setHomeCollection] = useState('');
	const [noResultsCollection, setNoResultsCollection] = useState('');
	const [isCountryActive, setIsCountryActive] = useState(true);
	const [freeTrialDays, setFreeTrialDays] = useState(14);
	const [endpoints, setEndpoints] = useState<endpointsType | null>(null);
	const [onboardingFlow, setOnboardingFlow] = useState(false);

	useEffect(() => {
		const getConfig = async () => {
			const resConfig = await fetch(
				`${config.hermesApiUrl}/config-provider/v1/client/startup`
			);

			const resConfigJson = await resConfig.json();

			setEndpoints(resConfigJson.endpoints);
			setFreeTrialDays(resConfigJson?.freeTrialDays);
			setIsCountryActive(resConfigJson?.isActive);
			setCountry(resConfigJson?.location.countryCode);
			setCountryCode(resConfigJson?.location.countryCode);
			setOnboardingFlow(
				resConfigJson?.onboardingInAppFlow?.[getPlatformName()] || false
			);

			// update config value
			config.subscriptionServiceUrl =
				resConfigJson.endpoints['subscription-backend'];
			config.userServiceUrl = resConfigJson.endpoints['user-backend'];

			if (testingCollection && !resConfigJson.isActive) {
				Sentry.captureMessage(
					`Testing collection is active for ${countryCode}`
				);

				setConfigCollection(`${config.collections.menu}test`);
				setHomeCollection(`${config.collections.home}test`);
				setNoResultsCollection(`${config.collections.noResults}test`);
				setTheme('default');
			} else {
				setConfigCollection(resConfigJson.collections.config);
				setHomeCollection(resConfigJson.collections.home);
				setNoResultsCollection(resConfigJson.collections.noResults);
				setTheme(resConfigJson.ui?.theme || 'default');
			}

			setCollectionSetupLoading(false);
		};

		getConfig();
	}, []);

	useEffect(() => {
		if (isTizen()) {
			setTestingCollection(true);
		}
	}, []);

	return (
		<ConfigContext.Provider
			value={{
				countryCode,
				country,
				isCountryActive,
				configCollection,
				homeCollection,
				noResultsCollection,
				collectionSetupLoading,
				theme,
				freeTrialDays,
				endpoints,
				testingCollection,
				onboardingFlow,
			}}
		>
			{collectionSetupLoading ? null : children}
		</ConfigContext.Provider>
	);
};

export { ConfigContext, ConfigProvider };
