export const DIRECTION_UP = 'up';
export const DIRECTION_DOWN = 'down';
export const DIRECTION_LEFT = 'left';
export const DIRECTION_RIGHT = 'right';
export const KEY_ENTER = 'enter';
export const KEY_BACK = 'back';
export const KEY_PLAYPAUSE = 'playpause';
export const KEY_PLAY = 'play';
export const KEY_PAUSE = 'pause';
export const KEY_FASTBACKWARD = 'fastbackward';
export const KEY_FASTFORWARD = 'fastforward';
export const MEDIA_PLAYPAUSE = 'mediaplaypause';
export const MEDIA_REWIND = 'mediarewind';
export const MEDIA_FASTFORWARD = 'mediafastforward';
export const MEDIA_FASTBACKWARD = 'fastbackward';
export const MEDIA_PLAY = 'mediaplay';
export const MEDIA_PAUSE = 'mediapause';
export const MEDIA_STOP = 'mediastop';
export const MEDIA_TRACK_PREVIOUS = 'mediatrackprevious';
export const MEDIA_TRACK_NEXT = 'mediatracknext';

export const keymap = {
	'38': DIRECTION_UP,
	'40': DIRECTION_DOWN,
	'37': DIRECTION_LEFT,
	'39': DIRECTION_RIGHT,
	'13': KEY_ENTER,
	'461': KEY_BACK,
	'27': KEY_BACK,
	'415': MEDIA_PLAY,
	'19': MEDIA_PAUSE,
	'412': MEDIA_FASTBACKWARD,
	'417': MEDIA_FASTFORWARD,
};
