import { AssetSubtype } from './AssetSubtype';
import { AssetType } from './AssetType';
import { BusinessType } from './BusinessType';
import { Details, EpisodeDetails, MovieDetails } from './Details';

export type BaseAsset = {
	id: string;
	shelfId: string;
	asset_type: AssetType;
	title: string;
	tags: string[];
	cover_image: string;
	list_image: string;
	image: {
		cover_1x1: string;
		cover_16x9: string;
		clean_cover_1x1: string;
		clean_cover_16x9: string;
		list_1x1: string;
		list_9x13: string;
		cover: string;
		clean_cover: string;
		list: string;
	};
	seo_title?: null;
	extended?: {
		custom: {
			type?: string;
			indexable: boolean;
			jumbotronLabel?: {
				[key: string]: string;
			};
			tags?: {
				[key: string]: {
					text: string;
					backgroundColor: string;
					textColor: string;
					color: string;
				};
			}[];
		};
	};
	asset_subtype?: AssetSubtype;
	description: string;
	short_description: string;
};

export type Collection = BaseAsset & {
	asset_type: AssetType.collection;
	items: Asset[];
	extended?: {
		custom: {
			tile?: string;
			topList?: boolean;
		};
	};
};

export type Movie = BaseAsset & {
	asset_type: AssetType.movie;
	asset_subtype: AssetSubtype.Movie;
	business_type: BusinessType;
	duration: number;
	genres: Genre[];
	languages: string[];
	subtitle_languages: string[];
};

export type Episode = BaseAsset & {
	asset_type: AssetType.episode;
	asset_subtype: AssetSubtype.Episode;
	duration: number;
	genres: Genre[];
	languages: string[];
	subtitle_languages: string[];
	tvshow: {
		id: string;
	};
	serie: string;
};

export type TVShow = BaseAsset & {
	asset_type: AssetType.tvshow;
	asset_subtype: AssetSubtype.TVShow;
	business_type: BusinessType;
	duration: number;
	genres: Genre[];
	languages: string[];
	subtitle_languages: string[];
	seasons: Season[];
};

export type Season = BaseAsset & {
	asset_type: AssetType.season;
	tvshow: {
		id: string;
	};
	episodes: Episode[];
};

type Genre = {
	id: string;
	value: string;
};

export type ImageExtras = {
	image: {
		uri: string;
	};
	background: {
		uri: string;
	};
};

type AssetExtras = {
	xIndex: number;
	yIndex: number;
	isHorizontal: boolean;
	isJumbotron: boolean;
};

export type Asset = Collection | Movie | Episode | TVShow | Season;

export type AddImageExtras<T extends Asset | Details> = Omit<T, 'image'> &
	ImageExtras;

export type MakeShelfAsset<T extends Movie | Episode | TVShow | Details> =
	AddImageExtras<T> & AssetExtras;

export type ShelfMovie = MakeShelfAsset<Movie>;
export type ShelfEpisode = MakeShelfAsset<Episode>;
export type ShelfTVShow = MakeShelfAsset<TVShow>;

export type ShelfAsset = ShelfMovie | ShelfEpisode | ShelfTVShow;

export function shelfAssetIsOfType<T extends ShelfAsset>(
	asset: ShelfAsset,
	assetType: T['asset_type']
): asset is T {
	return asset.asset_type === assetType;
}

export function isDetails(
	asset?: ShelfAsset | Details | Asset
): asset is Details {
	return !!asset && ('video' in asset || 'season' in asset);
}

export function isShelfMovieOrShelfTVShow(
	asset?: ShelfAsset
): asset is ShelfMovie | ShelfTVShow {
	return (
		asset?.asset_type === AssetType.movie ||
		asset?.asset_type === AssetType.tvshow
	);
}

export function isPlayable(
	asset?: ShelfAsset | Details
): asset is ShelfMovie | ShelfEpisode | MovieDetails | EpisodeDetails {
	return (
		asset?.asset_type === AssetType.movie ||
		asset?.asset_type === AssetType.tvshow ||
		asset?.asset_type === AssetType.episode
	);
}
