import { StyleSheet, View } from '@flexn/sdk';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';

import Button from '../components/button/button-flex';
import DeviceInfo from '../components/device-info';
import Title from '../components/title';
import { Screen } from '../flexn';
import { useI18n } from '../hooks/useI18n';
import { NavigatorParameterList } from '../navigators/main-screen-navigator';
import { getScaledValue } from '../utils/scale';

const styles = StyleSheet.create({
	screenContainer: {
		flex: 1,
	},
	contentContainer: {
		flex: 1,
		paddingTop: getScaledValue(20),
		paddingLeft: getScaledValue(100),
	},
	infoContainer: {
		marginTop: getScaledValue(8),
		marginBottom: getScaledValue(24),
	},
	button: {
		maxWidth: getScaledValue(256),
	},
	text: {
		color: 'white',
		fontSize: getScaledValue(16),
	},
});

type ScreenProps = NativeStackScreenProps<NavigatorParameterList, 'DeviceInfo'>;

const DeviceInfoScreen: React.FC<ScreenProps> = ({ navigation }) => {
	const { translate } = useI18n();

	return (
		<Screen
			style={styles.screenContainer}
			stealFocus={true}
		>
			<View style={styles.contentContainer}>
				<Title>{translate('device-info.title')}</Title>
				<DeviceInfo style={styles.infoContainer} />
				<View style={styles.infoContainer}>
					<Button
						label={translate('device-info.close')}
						style={styles.button}
						onPress={() => {
							navigation.navigate('Profile');
						}}
					/>
				</View>
			</View>
		</Screen>
	);
};

export default DeviceInfoScreen;
