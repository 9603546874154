import config from '../../../platformAssets/runtime/config';
import * as Sentry from '@sentry/browser';

import UserInterActionEvent, {
	USER_INTERACTION_CONTEXT,
	USER_INTERACTION_FEATURES,
	USER_INTERACTION_TYPE,
} from './UserInterActionEvent';
import VideoEvent from './VideoEvent';

export enum ClientTrackerDeviceType {
	MOBILE = 'MOBILE',
	DESKTOP = 'DESKTOP',
	SMART_TV = 'SMART_TV',
	UNKNOWN = 'UNKNOWN',
}

export enum ClientTrackerDevicePlatform {
	IOS = 'IOS',
	ANDROID = 'ANDROID',
	WEBTV = 'WEBTV',
	CHROMECAST = 'CHROMECAST',
	WEB = 'WEB',
	ANDROID_TV = 'ANDROID_TV',
	APPLE_TV = 'APPLE_TV',
	WEBOS = 'WEBOS',
	TIZEN = 'TIZEN',
	FIRE_TV = 'FIRE_TV',
	UNKNOWN = 'UNKNOWN',
}

class ClientTracker {
	private _channel: string | undefined;

	private _country: string | undefined;
	private _customerId: string | undefined;
	private _subscriptionId: string | undefined;
	private _profileId: string | undefined;

	private _deviceType: ClientTrackerDeviceType =
		ClientTrackerDeviceType.UNKNOWN;
	public _devicePlatform: ClientTrackerDevicePlatform =
		ClientTrackerDevicePlatform.UNKNOWN;
	private _deviceVersion: string | undefined;
	private _deviceLanguage: string | undefined;

	private _appName: string | undefined;
	private _appVersion: string | undefined;

	private _sessionJWT: string | undefined;

	private eventQueue: { type: string; body: any; retries: number }[] = [];
	private isSending: Boolean = false;

	private lastEventSent: Date = new Date();

	setChannel(channel: string): void {
		this._channel = channel;
	}

	setCountry(country: string): void {
		this._country = country;
	}

	setCustomerId(customerId: string): void {
		this._customerId = customerId;
	}

	setSubscriptionId(subscriptionId: string): void {
		this._subscriptionId = subscriptionId;
	}

	setProfileId(profileId: string): void {
		this._profileId = profileId;
	}

	setDeviceType(deviceType: ClientTrackerDeviceType): void {
		this._deviceType = deviceType;
	}

	setDevicePlatform(devicePlatform: ClientTrackerDevicePlatform): void {
		this._devicePlatform = devicePlatform;
	}

	setDeviceVersion(deviceVersion: string): void {
		this._deviceVersion = deviceVersion;
	}

	setDeviceLanguage(deviceLanguage: string): void {
		this._deviceLanguage = deviceLanguage;
	}

	setAppName(appName: string) {
		this._appName = appName;
	}

	setAppVersion(appVersion: string) {
		this._appVersion = appVersion;
	}

	setSessionJWT(sessionJWT: string): void {
		this._sessionJWT = sessionJWT;
	}

	defaultEventBody() {
		return {
			timestamp: new Date().toISOString(),
			channel: this._channel,
			country: this._country,
			customerId: this._customerId,
			subscriptionId: this._subscriptionId,
			deviceType: this._deviceType,
			devicePlatform: this._devicePlatform,
			deviceVersion: this._deviceVersion,
			deviceLanguage: this._deviceLanguage,
			appName: this._appName,
			appVersion: this._appVersion,
			profileId: this._profileId,
		};
	}

	async sendEvent(type: string, body: any, retries = 0) {
		this.eventQueue.push({ type, body, retries });

		if (!this.isSending) {
			await this.sendNextEvent();
		}
	}

	private async sendNextEvent() {
		if (this.eventQueue.length > 0) {
			this.isSending = true;
			const { type, body, retries } = this.eventQueue.shift()!;

			const timeSinceLastEvent =
				(new Date().getTime() - this.lastEventSent.getTime()) / (1000 * 60);

			const requestHeaders: any = {
				'Content-Type': 'application/json',
				...(this._sessionJWT &&
					timeSinceLastEvent < 30 && { 'x-session-jwt': this._sessionJWT }),
			};

			const eventBody = {
				...this.defaultEventBody(),
				...body,
			};

			const eventRequest = {
				method: 'POST',
				headers: requestHeaders,
				body: JSON.stringify(eventBody),
			};

			const maxRetries = 5;

			const sendRequest = async () => {
				try {
					const resEvent = await fetch(
						`${config.hermesApiUrl}/platform-tracker/v1/events/${type}`,
						eventRequest
					);

					if (!resEvent.ok) {
						throw resEvent;
					}

					const resBody = await resEvent.json();

					if (resBody?.sessionJwt) {
						this.lastEventSent = new Date();
						this.setSessionJWT(resBody?.sessionJwt);
					}
				} catch (error) {
					console.log(error);

					if (error instanceof Response) {
						if (error?.status >= 500 || error?.status === 0) {
							if (retries <= maxRetries) {
								console.log(`Retrying event send, attempt ${retries}`);
								this.sendEvent(type, body, retries + 1);
							} else {
								// TODO: Sentry
								Sentry.captureMessage(`Max retries reached, event not sent`);
								console.log(`Max retries reached, event not sent`);
							}
						}
					}
				} finally {
					this.isSending = false;
					await this.sendNextEvent();
				}
			};

			await sendRequest();
		}
	}

	// Event senders
	sendVideoEvent(videoEvent: VideoEvent) {
		try {
			this.sendEvent('video', {
				name: videoEvent.eventAction,
				feature: USER_INTERACTION_FEATURES.PLAYER,
				context: USER_INTERACTION_CONTEXT.PLAYER,
				type: USER_INTERACTION_TYPE.PLAYER_ANALYTICS,
				assetId: videoEvent.assetId,
				assetType: videoEvent.assetType,
				businessType: videoEvent.businessType,
				genre: videoEvent.genre,
				bitrate: videoEvent.bitrate,
				resolution: videoEvent.resolution,
				videoPlayTimeSec: videoEvent.videoPlayTimeSec,
				videoPosition: videoEvent.videoPosition,
			});
		} catch (error) {
			console.log(error);
		}
	}

	// Event senders
	sendUserInterActionEvent(userInteraction: UserInterActionEvent) {
		try {
			this.sendEvent('user-interactions', {
				feature: userInteraction.feature,
				context: userInteraction.context,
				type: userInteraction.type,
				name: userInteraction.name,
				...(userInteraction.body && {
					body: JSON.stringify(userInteraction.body),
				}),
			});
		} catch (error) {
			console.log(error);
		}
	}
}

export default new ClientTracker();
