export enum USER_INTERACTION_FEATURES {
	APP = 'app',
	COLLECTION_PAGE = 'collection_page',
	TAB_BAR = 'tab_bar',
	PROFILE_PAGE = 'profile_page',
	ASSET_DETAIL = 'asset_detail',
	PLAYER = 'player',
	SWITCH_PROFILE = 'switch_profile',
	SEARCH = 'search',
	LOGIN = 'login',
	REGISTER = 'register',
	EXIT_FLOW = 'exit_flow',
	STARTUP = 'startup',
	BROWSE = 'browse',
	SUBSCRIPTION_REACTIVE = 'subscription_reactive',
}

export enum USER_INTERACTION_CONTEXT {
	ASSET_PREVIEW = 'asset_preview',
	TAB_BAR_ITEM = 'tab_bar_item',
	SHELF_LIST = 'shelf_list',
	BUTTON_ROW = 'button_row',
	APP_NAVIGATOR = 'app_navigator',
	SEASON_SHELF_LIST = 'season_shelf_list',
	PLAYER_CONTROLS = 'player_controls',
	AVATARS = 'avatars',
	SEARCH_INPUT = 'search_input',
	LOGIN_EMAIL_PASSWORD = 'login_form',
	LOGIN_QRCODE = 'login_qrcode',
	REGISTER_QRCODE = 'register_qrcode',
	PLAYER = 'player',
	ENTITLEMENT = 'entitlement',
	STATE = 'state',
}

export enum USER_INTERACTION_TYPE {
	BUTTON = 'button',
	TABBAR_ITEM = 'tabbar_item',
	SHELF_TILE = 'shelf_tile',
	LOAD = 'load',
	INPUT = 'text-input',
	SUCCESS = 'success',
	FAILED = 'failed',
	ERROR = 'error',
	PLAYER_ANALYTICS = 'player_analytics',
	SWITCH = 'switch',
	ACTIVE = 'active',
	INACTIVE = 'inactive',
	BACKGROUND = 'background',
	UNKNOWN = 'unknown',
	CHANGE = 'change',
	REQUEST = 'request',
	ROUTE = 'route',
}

export default class UserInterActionEvent {
	public feature: USER_INTERACTION_FEATURES | undefined;
	public context: USER_INTERACTION_CONTEXT | undefined;
	public type: USER_INTERACTION_TYPE | undefined;
	public name: string | undefined;
	public body: any | undefined;

	setFeature(feature: USER_INTERACTION_FEATURES) {
		this.feature = feature;
	}

	setContext(context: USER_INTERACTION_CONTEXT) {
		this.context = context;
	}

	setType(type: USER_INTERACTION_TYPE) {
		this.type = type;
	}

	setName(name: string) {
		this.name = name;
	}

	setBody(body: any) {
		this.body = body;
	}
}
