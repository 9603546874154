import { getScaledValue } from '@rnv/renative';
import { getResponsiveScaling } from './scale';

function makeScaler() {
	const scale = 2 / getResponsiveScaling(0.1).width;
	function scaler(val: number): number;
	function scaler(val: string): string;
	function scaler(val: number | string): number | string;
	function scaler(val: number | string): number | string {
		if (typeof val === 'number') {
			return val / scale;
		} else if (typeof val === 'string') {
			if (Number(val) === Number(val)) {
				return (Number(val) / scale).toString();
			} else if (val.slice(-1) === '%') {
				const n = val.slice(0, -1);
				return Number(n) / scale === Number(n) / scale
					? Number(n) / scale + '%'
					: '0';
			}
			return '0';
		}
		return '0';
	}
	return scaler;
}

export const scale = makeScaler();
