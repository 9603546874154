import { ratingFileMap } from '../constants/age_rating';

import { BaseAsset, Episode, Movie, TVShow } from './Asset';
import { AssetType } from './AssetType';
import { BusinessType } from './BusinessType';

export type MovieDetails = Movie & {
	description: string;
	short_description: string;
	age_rating: keyof typeof ratingFileMap;
	actors: string[];
	directors: string[];
	release_date: string;
	countries: string[];
	rating: number;
	channels: unknown[];
	licensing: License;
	related: unknown[];
	video: Video;
	trailer?: Trailer;
	skip_available: boolean;
	intro_start?: string;
	intro_end?: string;
	serie?: EpisodeDetails;
};

export type EpisodeDetails = Episode & {
	season: {
		id: string;
	};
	tvshow: {
		id: string;
	};
	description: string;
	short_description: string;
	index: number;
	age_rating: keyof typeof ratingFileMap;
	actors: string[];
	directors: string[];
	release_date: string;
	countries: string[];
	rating: number;
	licensing: License;
	video: Video;
	intro_start?: string;
	intro_end?: string;
	credits_start_time?: string;
	serie?: EpisodeDetails;
	trailer?: Trailer;
};

export type TVShowDetails = TVShow & {
	channels: unknown[];
	related: unknown[];
	seasons: Season[];
	licensing: License;
	release_date: string;
	age_rating: string;
	trailer?: Trailer;
};

type Season = BaseAsset & {
	asset_type: AssetType.season;
	business_type: BusinessType;
};

export type Details = MovieDetails | EpisodeDetails | TVShowDetails;
export type AssetDetails = Details;

type License = {
	content_owner?: string;
	license_countries: string[];
	license_from: Date;
	license_until: Date;
	licensing_periods: {
		[language: string]: {
			license_from: Date;
			license_until: Date;
		};
	};
};

type Video = {
	url: string;
	hls_url: string;
	dash_url: string;
	audiotracks: string[];
	subtitles: string[];
	is_drm: boolean;
	drm_key_id: string;
};

export type Trailer = {
	url: string;
	hls_url: string;
	dash_url: string;
};

export function isTVShowDetails(details: Details): details is TVShowDetails {
	return 'seasons' in details;
}

export function isEpisodeDetails(details: Details): details is EpisodeDetails {
	return 'season' in details;
}

export function hasValidLicense(
	details: Details,
	countryCode: string,
	date: Date
) {
	const license =
		details.licensing.licensing_periods[countryCode.toLowerCase()];

	if (!license) return false;

	const from = new Date(license.license_from);
	const until = new Date(license.license_until);

	return date > from && date < until;
}
