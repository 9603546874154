import React, { ComponentProps, memo, useCallback, useState } from 'react';
import { StyleSheet } from 'react-native';

import { useCurrentEpisode, useToggleFavorite } from '../../hooks/api';
import { useI18n } from '../../hooks/useI18n';
import { AssetType } from '../../models/AssetType';
import { Details } from '../../models/Details';
import {
	USER_INTERACTION_CONTEXT,
	USER_INTERACTION_FEATURES,
	USER_INTERACTION_TYPE,
} from '../../services/event-tracker/UserInterActionEvent';
import { getScaledValue, getResponsiveScaling } from '../../utils/scale';
import Button from '../button/button-flex';
import Icon from '../icon';
import { IMAGE_WIDTH } from '../tile/tileBase';

const styles = StyleSheet.create({
	button: {
		marginTop: getScaledValue(10),
		marginRight: getScaledValue(10),
		width: getResponsiveScaling((IMAGE_WIDTH + 2) * 2).width,
	},
});

type FavoriteButtonProps = {
	asset: Details;
	onFocus: () => void;
} & ComponentProps<typeof Button>;

const FavoriteButton: React.FC<FavoriteButtonProps> = ({
	parentContext,
	focusOptions,
	asset,
	onFocus,
}) => {
	const {
		hasFavorite,
		removeFavorite,
		addFavorite,
		loading: favoriteLoading,
	} = useToggleFavorite();
	const assetToPlay =
		(asset.asset_type === AssetType.tvshow && useCurrentEpisode(asset)) ||
		asset;
	const a = !!hasFavorite(asset);

	const [isFavorite, setIsFavorite] = useState<boolean>(a);
	const { translate } = useI18n();

	const handleClick = useCallback(() => {
		if (!asset || favoriteLoading) return;

		if (isFavorite) {
			setIsFavorite(false);
			return removeFavorite(asset);
		}

		setIsFavorite(true);
		return addFavorite(asset);
	}, [addFavorite, asset, favoriteLoading, isFavorite, removeFavorite]);

	return (
		<Button
			testID={'asset-detail-button-favorite'}
			parentContext={parentContext}
			hasTVPreferredFocus={assetToPlay.asset_type === AssetType.tvshow}
			style={[styles.button]}
			label={
				isFavorite
					? translate('button.unfavorite')
					: translate('button.favorite')
			}
			leftIcon={<Icon type={isFavorite ? 'remove' : 'add'} />}
			onFocus={onFocus}
			onPress={handleClick}
			focusOptions={focusOptions}
			track={{
				feature: USER_INTERACTION_FEATURES.ASSET_DETAIL,
				context: USER_INTERACTION_CONTEXT.BUTTON_ROW,
				type: USER_INTERACTION_TYPE.BUTTON,
				name: `favorite_${isFavorite ? 'remove' : 'add'}`,
			}}
		/>
	);
};

FavoriteButton.displayName = 'TrailerButton';

export default React.memo(FavoriteButton, (p, n) => {
	return p.asset?.id === n.asset?.id;
});
