import { View } from '@flexn/sdk';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { isAndroidtv, isTvos } from '@rnv/renative';
import React from 'react';
import { StyleSheet, Image, BackHandler } from 'react-native';

import config from '../../platformAssets/runtime/config';
import logo from '../../platformAssets/runtime/icons/logo.png';
import BackgroundPoster from '../components/background-poster';
import Button from '../components/button/button-flex';
import Screen from '../components/screen';
import Title from '../components/title';
import { useAsset } from '../hooks/api';
import { useI18n } from '../hooks/useI18n';
import { NavigatorParameterList } from '../navigators/main-screen-navigator';
import { getScaledValue } from '../utils/scale';
import {
	USER_INTERACTION_CONTEXT,
	USER_INTERACTION_FEATURES,
	USER_INTERACTION_TYPE,
} from '../services/event-tracker/UserInterActionEvent';

const exitFlow = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'rgba(0,0,0,0.4)',
	},

	buttonContainer: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: getScaledValue(24),
	},
	button: {
		width: getScaledValue(110),
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: getScaledValue(8),
	},

	information: {
		color: 'white',
		fontSize: getScaledValue(18),
	},
	logoContainer: {
		position: 'absolute',
		zIndex: 100,
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
		bottom: getScaledValue(16),
		right: getScaledValue(16),
	},
	logo: {
		margin: 0,
		width: getScaledValue(config.dimensions.logo.width),
		height: getScaledValue(config.dimensions.logo.height),
	},
});

type ExitFlowScreenProps = NativeStackScreenProps<
	NavigatorParameterList,
	'ExitFlow'
>;

const ExitFlowScreen = ({ navigation }: ExitFlowScreenProps) => {
	const { data: selectedAsset } = useAsset();
	const { translate } = useI18n();
	return (
		<Screen stealFocus={true}>
			<BackgroundPoster poster={selectedAsset?.background.uri}>
				<View style={[exitFlow.container]}>
					<Title style={[exitFlow.information]}>
						{translate('exit.title')}
					</Title>

					<View style={exitFlow.buttonContainer}>
						<Button
							centeredText
							style={exitFlow.button}
							label={translate('exit.stay')}
							focusOptions={{
								focusKey: 'stay-in-app',
								nextFocusLeft: 'close-app',
							}}
							onPress={() => {
								navigation.goBack();
							}}
							track={{
								feature: USER_INTERACTION_FEATURES.EXIT_FLOW,
								context: USER_INTERACTION_CONTEXT.BUTTON_ROW,
								type: USER_INTERACTION_TYPE.BUTTON,
								name: 'stay_in_app',
							}}
						/>
						<Button
							centeredText
							style={exitFlow.button}
							label={translate('exit.leave')}
							focusOptions={{
								focusKey: 'close-app',
								hasInitialFocus: true,
								nextFocusRight: 'stay-in-app',
							}}
							exitFlow
							onPress={() => {
								if (isTvos()) {
									navigation.goBack();
									return;
								}

								if (isAndroidtv()) {
									BackHandler.exitApp();
									return;
								}
								window.close();
							}}
							track={{
								feature: USER_INTERACTION_FEATURES.EXIT_FLOW,
								context: USER_INTERACTION_CONTEXT.BUTTON_ROW,
								type: USER_INTERACTION_TYPE.BUTTON,
								name: 'close_app',
							}}
						/>
					</View>
				</View>
				<View style={exitFlow.logoContainer}>
					<Image
						style={exitFlow.logo}
						source={logo}
						resizeMode="contain"
					/>
				</View>
			</BackgroundPoster>
		</Screen>
	);
};

ExitFlowScreen.displayName = 'ExitFlowScreen';

export default ExitFlowScreen;
