import rating_age_12 from '../platformAssets/runtime/age_rating/rating_age_12.png';
import rating_age_18 from '../platformAssets/runtime/age_rating/rating_age_18.png';
import rating_age_6 from '../platformAssets/runtime/age_rating/rating_age_6.png';
import rating_age_9 from '../platformAssets/runtime/age_rating/rating_age_9.png';
import rating_age_all from '../platformAssets/runtime/age_rating/rating_age_all.png';
import rating_discrimination from '../platformAssets/runtime/age_rating/rating_discrimination.png';
import rating_dove_12 from '../platformAssets/runtime/age_rating/rating_dove_12.png';
import rating_dove_18 from '../platformAssets/runtime/age_rating/rating_dove_18.png';
import rating_dove_all from '../platformAssets/runtime/age_rating/rating_dove_all.png';
import rating_drugs from '../platformAssets/runtime/age_rating/rating_drugs.png';
import rating_fear from '../platformAssets/runtime/age_rating/rating_fear.png';
import rating_sex from '../platformAssets/runtime/age_rating/rating_sex.png';
import rating_swearing from '../platformAssets/runtime/age_rating/rating_swearing.png';
import rating_violence from '../platformAssets/runtime/age_rating/rating_violence.png';

export const ratingFileMap = {
	'6PLUS': rating_age_6,
	'18PLUS': rating_age_18,
	'12PLUS': rating_age_12,
	'9PLUS': rating_age_9,
	AL: rating_age_all,
	DISCRIMINATION: rating_discrimination,
	DRUGS: rating_drugs,
	FEAR: rating_fear,
	SEX: rating_sex,
	SWEARING: rating_swearing,
	VIOLENCE: rating_violence,
	DOVE_AL: rating_dove_all,
	DOVE_12PLUS: rating_dove_12,
	DOVE_18PLUS: rating_dove_18,
};
