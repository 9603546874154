import React, { ComponentProps, memo, useCallback } from 'react';
import { StyleSheet } from 'react-native';

import { PlayerType } from '../../contexts/PlayerContext';
import { useI18n } from '../../hooks/useI18n';
import { usePlayer } from '../../hooks/usePlayer';
import { isPlayable } from '../../models/Asset';
import { Details } from '../../models/Details';
import {
	USER_INTERACTION_CONTEXT,
	USER_INTERACTION_FEATURES,
	USER_INTERACTION_TYPE,
} from '../../services/event-tracker/UserInterActionEvent';
import { getScaledValue, getResponsiveScaling } from '../../utils/scale';
import Button from '../button/button-flex';
import Icon from '../icon';
import { IMAGE_WIDTH } from '../tile/tileBase';

const styles = StyleSheet.create({
	button: {
		marginTop: getScaledValue(10),
		marginRight: getScaledValue(10),
		width: getResponsiveScaling((IMAGE_WIDTH + 2) * 2).width,
	},
});

type TrailerButtonProps = {
	asset: Details;
	onFocus: () => void;
} & ComponentProps<typeof Button>;

const TrailerButton = memo(
	({ asset, onFocus, parentContext, focusOptions }: TrailerButtonProps) => {
		const { translate } = useI18n();
		const {
			setShowDetails,
			setPlaying,
			setCurrentTime,
			playerType,
			setIsEnd,
			isEnd,
			setPlayerType,
			setShowPlayer,
			setAsset,
		} = usePlayer();

		const handleClick = useCallback(() => {
			console.log('press trailer', asset?.id);

			if (isPlayable(asset)) {
				setAsset(asset);
			}

			if (playerType === PlayerType.Video) {
				setCurrentTime(0);
			}

			if (isEnd) {
				setCurrentTime(0);
				setIsEnd(false);
			}

			setPlayerType(PlayerType.Trailer);
			setPlaying(true);
			setShowDetails(false);
			setShowPlayer(true);
		}, [
			asset,
			isEnd,
			playerType,
			setAsset,
			setCurrentTime,
			setIsEnd,
			setPlayerType,
			setPlaying,
			setShowDetails,
			setShowPlayer,
		]);

		return (
			<Button
				style={[styles.button]}
				parentContext={parentContext}
				label={translate('button.play.trailer')}
				leftIcon={<Icon type="trailer" />}
				onFocus={onFocus}
				onPress={handleClick}
				focusOptions={focusOptions}
				track={{
					feature: USER_INTERACTION_FEATURES.ASSET_DETAIL,
					context: USER_INTERACTION_CONTEXT.BUTTON_ROW,
					type: USER_INTERACTION_TYPE.BUTTON,
					name: `trailer_play`,
				}}
			/>
		);
	}
);

TrailerButton.displayName = 'TrailerButton';

export default TrailerButton;
