import { View, Text } from '@flexn/sdk';
import RecyclableList, {
	RecyclableListLayoutProvider,
	RecyclableListDataProvider,
} from '@flexn/sdk/lib/components/RecyclableList';
import React, { useState } from 'react';
import { StyleSheet } from 'react-native';

import colors from '../../../platformAssets/runtime/colors';
import fontnames from '../../../platformAssets/runtime/fontnames';
import { getResponsiveScaling, getScaledValue } from '../../utils/scale';
import Pressable from '../pressable';
import { TILE_HEIGHT } from '../tile/tileBase';

type Props = {
	parentContext?: any;
	name: string;
	items: string[];
	onPress: (index: number) => void;
	onFocus?: () => void;
	numberOfCollumns: number;
	style?: any;
	selectedColor: string;
};

const ColorSelect = ({
	items,
	name,
	parentContext,
	onPress,
	onFocus,
	numberOfCollumns,
	selectedColor,
	style = {},
}: Props) => {
	const [dataProvider] = React.useState(
		new RecyclableListDataProvider((r1, r2) => r1 !== r2).cloneWithRows(items)
	);

	const layoutProvider = React.useRef(
		new RecyclableListLayoutProvider(
			() => '_',
			(_: string | number, dim: { width: number; height: number }) => {
				dim.width = getResponsiveScaling(14).width;
				dim.height = getResponsiveScaling(14).width;
			}
		)
	).current;

	return (
		<RecyclableList
			dataProvider={dataProvider}
			layoutProvider={layoutProvider}
			parentContext={parentContext}
			rowRenderer={(
				_type: string | number,
				data: any,
				index: number,
				repeatContext: any
			) => {
				const abbr = name.toUpperCase().substring(0, 2);
				return (
					<Color
						data={data}
						onPress={() => onPress(index)}
						text={abbr}
						repeatContext={repeatContext}
						parentContext={parentContext}
						hasFocus={data === selectedColor}
						focusOptions={{
							focusKey: `color-${index}`,
							nextFocusRight:
								index !== dataProvider.getSize() - 1 && `color-${index + 1}`,

							nextFocusLeft: `color-${index - 1}`,
						}}
					/>
				);
			}}
			isHorizontal={false}
			numberOfCollumns={numberOfCollumns}
			style={{
				justifyContent: 'center',
				alignItems: 'center',
				alignContent: 'center',
				display: 'flex',
			}}
			scrollViewProps={{
				showsVerticalScrollIndicator: false,
			}}
			contentContainerStyle={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: getResponsiveScaling(100).height,
				width: getResponsiveScaling(90).width,
			}}
			type={'grid'}
			renderFooter={() => (
				<View
					style={{
						height: getScaledValue(TILE_HEIGHT),
					}}
				/>
			)}
		/>
	);
};

const stylesColors = StyleSheet.create({
	container: {
		alignItems: 'center',
		flex: 1,
		backgroundColor: 'black',
		justifyContent: 'center',
		width: getResponsiveScaling(90).width,
	},
	avatar: {
		width: getResponsiveScaling(12).width,
		height: getResponsiveScaling(12).width,
		borderRadius: 9999,
		// borderColor: 'white',
		// borderWidth: 0,
		alignItems: 'center',
		justifyContent: 'center',
		overflow: 'hidden',
		marginHorizontal: getScaledValue(16),
	},
	text: {
		fontFamily: fontnames.secondaryBold,
		fontSize: getScaledValue(32),
		margin: getScaledValue(8),
		color: colors.primaryText,
	},
});

const Color = ({
	onPress,
	data,
	text,
	hasFocus,
	parentContext,
	repeatContext,
	focusOptions,
}) => {
	const [softFocus, setSoftFocus] = useState(hasFocus);
	return (
		<Pressable
			parentContext={parentContext}
			repeatContext={repeatContext}
			style={[
				stylesColors.avatar,
				{ backgroundColor: data, borderWidth: softFocus ? 4 : 0 },
			]}
			focusOptions={{
				hasInitialFocus: hasFocus,
				animatorOptions: {
					type: 'border',
					duration: 150,
				},
				...focusOptions,
			}}
			onPress={onPress}
			onFocus={() => setSoftFocus(true)}
			onBlur={() => setSoftFocus(false)}
		>
			<Text style={stylesColors.text}>{text}</Text>
		</Pressable>
	);
};

export default ColorSelect;
