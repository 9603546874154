import { Image, View } from '@flexn/sdk';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, Text } from 'react-native';
import { isWebos } from '@rnv/renative';

import colors from '../../platformAssets/runtime/colors';
import logo from '../../platformAssets/runtime/icons/logo.png';
import bottom_logo_shadow from '../../platformAssets/runtime/img/bottom_logo_shadow.png';
import config from '../../platformAssets/runtime/config';
import fonts from '../../platformAssets/runtime/fontnames';
import BackgroundPoster from '../components/background-poster';
import Button from '../components/button/button-flex';
import DeviceInfoTrigger from '../components/device-info-trigger';
import Input from '../components/input';
import Screen from '../components/screen';
import Title from '../components/title';
import { useAsset, useRegistrationBackground } from '../hooks/api';
import { useI18n } from '../hooks/useI18n';
import { NavigatorParameterList } from '../navigators/main-screen-navigator';
import { useAuth } from '../providers/auth';
import { getScaledValue } from '../utils/scale';
import UserInterActionEvent, {
	USER_INTERACTION_CONTEXT,
	USER_INTERACTION_FEATURES,
	USER_INTERACTION_TYPE,
} from '../services/event-tracker/UserInterActionEvent';
import ClientTracker from '../services/event-tracker/ClientTracker';

const styles = StyleSheet.create({
	screenContainer: {
		flex: 1,
		// justifyContent: 'space-between',
		paddingTop: getScaledValue(26),
		paddingBottom: getScaledValue(26),
		paddingLeft: getScaledValue(60),
		paddingRight: getScaledValue(60),
		backgroundColor: 'rgba(0,0,0,0.4)',
	},
	title: {
		color: colors.primaryText,
		marginBottom: getScaledValue(8),
	},
	text: {
		fontFamily: fonts.secondary,
		fontSize: getScaledValue(16),
		color: colors.secondaryText,
		marginBottom: getScaledValue(16),
	},
	form: {
		backgroundColor: 'rgba(0,0,0,0.6)',
		padding: getScaledValue(16),
		width: '40%',
		height: '100%',
		marginLeft: getScaledValue(16),
		borderWidth: getScaledValue(1),
		borderRadius: getScaledValue(4),
		borderColor: 'rgba(255,255,255,0.2)',
	},
	errorText: {
		color: 'tomato',
		fontSize: getScaledValue(16),
		marginBottom: getScaledValue(16),
	},

	qrCodeContainer: {
		flex: 1,
		padding: getScaledValue(16),
	},

	qrCodeTitle: {
		color: colors.primaryText,
		fontSize: getScaledValue(18),
		marginBottom: getScaledValue(16),
	},

	qrCodeLabel: {
		borderWidth: getScaledValue(1),
		borderColor: colors.primaryText,
		marginRight: getScaledValue(12),
		borderRadius: getScaledValue(4),
		paddingVertical: getScaledValue(4),
		paddingHorizontal: getScaledValue(8),
		height: getScaledValue(24),
	},

	qrCodeLabelText: {
		color: colors.primaryText,
		fontSize: getScaledValue(10),
		textTransform: 'uppercase',
		fontFamily: fonts.secondaryBold,
	},

	qrcodeOptionContainer: {
		display: 'flex',
		flexDirection: 'row',
		marginBottom: getScaledValue(16),
	},

	qrcodeOptionTitle: {
		color: colors.secondaryText,
		fontSize: getScaledValue(18),
	},
	qrcodeOptionText: {
		color: colors.primaryText,
		fontSize: getScaledValue(26),
		fontFamily: fonts.secondarySemiBold,
	},

	keyboardTextContainer: {
		borderTopWidth: getScaledValue(1),
		borderColor: 'rgba(255,255,255,0.2)',
		marginTop: getScaledValue(32),
		paddingTop: getScaledValue(16),
		marginRight: getScaledValue(80),
		display: 'flex',
		alignItems: 'center',
	},

	keyboardText: {
		color: colors.secondaryText,
		fontSize: getScaledValue(14),
		marginBottom: getScaledValue(8),
	},
	logoContainer: {
		position: 'absolute',
		zIndex: 100,
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
		bottom: getScaledValue(16),
		right: getScaledValue(16),
	},
	logo: {
		margin: 0,
		width: getScaledValue(config.dimensions.logo.width),
		height: getScaledValue(config.dimensions.logo.height),
	},
	logoShadow: {
		zIndex: 99,
		bottom: 0,
		right: getScaledValue(-50),
		height: getScaledValue(260),
		width: getScaledValue(425),
		position: 'absolute',
		opacity: 0.9,
	},
});

type LoginScreenProps = NativeStackScreenProps<NavigatorParameterList, 'Login'>;
const LOGIN_CODE_CHECKER_TIMEOUT = 3000;

const LoginScreen: React.FC<LoginScreenProps> = ({ navigation }) => {
	const { translate } = useI18n();
	const { data: background } = useRegistrationBackground();

	const [error, setError] = React.useState<string | null>(null);
	const [email, setEmail] = React.useState(config.dev.email || '');
	const [password, setPassword] = React.useState(config.dev.password || '');
	const { login, isLoggingIn, loginCode, user } = useAuth();
	const [authorizationCode, setAuthorizationCode] = useState(0);
	const timer = useRef<number | undefined>();

	const { data: selectedAsset } = useAsset();

	const handleChangeEmail = (email: string) => {
		setEmail(email);
	};

	const handleChangePassword = (password: string) => {
		setPassword(password);
	};

	const handleLogInPress = async () => {
		try {
			await login({ email, password });

			const userInterActionEvent = new UserInterActionEvent();
			userInterActionEvent.setFeature(USER_INTERACTION_FEATURES.LOGIN);
			userInterActionEvent.setContext(
				USER_INTERACTION_CONTEXT.LOGIN_EMAIL_PASSWORD
			);
			userInterActionEvent.setType(USER_INTERACTION_TYPE.REQUEST);
			userInterActionEvent.setName(`/v2/user/loginemail`);

			ClientTracker.sendUserInterActionEvent(userInterActionEvent);

			navigation.navigate('SwitchProfile');
		} catch (error: any) {
			const userInterActionEvent = new UserInterActionEvent();
			userInterActionEvent.setFeature(USER_INTERACTION_FEATURES.LOGIN);
			userInterActionEvent.setContext(
				USER_INTERACTION_CONTEXT.LOGIN_EMAIL_PASSWORD
			);
			userInterActionEvent.setType(USER_INTERACTION_TYPE.ERROR);
			userInterActionEvent.setName(`errorDetails`);
			userInterActionEvent.setBody({ message: error.message });

			ClientTracker.sendUserInterActionEvent(userInterActionEvent);

			setError(error.message);
		}
	};

	const loginCodeChecker = useCallback(async () => {
		clearTimeout(timer.current);

		if (authorizationCode === 0 || user) return;

		try {
			await loginCode(authorizationCode);

			const userInterActionEvent = new UserInterActionEvent();
			userInterActionEvent.setFeature(USER_INTERACTION_FEATURES.LOGIN);
			userInterActionEvent.setContext(USER_INTERACTION_CONTEXT.LOGIN_QRCODE);
			userInterActionEvent.setType(USER_INTERACTION_TYPE.REQUEST);
			userInterActionEvent.setName(`/v2/user/logincode/activate`);

			ClientTracker.sendUserInterActionEvent(userInterActionEvent);

			navigation.navigate('SwitchProfile');
		} catch (error: any) {
			timer.current = setTimeout(() => {
				loginCodeChecker();
			}, LOGIN_CODE_CHECKER_TIMEOUT) as unknown as number;
		}
	}, [authorizationCode, loginCode, navigation, user]);

	useEffect(() => {
		setAuthorizationCode(Math.floor(100000 + Math.random() * 900000));
	}, []);

	useFocusEffect(
		useCallback(() => {
			loginCodeChecker();

			return () => {
				clearTimeout(timer.current);
			};
		}, [loginCodeChecker])
	);

	const isFocused = useIsFocused();

	return (
		<Screen stealFocus={true}>
			<BackgroundPoster poster={background}>
				<View style={styles.screenContainer}>
					<DeviceInfoTrigger />

					<View
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'flex-start',
							flex: 1,
						}}
					>
						<View
							style={{
								display: 'flex',
								justifyContent: 'center',
								flexDirection: 'row',
								flex: 1,
								position: 'relative',
								marginTop: getScaledValue(32),
							}}
						>
							<View style={styles.qrCodeContainer}>
								<View style={styles.qrcodeOptionContainer}>
									<View style={styles.qrCodeLabel}>
										<Text style={styles.qrCodeLabelText}>
											{translate('login.code.step1.label')}
										</Text>
									</View>
									<View>
										<Text style={styles.qrcodeOptionTitle}>
											{translate('login.code.step1.title')}
										</Text>
										<Text style={styles.qrcodeOptionText}>
											{translate('login.code.step1.text')}
										</Text>

										<Image
											source={{
												uri: `http://api.qrserver.com/v1/create-qr-code/?color=000000&bgcolor=FFFFFF&data=${encodeURIComponent(
													config.qrCodePage
												)}&qzone=1&margin=0&size=400x400&ecc=L`,
											}}
											style={{
												height: getScaledValue(100),
												width: getScaledValue(100),
												marginTop: getScaledValue(8),
												borderRadius: getScaledValue(4),
											}}
										/>
									</View>
								</View>

								<View style={styles.qrcodeOptionContainer}>
									<View style={styles.qrCodeLabel}>
										<Text style={styles.qrCodeLabelText}>
											{translate('login.code.step2.label')}
										</Text>
									</View>
									<View>
										<Text style={styles.qrcodeOptionTitle}>
											{translate('login.code.step2.title')}
										</Text>

										<Text
											style={[
												styles.qrcodeOptionText,
												{ letterSpacing: getScaledValue(8) },
											]}
										>
											{authorizationCode}
										</Text>
									</View>
								</View>

								<View style={styles.qrcodeOptionContainer}>
									<View style={styles.qrCodeLabel}>
										<Text style={styles.qrCodeLabelText}>
											{translate('login.code.step3.label')}
										</Text>
									</View>
									<View>
										<Text
											style={[
												styles.qrcodeOptionTitle,
												{ color: colors.primaryText },
											]}
										>
											{translate('login.code.step3.title')}
										</Text>
									</View>
								</View>
							</View>
							<View style={styles.form}>
								<Text style={styles.qrCodeTitle}>
									{translate('login.input.title')}
								</Text>
								<Input
									style={{ width: '100%' }}
									autoComplete="email"
									value={email}
									placeholder={translate('email.title')}
									keyboardType="email-address"
									textContentType="emailAddress"
									onChangeText={handleChangeEmail}
									focusOptions={{
										forbiddenFocusDirections: ['up'],
									}}
									track={{
										feature: USER_INTERACTION_FEATURES.LOGIN,
										context: USER_INTERACTION_CONTEXT.LOGIN_EMAIL_PASSWORD,
										type: USER_INTERACTION_TYPE.INPUT,
										name: 'email',
									}}
								/>

								<Input
									style={{ width: '100%' }}
									value={password}
									autoComplete="password"
									placeholder={translate('password.title')}
									textContentType="password"
									secureTextEntry={true}
									onChangeText={handleChangePassword}
									track={{
										feature: USER_INTERACTION_FEATURES.LOGIN,
										context: USER_INTERACTION_CONTEXT.LOGIN_EMAIL_PASSWORD,
										type: USER_INTERACTION_TYPE.INPUT,
										name: 'password',
									}}
								/>

								{error && <Text style={styles.errorText}>{error}</Text>}

								<Button
									onPress={handleLogInPress}
									hasTVPreferredFocus={navigation.isFocused()}
									focusOptions={{
										forbiddenFocusDirections: ['down'],
									}}
									label={
										isLoggingIn
											? translate('login.button.process')
											: translate('login.button.text')
									}
									track={{
										feature: USER_INTERACTION_FEATURES.LOGIN,
										context: USER_INTERACTION_CONTEXT.LOGIN_EMAIL_PASSWORD,
										type: USER_INTERACTION_TYPE.BUTTON,
										name: 'submit',
									}}
								/>
							</View>
						</View>
					</View>
				</View>
			</BackgroundPoster>
			{!isWebos() && (
				<Image
					source={bottom_logo_shadow}
					resizeMode={'contain'}
					style={styles.logoShadow}
				/>
			)}
			<View style={styles.logoContainer}>
				<Image
					style={styles.logo}
					source={logo}
					resizeMode="contain"
				/>
			</View>
		</Screen>
	);
};

export default LoginScreen;
