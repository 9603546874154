export const assetTypeMap = {
	movie: 0,
	episode: 1,
	season: 2,
	tvshow: 6,
	collection: 8,
	subscriptionPlan: 11,
	liveEvent: 14,
	'0': 'movie',
	'1': 'episode',
	'2': 'season',
	'6': 'tvshow',
	'8': 'collection',
	'11': 'subscriptionPlan',
	'14': 'liveEvent',
};

export enum AssetType {
	movie = 0,
	episode = 1,
	season = 2,
	tvshow = 6,
	collection = 8,
	subscriptionPlan = 11,
	liveEvent = 14,
}
