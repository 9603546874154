import { StyleSheet, Text, View } from '@flexn/sdk';
import { isTizen, isWebos, platform } from '@rnv/renative';
import React, { useEffect, useReducer } from 'react';
import DeviceInfo from 'react-native-device-info';
import { useI18n } from '../hooks/useI18n';
import { useSubscriptions } from '../hooks/api';
import { useAuth } from '../providers/auth';
import version from '../../release.current-version';
import config from '../../platformAssets/runtime/config';
import { getScaledValue } from '../utils/scale';

const styles = StyleSheet.create({
	container: {
		flex: 1,
		overflow: 'scroll',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	column: {
		flex: 1,
	},
	row: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	cellProp: {
		width: '30%',
	},
	textProp: {
		color: 'grey',
		fontSize: getScaledValue(12),
		marginRight: getScaledValue(4),
	},
	cellValue: {
		width: '70%',
	},
	textValue: {
		color: 'white',
		fontSize: getScaledValue(14),
		fontWeight: 'bold',
	},
});

const DeviceInfoComponent: React.FC = ({ style }: { style?: any}) => {
	const { language, country, countryCode } = useI18n();
	const { user } = useAuth();
	const { activeSubscription } = useSubscriptions();
	let appName = DeviceInfo.getApplicationName();
	let systemName = DeviceInfo.getSystemName();
	let readableVersion = DeviceInfo.getReadableVersion();
	let type = DeviceInfo.getDeviceType();
	const brand = DeviceInfo.getBrand();
	const buildNumber = DeviceInfo.getBuildNumber();
	const deviceId = DeviceInfo.getDeviceId();
	let bundleId = DeviceInfo.getBundleId();
	const systemVersion = DeviceInfo.getSystemVersion();

	if (isTizen() || isWebos()) {
		appName = config.platformName;
		bundleId = config.appId;
		systemName = platform;
		readableVersion = version;
		type = 'Web';
	}

	const [info, updateInfo] = useReducer(
		(currentInfo, updateInfo) => {
			return { ...currentInfo, ...updateInfo };
		},
		{
			appName,
			systemName,
			deviceId,
			platform,
			readableVersion,
			buildNumber,
			bundleId,
			language,
			country,
			countryCode,

			type,
			brand,
			systemVersion,
		}
	);
	useEffect(() => {
		updateInfo({
			userId: user?.id,
			subscriptionId: activeSubscription?.id,
		});
	}, [activeSubscription, user]);

	useEffect(() => {
		DeviceInfo.getCodename().then((codename) => {
			// "REL"
			updateInfo({ codename });
		});

		DeviceInfo.getBaseOs().then((baseOs) => {
			// "Windows", "Android" etc
			updateInfo({ baseOs });
		});

		DeviceInfo.getBootloader().then((bootloader) => {
			updateInfo({ bootloader });

			// "mw8998-002.0069.00"
		});

		DeviceInfo.getDevice().then((device) => {
			updateInfo({ device });

			// "walleye"
		});

		DeviceInfo.getDisplay().then((display) => {
			updateInfo({ display });

			// "OPM2.171026.006.G1"
		});

		DeviceInfo.getDeviceName().then((deviceName) => {
			updateInfo({ deviceName });

			// iOS: "Becca's iPhone 6"
			// Android: ?
			// Windows: ?
		});

		DeviceInfo.getFirstInstallTime().then((firstInstallTime) => {
			updateInfo({ firstInstallTime });

			// Android: 1517681764528
		});

		DeviceInfo.getHardware().then((hardware) => {
			updateInfo({ hardware });

			// "walleye"
		});

		DeviceInfo.getHost().then((host) => {
			updateInfo({ host });

			// "wprd10.hot.corp.google.com"
		});

		DeviceInfo.getIpAddress().then((ip) => {
			updateInfo({ ip });
		});

		DeviceInfo.getInstallerPackageName().then((installerPackageName) => {
			updateInfo({ installerPackageName });

			// Play Store: "com.android.vending"
			// Amazon: "com.amazon.venezia"
			// Samsung App Store: "com.sec.android.app.samsungapps"
			// iOS: "AppStore", "TestFlight", "Other"
		});

		DeviceInfo.getLastUpdateTime().then((lastUpdateTime) => {
			updateInfo({ lastUpdateTime });

			// Android: 1517681764992
		});

		DeviceInfo.getManufacturer().then((manufacturer) => {
			updateInfo({ manufacturer });
		});

		DeviceInfo.getMaxMemory().then((maxMemory) => {
			updateInfo({ maxMemory });
		});

		DeviceInfo.getProduct().then((product) => {
			updateInfo({ product });
		});

		// iOS: "11.0"
		// Android: "7.1.1"
		// Windows: ?

		DeviceInfo.getBuildId().then((buildId) => {
			updateInfo({ buildId });
			// iOS: "12A269"
			// tvOS: not available
			// Android: "13D15"
			// Windows: not available
		});

		DeviceInfo.getUserAgent().then((userAgent) => {
			updateInfo({ userAgent });
			// iOS: "Mozilla/5.0 (iPhone; CPU iPhone OS 9_1 like Mac OS X) AppleWebKit/601.1.46 (KHTML, like Gecko) Version/9.0 Mobile/13B143"
			// tvOS: not available
			// Android: ?
			// Windows: ?
		});
	}, []);

	const firstColumn = () => {
		const keys = Object.keys(info);
		const l = Math.ceil(keys.length / 2);

		return keys.splice(0, l);
	};

	const secondColumn = () => {
		const keys = Object.keys(info);
		const l = Math.ceil(keys.length / 2);

		return keys.splice(l, keys.length);
	};

	const renderColumn = (key) => {
		return (
			<View
				key={key}
				style={styles.row}
			>
				<View style={styles.cellProp}>
					<Text style={styles.textProp}>{key}</Text>
				</View>
				<View style={styles.cellValue}>
					<Text style={styles.textValue}>{info[key]}</Text>
				</View>
			</View>
		);
	};
	return (
		<View style={[styles.container, style]}>
			<View style={styles.column}>{firstColumn().map(renderColumn)}</View>
			<View style={styles.column}>{secondColumn().map(renderColumn)}</View>
		</View>
	);
};

export default DeviceInfoComponent;
