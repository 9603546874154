import {
	RecyclableList,
	RecyclableListDataProvider,
	RecyclableListLayoutProvider,
} from '@flexn/sdk';
import { useEffect, useRef, useState } from 'react';
import { StyleSheet, Image, Dimensions } from 'react-native';
import { usePlayer } from '../../hooks/usePlayer';
import * as RNLocalize from 'react-native-localize';
import { Subtitle } from '../../contexts/PlayerContext';
import Button from '../button/button-flex';
import { isWebBased } from '@rnv/renative';
import { Storage } from '../../utils/storage';
import { useI18n } from '../../hooks/useI18n';
import { getResponsiveScaling, getScaledValue } from '../../utils/scale';
import config from '../../../platformAssets/runtime/config';
import isNative from '../../utils/isNative';
import subtitlesIcon from '../../../platformAssets/runtime/icon/subtitles.png';
import {
	USER_INTERACTION_CONTEXT,
	USER_INTERACTION_FEATURES,
	USER_INTERACTION_TYPE,
} from '../../services/event-tracker/UserInterActionEvent';
import { preferredSubtitleLanguageStorageKey } from '../../utils/storageKeys';

const subtitleLanguageMapper = {
	'no-nob': 'nb',
};

const SubtitleList = ({ parentContext }) => {
	const {
		subtitles,
		playerWeb,
		setSelectedSubtitle,
		setShowSettingsDialog,
		selectedSubtitle,
	} = usePlayer();

	const { translate } = useI18n();

	const layoutProvider: any = useRef();

	const dataProviderInstance = useRef(
		new RecyclableListDataProvider((r1, r2) => r1 !== r2)
	).current;

	const [dataProvider, setDataProvider] = useState(
		dataProviderInstance.cloneWithRows([{ disable: true }, ...subtitles])
	);

	const setLayoutProvider = () => {
		layoutProvider.current = new RecyclableListLayoutProvider(
			// @ts-ignore
			// @note: support for custom types in Flexn is not supported
			(index) => index,
			(item, dim: { width: number; height: number }) => {
				dim.width = Dimensions.get('screen').width;
				dim.height = getResponsiveScaling(11).height;
			}
		);
	};

	const disableSubtitle = () => {
		if (isWebBased) {
			playerWeb?.subtitles.disable(
				subtitles.find((s) => s.language === selectedSubtitle)?.id || ''
			);
		}

		setSelectedSubtitle('disabled');
		Storage.setItem('lang', 'disabled');
		setShowSettingsDialog(false);
	};

	const selectSubtitle = (subtitle: Subtitle) => {
		setSelectedSubtitle(subtitle.language || '');
		Storage.setItem(
			preferredSubtitleLanguageStorageKey,
			subtitle.language || ''
		);
		setShowSettingsDialog(false);
	};

	const getSubtitleTitleForLanguage = (subtitle: Subtitle) => {
		const l =
			subtitle?.language ||
			subtitle?.lang ||
			config.localisation.default.language;
		const language = subtitleLanguageMapper[l] || l;

		const fallback = subtitle?.title || subtitle?.language || subtitle.lang;

		const lokaliseValue = translate(`subtitle.${language}`);

		return lokaliseValue !== `subtitle.${language}` ? lokaliseValue : fallback;
	};

	useEffect(() => {
		const langs = RNLocalize.getLocales();

		setLayoutProvider();
		setDataProvider(
			dataProviderInstance.cloneWithRows([
				{ disable: true },
				...subtitles
					.filter((s) => s.language || s.lang)
					.sort((a: Subtitle, b: Subtitle) => {
						if (a.language === langs[0].countryCode.toLowerCase()) {
							return -1; // own language comes first
						} else if (a.language === 'en' && b.language !== 'nl') {
							return -1; // 'english' comes second
						} else if (a.language === selectedSubtitle) {
							return -1; // selected language comes first
						} else {
							return a?.language?.localeCompare(b?.language);
						}
					}),
			])
		);
	}, [dataProviderInstance, subtitles.length]);

	return (
		<RecyclableList
			parentContext={parentContext}
			renderAheadOffset={10}
			type="list"
			isHorizontal={false}
			scrollViewProps={{
				showsVerticalScrollIndicator: false,
			}}
			style={{
				height: Dimensions.get('screen').height * 0.6,
			}}
			dataProvider={dataProvider}
			layoutProvider={layoutProvider.current}
			rowRenderer={(
				_type: string | number,
				subtitle: any,
				index: number,
				repeatContext: any
			) => {
				if (subtitle?.disable) {
					return (
						<Button
							onPress={disableSubtitle}
							repeatContext={repeatContext}
							label={translate('subtitle.off')}
							rightIcon={
								selectedSubtitle === 'disabled' ? (
									<Image
										source={subtitlesIcon}
										style={styles.iconSmall}
									/>
								) : null
							}
							track={{
								feature: USER_INTERACTION_FEATURES.PLAYER,
								context: USER_INTERACTION_CONTEXT.PLAYER_CONTROLS,
								type: USER_INTERACTION_TYPE.BUTTON,
								name: 'subtile_nosubtitles',
							}}
						/>
					);
				}

				return (
					<Button
						onPress={() => selectSubtitle(subtitle)}
						style={styles.subtileButton}
						key={isNative ? subtitle.index : subtitle.id}
						label={getSubtitleTitleForLanguage(subtitle)}
						rightIcon={
							selectedSubtitle === subtitle.language ? (
								<Image
									source={subtitlesIcon}
									style={styles.iconSmall}
								/>
							) : null
						}
						repeatContext={repeatContext}
						track={{
							feature: USER_INTERACTION_FEATURES.PLAYER,
							context: USER_INTERACTION_CONTEXT.PLAYER_CONTROLS,
							type: USER_INTERACTION_TYPE.BUTTON,
							name: `subtile_${
								subtitle.language ||
								subtitle.lang ||
								config.localisation.default.language
							}`,
						}}
						focusOptions={{
							...(index === subtitle.length
								? { forbiddenFocusDirections: ['down'] }
								: {}),
						}}
					/>
				);
			}}
		/>
	);
};

const styles = StyleSheet.create({
	iconSmall: {
		width: getScaledValue(15),
		height: getScaledValue(15),
	},

	subtileButton: {
		marginBottom: getScaledValue(10),
	},
});

export default SubtitleList;
