import {
	isPlatformAndroidtv,
	isPlatformTizen,
	isPlatformTvos,
	isPlatformWebos,
	isTizen,
	isWebos,
} from '@rnv/renative';
import { useEffect } from 'react';
import { AppState, AppStateStatus } from 'react-native';
import DeviceInfo from 'react-native-device-info';

import config from '../../platformAssets/runtime/config';
import version from '../../release.current-version';
import { useAuth } from '../providers/auth';
import ClientTracker, {
	ClientTrackerDevicePlatform,
	ClientTrackerDeviceType,
} from '../services/event-tracker/ClientTracker';
import UserInterActionEvent, {
	USER_INTERACTION_CONTEXT,
	USER_INTERACTION_FEATURES,
	USER_INTERACTION_TYPE,
} from '../services/event-tracker/UserInterActionEvent';

import { useSubscriptions } from './api';
import { useI18n } from './useI18n';

export function useClientTracker() {
	const { user, profile } = useAuth();
	const { activeSubscription } = useSubscriptions();
	const { countryCode, language } = useI18n();

	const handleAppStateChange = (nextAppState: AppStateStatus) => {
		const userInterActionEvent = new UserInterActionEvent();
		userInterActionEvent.setFeature(USER_INTERACTION_FEATURES.STARTUP);
		userInterActionEvent.setContext(USER_INTERACTION_CONTEXT.STATE);
		userInterActionEvent.setType(USER_INTERACTION_TYPE.CHANGE);

		if (nextAppState === 'active') {
			userInterActionEvent.setName('active');
		} else if (nextAppState === 'background') {
			userInterActionEvent.setName('background');
		} else if (nextAppState === 'inactive') {
			userInterActionEvent.setName('inactive');
		}

		ClientTracker.sendUserInterActionEvent(userInterActionEvent);
	};

	useEffect(() => {
		if (user) {
			ClientTracker.setCustomerId(user.id);
		}
	}, [user]);

	useEffect(() => {
		if (activeSubscription) {
			ClientTracker.setSubscriptionId(activeSubscription.id);
		}
	}, [activeSubscription]);

	useEffect(() => {
		if (profile) {
			ClientTracker.setProfileId(profile.id);
		}
	}, [profile]);

	useEffect(() => {
		if (countryCode) {
			ClientTracker.setCountry(countryCode);
		}
	}, [countryCode]);

	useEffect(() => {
		if (language) {
			ClientTracker.setDeviceLanguage(language);
		}
	}, [language]);

	// Devive info
	useEffect(() => {
		let platform = ClientTrackerDevicePlatform.UNKNOWN;
		if (isPlatformAndroidtv && DeviceInfo.getBrand() === 'Amazon') {
			platform = ClientTrackerDevicePlatform.FIRE_TV;
		} else if (isPlatformAndroidtv) {
			platform = ClientTrackerDevicePlatform.ANDROID_TV;
		} else if (isPlatformTvos) {
			platform = ClientTrackerDevicePlatform.APPLE_TV;
		} else if (isPlatformWebos) {
			platform = ClientTrackerDevicePlatform.WEBOS;
		} else if (isPlatformTizen) {
			platform = ClientTrackerDevicePlatform.TIZEN;
		}

		ClientTracker.setDevicePlatform(platform);
		ClientTracker.setDeviceType(ClientTrackerDeviceType.SMART_TV);
		ClientTracker.setDeviceVersion(DeviceInfo.getSystemVersion());
	}, []);

	// Config setters
	useEffect(() => {
		ClientTracker.setChannel(config.appName);
		ClientTracker.setAppName(config.platformName);

		if (isTizen() || isWebos()) {
			ClientTracker.setAppVersion(version);
		} else {
			ClientTracker.setAppVersion(DeviceInfo.getReadableVersion());
		}
	}, []);

	useEffect(() => {
		const stateListener = AppState.addEventListener(
			'change',
			handleAppStateChange
		);

		return () => {
			stateListener.remove();
		};
	}, []);
}
