import { View } from '@flexn/sdk';
import { PressableFocusOptions } from '@flexn/sdk/lib/focusManager/types';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import * as Sentry from '@sentry/browser';
import React, { useEffect, useState } from 'react';
import { Image, StyleSheet, Text } from 'react-native';
import deviceInfoModule from 'react-native-device-info';

import colors from '../../platformAssets/runtime/colors';
import config from '../../platformAssets/runtime/config';
import fontnames from '../../platformAssets/runtime/fontnames';
import logo from '../../platformAssets/runtime/icons/logo.png';
import pen from '../../platformAssets/runtime/icon/pen.png';
import Avatar from '../components/avatar';

import Pressable from '../components/pressable';
import Screen from '../components/screen';
import Title from '../components/title';
import { useI18n } from '../hooks/useI18n';
import { Profile } from '../models/Profile';
import { NavigatorParameterList } from '../navigators/main-screen-navigator';
import { useAuth } from '../providers/auth';
import { getScaledValue } from '../utils/scale';

const styles = StyleSheet.create({
	screenContainer: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'black',
	},
	profileList: {
		flexDirection: 'row',
		justifyContent: 'center',
		width: '100%',
	},
	profileCard: {
		alignItems: 'center',
		justifyContent: 'flex-start',
		margin: getScaledValue(20),
		width: getScaledValue(150),
	},
	profileName: {
		fontFamily: fontnames.secondary,
		fontSize: getScaledValue(16),
		margin: getScaledValue(12),
		color: colors.primaryText,
	},
	input: {
		width: getScaledValue(256),
		height: getScaledValue(48),
		marginBottom: getScaledValue(16),
		borderRadius: getScaledValue(8),
		fontSize: getScaledValue(16),
		backgroundColor: '#ffffff20',
	},
	button: {
		marginTop: getScaledValue(16),
		width: getScaledValue(180),
		borderRadius: getScaledValue(8),
		backgroundColor: '#ffffff20',
		overflow: 'hidden',
	},
	buttonText: {
		marginLeft: getScaledValue(12),
		marginRight: getScaledValue(12),
		marginTop: getScaledValue(16),
		marginBottom: getScaledValue(16),
		fontSize: getScaledValue(16),
		color: 'white',
	},
	logoContainer: {
		position: 'absolute',
		zIndex: 100,
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
		bottom: getScaledValue(16),
		right: getScaledValue(
			deviceInfoModule.getBrand()?.includes('Amazon') ? 24 : 16
		),
	},
	logo: {
		margin: 0,
		width: getScaledValue(config.dimensions.logo.width),
		height: getScaledValue(config.dimensions.logo.height),
	},
});

type SwitchProfileScreenProps = NativeStackScreenProps<
	NavigatorParameterList,
	'SwitchProfile'
>;

const SwitchProfileScreen: React.FC<SwitchProfileScreenProps> = ({
	navigation,
}) => {
	const {
		user,
		profile,
		profiles,
		switchProfile,
		refetchUser,
		refetchProfiles,
	} = useAuth();
	const { translate } = useI18n();
	const [focusId, setFocusId] = useState(
		profiles?.findIndex((p) => profile?.id === p.id) || 0
	);

	const handleProfileCardPress = async (profile: Profile) => {
		try {
			await switchProfile(profile);

			navigation.navigate('Home');
		} catch (error) {
			Sentry.captureMessage(
				`SwitchProfileScreen - Profile ${profile} - ${error}`
			);
			navigation.navigate('Home');
		}
	};

	const selectedProfile = () => {
		return profiles?.find((p) => profile?.id === p.id);
	};

	useEffect(() => {
		if (!user) {
			refetchUser();
		}

		if (!profiles) {
			refetchProfiles();
		}
	}, [user, profile]);

	return (
		<Screen
			style={styles.screenContainer}
			stealFocus={true}
		>
			<Title>{translate('user.profile.who-is-watching')}</Title>
			<View style={styles.profileList}>
				{profiles?.map((profile, index) => (
					<View
						style={styles.profileCard}
						key={profile.id}
					>
						<Avatar
							name={profile.name}
							color={colors.profileAvatars[profile.image_url] || colors.primary}
							onPress={() => handleProfileCardPress(profile)}
							hasFocus={profile.id === selectedProfile()?.id}
							onFocus={() => {
								setFocusId(index);
							}}
						/>
						{focusId === index && (
							<Pressable
								onPress={() => navigation.navigate('EditProfile', { profile })}
								style={{
									marginTop: getScaledValue(16),
									borderRadius: getScaledValue(9999),
									width: getScaledValue(28),
									height: getScaledValue(28),
									justifyContent: 'center',
									alignItems: 'center',
								}}
								focusOptions={
									{
										animatorOptions: {
											type: 'background',
											backgroundColorFocus: colors.muted, // Required. Color which will be applied when element gains focus
											duration: 50, // Optional. How long time border transition should take
										},
									} as PressableFocusOptions
								}
							>
								<Image
									source={pen}
									style={{
										width: getScaledValue(20),
										height: getScaledValue(20),
									}}
								/>
							</Pressable>
						)}
					</View>
				))}
				{profiles && profiles.length < 4 && (
					<View style={styles.profileCard}>
						<Avatar
							name={'+'}
							color={colors.inactive}
							onPress={() =>
								navigation.navigate('EditProfile', { profile: undefined })
							}
							hasFocus={false}
							onFocus={() => {
								setFocusId(-1);
							}}
						/>
					</View>
				)}
			</View>
			<View style={styles.logoContainer}>
				<Image
					style={styles.logo}
					source={logo}
					resizeMode="contain"
				/>
			</View>
		</Screen>
	);
};

export default SwitchProfileScreen;
