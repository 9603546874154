import * as Sentry from '@sentry/browser';
import { useContext } from 'react';
import { ConfigContext } from '../contexts/ConfigContext';

export function useConfig() {
	const context = useContext(ConfigContext);

	if (context === undefined) {
		Sentry.captureMessage(`useConfig must be within ConfigProvider`);
		throw new Error('useConfig must be within ConfigProvider');
	}

	return context;
}
