import * as Sentry from '@sentry/browser';
import React from 'react';
import {
	QueryFunctionContext,
	QueryObserverResult,
	RefetchOptions,
	UseMutateAsyncFunction,
	useMutation,
	useQuery,
	useQueryClient,
} from 'react-query';

import config from '../../platformAssets/runtime/config';
import { Profile } from '../models/Profile';
import { User } from '../models/User';
import { Storage } from '../utils/storage';
import { authTokenStorageKey } from '../utils/storageKeys';

export type LoginCredentials = {
	email: string;
	password: string;
};

export type RegisterCredentials = {
	email: string;
	name: string;
	password: string;
};

export type Token = string;

export interface AuthProviderConfig<User = unknown, Error = unknown> {
	tokenKey?: string;
	userKey?: string;
	profileKey?: string;
	profilesKey?: string;
	settingKey?: string;
	fetchUser: (context: QueryFunctionContext) => Promise<User | null>;
	fetchProfiles: (context: QueryFunctionContext) => Promise<Profile[] | null>;
	fetchSettings: (
		context: QueryFunctionContext
	) => Promise<[key: string, value: string][] | null>;
	switchProfileFn: (
		profile: Profile
	) => Promise<{ profile: Profile; token: Token }>;
	updateProfileFn: (profile: Profile) => Promise<void>;
	createProfileFn: (data: {
		name: string;
		image_url?: string;
	}) => Promise<void>;
	deleteProfileFn: (profile: Profile) => Promise<void>;
	loginFn: (data: LoginCredentials) => Promise<Token>;
	loginCodeFn: (code: number) => Promise<Token>;
	logoutFn: () => Promise<void>;
}

export interface AuthContextValue<
	User = unknown,
	Error = unknown,
	LoginCredentials = unknown
> {
	token: string | undefined;
	user: User | undefined | null;
	profile: Profile | undefined | null;
	profiles: Profile[] | undefined | null;
	switchProfile: UseMutateAsyncFunction<
		{ profile: Profile; token: Token },
		any,
		Profile,
		any
	>;
	updateProfile: UseMutateAsyncFunction<any, any, Profile, any>;
	createProfile: UseMutateAsyncFunction<
		any,
		any,
		{ name: string; image_url?: string },
		any
	>;
	deleteProfile: UseMutateAsyncFunction<any, any, Profile, any>;
	isLoading: boolean;
	isSwitchingProfile: boolean;
	login: UseMutateAsyncFunction<Token, any, LoginCredentials>;
	loginCode: UseMutateAsyncFunction<Token, any, number>;
	isLoggingIn: boolean;
	logout: UseMutateAsyncFunction<any, any, void, any>;
	isLoggingOut: boolean;
	refetchProfiles: (
		options?: RefetchOptions | undefined
	) => Promise<QueryObserverResult<Profile[] | null, Error>>;
	refetchUser: (
		options?: RefetchOptions | undefined
	) => Promise<QueryObserverResult<User, Error>>;
	error: Error | null;
	userSettings: [key: string, value: string][];
}

export interface AuthProviderProps {
	children: React.ReactNode;
}

function initReactQueryAuth<User = unknown, Error = unknown>(
	config: AuthProviderConfig<User, Error>
) {
	const AuthContext = React.createContext<AuthContextValue<
		User | null,
		Error,
		LoginCredentials
	> | null>(null);
	AuthContext.displayName = 'AuthContext';

	const {
		fetchUser,
		fetchProfiles,
		fetchSettings,
		switchProfileFn,
		updateProfileFn,
		createProfileFn,
		deleteProfileFn,
		loginFn,
		logoutFn,
		tokenKey = 'authToken',
		userKey = 'authUser',
		profileKey = 'authUserProfile',
		profilesKey = 'authUserProfiles',
		settingKey = 'authUserSettings',
	} = config;

	function AuthProvider({ children }: AuthProviderProps): JSX.Element {
		console.log('auth provider');
		const queryClient = useQueryClient();
		// queryClient.invalidateQueries(tokenKey);

		const { data: token } = useQuery(tokenKey, async () => {
			const _token = await Storage.getItem(authTokenStorageKey);

			return _token || '';
		});

		const {
			data: user,
			error,
			status,
			isLoading: isLoadingUser,
			isIdle,
			isSuccess,
			refetch,
		} = useQuery<User | null, Error>({
			enabled: !!token,
			queryKey: userKey,
			queryFn: fetchUser,
		});

		const {
			data: profiles,
			isLoading: isLoadingProfiles,
			refetch: refetchProfiles,
		} = useQuery<Profile[] | null, Error>({
			enabled: !!user,
			queryKey: profilesKey,
			queryFn: fetchProfiles,
		});

		const {
			data: userSettings,
			isLoading: IsUserSettingsLoading,
			refetch: refetchSettings,
		} = useQuery<[key: string, value: string][] | null, Error>({
			enabled: !!user,
			queryKey: settingKey,
			queryFn: fetchSettings,
		});

		const { data: profile } = useQuery(profileKey, () => {
			return queryClient.getQueryData<Profile>(profileKey);
		});

		const setToken = React.useCallback(
			(data: string) => queryClient.setQueryData(tokenKey, data),
			[queryClient]
		);

		const setProfile = React.useCallback(
			(data: Profile) => queryClient.setQueryData(profileKey, data),
			[queryClient]
		);

		const switchProfileMutation = useMutation({
			mutationFn: switchProfileFn,
			onSuccess: ({ profile, token }) => {
				if (token) {
					setToken(token);
					setProfile(profile);
				}
			},
		});

		const loginMutation = useMutation({
			mutationFn: loginFn,
			onSuccess: (token) => {
				setToken(token);
			},
		});

		const loginCodeMutation = useMutation({
			mutationFn: loginCodeFn,
			onSuccess: (token) => {
				setToken(token);
			},
		});

		const logoutMutation = useMutation({
			mutationFn: logoutFn,
			onSuccess: () => {
				queryClient.clear();
			},
		});

		const updateProfileMutation = useMutation({
			mutationFn: updateProfileFn,
			onSuccess: () => {
				queryClient.clear();
			},
		});

		const createProfileMutation = useMutation({
			mutationFn: createProfileFn,
			onSuccess: () => {
				queryClient.clear();
			},
		});

		const deleteProfileMutation = useMutation({
			mutationFn: deleteProfileFn,
			onSuccess: () => {
				queryClient.clear();
			},
		});

		const value = {
			isLoading: isLoadingUser || isLoadingProfiles,
			token,
			user,
			profile,
			profiles,
			error,
			refetchUser: refetch,
			refetchProfiles: refetchProfiles,
			login: loginMutation.mutateAsync,
			loginCode: loginCodeMutation.mutateAsync,
			isLoggingIn: loginMutation.isLoading,
			logout: logoutMutation.mutateAsync,
			isLoggingOut: logoutMutation.isLoading,
			switchProfile: switchProfileMutation.mutateAsync,
			updateProfile: updateProfileMutation.mutateAsync,
			createProfile: createProfileMutation.mutateAsync,
			deleteProfile: deleteProfileMutation.mutateAsync,
			isSwitchingProfile: switchProfileMutation.isLoading,
			setToken,
			userSettings,
		};

		return (
			<AuthContext.Provider value={value}>{children}</AuthContext.Provider>
		);
	}

	function useAuth() {
		const context = React.useContext(AuthContext);

		if (!context) {
			Sentry.captureMessage(`useAuth must be used within an AuthProvider`);
			throw new Error(`useAuth must be used within an AuthProvider`);
		}

		return context;
	}

	return { AuthProvider, AuthConsumer: AuthContext.Consumer, useAuth };
}

const fetchUser = async (): Promise<User | null> => {
	const token = await Storage.getItem(authTokenStorageKey);
	console.log(token);

	if (!token) {
		return null;
	}

	const res = await fetch(`${config.userServiceUrl}/v1/user`, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	});

	if (!res.ok) {
		const { message } = await res.json();
		Sentry.captureMessage(`Load user failed.`);
		throw new Error(message || 'Load user failed.');
	}

	const user: User = await res.json();

	await fetchProfiles();

	Sentry.setUser({ userId: user.id });

	return user;
};

const fetchProfiles = async (): Promise<Profile[] | null> => {
	const token = await Storage.getItem(authTokenStorageKey);

	if (!token) {
		return null;
	}

	const res = await fetch(`${config.userServiceUrl}/v1/profile`, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	});

	if (!res.ok) {
		const { message } = await res.json();
		Sentry.captureMessage(`Fetch profiles failed.`);
		throw new Error(message || 'Fetch profiles failed.');
	}

	const profiles: Profile[] = await res.json();

	return profiles;
};

const updateProfileFn = async (profile: Profile): Promise<void> => {
	const token = await Storage.getItem(authTokenStorageKey);

	if (!token) {
		return;
	}

	try {
		await fetch(`${config.userServiceUrl}/v1/profile`, {
			method: 'PUT',
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				id: profile.id,
				name: profile.name,
				image_url: profile.image_url,
				is_default: profile.is_default,
			}),
		});
	} catch (error) {
		Sentry.captureMessage(`Update profile failed.`);
		throw new Error(error || 'Update profile failed.');
	}
};

const createProfileFn = async ({
	name,
	image_url,
}: {
	name: string;
	image_url: string;
}): Promise<void> => {
	const token = await Storage.getItem(authTokenStorageKey);

	if (!token) {
		return;
	}

	try {
		await fetch(`${config.userServiceUrl}/v1/profile`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				name: name,
				image_url: image_url,
			}),
		});
	} catch (error) {
		Sentry.captureMessage(`Create profile failed.`);
		throw new Error(error || 'Create profile failed.');
	}
};

const deleteProfileFn = async (profile: Profile): Promise<void> => {
	const token = await Storage.getItem(authTokenStorageKey);

	if (!token) {
		return;
	}

	try {
		await fetch(`${config.userServiceUrl}/v1/profile/${profile.id}`, {
			method: 'DELETE',
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
		});
	} catch (error) {
		Sentry.captureMessage(`Delete profile failed.`);
		throw new Error(error || 'Delete profile failed.');
	}
};

const switchProfileFn = async (
	profile: Profile
): Promise<{ profile: Profile; token: Token }> => {
	const token = await Storage.getItem(authTokenStorageKey);

	if (!token) {
		Sentry.captureMessage(`No token ${profile.id}`);
		throw new Error('No token');
	}

	const res = await fetch(`${config.userServiceUrl}/v2/user/switchprofile`, {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ profile_id: profile.id }),
	});

	if (!res.ok) {
		const { message } = await res.json();
		Sentry.captureMessage(`Switch profile failed. ${profile.id}`);
		throw new Error(message || 'Switch profile failed.');
	}

	const { token: newToken } = await res.json();

	if (!newToken) {
		Sentry.captureMessage(`Switch profile failed. No new token. ${profile.id}`);
		throw new Error('Switch profile failed. No new token.');
	}

	try {
		await Storage.setItem('token', newToken);
		await Storage.setItem('profileId', profile.id);
		await Storage.setItem('profileName', profile.name);
	} catch (error) {
		Sentry.captureMessage(`Token can not be stored. ${profile.id}`);
		throw new Error('Token can not be stored.', { cause: error as Error });
	}

	return {
		profile,
		token: newToken,
	};
};

const fetchSettings = async (): Promise<[key: string, value: string][]> => {
	const token = await Storage.getItem(authTokenStorageKey);

	if (!token) {
		return [];
	}

	const res = await fetch(`${config.userServiceUrl}/v1/settings`, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	});

	if (!res.ok) {
		const { message } = await res.json();
		Sentry.captureMessage(`Fetch settings failed.`);
		throw new Error(message || 'Fetch settings failed.');
	}

	const settings: [key: string, value: string][] = await res.json();

	return settings;
};

const loginFn = async (data: LoginCredentials): Promise<Token> => {
	const res = await fetch(`${config.userServiceUrl}/v2/user/loginemail`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	});

	if (!res.ok) {
		const { message } = await res.json();
		Sentry.captureMessage(`Login failed.`);
		throw new Error(message || 'Login failed.');
	}

	const { token } = await res.json();

	if (!token) {
		Sentry.captureMessage(`Login failed. Token is missing.`);
		throw new Error('Login failed. Token is missing.');
	}

	try {
		await Storage.setItem('token', token);
	} catch (error) {
		Sentry.captureMessage(`Token can not be stored.`);
		throw new Error('Token can not be stored.', { cause: error as Error });
	}

	return token;
};

const loginCodeFn = async (authorizationCode: number): Promise<Token> => {
	const res = await fetch(
		`${config.userServiceUrl}/v2/user/logincode/activate/${authorizationCode}`,
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		}
	);

	if (!res.ok) {
		const { message } = await res.json();
		throw new Error(message || 'LoginCode failed.');
	}

	const { token } = await res.json();

	if (!token) {
		throw new Error('Login failed. Token is missing.');
	}

	try {
		await Storage.setItem('token', token);
	} catch (error) {
		Sentry.captureMessage(`Token can not be stored.`);
		throw new Error('Token can not be stored.', { cause: error as Error });
	}

	return token;
};

const logoutFn = async (): Promise<void> => {
	try {
		await Storage.setItem('token', '');
		await Storage.setItem('profileId', '');
	} catch (error) {
		Sentry.captureMessage(`Token can not be cleared.`);
		throw new Error('Token can not be cleared.', { cause: error as Error });
	}
};

const { AuthProvider, AuthConsumer, useAuth } = initReactQueryAuth<User, any>({
	fetchUser,
	fetchProfiles,
	switchProfileFn,
	updateProfileFn,
	createProfileFn,
	fetchSettings,
	deleteProfileFn,
	loginFn,
	loginCodeFn,
	logoutFn,
});

export { AuthProvider, AuthConsumer, useAuth };
