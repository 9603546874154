import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import React from 'react';

import TabBar from '../components/tabbar';
import { useMenu } from '../hooks/api';
import { ShelfAsset } from '../models/Asset';
import { Details } from '../models/Details';
import { Profile } from '../models/Profile';
import { useAuth } from '../providers/auth';
import AssetDetailScreen from '../screens/asset-detail-screen';
import DeviceInfoScreen from '../screens/device-info-screen';
import EditProfileScreen from '../screens/edit-profile-screen';
import ExitFlowScreen from '../screens/exit-flow-screen';
import GenreScreen from '../screens/genre-screen';
import HomeScreen from '../screens/home-screen';
import LoginScreen from '../screens/login-screen';
import ProfileScreen from '../screens/profile-screen';
import SearchScreen from '../screens/search-screen';
import SwitchProfileScreen from '../screens/switch-profile-screen';
import { useUI } from '../hooks/useUI';
import SubscriptionReactivationScreen from '../screens/subscription-reactivation-screen';
import RegisterScreen from '../screens/register-screen';
import RegisterCodeScreen from '../screens/register-code-screen';

export type NavigatorParameterList = {
	Home: { collectionId: string | undefined; href: string | undefined };
	Search: undefined;
	Profile: undefined;
	SwitchProfile: undefined;
	EditProfile: {
		profile?: Profile;
	};
	Player: undefined;
	AssetDetail: {
		asset?: ShelfAsset | Details | undefined;
		id?: string;
		type?: number;
		play?: number;
	};
	Genre: {
		genre: string;
	};
	Login: undefined;
	ExitFlow: undefined;
	DeviceInfo: undefined;
	ReactivateSubscription: undefined;
	Register: undefined;
	RegisterCode: undefined;
};

const MainScreenNavigator: React.FC = () => {
	const sceneContainerStyle = { backgroundColor: '#000000' };
	const { user, profile } = useAuth();
	const { configCollection } = useUI();
	const { data: menuItems, isLoading: isLoadingMenuItems } = useMenu({
		id: configCollection,
	});

	const Main = createBottomTabNavigator<NavigatorParameterList>();

	let initialRouteName: keyof NavigatorParameterList = 'Register';
	// let initialRouteName: keyof NavigatorParameterList = 'Test';
	let profileComponent = ProfileScreen;
	let switchProfileComponent = SwitchProfileScreen;
	const editProfileComponent = EditProfileScreen;

	if (user && !profile) {
		initialRouteName = 'SwitchProfile';
	}

	if (user && profile) {
		initialRouteName = 'Home';
	}
	if (isLoadingMenuItems) return null;

	return (
		<Main.Navigator
			initialRouteName={initialRouteName}
			backBehavior="history"
			sceneContainerStyle={sceneContainerStyle}
			tabBar={(props) => <TabBar {...props} />}
			screenOptions={{
				headerShown: false,
			}}
		>
			{menuItems?.map((menuItem) => (
				<Main.Screen
					key={`main-screen-navigator-screen-${menuItem.href}`}
					name={menuItem.href.includes('/discover') ? 'Home' : menuItem.title}
					component={HomeScreen}
					initialParams={{
						href: menuItem.href,
						collectionId: menuItem.href.split('/')[2],
					}}
				/>
			))}
			<Main.Screen
				name="Search"
				component={SearchScreen}
			/>
			<Main.Screen
				name="Login"
				component={LoginScreen}
				options={{
					unmountOnBlur: true,
				}}
			/>
			<Main.Screen
				name="Profile"
				component={profileComponent}
			/>
			<Main.Screen
				name="Register"
				component={RegisterScreen}
			/>
			<Main.Screen
				name="RegisterCode"
				component={RegisterCodeScreen}
			/>
			<Main.Screen
				name="SwitchProfile"
				component={switchProfileComponent}
			/>
			<Main.Screen
				name="EditProfile"
				component={editProfileComponent}
				options={{
					unmountOnBlur: true,
				}}
			/>
			<Main.Screen
				name="AssetDetail"
				component={AssetDetailScreen}
				options={{
					unmountOnBlur: true,
				}}
			/>
			<Main.Screen
				name="Genre"
				component={GenreScreen}
				options={{
					unmountOnBlur: true,
				}}
			/>
			<Main.Screen
				name="ExitFlow"
				component={ExitFlowScreen}
				options={{
					unmountOnBlur: true,
				}}
			/>
			<Main.Screen
				name="DeviceInfo"
				component={DeviceInfoScreen}
				options={{
					unmountOnBlur: true,
				}}
			/>
			<Main.Screen
				name="ReactivateSubscription"
				component={SubscriptionReactivationScreen}
				options={{
					unmountOnBlur: true,
				}}
			/>
		</Main.Navigator>
	);
};

export default MainScreenNavigator;
