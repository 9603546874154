import { Pressable, View } from '@flexn/sdk';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Text } from 'react-native';

import colors from '../../../platformAssets/runtime/colors';
import fonts from '../../../platformAssets/runtime/fontManager';
import { useWatchHistory } from '../../hooks/api';
import { useI18n } from '../../hooks/useI18n';
import { useAuth } from '../../providers/auth';
import { getScaledValue } from '../../utils/scale';
import Button from '../button/button-flex';

import Shelf from './shelf-flexn';

const ContinueWatchingShelf: React.FC = ({ parentContext, ...props }) => {
	const { history, isLoading: isHistoryLoading } = useWatchHistory();
	const { translate } = useI18n();
	const { navigate } = useNavigation();
	const { user } = useAuth();

	if (isHistoryLoading) return null;

	const items =
		history?.length === 0
			? [
					{
						isIncentive: true,
					},
			  ]
			: history;

	const renderEmptyTile = ({ repeatContext }) => {
		return (
			<Pressable
				onPress={() => undefined}
				parentContext={parentContext}
				repeatContext={repeatContext}
			>
				<Text
					style={{
						fontFamily: fonts.secondary,
						fontSize: getScaledValue(14),
						color: colors.secondaryText,
					}}
				>
					{translate('shelves.continuewatching.empty')}
				</Text>
			</Pressable>
		);
	};

	const renderEmptyLoginTile = ({ repeatContext }) => {
		return (
			<>
				<Text
					style={{
						fontFamily: fonts.secondary,
						fontSize: getScaledValue(14),
						color: colors.secondaryText,
					}}
				>
					{translate('shelves.continuewatching.empty.login')}
				</Text>
				<Button
					repeatContext={repeatContext}
					onPress={() => navigate('Register')}
					label={translate('login.button.text')}
					style={{ height: getScaledValue(1), opacity: 0.1 }}
				/>
			</>
		);
	};

	const renderEmpty = ({ cardStyle, repeatContext, itemDimensions }) => {
		return (
			<View
				style={[
					cardStyle,
					{
						flex: 1,
						justifyContent: 'space-between',
						width: itemDimensions.width,
						height: itemDimensions.height,
						padding: getScaledValue(16),
					},
				]}
			>
				{!user && renderEmptyLoginTile({ repeatContext })}
				{user && renderEmptyTile({ repeatContext })}
			</View>
		);
	};

	return (
		<Shelf
			{...props}
			isEmpty={!history || history.length === 0}
			renderEmpty={renderEmpty}
			items={items}
			showProgressBar={true}
		/>
	);
};

export default ContinueWatchingShelf;
