import { View } from '@flexn/sdk';
import { useIsFocused } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { isAndroidtv, isWebos } from '@rnv/renative';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ActivityIndicator, Image, StyleSheet } from 'react-native';
import DeviceInfo from 'react-native-device-info';

import colors from '../../platformAssets/runtime/colors';
import config from '../../platformAssets/runtime/config';
import logo from '../../platformAssets/runtime/icons/logo.png';
import bottom_logo_shadow from '../../platformAssets/runtime/img/bottom_logo_shadow.png';
import AssetPreview from '../components/asset-preview';
import BackgroundPoster from '../components/background-poster';
import Screen from '../components/screen';
import ShelfList from '../components/shelf-list/shelf-list-flexn';
import { useAsset, useJumbotron, useSetAsset } from '../hooks/api';
import { useShelves } from '../hooks/shelves';
import { useI18n } from '../hooks/useI18n';
import { ShelfAsset } from '../models/Asset';
import { NavigatorParameterList } from '../navigators/main-screen-navigator';
import ClientTracker from '../services/event-tracker/ClientTracker';
import UserInterActionEvent, {
	USER_INTERACTION_CONTEXT,
	USER_INTERACTION_FEATURES,
	USER_INTERACTION_TYPE,
} from '../services/event-tracker/UserInterActionEvent';
import isNative from '../utils/isNative';
import { getScaledValue } from '../utils/scale';
import { useUI } from '../hooks/useUI';

const styles = StyleSheet.create({
	screenContainer: {
		flex: 1,
		backgroundColor: 'black',
	},
	loadingContainer: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
	},
	backgroundImage: {
		flex: 1,
	},
	contentContainer: {
		marginLeft: getScaledValue(100),
		marginTop: getScaledValue(24),
	},
	previewContainer: {
		marginTop: getScaledValue(24),
	},
	shelvesContainer: {
		flex: 1,
		width: '100%',
	},
	logoContainer: {
		position: 'absolute',
		zIndex: 100,
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
		bottom: getScaledValue(16),
		right: getScaledValue(DeviceInfo.getBrand()?.includes('Amazon') ? 24 : 16),
	},
	logo: {
		margin: 0,
		width: getScaledValue(config.dimensions.logo.width),
		height: getScaledValue(config.dimensions.logo.height),
	},
	logoShadow: {
		zIndex: 99,
		bottom: 0,
		right: getScaledValue(-50),
		height: getScaledValue(260),
		width: getScaledValue(425),
		position: 'absolute',
		opacity: 0.9,
	},
});

type HomeScreenProps = NativeStackScreenProps<NavigatorParameterList, 'Home'>;

const HomeScreen: React.FC<HomeScreenProps> = ({ navigation, route }) => {
	const { homeCollection } = useUI();
	console.log(
		'--- home screen render',
		route?.params,
		route?.params?.collectionId
	);

	const timeoutRef = useRef<any | undefined>(undefined);
	const isFocused = useIsFocused();
	const collectionId = route?.params?.collectionId || homeCollection;

	const { shelves, shelvesLoading, jumbotrons } = useShelves({
		collectionId,
	});

	const [jumbotron, setJumbotron] = useState(null);

	const { mutate: setAsset } = useSetAsset();
	const { data: selectedAsset } = useAsset();

	const handleTilePress = useCallback(
		(asset: ShelfAsset) => {
			const userInterActionEvent = new UserInterActionEvent();
			userInterActionEvent.setFeature(USER_INTERACTION_FEATURES.BROWSE);
			userInterActionEvent.setContext(USER_INTERACTION_CONTEXT.SHELF_LIST);
			userInterActionEvent.setType(USER_INTERACTION_TYPE.BUTTON);
			userInterActionEvent.setName(`shelf_${asset.shelfId}_asset_${asset.id}`);
			userInterActionEvent.setBody({
				page: collectionId,
				shelf: asset.shelfId,
				asset: asset.id,
			});

			ClientTracker.sendUserInterActionEvent(userInterActionEvent);

			navigation.navigate('AssetDetail', { asset });
		},
		[collectionId, navigation]
	);

	const handleTileFocus = useCallback(
		(asset: ShelfAsset) => {
			console.log('-------------- focused on', asset.id);

			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}

			if (selectedAsset?.isJumbotron) {
				setAsset(asset);
			}

			timeoutRef.current = setTimeout(() => {
				setAsset(asset);
			}, 500);
		},
		[setAsset, selectedAsset]
	);

	const handlePlayButtonPress = (asset) => {
		navigation.navigate('AssetDetail', { asset });
	};

	const handlePlayButtonFocus = () => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
		jumbotron && setAsset(jumbotron);
	};

	useEffect(() => {
		if (!shelvesLoading) {
			const userInterActionEvent = new UserInterActionEvent();
			userInterActionEvent.setFeature(USER_INTERACTION_FEATURES.BROWSE);
			userInterActionEvent.setContext(USER_INTERACTION_CONTEXT.SHELF_LIST);
			userInterActionEvent.setType(USER_INTERACTION_TYPE.REQUEST);
			userInterActionEvent.setName(`/colections`);
			userInterActionEvent.setBody({
				collectionId,
			});

			ClientTracker.sendUserInterActionEvent(userInterActionEvent);
		}
	}, [collectionId, shelvesLoading]);

	useEffect(() => {
		const firstJumbotron =
			jumbotrons?.[0]?.items?.[0] ||
			shelves?.[0]?.items?.[0] ||
			shelves?.[1]?.items?.[0];

		setJumbotron(firstJumbotron);
	}, [jumbotrons, shelves]);

	if (!jumbotron)
		return (
			<View style={styles.screenContainer}>
				<View style={styles.loadingContainer}>
					<ActivityIndicator color={colors.primary} />
				</View>
			</View>
		);
	return (
		<Screen
			style={styles.screenContainer}
			stealFocus={true}
			focusOptions={{
				verticalViewportOffset: 0,
				horizontalViewportOffset: getScaledValue(60),
			}}
		>
			<BackgroundPoster
				poster={selectedAsset?.background.uri}
				fullScreen={selectedAsset?.isJumbotron}
				asset={selectedAsset}
				trailer={selectedAsset?.isJumbotron && isFocused}
			/>
			<View style={styles.contentContainer}>
				<AssetPreview
					isJumbotron={selectedAsset?.isJumbotron}
					onPlayButtonPress={
						jumbotron?.isJumbotron ? handlePlayButtonPress : null
					}
					onPlayButtonFocus={handlePlayButtonFocus}
				/>
			</View>

			{!shelvesLoading ? (
				<ShelfList
					key={route?.params?.collectionId || 'default'}
					initialXOffset={getScaledValue(isAndroidtv() ? 30 : 40)}
					items={shelves}
					onPress={(data) => handleTilePress(data)}
					onFocus={(data) => handleTileFocus(data)}
					animatorOptions={{
						type: isNative ? 'scale_with_border' : 'border',
						scale: 1.1,
					}}
					focusOptions={{
						forbiddenFocusDirections: ['right'],
						nextFocusLeft: `tab-bar-item-${route?.params?.href}`,
					}}
					titleStyle={{
						paddingLeft: getScaledValue(100),
					}}
					cardStyle={{
						borderWidth: isNative ? getScaledValue(isAndroidtv() ? 4 : 2) : 0,
						borderColor: '#FFFFFF',
						borderRadius: getScaledValue(16),
					}}
				/>
			) : (
				<ActivityIndicator
					color={colors.primary}
					size="large"
				/>
			)}
			{!isWebos() && (
				<Image
					source={bottom_logo_shadow}
					resizeMode={'contain'}
					style={styles.logoShadow}
				/>
			)}
			<View style={styles.logoContainer}>
				<Image
					style={styles.logo}
					source={logo}
					resizeMode="contain"
				/>
			</View>
		</Screen>
	);
};

export default HomeScreen;
