export enum VideoEventAssetType {
	MOVIE = 'MOVIE',
	EPISODE = 'EPISODE',
	SEASON = 'SEASON',
	TVSHOW = 'TVSHOW',
}

export enum VideoEventBusinessType {
	PREMIUM = 'PREMIUM',
	FREE = 'FREE',
	TRAILER = 'TRAILER',
	UNKNOWN = 'UNKNOWN',
}

export enum VideoEventAction {
	VIEW = 'VIEW',
	PLAY = 'PLAY',
	PAUSE = 'PAUSE',
	TIME = 'TIME',
	COMPLETE = 'COMPLETE',
	START = 'START',
}

export default class VideoEvent {
	public eventAction: VideoEventAction | undefined;

	public assetId: string | undefined;
	public assetType: VideoEventAssetType | undefined;
	public businessType: VideoEventBusinessType | undefined;

	public genre: string | undefined;
	public bitrate: string | undefined;
	public resolution: string | undefined;
	public videoPlayTimeSec: number | undefined;
	public videoPosition: number | undefined;

	setEventAction(eventAction: VideoEventAction) {
		this.eventAction = eventAction;
	}

	setAssetId(assetId: string) {
		this.assetId = assetId;
	}

	setAssetType(assetType: VideoEventAssetType) {
		this.assetType = assetType;
	}

	setBusinessType(businessType: VideoEventBusinessType) {
		this.businessType = businessType;
	}

	setGenre(genre: string) {
		this.genre = genre;
	}

	setBitrate(bitrate: string) {
		this.bitrate = bitrate;
	}

	setResolution(resolution: string) {
		this.resolution = resolution;
	}

	setVideoPlayTimeSec(videoPlayTimeSec: number) {
		this.videoPlayTimeSec = videoPlayTimeSec;
	}

	setVideoPosition(videoPosition: number) {
		this.videoPosition = videoPosition;
	}
}
