import { CoreManager, Screen, View } from '@flexn/sdk';
import { parse } from '@plussub/srt-vtt-parser';
import React, { memo, useEffect, useRef, useState } from 'react';
import { StyleSheet, Text, Image, Dimensions } from 'react-native';

import colors from '../../../platformAssets/runtime/colors';
import config from '../../../platformAssets/runtime/config';
import arrow_left from '../../../platformAssets/runtime/icon/arrow_left.png';
import backwardButton from '../../../platformAssets/runtime/icon/backward_button.png';
import forwardButton from '../../../platformAssets/runtime/icon/forward_button.png';
import pause from '../../../platformAssets/runtime/icon/pause_button.png';
import play from '../../../platformAssets/runtime/icon/play_button.png';
import restart from '../../../platformAssets/runtime/icon/restart.png';
import settingsIcon from '../../../platformAssets/runtime/icon/settings.png';
import { PlayerType } from '../../contexts/PlayerContext';
import { useI18n } from '../../hooks/useI18n';
import { useKeyHandler } from '../../hooks/useKeyHandler';
import { usePlayer } from '../../hooks/usePlayer';
import {
	USER_INTERACTION_CONTEXT,
	USER_INTERACTION_FEATURES,
	USER_INTERACTION_TYPE,
} from '../../services/event-tracker/UserInterActionEvent';
import isNative from '../../utils/isNative';
import {
	DIRECTION_LEFT,
	DIRECTION_RIGHT,
	MEDIA_FASTFORWARD,
	MEDIA_REWIND,
} from '../../utils/keymap/index';
import { getScaledValue } from '../../utils/scale';
import { convertSeconds, toTimeString } from '../../utils/time';
import Pressable from '../pressable';
import Title from '../title';

import NextEpisode from './next-episode';
import SkipIntro from './skip-intro';
import SubtitleList from './subtitle-list';
import AudioTrackList from './audiotracks-list';
import { isWebBased } from '@rnv/renative';

const PlayerControls = memo(
	({
		showControls,
		parentContext,
	}: {
		showControls: boolean;
		parentContext?: any;
	}) => {
		const {
			duration,
			seekTime,
			setSeekTime,
			currentTime,
			playing,
			playpause,
			player,
			setPlaying,
			subtitles,
			asset,
			setShowDetails,
			setShowControls,
			playerType,
			fireDidSeek,
			playerWeb,

			showSkipIntro,
			showNextEpisode,

			showSettingsDialog,
			setShowSettingsDialog,

			audioTracks,
		} = usePlayer();

		const { translate } = useI18n();

		const [indicatorHasFocus, setIndicatorHasFocus] = useState(false);
		const [activeOption, setActiveOption] = useState<string | null>(null);

		const seekSpeed = useRef<number>(0);
		const lastKey = useRef<string>('');
		const indicatorTimer = useRef<number | undefined>();
		const [, setLastKeyEvent] = useState<string>('');

		const durationString = toTimeString(convertSeconds(duration || 0));
		const currentTimeString = toTimeString(convertSeconds(currentTime || 0));
		const indidactorTimeString = toTimeString(convertSeconds(seekTime || 0));

		const restartPlayer = () => {
			const playerRef = isNative ? player.current : playerWeb;

			playerRef?.seek(0);
			fireDidSeek({});
			setPlaying(true);
		};

		const optionFocusHandle = (option: string | null) => {
			setActiveOption(option);
			setIndicatorHasFocus(false);
		};

		const openSettingsHandle = () => {
			setShowSettingsDialog(true);
		};

		const handleSeek = (time: number) => {
			const playerRef = isNative ? player.current : playerWeb;

			playerRef?.seek(time);

			fireDidSeek({});
			setPlaying(true);
		};

		useKeyHandler(
			[DIRECTION_RIGHT, DIRECTION_LEFT, MEDIA_FASTFORWARD, MEDIA_REWIND],
			(key) => {
				setLastKeyEvent(new Date().toString());
				let newTime = 0;

				const seekStap = playerType === PlayerType.Video ? 30 : 5;

				if (!duration) return;

				if (indicatorTimer.current) {
					clearTimeout(indicatorTimer.current);
				}

				if (key === DIRECTION_RIGHT && indicatorHasFocus) {
					seekSpeed.current =
						lastKey.current === DIRECTION_LEFT ||
						lastKey.current === MEDIA_REWIND ||
						lastKey.current === ''
							? 1
							: seekSpeed.current === 5
							? seekSpeed.current
							: seekSpeed.current + 1;

					if (seekTime) {
						if (seekTime + seekSpeed.current * seekStap < duration) {
							newTime = seekTime + seekSpeed.current * seekStap;
						}
						if (seekTime + seekSpeed.current * seekStap >= duration) {
							newTime = duration - 10;
						}
					} else {
						if (currentTime + seekSpeed.current * seekStap < duration) {
							newTime = currentTime + seekSpeed.current * seekStap;
						}
					}
					lastKey.current = DIRECTION_RIGHT;
				}

				if (key === DIRECTION_LEFT && indicatorHasFocus) {
					seekSpeed.current =
						lastKey.current === DIRECTION_RIGHT ||
						lastKey.current === MEDIA_FASTFORWARD ||
						lastKey.current === ''
							? 1
							: seekSpeed.current === 5
							? seekSpeed.current
							: seekSpeed.current + 1;

					if (seekTime) {
						if (seekTime - seekSpeed.current * seekStap > 0) {
							newTime = seekTime - seekSpeed.current * seekStap;
						}
					} else {
						if (currentTime - seekSpeed.current * seekStap > 0) {
							newTime = currentTime - seekSpeed.current * seekStap;
						}
					}
					lastKey.current = DIRECTION_LEFT;
				}

				if (key === MEDIA_FASTFORWARD) {
					seekSpeed.current =
						lastKey.current === DIRECTION_LEFT ||
						lastKey.current === MEDIA_REWIND ||
						lastKey.current === ''
							? 1
							: seekSpeed.current === 5
							? seekSpeed.current
							: seekSpeed.current + 1;

					if (currentTime + seekSpeed.current * seekStap < duration) {
						newTime = currentTime + seekSpeed.current * seekStap;
					} else {
						newTime = currentTime + seekStap;
					}
					lastKey.current = MEDIA_FASTFORWARD;
				}

				if (key === MEDIA_REWIND) {
					seekSpeed.current =
						lastKey.current === DIRECTION_RIGHT ||
						lastKey.current === MEDIA_FASTFORWARD ||
						lastKey.current === ''
							? 1
							: seekSpeed.current === 5
							? seekSpeed.current
							: seekSpeed.current + 1;

					if (currentTime - seekSpeed.current * seekStap > 0) {
						newTime = currentTime - seekSpeed.current * seekStap;
					}
					lastKey.current = MEDIA_REWIND;
				}

				if (newTime > 0) setSeekTime(newTime);

				indicatorTimer.current = setTimeout(
					() => {
						if (
							[MEDIA_FASTFORWARD, MEDIA_REWIND].includes(key) ||
							indicatorHasFocus
						) {
							handleSeek(newTime);
							seekSpeed.current = 1;
						}
					},
					isNative ? 700 : 1200
				) as unknown as number;
			}
		);

		return (
			<View
				style={{ left: 0, right: 0, bottom: 0, top: 0, position: 'absolute' }}
			>
				<Screen
					stealFocus
					screenState={
						showControls || showSkipIntro || showNextEpisode
							? 'foreground'
							: 'background'
					}
					screenOrder={1}
					style={{ flex: 1 }}
					onFocus={() => CoreManager.focusElementByFocusKey('play-button')}
				>
					<View style={[styles.overlay, { opacity: showControls ? 1 : 0 }]}>
						{!showSettingsDialog && (
							<View style={styles.content}>
								<View style={styles.topView}>
									<View style={styles.topViewOptions}>
										<View style={styles.topViewIcons}>
											<Pressable
												onFocus={() => optionFocusHandle('back')}
												onPress={() => {
													setShowControls(false);
													setShowDetails(true);
													setPlaying(false);
												}}
												style={styles.controlItem}
												focusOptions={{
													nextFocusDown: 'play-button',
													animatorOptions: {
														type: 'background',
														backgroundColorFocus: colors.primary,
													},
												}}
												track={{
													feature: USER_INTERACTION_FEATURES.PLAYER,
													context: USER_INTERACTION_CONTEXT.PLAYER_CONTROLS,
													type: USER_INTERACTION_TYPE.BUTTON,
													name: 'back',
												}}
											>
												<Image
													source={arrow_left}
													style={styles.icon}
												/>
											</Pressable>
											{((audioTracks && audioTracks?.length > 0) ||
												subtitles.length > 0) && (
												<Pressable
													onPress={openSettingsHandle}
													onFocus={() => optionFocusHandle('settings')}
													style={styles.controlItem}
													focusOptions={{
														focusKey: 'play-controls-settings',
														nextFocusDown: 'play-button',
														animatorOptions: {
															type: 'background',
															backgroundColorFocus: colors.primary,
														},
													}}
													track={{
														feature: USER_INTERACTION_FEATURES.PLAYER,
														context: USER_INTERACTION_CONTEXT.PLAYER_CONTROLS,
														type: USER_INTERACTION_TYPE.BUTTON,
														name: 'settings',
													}}
												>
													<Image
														source={settingsIcon}
														style={styles.icon}
													/>
												</Pressable>
											)}
											{currentTime > 0 && playerType === PlayerType.Video && (
												<Pressable
													onFocus={() => optionFocusHandle('restart')}
													onPress={restartPlayer}
													style={styles.controlItem}
													focusOptions={{
														nextFocusDown: 'play-button',
														nextFocusLeft: 'play-controls-settings',
														animatorOptions: {
															type: 'background',
															backgroundColorFocus: colors.primary,
														},
													}}
													track={{
														feature: USER_INTERACTION_FEATURES.PLAYER,
														context: USER_INTERACTION_CONTEXT.PLAYER_CONTROLS,
														type: USER_INTERACTION_TYPE.BUTTON,
														name: 'restart',
													}}
												>
													<Image
														source={restart}
														style={styles.icon}
													/>
												</Pressable>
											)}
										</View>
										<View style={{ height: getScaledValue(50) }}>
											{activeOption && (
												<Text style={styles.topViewOptionsText}>
													{translate(`playerControl.options.${activeOption}`)}
												</Text>
											)}
										</View>
									</View>
									<View>
										<Text style={styles.assetTitle}>
											{asset?.serie ? asset.serie : asset?.title}
										</Text>
										{asset?.serie && (
											<Text style={styles.assetSubTitle}>{asset?.title}</Text>
										)}
										{playerType === PlayerType.Trailer && (
											<Text style={styles.assetSubTitle}>Trailer</Text>
										)}
									</View>
								</View>
								<View style={styles.playButtonView}>
									{playerType !== PlayerType.Trailer && (
										<Pressable
											style={styles.playButtonNext}
											onFocus={() => optionFocusHandle(null)}
											onBlur={() => setIndicatorHasFocus(false)}
											onPress={() => {
												if (currentTime >= 30) {
													handleSeek(currentTime - 30);
												}
											}}
											focusOptions={{
												focusKey: 'play-button-left',
												nextFocusDown: 'play-controls-action-button',
												animatorOptions: {
													type: 'background',
													backgroundColorFocus: colors.primary,
												},
											}}
											track={{
												feature: USER_INTERACTION_FEATURES.PLAYER,
												context: USER_INTERACTION_CONTEXT.PLAYER_CONTROLS,
												type: USER_INTERACTION_TYPE.BUTTON,
												name: 'play_button_left',
											}}
										>
											<Image
												source={backwardButton}
												style={styles.icon}
											/>
										</Pressable>
									)}
									<Pressable
										style={styles.playButton}
										onFocus={() => optionFocusHandle(null)}
										onBlur={() => setIndicatorHasFocus(false)}
										onPress={playpause}
										focusOptions={{
											focusKey: 'play-button',
											nextFocusDown: 'progress-indicator',
											animatorOptions: {
												type: 'background',
												backgroundColorFocus: colors.primary,
											},
										}}
										track={{
											feature: USER_INTERACTION_FEATURES.PLAYER,
											context: USER_INTERACTION_CONTEXT.PLAYER_CONTROLS,
											type: USER_INTERACTION_TYPE.BUTTON,
											name: 'play_button',
										}}
									>
										<Image
											source={playing ? pause : play}
											style={styles.iconLarge}
										/>
									</Pressable>
									{playerType !== PlayerType.Trailer && (
										<Pressable
											style={styles.playButtonNext}
											onFocus={() => optionFocusHandle(null)}
											onBlur={() => setIndicatorHasFocus(false)}
											onPress={() => handleSeek(currentTime + 30)}
											focusOptions={{
												focusKey: 'play-button-right',
												nextFocusDown: 'play-controls-action-button',
												animatorOptions: {
													type: 'background',
													backgroundColorFocus: colors.primary,
												},
											}}
											track={{
												feature: USER_INTERACTION_FEATURES.PLAYER,
												context: USER_INTERACTION_CONTEXT.PLAYER_CONTROLS,
												type: USER_INTERACTION_TYPE.BUTTON,
												name: 'play_button_right',
											}}
										>
											<Image
												source={forwardButton}
												style={styles.icon}
											/>
										</Pressable>
									)}
								</View>

								<View>
									<Pressable
										style={[
											styles.progressIndicator,
											{
												left: `${
													((seekTime || currentTime) / (duration || 0)) * 100 -
													1
												}%`,
											},
										]}
										onFocus={() => setIndicatorHasFocus(true)}
										onBlur={() => setIndicatorHasFocus(false)}
										focusOptions={{
											forbiddenFocusDirections: ['right', 'left', 'down'],
											nextFocusUp: 'play-button',
											focusKey: 'progress-indicator',
											animatorOptions: {
												type: 'background',
												backgroundColorFocus: '#FFFFFF',
											},
										}}
										track={{
											feature: USER_INTERACTION_FEATURES.PLAYER,
											context: USER_INTERACTION_CONTEXT.PLAYER_CONTROLS,
											type: USER_INTERACTION_TYPE.BUTTON,
											name: 'progress_indicator',
										}}
									/>

									{playerType !== PlayerType.Trailer &&
										indicatorHasFocus &&
										asset && (
											<ProgressThumbnail
												time={seekTime || currentTime}
												asset={asset}
												style={{
													left: `${
														((seekTime || currentTime) / (duration || 0)) *
															100 -
														1
													}%`,
												}}
											/>
										)}

									<View style={styles.progress}>
										<View
											style={[
												styles.progressFill,
												{
													width: `${
														((seekTime || currentTime) / (duration || 0)) * 100
													}%`,
												},
											]}
										/>
									</View>

									<View style={styles.timeView}>
										<Text style={styles.timeText}>
											{seekTime ? indidactorTimeString : currentTimeString}
										</Text>
										<Text style={styles.timeText}>{durationString}</Text>
									</View>
								</View>
							</View>
						)}
						{showSettingsDialog && (
							<View style={styles.settingsView}>
								<View style={styles.settingsSubView}>
									<Title style={styles.settingsText}>
										{translate('moviecontrols.subtitles')}
									</Title>

									<SubtitleList parentContext={parentContext} />
								</View>
								{audioTracks && audioTracks.length > 0 && (
									<View style={styles.settingsSubView}>
										<Title style={styles.settingsText}>
											{translate('moviecontrols.audiotracks')}
										</Title>

										<AudioTrackList parentContext={parentContext} />
									</View>
								)}
							</View>
						)}
					</View>

					<View style={styles.skipIntroView}>
						<SkipIntro />
					</View>
					{showNextEpisode && (
						<View style={styles.skipIntroView}>
							<NextEpisode />
						</View>
					)}
				</Screen>
			</View>
		);
	}
);

const ProgressThumbnail = ({ style, time, asset }) => {
	const [thumbnails, setThumbnails] = useState<any[]>([]);
	const lastThumbnail = thumbnails[thumbnails.length - 1];
	const thumbnail = thumbnails.find(
		(t) => time >= t.timeFrom && time < t.timeTill
	);

	useEffect(() => {
		if (thumbnails[0]?.url) {
			Image.prefetch(thumbnails[0]?.url);
		}
	}, [thumbnails]);

	useEffect(() => {
		const fetchVtt = async () => {
			try {
				if (asset) {
					const url = `${config.webappCdnUrl}/media/${asset.id}/captions/thumbnails/web.vtt`;

					const res = await fetch(url);

					if (res.status !== 200) {
						throw new Error('Could not load VTT', { cause: res.statusText });
					}

					const vttData = await res.text();

					const { entries } = parse(vttData);

					setThumbnails(
						entries.map(({ from, to, text }) => {
							const spriteUrl = text.split('#')[0];
							const rect = text.split('xywh=')[1].split(',');

							return {
								timeFrom: Math.round(from / 1000),
								timeTill: Math.round(to / 1000),
								left: parseInt(rect[0]),
								top: parseInt(rect[1]),
								width: parseInt(rect[2]),
								height: parseInt(rect[3]),
								url: spriteUrl,
							};
						})
					);
				}
			} catch (error) {
				console.error(error);
			}

			return [];
		};

		fetchVtt();
	}, [asset]);

	if (!thumbnail) return null;

	return (
		<View style={[styles.progressThumbnail, style]}>
			<Image
				style={[
					styles.progressThumbnailImage,
					{
						top: getScaledValue((thumbnail.top / 2) * -1),
						left: getScaledValue((thumbnail.left / 2) * -1),
						height: getScaledValue(
							lastThumbnail.top / 2 + lastThumbnail.height / 2
						),
					},
				]}
				source={{
					uri: thumbnail.url,
				}}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	overlay: {
		flex: 1,
		backgroundColor: 'rgba(0, 0, 0, 0.6)',
	},

	content: {
		width: '80%',
		marginLeft: '10%',
		marginRight: '10%',
		marginTop: getScaledValue(20),
		marginBottom: getScaledValue(40),
		flex: 1,
		justifyContent: 'space-between',
	},

	topView: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},

	playButtonView: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},

	playButton: {
		backgroundColor: '#000000',
		height: getScaledValue(90),
		width: getScaledValue(90),
		borderRadius: getScaledValue(90),
		opacity: 0.8,
		justifyContent: 'center',
		alignItems: 'center',
	},

	playButtonNext: {
		backgroundColor: 'transparent',
		height: getScaledValue(45),
		width: getScaledValue(45),
		borderRadius: getScaledValue(45),
		opacity: 0.8,
		justifyContent: 'center',
		alignItems: 'center',
		marginHorizontal: getScaledValue(10),
	},

	assetTitle: {
		fontSize: getScaledValue(19),
		color: colors.primaryText,
		marginTop: getScaledValue(20),
	},

	assetSubTitle: {
		fontSize: getScaledValue(16),
		color: colors.primaryText,
	},

	topViewOptionsText: {
		color: colors.primaryText,
		fontSize: getScaledValue(14),
		marginTop: getScaledValue(6),
	},

	topViewOptions: {
		flexDirection: 'column',
		alignItems: 'center',
	},

	topViewIcons: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},

	controlItem: {
		...(isWebBased ? { backgroundColor: 'transparent' } : {}),
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: getScaledValue(40),
		width: getScaledValue(50),
		height: getScaledValue(50),
		opacity: 0.8,
	},

	icon: {
		width: getScaledValue(30),
		height: getScaledValue(30),
	},

	iconLarge: {
		width: getScaledValue(40),
		height: getScaledValue(40),
	},

	progress: {
		width: '100%',
		height: getScaledValue(6),
		borderRadius: 100,
		backgroundColor: '#ffffff7f',
	},

	progressFill: {
		height: getScaledValue(6),
		borderRadius: 100,
		backgroundColor: colors.primary,
	},

	progressIndicator: {
		position: 'absolute',
		zIndex: 99,
		top: -getScaledValue(7),
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: getScaledValue(20),
		width: getScaledValue(20),
		height: getScaledValue(20),
		backgroundColor: colors.primary,
	},

	progressThumbnail: {
		overflow: 'hidden',
		position: 'absolute',
		zIndex: 99,
		bottom: getScaledValue(52),
		width: getScaledValue(240 / 2 + 4),
		height: getScaledValue(135 / 2 + 4),
		marginLeft: -getScaledValue(240 / 2 / 2 - 10),
		justifyContent: 'center',
		alignItems: 'center',
		borderColor: '#ffffff',
		backgroundColor: '#ffffff7f',
		borderWidth: getScaledValue(2),
		borderRadius: getScaledValue(8),
	},

	progressThumbnailImage: {
		position: 'absolute',
		left: 0,
		top: 0,
		width: getScaledValue(2400 / 2),
		height: getScaledValue(3375 / 2),
	},

	timeView: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginTop: getScaledValue(10),
	},

	timeText: {
		color: colors.primaryText,
		fontSize: getScaledValue(15),
	},

	settingsView: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},

	settingsText: {
		marginBottom: getScaledValue(20),
	},

	settingsSubView: {
		width: '50%',
		padding: '5%',
	},

	skipIntroView: {
		position: 'absolute',
		bottom: getScaledValue(92),
		right: '10%',
	},
});

PlayerControls.displayName = 'PlayerControls';

export default PlayerControls;
