import { EntitlementProvider } from './EntitlementProvider';

export enum ObjectIdentifier {
	trailer = 'trailer',
	video = 'video',
}

export const objectIdentifierMap = {
	[EntitlementProvider.trailer]: ObjectIdentifier.trailer,
	[EntitlementProvider.internal]: ObjectIdentifier.video,
};
