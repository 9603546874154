import { useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import config from '../../platformAssets/runtime/config';
import {
	INCENTIVE_BANNER_HEIGHT,
	SHELF_HEIGHT,
	SHELF_LANDSCAPE_HEIGHT,
} from '../components/shelf/constants';
import {
	Asset,
	Collection,
	Episode,
	Movie,
	Season,
	ShelfAsset,
	ShelfEpisode,
	TVShow,
} from '../models/Asset';
import {
	isCollection,
	isJumbotron,
	isNotCollectionOrSeason,
	isNotEmptyCollection,
	isNotFavorites,
	isNotJumbotron,
} from '../models/Collection';
import { isTVShowDetails, TVShowDetails } from '../models/Details';
import { Shelf } from '../models/Shelf';

import {
	useCollection,
	useSearch,
	useSeasons,
	useGenres,
	useGenreSearch,
	useGenresList,
} from './api';
import { useI18n } from './useI18n';
import { useUI } from './useUI';

type SearchResult = any;

export enum MediaCategories {
	MOVIES = 'Movies',
	SERIES = 'Series',
	RESULTS = 'Results',
}

const MEDIA_CATEGORIES = {
	['results']: MediaCategories.RESULTS,
	[config.search.assetKeys.movie]: MediaCategories.MOVIES,
	[config.search.assetKeys.series]: MediaCategories.SERIES,
};

let offset = 0;

export function useShelves(
	{ collectionId }: { collectionId?: string } = {
		collectionId: undefined,
	}
) {
	const client = useQueryClient();
	const { homeCollection } = useUI();

	console.log('useShelves', collectionId);

	const {
		isLoading,
		error,
		data: collection,
	} = useCollection({
		id: collectionId || homeCollection,
	});

	const shelvesLoading = isLoading;

	const memoShelves = useMemo(() => {
		console.log('>>>>>>>>> useShelves useMemo');

		if (shelvesLoading) {
			return [];
		}

		if (collection) {
			return collection.items
				.filter(isCollection)
				.filter(isNotJumbotron)
				.filter(isNotEmptyCollection)
				.map((collection, index) => mapCollectionToShelf(collection, index))
				.map((shelf) => {
					client.setQueryData(['assets', 'shelf', shelf.id], shelf.items);

					shelf.items.forEach((asset) => {
						client.setQueryData(['assets', 'detail', asset.id], asset);
					});

					return shelf;
				});
		}

		return [];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [collectionId, collection?.items.length]);

	const memoJumbotrons = useMemo<Shelf[]>(() => {
		const jumbotrons = collection?.items
			.filter(isCollection)
			.filter(isJumbotron)
			.map((collection, index) => mapCollectionToShelf(collection, index));

		const firstJumbotron =
			jumbotrons?.[0]?.items?.[0] ||
			memoShelves?.[0]?.items?.[0] ||
			memoShelves?.[1]?.items?.[0]; // if shelf 0 is an incentive with zero items

		client.setQueryData('asset', firstJumbotron);
		client.setQueryData('jumbotron', firstJumbotron);

		return jumbotrons ? jumbotrons : [];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [collectionId, collection?.items.length]);

	return {
		shelvesLoading,
		shelvesError: error,
		shelves: memoShelves,
		jumbotrons: memoJumbotrons,
	};
}

export function useTile(namespace, yIndex, xIndex) {
	const client = useQueryClient();

	return () => {
		return client.getQueryData(['tile', namespace, yIndex, xIndex]);
	};
}

export function useTileQuery(namespace, yIndex, xIndex) {
	const client = useQueryClient();

	const { data: tile, isLoading } = useQuery(
		['tile', namespace, yIndex, xIndex],
		() => {
			return client.getQueryData(['tile', namespace, yIndex, xIndex]);
		}
	);

	return tile;
}

export function useSetTile(namespace, asset) {
	const client = useQueryClient();

	return (tile) => {
		client.setQueryData(['tile', namespace, asset.yIndex, asset.xIndex], tile);
	};
}

export function useShelfTile(namespace, yIndex) {
	const client = useQueryClient();

	return () => {
		return client.getQueryData(['shelf-tile', namespace, yIndex]);
	};
}

export function useSetShelfTile(namespace, asset) {
	const client = useQueryClient();

	return (tile) => {
		client.setQueryData(['shelf-tile', namespace, asset.yIndex], tile);
	};
}

export function useCurrentTile(namespace) {
	const client = useQueryClient();

	return () => {
		return client.getQueryData(['current-tile', namespace]);
	};
}

export function useSetCurrentTile(namespace) {
	const client = useQueryClient();

	return (tile) => {
		client.setQueryData(['current-tile', namespace], tile);
	};
}

export function useSeasonsShelves(asset?: TVShowDetails | null) {
	offset = 0;

	const ids = asset?.seasons?.map((s: any) => s.id) || [];
	const { results } = useSeasons({
		items: ids,
	});

	if (!results) {
		return {
			shelvesLoading: false,
			shelvesError: false,
			shelves: [],
		};
	}

	const isLoading = results.some((result) => result.isLoading);
	const error = results.some((result) => result.error);

	const memoShelves = useMemo<Shelf[]>(() => {
		if (results) {
			const newData: Season[] = [];

			results.forEach((result: any) => {
				if (result.data) {
					newData.push(result.data);
				}
			});
			return newData.filter((s) => s?.episodes?.length).map(mapItemToShelf);
		}
		return [];
	}, [results]);

	return {
		shelvesLoading: isLoading,
		shelvesError: error,
		shelves: asset && !isTVShowDetails(asset) ? [] : memoShelves,
	};
}

export function useSearchShelves(text: string, searchView?: boolean) {
	console.log('useSearchShelves', text);
	const { homeCollection } = useUI();

	const { isLoading, error, data } = useSearch({
		text: text,
		searchView: searchView,
	});
	const { data: recent } = useCollection({
		id: homeCollection,
	});

	offset = 0;

	const memoShelves = useMemo<Shelf[]>(() => {
		if (data) {
			return Object.entries(data)
				.filter(([key, value]) => value.length > 0)
				.map(([key, value], index) => {
					console.log({ key });

					return mapSearchToShelves(key, value, index, searchView);
				});
		}

		if (recent && text === '') {
			return [...recent.items]
				.filter(isCollection)
				.filter(isNotEmptyCollection)
				.filter(isNotJumbotron)
				.filter(isNotFavorites)
				.filter((shelf, index) => index < 1) // TODO replace by isRecentCollection()
				.map((collection, index) =>
					mapCollectionToShelf(collection, index, searchView)
				);
		}

		return [];
	}, [data, recent, searchView, text]);

	return {
		shelvesLoading: isLoading,
		shelvesError: error,
		shelves: memoShelves,
	};
}

export function useSearchGenreShelves(text: string, searchView?: boolean) {
	console.log('useSearchGenreShelves', text);
	const { homeCollection } = useUI();

	const { isLoading, error, data } = useGenreSearch({
		text: text,
		searchView: searchView,
	});
	const { data: recent } = useCollection({
		id: homeCollection,
	});

	offset = 0;

	const memoShelves = useMemo<Shelf[]>(() => {
		if (data) {
			return Object.entries(data)
				.filter(([key, value]) => value.length > 0)
				.map(([key, value], index) => {
					return mapSearchToShelves(key, value, index, searchView);
				});
		}

		if (recent && text === '') {
			return [...recent.items]
				.filter(isCollection)
				.filter(isNotEmptyCollection)
				.filter(isNotJumbotron)
				.filter(isNotFavorites)
				.filter((shelf, index) => index < 1) // TODO replace by isRecentCollection()
				.map((collection, index) =>
					mapCollectionToShelf(collection, index, searchView)
				);
		}

		return [];
	}, [data, recent, searchView, text]);

	return {
		shelvesLoading: isLoading,
		shelvesError: error,
		shelves: memoShelves,
	};
}

export interface Genre {
	slug: string;
	title: string;
	backgroundColor: { hex: string };
	image: {
		alt: string;
		url: string;
		listUrl: string;
	};
	titleImage: {
		alt: string;
		url: string;
		listUrl: string;
	};
}

export function useGenresShelves() {
	console.log('useGenreShelf');

	const { isLoading, error, data } = useGenres();

	const {
		isLoading: isLoadingList,
		error: errorList,
		data: list,
	} = useGenresList();

	offset = 0;

	const memoGenres = useMemo<Genre[]>(() => {
		if (data && list) {
			const genres = data.map((genre) => genre.toLocaleLowerCase());
			const filteredList = list
				.map((genre: any) => {
					return {
						...genre,
						slug: genre.slug.replace(`-${config.appName}`, ''),
					};
				})
				.filter((genre: any) => {
					return genres.includes(genre.slug);
				});

			return filteredList;
		}

		return [];
	}, [data, list]);

	return {
		genresLoading: isLoading || isLoadingList,
		genresError: error || errorList,
		genres: memoGenres,
	};
}

export function useProfileShelves() {
	const { translate } = useI18n();

	return {
		shelvesLoading: false,
		shelvesError: undefined,
		shelves: [
			{
				title: translate('shelves.favorites'),
				type: 'favorites',
				items: [],
			},
			{
				title: translate('shelves.continuewatching'),
				type: 'continueWatching',
				items: [],
				isHorizontal: true,
			},
		],
	};
}

function mapItemToShelf(season: Season, index: number): Shelf<ShelfEpisode> {
	const shelf: Shelf<ShelfEpisode> = {
		index,
		title: season.title,
		isHorizontal: true,
		loopable: false,
		id: season?.id,
		layout: {
			length: SHELF_LANDSCAPE_HEIGHT,
			offset,
			index,
		},
		isIncentive: false,
		incentiveBannerImage: null,
		items: season.episodes.map((e: any, idx: number) => ({
			...e,
			...getImages(e, true),
			shelfId: season?.id,
			xIndex: idx,
			yIndex: index,
			isHorizontal: true,
			elapsed: 0,
			serie: season.title,
		})),
	};

	offset += SHELF_LANDSCAPE_HEIGHT + 111;

	return shelf;
}

function mapSearchToShelves(
	key: string,
	value: SearchResult[],
	index: number,
	searchView?: boolean
): Shelf {
	const isHorizontal = MEDIA_CATEGORIES[key] !== MediaCategories.MOVIES;
	const height = isHorizontal ? SHELF_LANDSCAPE_HEIGHT : SHELF_HEIGHT;

	// TODO get watchHistory from userHook
	const watchHistory: any[] = [];

	const shelf: Shelf = {
		title: MEDIA_CATEGORIES[key],
		id: `search_${key}`,
		index,
		isHorizontal,
		loopable: false,
		incentiveBannerImage: null,
		isIncentive: false,
		items: value.map((shelfItem: any, idx: number) => {
			const item = shelfItem._source;

			const elapsed =
				watchHistory.find((history) => history.id === shelfItem['_id'])
					?.duration || 0;

			return {
				...item,
				id: item.id,
				shelfId: `search_${key}`,
				...getImages(item, searchView ? false : isHorizontal),
				xIndex: idx,
				yIndex: index,
				isHorizontal: isHorizontal,
				isFirst: idx === 0,
				isLast: idx === value.length - 1,
				elapsed: elapsed,
			};
		}),
		layout: {
			length: height,
			offset: offset,
			index,
		},
	};

	if (isHorizontal) {
		offset += SHELF_LANDSCAPE_HEIGHT + 100;
	} else {
		offset += SHELF_HEIGHT;
	}

	return shelf;
}

function mapCollectionToShelf(
	collection: Collection,
	index: number,
	searchView?: boolean
): Shelf {
	const isHorizontal =
		(collection.extended?.custom as { tile: string })?.tile === 'landscape';

	const isIncentive =
		collection.extended?.custom?.type === 'incentive' ||
		collection.id.includes('incentive');

	const isTopShelf = collection.extended?.custom?.topList;

	const collectionIsJumbotron = isJumbotron(collection);

	let height = isHorizontal ? SHELF_LANDSCAPE_HEIGHT : SHELF_HEIGHT;

	if (isIncentive) {
		height = INCENTIVE_BANNER_HEIGHT;
	}

	const items = collection.items.filter(isNotCollectionOrSeason);

	const shelf: Shelf = {
		title: collection.title,
		id: collection.id,
		type: collection.extended?.custom?.type || 'default',
		index,
		items: items.map((shelfItem, idx) => ({
			...shelfItem,
			id: shelfItem.id,
			shelfId: collection.id,
			...getImages(shelfItem, searchView ? false : isHorizontal),
			xIndex: idx,
			yIndex: index, // + (watchHistory?.length ? 1 : 0),
			isFirst: idx === 0,
			isLast: idx === items.length - 1,
			isHorizontal,
			isJumbotron: collectionIsJumbotron,
		})),
		isHorizontal,
		isIncentive,
		isTopShelf,
		incentiveBannerImage: isIncentive
			? `${config.resourceUrl}/${collection.id}/cover/2048x512/${collection.cover_image}.jpeg`
			: null,
		loopable: false,
		layout: {
			length: height,
			offset,
			index,
		},
	};

	if (isHorizontal) {
		offset += SHELF_LANDSCAPE_HEIGHT;
	} else if (isIncentive) {
		offset += INCENTIVE_BANNER_HEIGHT;
	} else {
		offset += SHELF_HEIGHT;
	}

	return shelf;
}

export function makeShelfAsset(
	asset: Movie | Episode | TVShow,
	isHorizontal = false
): ShelfAsset {
	const isJumbotron = false;

	return {
		...asset,
		...getImages(asset, isHorizontal),
		xIndex: 0,
		yIndex: 0,
		isHorizontal,
		isJumbotron,
	};
}

export function replaceImageResolution(url: string, resolution: string) {
	return url.replace('$Wx$H', resolution);
}

export function getImages(asset: Asset, isHorizontal = false) {
	let image;

	if (isHorizontal) {
		image = asset.image.cover_16x9
			? `${config.resourceUrl}` +
			  replaceImageResolution(asset.image.cover_16x9, '640x360')
			: `${config.resourceUrl}` +
			  replaceImageResolution(asset.image.list_9x13, '360x520');
	} else {
		image = asset.list_image
			? `${config.resourceUrl}` +
			  replaceImageResolution(asset.image.list_9x13, '360x520')
			: `${config.resourceUrl}` +
			  replaceImageResolution(asset.image.cover_16x9, '640x360');
	}

	const background =
		`${config.resourceUrl}` +
		replaceImageResolution(
			asset.image.clean_cover_16x9 || asset.image.cover_16x9,
			'1280x720'
		);

	return {
		image: {
			uri: image,
		},
		background: {
			uri: background,
		},
	};
}
