import { keymap } from '../keymap';

import { Listener, ListenerCallback } from './listener';

const LONG_PRESS_TRESHOLD = 600;

class TVEvents {
	_listeners: Listener[] = [];
	_listenerId = 0;
	_pressedKeys = {};
	_enableDefault = false;

	constructor() {
		document.addEventListener('keydown', this._onKeyDown);
		document.addEventListener('keyup', this._onKeyUp);
	}

	_onKeyDown = (event: KeyboardEvent) => {
		this._pressedKeys[event.keyCode] = {
			event,
			timeStamp: Date.now(),
		};
	};

	setEnableDefault = (enable = false) => {
		this._enableDefault = enable;
	};

	_onKeyUp = (event: KeyboardEvent) => {
		const duration = Date.now() - this._pressedKeys[event.keyCode]?.timeStamp;
		let eventType;

		const key = keymap[event.keyCode] ? keymap[event.keyCode] : 'UNKNOWN_KEY';
		if (duration > LONG_PRESS_TRESHOLD) {
			eventType = `long${key}`;
		} else {
			eventType = key.toLowerCase();
		}

		this._fireEvent(eventType);
	};

	_fireEvent = (e: string) => {
		for (let i = this._listeners.length; i > 0; i--) {
			if (this._listeners[i - 1]?.listener(e) === true) break;
		}
	};

	addEventListener = (cb: ListenerCallback) => {
		const listenerId = this._listenerId;
		this._listenerId++;
		this._listeners.push({
			listener: cb,
			id: listenerId,
		});
		return {
			id: listenerId,
			remove: () => {
				this._listeners = this._listeners.filter((l) => l.id !== listenerId);
			},
		};
	};
}

export default new TVEvents();
