import { Image, View } from '@flexn/sdk';
import React from 'react';
import { StyleSheet } from 'react-native';

import { ShelfAsset } from '../../models/Asset';
import { Shelf } from '../../models/Shelf';
import isNative from '../../utils/isNative';
import Pressable from '../pressable';
import { getScaledValue, getResponsiveScaling } from '../../utils/scale';

const styles = StyleSheet.create({
	container: {
		width: '86%',
	},

	bannerContainer: {
		borderRadius: getScaledValue(6),
		overflow: 'hidden',
		borderWidth: isNative ? getScaledValue(2) : 0,
		borderColor: '#FFFFFF',
	},

	banner: {
		width: '100%',
		height: getResponsiveScaling(18).width,
		borderRadius: getScaledValue(4),
	},
});

type Props = {
	parentContext?: any;
	repeatContext?: any;
	shelf: Shelf;
	onFocus?: (asset: ShelfAsset) => void;
	onPress?: (asset: ShelfAsset) => void;
	onBlur?: (asset: ShelfAsset) => void;
};

const IncentiveBanner = ({
	shelf,
	parentContext,
	repeatContext,
	onFocus,
	onBlur,
	onPress,
}: Props) => {
	return (
		<View
			style={styles.container}
			parentContext={parentContext}
		>
			<Pressable
				repeatContext={repeatContext}
				style={[styles.bannerContainer]}
				onFocus={onFocus}
				onBlur={onBlur}
				onPress={onPress}
				focusOptions={{
					animatorOptions: {
						type: 'border',
					},
				}}
			>
				<Image
					style={styles.banner}
					source={{
						uri: shelf.incentiveBannerImage || '',
					}}
				/>
			</Pressable>
		</View>
	);
};

export default IncentiveBanner;
