import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useRef, useState } from 'react';

import { useKeyHandler } from '../hooks/useKeyHandler';
import { KEY_PLAYPAUSE, MEDIA_PLAY, MEDIA_PLAYPAUSE } from '../utils/keymap';

const DeviceInfoTrigger: React.FC = () => {
	const triggerTimeout = useRef<ReturnType<typeof setTimeout>>();
	const [triggerCount, setTriggerCount] = useState(0);
	const { navigate, isFocused } = useNavigation();

	useKeyHandler([MEDIA_PLAY, MEDIA_PLAYPAUSE, KEY_PLAYPAUSE], () => {
		clearTimeout(triggerTimeout.current);
		setTriggerCount((tc) => tc + 1);

		triggerTimeout.current = setTimeout(() => {
			setTriggerCount(0);
		}, 1000);
	});

	useEffect(() => {
		if (triggerCount >= 3 && isFocused()) {
			navigate('DeviceInfo');
		}
	}, [triggerCount, navigate, isFocused]);

	return null;
};

export default DeviceInfoTrigger;
