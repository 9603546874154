import React, {
	createContext,
	useState,
	ReactNode,
	Dispatch,
	SetStateAction,
	useRef,
	RefObject,
} from 'react';
import { TouchableHighlight, TextInput } from 'react-native';
import { useConfig } from '../hooks/useConfig';

type Props = {
	children?: ReactNode;
};

const UIContext = createContext<
	| {
			homeMenuTab: RefObject<TouchableHighlight>;
			menuNextRight: TextInput | TouchableHighlight | null;
			isMenuFocused: boolean;
			setIsMenuFocused: Dispatch<SetStateAction<boolean>>;
			setMenuNextRight: Dispatch<
				SetStateAction<TextInput | TouchableHighlight | null>
			>;
			testingCollection: boolean;
			appLoading: boolean;
			setAppLoading: Dispatch<SetStateAction<boolean>>;
			isCountryActive: boolean;
			configCollection: string;
			homeCollection: string;
			noResultsCollection: string;
			collectionSetupLoading: boolean;
	  }
	| undefined
>(undefined);

const UIProvider = ({ children }: Props) => {
	const homeMenuTab = useRef<TouchableHighlight>(null);

	const [appLoading, setAppLoading] = useState<boolean>(false);

	const [menuNextRight, setMenuNextRight] = useState<
		TextInput | TouchableHighlight | null
	>(null);

	const [isMenuFocused, setIsMenuFocused] = useState<boolean>(true);

	const {
		isCountryActive,
		configCollection,
		homeCollection,
		noResultsCollection,
		collectionSetupLoading,
		testingCollection,
	} = useConfig();

	return (
		<UIContext.Provider
			value={{
				homeMenuTab,
				menuNextRight,
				setMenuNextRight,
				isMenuFocused,
				setIsMenuFocused,
				testingCollection,
				appLoading,
				setAppLoading,
				isCountryActive,
				configCollection,
				homeCollection,
				noResultsCollection,
				collectionSetupLoading,
			}}
		>
			{children}
		</UIContext.Provider>
	);
};

export { UIContext, UIProvider };
