import React from 'react';
import { StyleSheet, Text } from 'react-native';

import colors from '../../platformAssets/runtime/colors';
import fonts from '../../platformAssets/runtime/fontnames';
import { getScaledValue } from '../utils/scale';

const styles = StyleSheet.create({
	title: {
		fontFamily: fonts.primary,
		color: colors.primaryText,
		marginBottom: getScaledValue(0),
		marginLeft: getScaledValue(-2),
	},
});

const Title = ({ fontSize = 44, children, style = {} }) => {
	return (
		<Text
			numberOfLines={1}
			style={[
				styles.title,
				{
					fontSize: getScaledValue(fontSize),
				},
				style,
			]}
		>
			{children && children?.toUpperCase ? children.toUpperCase() : children}
		</Text>
	);
};

export default Title;
