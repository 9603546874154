import { CoreManager, View } from '@flexn/sdk';
import React, { useLayoutEffect, useState } from 'react';
import { StyleSheet, Text } from 'react-native';

import colors from '../../../platformAssets/runtime/colors';
import fontnames from '../../../platformAssets/runtime/fontnames';
import { useI18n } from '../../hooks/useI18n';
import { usePlayer } from '../../hooks/usePlayer';
import isNative from '../../utils/isNative';
import parseTimeFormat from '../../utils/parseTimeFormat';
import { getScaledValue } from '../../utils/scale';
import Button from '../button/button-flex';
import {
	USER_INTERACTION_CONTEXT,
	USER_INTERACTION_FEATURES,
	USER_INTERACTION_TYPE,
} from '../../services/event-tracker/UserInterActionEvent';

export const SKIP_INTRO_FOCUS_KEY = 'PLAYER-SKIP-INTRO';

type SkipIntroProps = {
	parentContext?: any;
};

const SkipIntro: React.FC<SkipIntroProps> = ({ parentContext }) => {
	const { showSkipIntro, asset, player, playerWeb, fireDidSeek, setPlaying } =
		usePlayer();
	const { translate } = useI18n();

	const [focusAlreadySet, setFocusAlreadySet] = useState<boolean>(false);

	const handlePress = () => {
		const playerRef = isNative ? player.current : playerWeb;
		asset?.intro_end && playerRef?.seek(parseTimeFormat(asset?.intro_end));
		fireDidSeek({});
		setPlaying(true);
	};

	useLayoutEffect(() => {
		if (!focusAlreadySet && showSkipIntro) {
			setFocusAlreadySet(true);
			setTimeout(() => {
				CoreManager.focusElementByFocusKey('play-controls-action-button');
			}, 100);
		}
	}, [showSkipIntro]);

	if (!showSkipIntro) return null;

	return (
		<View parentContext={parentContext}>
			<Button
				onPress={handlePress}
				focusOptions={{ focusKey: 'play-controls-action-button' }}
				track={{
					feature: USER_INTERACTION_FEATURES.PLAYER,
					context: USER_INTERACTION_CONTEXT.PLAYER_CONTROLS,
					type: USER_INTERACTION_TYPE.BUTTON,
					name: 'skip_intro',
				}}
			>
				<>
					<Text
						numberOfLines={1}
						style={styles.buttonText}
					>
						{translate('player.skip_intro')}
					</Text>
				</>
			</Button>
		</View>
	);
};

const styles = StyleSheet.create({
	button: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingHorizontal: getScaledValue(12),
		borderRadius: getScaledValue(8),
		backgroundColor: '#ffffff20',
	},
	buttonText: {
		marginLeft: getScaledValue(4),
		marginRight: getScaledValue(4),
		marginTop: getScaledValue(16),
		marginBottom: getScaledValue(16),
		fontSize: getScaledValue(16),
		fontFamily: fontnames.secondarySemiBold,
		color: 'white',
	},

	focusedButton: {
		backgroundColor: colors.primary,
	},
});

export default SkipIntro;
