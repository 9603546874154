import { PressableFocusOptions } from '@flexn/sdk/lib/focusManager/types';
import { isTizen, isWebos } from '@rnv/renative';
import React, { ComponentProps, useRef, useState } from 'react';
import { StyleSheet, TextInput } from 'react-native';

import colors from '../../../platformAssets/runtime/colors';
import isNative from '../../utils/isNative';
import Pressable from '../pressable';
import { getScaledValue } from '../../utils/scale';
import {
	USER_INTERACTION_CONTEXT,
	USER_INTERACTION_FEATURES,
	USER_INTERACTION_TYPE,
} from '../../services/event-tracker/UserInterActionEvent';

const styles = StyleSheet.create({
	pressable: {
		marginBottom: getScaledValue(12),
	},
	textInput: {
		paddingHorizontal: getScaledValue(12),
		paddingVertical: getScaledValue(12),
		borderWidth: 0,
		borderRadius: getScaledValue(8),
		overflow: 'hidden',
		fontSize: getScaledValue(16),
		color: colors.primaryText,
		backgroundColor: colors.inactive,
	},
	textInputFocused: {
		backgroundColor: colors.textInputFocus,
	},
});

type InputProps = {
	parentContext?: any;
	focusOptions?: PressableFocusOptions;
	track?: {
		feature: USER_INTERACTION_FEATURES;
		context: USER_INTERACTION_CONTEXT;
		type: USER_INTERACTION_TYPE;
		name: string;
		body?: any;
	};
} & ComponentProps<typeof TextInput>;
const Input = React.forwardRef(
	(
		{
			value,
			placeholder,
			keyboardType,
			textContentType,
			secureTextEntry,
			onChangeText,
			parentContext,
			focusOptions,
			testID,
			track,
			style,
		}: InputProps,
		ref
	) => {
		const inputRef = useRef<TextInput>(null);
		const [textInputFocused, setTextInputFocused] = useState(false);
		const [keyboard, setKeyboard] = useState(false);

		const handleSubmitEditing = () => {
			onPressBlock();
			inputRef.current?.blur();
		};

		const onPressBlock = () => {
			setTimeout(() => {
				setKeyboard(false);
			}, 1000);
		};

		return (
			<Pressable
				parentContext={parentContext}
				ref={ref}
				style={{ ...styles.pressable, ...style }}
				onPress={() => {
					isNative && inputRef.current?.focus();

					if ((isWebos() || isTizen()) && !keyboard) {
						inputRef.current?.focus();
						setKeyboard(true);
					}

					setTextInputFocused(true);
				}}
				onFocus={() => {
					setTextInputFocused(true);
				}}
				onBlur={() => {
					inputRef.current?.blur();
					onPressBlock();
					setTextInputFocused(false);
				}}
				focusOptions={{
					animatorOptions: {
						type: 'scale',
						scale: 1,
					},
					...focusOptions,
				}}
				track={track}
			>
				<TextInput
					testID={testID}
					ref={inputRef}
					style={[
						styles.textInput,
						textInputFocused && styles.textInputFocused,
					]}
					value={value}
					placeholder={placeholder}
					placeholderTextColor={colors.secondaryText}
					keyboardType={keyboardType || 'default'}
					textContentType={textContentType || 'none'}
					secureTextEntry={secureTextEntry || false}
					onChangeText={onChangeText}
					onSubmitEditing={handleSubmitEditing}
				/>
			</Pressable>
		);
	}
);

Input.displayName = 'Input';

export default Input;
