import { View } from '@flexn/sdk';
import { isTvos } from '@rnv/renative';
import React, { useRef, useLayoutEffect, useState } from 'react';
import { StyleSheet, Animated, StyleProp, ViewStyle } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import config from '../../platformAssets/runtime/config';
import { useAssetDetails } from '../hooks/api';
import { ShelfAsset } from '../models/Asset';

import Video from './video';

const BackgroundPoster = ({
	parentContext,
	children,
	style = {},
	poster,
	blur = false,
	fullScreen = true,
	trailer = false,
	asset = undefined,
	animated = false,
}: {
	parentContext?: any;
	children?: React.ReactNode;
	style?: StyleProp<ViewStyle>;
	poster?: string;
	gradient?: boolean;
	blur?: boolean;
	fullScreen?: boolean;
	trailer?: boolean;
	asset?: ShelfAsset;
	animated?: boolean;
}) => {
	const source = poster ? { uri: poster } : {};
	const fadeAnimation = useRef<Animated.Value>(new Animated.Value(0)).current;
	const panAnimation = useRef(new Animated.Value(0)).current;
	const trailerTimer = useRef<number | undefined>();

	const [isVideoShow, setisVideoShow] = useState(false);

	const { data: assetDetails } = useAssetDetails({
		asset,
		enabled: trailer,
	});

	useLayoutEffect(() => {
		setisVideoShow(false);

		if (trailerTimer.current) {
			clearTimeout(trailerTimer.current);
		}

		if (assetDetails && assetDetails.trailer && trailer) {
			trailerTimer.current = setTimeout(() => {
				setisVideoShow(true);
			}, 2000) as unknown as number;
		}
	}, [assetDetails, trailer]);

	useLayoutEffect(() => {
		fadeAnimation.setValue(0);
		if (poster) {
			Animated.timing(fadeAnimation, {
				toValue: 1,
				duration: 350,
				useNativeDriver: true,
			}).start();
		}
	}, [poster, fadeAnimation]);

	useLayoutEffect(() => {
		Animated.timing(panAnimation, {
			toValue: 1,
			duration: 10000,
			useNativeDriver: true,
		}).start();
	}, [panAnimation]);

	const panInterpolate = panAnimation.interpolate({
		inputRange: [0, 1],
		outputRange: [0, 60],
	});

	return (
		<View
			style={[styles.container, style]}
			parentContext={parentContext}
		>
			<Animated.Image
				fadeDuration={0}
				source={source}
				resizeMode="cover"
				blurRadius={blur ? 10 : 0}
				style={[
					fullScreen ? styles.posterImageFullScreen : styles.posterImage,
					{ opacity: fadeAnimation },
					{ transform: [{ translateX: animated ? panInterpolate : 0 }] },
				]}
				onLoad={() => {
					console.log('>> loaded poster');
				}}
			/>

			{trailer && assetDetails && assetDetails.trailer && isVideoShow && (
				<Video
					onEnd={() => {
						setisVideoShow(false);
					}}
					media={
						isTvos()
							? assetDetails.trailer.hls_url.replace('media', config.mediaUrl)
							: assetDetails.trailer.dash_url.replace('media', config.mediaUrl)
					}
					style={{
						backgroundColor: 'black',
						height: '100%',
						width: '100%',
						position: 'absolute',
						left: 0,
						right: 0,
						top: 0,
						bottom: 0,
						objectFit: 'cover',
					}}
				/>
			)}

			<LinearGradient
				colors={['black', 'transparent']}
				start={{ x: 0, y: 0 }}
				end={{ x: 1, y: 0 }}
				locations={[0.1, 1]}
				style={
					fullScreen
						? {
								...styles.gradientImageFullScreen,
								left: isVideoShow ? 0 : '40%',
						  }
						: styles.gradientImage
				}
			/>

			{!fullScreen && (
				<LinearGradient
					colors={['transparent', 'black']}
					style={{
						position: 'absolute',
						left: '40%',
						right: 0,
						bottom: '30%',
						top: '60%',
					}}
				/>
			)}

			{children}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: 'black',
		height: '100%',
		width: '100%',
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
	},
	posterImage: {
		position: 'absolute',
		left: '40%',
		right: 0,
		bottom: '30%',
		top: 0,
	},
	gradientImage: {
		position: 'absolute',
		left: '40%',
		right: 0,
		bottom: '30%',
		top: 0,
	},
	posterImageFullScreen: {
		position: 'absolute',
		left: '40%',
		right: 0,
		bottom: 0,
		top: 0,
	},
	gradientImageFullScreen: {
		position: 'absolute',
		left: '40%',
		right: 0,
		bottom: 0,
		top: 0,
	},
});

BackgroundPoster.displayName = 'BackgroundPoster';

export default React.memo(BackgroundPoster, (p, n) => {
	if (p.children && n.children) return false;

	if (p.trailer !== n.trailer) return false;

	return p.poster === n.poster;
});
