import React from 'react';
import { StyleSheet, Text, TouchableHighlight, View } from 'react-native';

import colors from '../../platformAssets/runtime/colors';
import fonts from '../../platformAssets/runtime/fontnames';
import { getScaledValue } from '../utils/scale';

const styles = StyleSheet.create({
	container: {
		marginTop: getScaledValue(8),
		minHeight: getScaledValue(45),
	},
	title: {
		fontFamily: fonts.primary,
		fontSize: getScaledValue(22),
		color: colors.primaryText,
	},
	button: {
		width: getScaledValue(256),
		borderRadius: getScaledValue(8),
		backgroundColor: '#ffffff20',
		overflow: 'hidden',
	},
	buttonText: {
		marginLeft: getScaledValue(12),
		marginRight: getScaledValue(12),
		marginTop: getScaledValue(16),
		marginBottom: getScaledValue(16),
		fontSize: getScaledValue(16),
		color: 'white',
	},
});

interface Props {
	title: string;
	style?: any;
	children?: any;
	cta?: { href: string; title: string };
}

const NoResults: React.FC<Props> = ({ title, cta, style, children }) => {
	return (
		<View style={[styles.container, style]}>
			<Text
				numberOfLines={1}
				style={styles.title}
			>
				{title.toUpperCase()}
			</Text>
			{children}
			{cta && (
				<TouchableHighlight style={styles.button}>
					<Text style={styles.buttonText}>{cta?.title}</Text>
				</TouchableHighlight>
			)}
		</View>
	);
};

NoResults.displayName = 'NoResults';

export default NoResults;
