import React from 'react';
import { Image, StyleSheet } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import logo from '../../../platformAssets/runtime/icons/logo.png';
import { useI18n } from '../../hooks/useI18n';
import Title from '../title';
import { getScaledValue } from '../../utils/scale';

const styles = StyleSheet.create({
	container: {
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
	},
	gradient: {
		position: 'absolute',
		zIndex: 1,
		left: 0,
		width: getScaledValue(100),
		height: '100%',
		flex: 1,
	},

	logo: {
		height: getScaledValue(62),
		width: getScaledValue(110),
		marginBottom: getScaledValue(24),
	},
});

const NoActiveSubscription = () => {
	const { translate } = useI18n();

	return (
		<LinearGradient
			colors={['black', 'transparent']}
			start={{ x: 0, y: 0 }}
			end={{ x: 1, y: 0 }}
			style={styles.container}
		>
			<Image
				source={logo}
				resizeMode={'contain'}
				style={styles.logo}
			/>
			<Title style={{ fontSize: getScaledValue(18) }}>
				{translate('noActiveSubscription')}
			</Title>
		</LinearGradient>
	);
};

export default NoActiveSubscription;
