import { View, Text } from '@flexn/sdk';
import RecyclableList, {
	RecyclableListLayoutProvider,
	RecyclableListDataProvider,
} from '@flexn/sdk/lib/components/RecyclableList';
import {
	Context,
	RecyclableListFocusOptions,
} from '@flexn/sdk/lib/focusManager/types';
import { isPlatformTvos } from '@rnv/renative';
import React, { useRef, useMemo } from 'react';
import {
	StyleProp,
	ViewStyle,
	TextStyle,
	StyleSheet,
	Dimensions,
} from 'react-native';

import colors from '../../../platformAssets/runtime/colors';
import fonts from '../../../platformAssets/runtime/fontnames';
import { useI18n } from '../../hooks/useI18n';
import { Shelf as ShelfModel } from '../../models/Shelf';
import IncentiveBanner from '../incentive-banner';
import TileFlexn, { NUMBER_WIDTH } from '../tile/tile';
import { TILE_LANDSCAPE_WIDTH } from '../tile/tileBase';
import { INCENTIVE_BANNER_TILE_HEIGHT } from './constants';
import { getScaledValue, getResponsiveScaling } from '../../utils/scale';

type RowItem = {
	backgroundImage: string;
	title?: string;
};
interface RowProps {
	index?: number;
	parentContext?: Context;
	repeatContext?: Context;
	title?: string;
	focusOptions?: RecyclableListFocusOptions;
	animatorOptions?: any;
	itemsInViewport?: number;
	style?: StyleProp<ViewStyle>;
	cardStyle?: StyleProp<ViewStyle> | StyleProp<TextStyle>;
	titleStyle?: StyleProp<TextStyle>;
	onFocus?(data: any): void;
	onBlur?(data: any): void;
	onPress?(data: any): void;
	renderCard?(
		data: any,
		_repeatContext: any,
		dimensions: any,
		_renderProps: any
	): JSX.Element | JSX.Element[] | null;
	items: RowItem[];
	rerenderData?: any;
	itemDimensions: { height: number; width: number };
	itemSpacing?: number;
	verticalItemSpacing?: number;
	horizontalItemSpacing?: number;
	initialXOffset?: number;
	disableItemContainer?: boolean;
	showDetail: boolean;
	shelf: ShelfModel;
	isEmpty?: boolean;
	showProgressBar?: boolean;
	renderEmpty?({
		repeatContext,
		cardStyle,
		itemDimensions,
	}): JSX.Element | null;
	isTopShelf?: boolean;
}

const Shelf = ({
	items,
	title,
	itemsInViewport,
	parentContext,
	repeatContext,
	focusOptions,
	animatorOptions,
	style = {},
	cardStyle = {},
	titleStyle = {},
	rerenderData,
	onFocus,
	onPress,
	onBlur,
	renderCard,
	itemDimensions,
	itemSpacing = 0,
	verticalItemSpacing = 0,
	horizontalItemSpacing = 0,
	initialXOffset = 0,
	disableItemContainer = false,
	shelf,
	showDetail = false,
	isEmpty = false,
	renderEmpty = () => null,
	isTopShelf,
	showProgressBar = false,
}: RowProps) => {
	const itemsKey = JSON.stringify(items);
	const ref: any = useRef();
	const layoutProvider: any = useRef();
	const dataProviderInstance = useRef(
		new RecyclableListDataProvider((r1, r2) => r1 !== r2)
	).current;
	const dataProvider = useMemo(
		() => dataProviderInstance.cloneWithRows(items),
		[dataProviderInstance, itemsKey]
	);
	const flattenTitleStyles = StyleSheet.flatten(titleStyle);
	const SPACING_SIZE = getScaledValue(12);
	const showDetailEnabled = showDetail && shelf.isHorizontal;
	const showDetailHeight = showDetailEnabled ? 10 : 0;
	const { translate } = useI18n();

	const setLayoutProvider = () => {
		if (!layoutProvider.current) {
			layoutProvider.current = new RecyclableListLayoutProvider(
				(index) => index,
				(index: number, dim: { width: number; height: number }) => {
					if (shelf.isIncentive) {
						dim.width = Dimensions.get('window').width;
						dim.height = getResponsiveScaling(
							INCENTIVE_BANNER_TILE_HEIGHT
						).width;
					} else {
						const additionalWidth = isTopShelf
							? getScaledValue(index >= 9 ? NUMBER_WIDTH * 2 : NUMBER_WIDTH)
							: 0;

						dim.width =
							items?.[index]?.type === 'footer'
								? itemDimensions.width * 0.2
								: itemDimensions.width +
								  getScaledValue(shelf.isHorizontal ? 16 : 10) +
								  additionalWidth;
						dim.height =
							itemDimensions.height +
							getResponsiveScaling(showDetailHeight).width;
					}
				}
			);
		}
	};

	setLayoutProvider();

	const rowRenderer = (
		_type: string | number,
		data: any,
		_index: number,
		_repeatContext: any,
		_renderProps: any
	) => {
		if (data.type === 'footer')
			return (
				<View
					style={[
						{
							width: itemDimensions.width * 0.2,
							height: itemDimensions.height,
						},
					]}
				/>
			);

		if (isEmpty) {
			return renderEmpty({
				cardStyle,
				repeatContext: _repeatContext,
				itemDimensions,
			});
		}

		if (shelf.isIncentive) {
			return (
				<IncentiveBanner
					shelf={shelf}
					repeatContext={_repeatContext}
					onFocus={() => onFocus?.(data)}
					onBlur={() => onBlur?.(data)}
					onPress={() => onPress?.(data)}
				/>
			);
		}

		return (
			<TileFlexn
				asset={data}
				id={data.id}
				src={{ uri: data.image.uri }}
				title={data.title}
				style={[
					cardStyle,
					{
						width: itemDimensions.width,
						height: itemDimensions.height,
					},
				]}
				onFocus={() => onFocus?.(data)}
				onBlur={() => onBlur?.(data)}
				onPress={() => onPress?.(data)}
				repeatContext={_repeatContext}
				renderProps={_renderProps}
				focusOptions={{
					animatorOptions,
					focusKey: `shelf-${shelf.index}-${data.yIndex}-${data.xIndex}`,
				}}
				renderImage
				showDetail={showDetailEnabled}
				testID={`shelf-${shelf.index}-${data.yIndex}-${data.xIndex}`}
				number={isTopShelf ? _index + 1 : null}
				showProgressBar={showProgressBar}
			/>
		);
	};

	const renderRecycler = () => {
		return (
			<RecyclableList
				key={itemsKey}
				type="row"
				dataProvider={dataProvider}
				layoutProvider={layoutProvider.current}
				initialXOffset={initialXOffset + 40}
				repeatContext={repeatContext}
				renderAheadOffset={10}
				rowRenderer={rowRenderer}
				disableItemContainer={disableItemContainer && isPlatformTvos}
				isHorizontal
				contentContainerStyle={{
					paddingTop: SPACING_SIZE,
					paddingBottom: SPACING_SIZE,
					paddingLeft: initialXOffset,
					paddingRight:
						initialXOffset +
						getResponsiveScaling(TILE_LANDSCAPE_WIDTH * 4).width,
				}}
				style={[
					{
						width: Dimensions.get('screen').width,
						height:
							itemDimensions.height +
							SPACING_SIZE * 3 +
							getResponsiveScaling(showDetailHeight).width,
					},
				]}
				scrollViewProps={{
					showsHorizontalScrollIndicator: false,
				}}
				focusOptions={{
					...focusOptions,
				}}
				initialRenderIndex={
					shelf.selectedAssetIndex
						? shelf.selectedAssetIndex - 1
						: shelf.selectedAssetIndex
				}
			/>
		);
	};

	const renderTitle = () => {
		if (title) {
			return (
				<Text
					style={[
						{
							marginBottom: getScaledValue(4),
							color: colors.primaryText,
							fontFamily: fonts.secondary,
							fontSize: getScaledValue(14),
						},
						titleStyle,
					]}
				>
					{shelf?.titlePrefix ? `${shelf.titlePrefix} ${title}` : title}
				</Text>
			);
		}

		return null;
	};

	return (
		<View
			testID={shelf ? `shelf-${shelf.index}` : 'shelf'}
			parentContext={parentContext}
			style={style}
			ref={ref}
		>
			{!shelf?.isIncentive && renderTitle()}
			{renderRecycler()}
		</View>
	);
};

export default Shelf;
