import styled from '@emotion/native';
import React, { memo } from 'react';

import gradients from '../../platformAssets/runtime/img/gradient.png';

const Gradients = memo(function ({ children }) {
	return (
		<StyledImageBackground source={gradients}>{children}</StyledImageBackground>
	);
});

const StyledImageBackground = styled.ImageBackground`
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 10;
	top: 0;
	left: 0;
`;

Gradients.displayName = 'Gradients';
export { Gradients };
