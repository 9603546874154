import * as Sentry from '@sentry/browser';
import { useContext } from 'react';

import { PlayerContext } from '../contexts/PlayerContext';

export function usePlayer() {
	const context = useContext(PlayerContext);

	if (context === undefined) {
		Sentry.captureMessage(`usePlayer must be within PlayerProvider`);
		throw new Error('usePlayer must be within PlayerProvider');
	}

	return context;
}
