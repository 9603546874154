import { isAndroidtv } from '@rnv/renative';
import React, { useEffect, useRef, useState } from 'react';
import { Animated, Easing, StyleSheet, Text, View } from 'react-native';

import colors from '../../../platformAssets/runtime/colors';
import fonts from '../../../platformAssets/runtime/fontnames';
import Pressable from '../../components/pressable';

import isNative from '../../utils/isNative';
import { getScaledValue, getResponsiveScaling } from '../../utils/scale';
import Image from '../image';
import {
	IMAGE_LANDSCAPE_HEIGHT,
	IMAGE_LANDSCAPE_WIDTH,
	TILE_LANDSCAPE_HEIGHT,
	TILE_LANDSCAPE_WIDTH,
} from '../tile/tileBase';
import { Genre as GenreType } from '../../hooks/shelves';

type Props = {
	repeatContext?: any;
	genre: GenreType;
	setGenre: (text: string) => void;
	focusOptions: any;
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		width: getResponsiveScaling(IMAGE_LANDSCAPE_WIDTH - 1).width,
		height: getResponsiveScaling(IMAGE_LANDSCAPE_HEIGHT - 1).width,
		padding: getScaledValue(16),
		margin: getScaledValue(8),
		justifyContent: 'center',
		alignItems: 'center',
		borderWidth: isNative ? getScaledValue(isAndroidtv() ? 2 : 2) : 0,
		borderColor: colors.primaryText,
		borderRadius: getScaledValue(12),
	},
	image: {
		position: 'absolute',
		zIndex: -1,
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		borderRadius: getScaledValue(12),
	},
	darken: {
		position: 'absolute',
		zIndex: -1,
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		borderRadius: getScaledValue(12),
		backgroundColor: '#000000' + '35',
	},
	titleImage: {
		position: 'absolute',
		zIndex: -1,
		left: getScaledValue(12),
		right: getScaledValue(12),
		top: getScaledValue(12),
		bottom: getScaledValue(12),
		display: 'flex',
		justifyContent: 'center',
	},
	textContainer: {
		position: 'absolute',
		zIndex: -1,
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		borderRadius: getScaledValue(12),
		justifyContent: 'center',
		alignItems: 'center',
	},
	text: {
		fontFamily: fonts.primary,
		fontSize: getScaledValue(20),
		padding: getScaledValue(8),
		color: colors.primaryText,
	},
	LoadingRect: {
		position: 'absolute',
		zIndex: -1,
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		display: 'flex',
		backgroundColor: 'white',
		justifyContent: 'center',
	},
});

const LoadingRect = (props: { style?: any }) => {
	const pulseAnim = useRef(new Animated.Value(0)).current;

	useEffect(() => {
		const sharedAnimationConfig = {
			duration: 1000,
			useNativeDriver: true,
		};
		Animated.loop(
			Animated.sequence([
				Animated.timing(pulseAnim, {
					...sharedAnimationConfig,
					toValue: 1,
					easing: Easing.out(Easing.ease),
				}),
				Animated.timing(pulseAnim, {
					...sharedAnimationConfig,
					toValue: 0,
					easing: Easing.in(Easing.ease),
				}),
			])
		).start();

		return () => {
			pulseAnim.stopAnimation();
		};
	}, []);

	const opacityAnim = pulseAnim.interpolate({
		inputRange: [0, 1],
		outputRange: [0.05, 0.15],
	});

	return (
		<Animated.View
			style={[
				styles.LoadingRect,
				{
					width: getResponsiveScaling(IMAGE_LANDSCAPE_WIDTH - 1).width,
					height: getResponsiveScaling(IMAGE_LANDSCAPE_HEIGHT - 1).width,
					borderRadius: getScaledValue(12),
				},
				{ opacity: opacityAnim },
				props.style,
			]}
		/>
	);
};

const TileImage = ({
	uri,
	style,
	setLoadedImage,
}: {
	uri: string;
	style: any;
	setLoadedImage: () => void;
}) => {
	return (
		<Image
			style={[styles.image, style]}
			onLoadEnd={() => setLoadedImage()}
			source={{ uri }}
			resizeMode="cover"
		/>
	);
};

const MemoizedTileImage = React.memo(
	TileImage,
	(p, n) => p.uri === n.uri && p.style === n.style
);

const TileTitleImage = ({
	uri,
	style,
	setLoadedTitleImage,
}: {
	uri: string;
	style: any;
	setLoadedTitleImage: () => void;
}) => {
	return (
		<Image
			onLoadEnd={() => setLoadedTitleImage()}
			style={[styles.titleImage, style]}
			source={{ uri }}
			resizeMode="contain"
		/>
	);
};

const MemoizedTileTitleImage = React.memo(
	TileTitleImage,
	(p, n) => p.uri === n.uri && p.style === n.style
);

export const Genre = ({
	repeatContext,
	genre,
	setGenre,
	focusOptions,
}: Props) => {
	const [loadedImage, setLoadedImage] = useState(false);
	const [loadedTitleImage, setLoadedTitleImage] = useState(false);

	const setImage = () => {
		setLoadedImage(true);
	};

	const setTitleImage = () => {
		setLoadedTitleImage(true);
	};

	const getTile = () => {
		if (genre.image?.listUrl && genre.titleImage?.listUrl) {
			return (
				<>
					<MemoizedTileImage
						uri={genre.image.listUrl}
						setLoadedImage={setImage}
						style={{ opacity: loadedImage && loadedTitleImage ? 1 : 0 }}
					/>
					<View style={styles.darken}></View>
					<MemoizedTileTitleImage
						uri={genre.titleImage.listUrl}
						setLoadedTitleImage={setTitleImage}
						style={{ opacity: loadedImage && loadedTitleImage ? 1 : 0 }}
					/>
					{(!loadedImage || !loadedTitleImage) && <LoadingRect />}
				</>
			);
		}
		return (
			<View
				style={[
					styles.textContainer,
					{ backgroundColor: genre.backgroundColor.hex + '80' },
				]}
			>
				<Text style={styles.text}>{genre.title.toUpperCase()}</Text>
			</View>
		);
	};

	return (
		<Pressable
			onPress={() => {
				setGenre(genre.slug);
			}}
			repeatContext={repeatContext}
			style={[styles.container]}
			focusOptions={focusOptions}
		>
			{getTile()}
		</Pressable>
	);
};

Genre.displayName = 'Genre';
