import { Player, PlayerAPI, PlayerEvent } from 'bitmovin-player';
import AbrModule from 'bitmovin-player/modules/bitmovinplayer-abr';
import Mp4Module from 'bitmovin-player/modules/bitmovinplayer-container-mp4';
import CryptoModule from 'bitmovin-player/modules/bitmovinplayer-crypto';
import DashModule from 'bitmovin-player/modules/bitmovinplayer-dash';
import BitmovinEngine from 'bitmovin-player/modules/bitmovinplayer-engine-bitmovin';
import MserendererModule from 'bitmovin-player/modules/bitmovinplayer-mserenderer';
import PolyFillModule from 'bitmovin-player/modules/bitmovinplayer-polyfill';
import StyleModule from 'bitmovin-player/modules/bitmovinplayer-style';
import TizenModule from 'bitmovin-player/modules/bitmovinplayer-tizen';
import WebOSModule from 'bitmovin-player/modules/bitmovinplayer-webos';
import XmlModule from 'bitmovin-player/modules/bitmovinplayer-xml';
import { useEffect, useRef } from 'react';
import React from 'react';

import { DEFAULT_CONFIGURATION } from '../player/player.web';
let playerInstance: PlayerAPI | null = null;

Player.addModule(TizenModule);
Player.addModule(PolyFillModule);
Player.addModule(MserendererModule);
Player.addModule(Mp4Module);
Player.addModule(BitmovinEngine);
Player.addModule(CryptoModule);
Player.addModule(DashModule);
Player.addModule(XmlModule);
Player.addModule(AbrModule);
Player.addModule(StyleModule);
Player.addModule(WebOSModule);

const BitmovinPlayer = ({ style, onEnd, media }) => {
	const playerRef = useRef(null);

	useEffect(() => {
		if (!playerRef.current) return null;

		playerInstance = new Player(playerRef.current, {
			...DEFAULT_CONFIGURATION,
		});

		if (playerInstance === null) return;

		playerInstance.on(PlayerEvent.PlaybackFinished, onEnd);

		void playerInstance.load({
			dash: media,
		});

		return () => {
			playerInstance && playerInstance.destroy();
		};
	}, [media, onEnd]);

	return (
		<div id="video-web-bitmovin">
			<div
				ref={playerRef}
				style={style}
			/>
		</div>
	);
};

export default BitmovinPlayer;
