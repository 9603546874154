import { useCallback, useEffect, useState } from 'react';
import { getDeviceId, getDeviceName } from 'react-native-device-info';

import { PlayerType } from '../contexts/PlayerContext';
import { AssetType } from '../models/AssetType';
import { BusinessType } from '../models/BusinessType';
import { useAuth } from '../providers/auth';
// Event tracker
import ClientTracker from '../services/event-tracker/ClientTracker';
import VideoEvent, {
	VideoEventAction,
	VideoEventBusinessType,
} from '../services/event-tracker/VideoEvent';

import { useDuration, useSubscriptions } from './api';
import { usePlayer } from './usePlayer';
import { useConfig } from './useConfig';

const SEND_ANALYTICS_EVENT_INTERVAL = 10;
const SEND_DEVICE_UPDATE_INTERVAL_MIN = 2;

export function useVideoAnalytics() {
	const {
		isEnd,
		asset,
		currentTime,
		playing,
		currentUrl,
		trailer,
		didSeek,
		playerType,
	} = usePlayer();
	const { token } = useAuth();
	const { activeSubscription } = useSubscriptions();
	const { updateDuration } = useDuration(asset);
	const { endpoints } = useConfig();

	const [prevTimestamp, setPrevTimestamp] = useState<number>(0);
	const [didSeekFlag, setDidSeekFlag] = useState<boolean>(false);
	const [prevCurrentUrl, setPrevCurrentUrl] = useState<string>('');
	const [prevPlaying, setPrevPlaying] = useState<boolean>(false);
	const [prevDeviceUpdate, setPrevDeviceUpdate] = useState<string>(
		new Date().toString()
	);

	const [sendStartEvent, setSendStartEvent] = useState<boolean>(false);
	const [assetId, setAssetId] = useState<string>();
	const [lastAssetChange, setLastAssetChange] = useState<number>(Date.now());

	const getVideoType = useCallback(() => {
		if (currentUrl === trailer.url) {
			return VideoEventBusinessType.TRAILER;
		} else if (asset?.asset_type === AssetType.episode) {
			return VideoEventBusinessType.PREMIUM;
		} else if (asset?.business_type === BusinessType.free) {
			return VideoEventBusinessType.FREE;
		} else if (asset?.business_type === BusinessType.premium) {
			return VideoEventBusinessType.PREMIUM;
		}
		return VideoEventBusinessType.UNKNOWN;
	}, [asset, currentUrl, trailer.url]);

	const logTimeEvent = useCallback(
		(time: number) => {
			const videoEvent = new VideoEvent();
			videoEvent.setEventAction(VideoEventAction.TIME);
			videoEvent.setBusinessType(getVideoType());
			videoEvent.setAssetId(asset!.id);
			videoEvent.setVideoPlayTimeSec(time);
			videoEvent.setVideoPosition(currentTime);
			ClientTracker.sendVideoEvent(videoEvent);
		},
		[asset, currentTime, getVideoType]
	);

	// Log time event
	useEffect(() => {
		if (prevCurrentUrl === currentUrl || currentUrl === '') return;
		setPrevCurrentUrl(currentUrl);
		setSendStartEvent(false);

		if (asset && playing && currentTime > 0) {
			logTimeEvent(Math.round(currentTime - prevTimestamp));
		}
		setDidSeekFlag(true);
	}, [
		asset,
		currentTime,
		currentUrl,
		logTimeEvent,
		playing,
		prevCurrentUrl,
		prevTimestamp,
	]);

	useEffect(() => {
		if (didSeek) setDidSeekFlag(true);
	}, [didSeek]);

	useEffect(() => {
		const eventSender = async () => {
			if (!asset) return;

			if (currentTime === 0) {
				setPrevTimestamp(0);
				return;
			}
			if (didSeekFlag) {
				setPrevTimestamp(currentTime);
				setDidSeekFlag(false);
				return;
			}

			// Log device event
			if (
				new Date() >
				new Date(
					new Date(prevDeviceUpdate).getTime() +
						SEND_DEVICE_UPDATE_INTERVAL_MIN * 60000
				)
			) {
				setPrevDeviceUpdate(new Date().toString());

				if (
					token &&
					activeSubscription?.id &&
					playerType === PlayerType.Video
				) {
					await fetch(
						`${endpoints?.['subscription-backend']}/v1/devices/update`,
						{
							method: 'POST',
							headers: {
								Authorization: `Bearer ${token}`,
								'Content-Type': 'application/json',
							},
							body: JSON.stringify({
								device_id: getDeviceId(),
								device_name: await getDeviceName(),
								subscription_id: activeSubscription?.id,
							}),
						}
					);
				}
			}

			// Log time event
			const diff = currentTime - prevTimestamp;

			let time = 0;

			if (diff >= SEND_ANALYTICS_EVENT_INTERVAL) {
				time = Math.round(diff);
			} else {
				return;
			}

			if (time === 0) {
				return;
			}

			logTimeEvent(time);
			const currentTimestamp = Date.now();

			const fourSecondsAgo = currentTimestamp - 5000;
			if (
				lastAssetChange <= fourSecondsAgo &&
				playerType === PlayerType.Video
			) {
				updateDuration({ duration: Math.floor(currentTime) });
			}
			setPrevTimestamp(currentTime);
			setDidSeekFlag(false);
		};

		eventSender();
	}, [
		activeSubscription?.id,
		asset,
		currentTime,
		didSeekFlag,
		logTimeEvent,
		playerType,
		prevDeviceUpdate,
		prevTimestamp,
		token,
		updateDuration,
	]);

	useEffect(() => {
		if (!asset) return;

		if (prevPlaying === playing && asset.id === assetId) return;
		setPrevPlaying(playing);

		if (asset.id !== assetId) {
			setLastAssetChange(Date.now());
			setAssetId(asset.id);
		}

		if (!sendStartEvent) return;

		const videoEvent = new VideoEvent();

		videoEvent.setEventAction(
			playing ? VideoEventAction.PLAY : VideoEventAction.PAUSE
		);
		const time = Math.max(0, Math.round(currentTime - prevTimestamp)) || 0;

		if (!playing) {
			setPrevTimestamp(currentTime);
			videoEvent.setVideoPlayTimeSec(time);
		}

		videoEvent.setVideoPosition(currentTime || 0);
		videoEvent.setAssetId(asset.id);

		videoEvent.setBusinessType(getVideoType());
		ClientTracker.sendVideoEvent(videoEvent);
	}, [
		asset,
		assetId,
		currentTime,
		getVideoType,
		playing,
		prevPlaying,
		prevTimestamp,
	]);

	// Player Start Event
	useEffect(() => {
		if (playing && asset && !sendStartEvent) {
			const videoEvent = new VideoEvent();

			videoEvent.setEventAction(VideoEventAction.START);
			videoEvent.setAssetId(asset.id);

			videoEvent.setBusinessType(getVideoType());
			ClientTracker.sendVideoEvent(videoEvent);

			setSendStartEvent(true);
		}
	}, [asset, getVideoType, isEnd, playing, sendStartEvent]);

	// Player Finish Event
	useEffect(() => {
		if (isEnd && asset) {
			const videoEvent = new VideoEvent();
			videoEvent.setEventAction(VideoEventAction.COMPLETE);
			videoEvent.setAssetId(asset.id);
			videoEvent.setBusinessType(getVideoType());
			ClientTracker.sendVideoEvent(videoEvent);
		}
	}, [asset, getVideoType, isEnd]);
}
