import { ANIMATION_TYPES } from '@flexn/sdk';
import {
	useCombinedRefs,
	usePrevious,
} from '@flexn/sdk/lib/focusManager/helpers';
import { measure } from '@flexn/sdk/lib/focusManager/layoutManager';
import CoreManager from '@flexn/sdk/lib/focusManager/model/core';
import Screen from '@flexn/sdk/lib/focusManager/model/screen';
import ViewClass from '@flexn/sdk/lib/focusManager/model/view';
import type { ViewProps } from '@flexn/sdk/lib/focusManager/types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { View as RNView, Animated, StyleSheet } from 'react-native';
import UserInterActionEvent, {
	USER_INTERACTION_CONTEXT,
	USER_INTERACTION_FEATURES,
	USER_INTERACTION_TYPE,
} from '../../services/event-tracker/UserInterActionEvent';
import ClientTracker from '../../services/event-tracker/ClientTracker';
import { isTizen, isWebos } from '@rnv/renative';

export const defaultAnimation = {
	type: 'scale',
	scale: 1.1,
};

type Props = {
	track?: {
		feature: USER_INTERACTION_FEATURES;
		context: USER_INTERACTION_CONTEXT;
		type: USER_INTERACTION_TYPE;
		name: string;
		body?: any;
	};
} & ViewProps;

const View = React.forwardRef<any, Props>(
	(
		{
			children,
			style,
			focus = true,
			focusOptions = {},
			parentContext,
			repeatContext,
			onPress = () => {
				return null;
			},
			onFocus = () => {
				return null;
			},
			onBlur = () => {
				return null;
			},
			track = null,
			...props
		},
		refOuter
	) => {
		const scaleAnim = useRef(new Animated.Value(1)).current;
		const refInner = useRef(null);
		const ref = useCombinedRefs(refOuter, refInner);
		const prevFocus = usePrevious(focus);
		const pctx = repeatContext?.parentContext || parentContext;
		const animatorOptions = focusOptions.animatorOptions || defaultAnimation;
		const flattenedStyle = StyleSheet.flatten(style);

		const [ViewInstance, setViewInstance] = useState(() => {
			if (!focus) {
				return pctx;
			} else {
				console.log(ViewClass);

				return new ViewClass({
					focus,
					repeatContext,
					parent: pctx,
					...focusOptions,
					onFocus: onComponentFocus,
					onBlur: onComponentBlur,
				});
			}
		});

		const onComponentFocus = () => {
			console.log('--- onComponentFocus');
			if (!ref.current) return;

			switch (animatorOptions.type) {
				case ANIMATION_TYPES.SCALE:
					Animated.timing(scaleAnim, {
						toValue: animatorOptions.scale,
						duration: 200,
						useNativeDriver: true,
					}).start();
					break;
				case ANIMATION_TYPES.SCALE_BORDER:
					Animated.timing(scaleAnim, {
						toValue: animatorOptions.scale,
						duration: 200,
						useNativeDriver: true,
					}).start();
					ref.current.style.border = `4px solid ${
						flattenedStyle?.borderColor?.toString() || '#FFFFFF'
					}`;
					break;
				case ANIMATION_TYPES.BORDER:
					ref.current.style.border = `4px solid ${
						flattenedStyle?.borderColor?.toString() || '#FFFFFF'
					}`;
					break;
				case ANIMATION_TYPES.BACKGROUND:
					!isWebos() && !isTizen()
						? ref.current?.setNativeProps?.({
								style: {
									backgroundColor: animatorOptions.backgroundColorFocus,
								},
						  })
						: (ref.current.style.backgroundColor =
								animatorOptions.backgroundColorFocus);
					break;
				default:
					break;
			}

			onFocus();
		};

		const onComponentBlur = () => {
			if (!ref.current) return;

			switch (animatorOptions.type) {
				case ANIMATION_TYPES.SCALE:
					Animated.timing(scaleAnim, {
						toValue: 1,
						duration: 200,
						useNativeDriver: true,
					}).start();
					break;
				case ANIMATION_TYPES.SCALE_BORDER:
					Animated.timing(scaleAnim, {
						toValue: 1,
						duration: 200,
						useNativeDriver: true,
					}).start();
					ref.current.style.border = `0px solid ${
						flattenedStyle?.borderColor?.toString() || '#FFFFFF'
					}`;
					break;
				case ANIMATION_TYPES.BORDER:
					ref.current.style.border = `0px solid ${
						flattenedStyle?.borderColor?.toString() || '#FFFFFF'
					}`;
					break;
				case ANIMATION_TYPES.BACKGROUND:
					!isWebos() && !isTizen()
						? ref.current?.setNativeProps?.({
								style: {
									backgroundColor: flattenedStyle?.backgroundColor,
								},
						  })
						: (ref.current.style.backgroundColor =
								flattenedStyle?.backgroundColor);
					break;
				default:
					break;
			}

			onBlur();
		};

		// We must re-assign repeat context as View instances are re-used in recycled
		if (repeatContext) {
			ViewInstance.setRepeatContext(repeatContext);
		}

		const onPressFC = useCallback(
			(e) => {
				if (track) {
					const userInterActionEvent = new UserInterActionEvent();
					userInterActionEvent.setFeature(track.feature);
					userInterActionEvent.setContext(track.context);
					userInterActionEvent.setType(track.type);
					userInterActionEvent.setName(track.name);

					if (track.body) {
						userInterActionEvent.setBody(track.body);
					}

					ClientTracker.sendUserInterActionEvent(userInterActionEvent);
				}

				onPress && onPress(e);
			},
			[onPress, track]
		);

		useEffect(() => {
			if (focus) {
				scaleAnim.addListener(({ value }) => {
					if (ref.current) {
						!isWebos()
							? ref.current?.setNativeProps?.({
									style: {
										transform: [{ scale: value }],
									},
							  })
							: (ref.current.style.transform = `scale(${value})`);
					}
				});
			}

			return () => {
				scaleAnim.removeAllListeners();
			};
		}, [focus]);

		useEffect(() => {
			// If item initially was not focusable, but during the time it became focusable we capturing that here
			if (prevFocus === false && focus === true) {
				const cls = new ViewClass({
					focus: true,
					repeatContext,
					parent: pctx,
					forbiddenFocusDirections: focusOptions.forbiddenFocusDirections,
				});

				setViewInstance(cls);
				CoreManager.registerFocusable(cls, ref);
			}
		}, [focus]);

		useEffect(() => {
			(ViewInstance as ViewClass)?.updateEvents?.({
				onPress: onPressFC,
				onFocus: onComponentFocus,
				onBlur: onComponentBlur,
				...focusOptions,
			});
		}, [onPressFC, onFocus, onBlur, focusOptions]);

		useEffect(() => {
			if (focus) {
				CoreManager.registerFocusable(ViewInstance, ref);
				const screen = ViewInstance.getScreen() as Screen;
				if (screen) {
					screen.addComponentToPendingLayoutMap(ViewInstance.getId());
					if (ViewInstance.hasPreferredFocus())
						screen.setPreferredFocus(ViewInstance);
				}
			}

			return () => {
				if (focus) {
					CoreManager.removeFocusable(ViewInstance);
					ViewInstance.getScreen()?.onViewRemoved(ViewInstance);
				}
			};
		}, []);

		const childrenWithProps = React.Children.map(children, (child) => {
			if (React.isValidElement(child)) {
				return React.cloneElement(child as React.ReactElement, {
					parentContext: ViewInstance,
				});
			}

			return child;
		});

		const onLayout = () => {
			measure(ViewInstance, ref, undefined, () => {
				ViewInstance.getScreen()?.removeComponentFromPendingLayoutMap(
					ViewInstance.getId()
				);
			});
		};

		// In recycled mode we must re-measure on render
		if (repeatContext && ref.current) {
			measure(ViewInstance, ref);
		}

		if (focus) {
			return (
				<a
					onClick={(e) => onPress(e)}
					onMouseEnter={(e) => onFocus(e)}
					onMouseLeave={(e) => onBlur(e)}
					style={{ cursor: 'pointer' }}
				>
					<RNView
						style={style}
						onLayout={onLayout}
						{...props}
						ref={ref}
					>
						{childrenWithProps}
					</RNView>
				</a>
			);
		}

		return (
			<RNView
				style={style}
				{...props}
				ref={ref}
			>
				{childrenWithProps}
			</RNView>
		);
	}
);

View.displayName = 'View';

export default View;
