import {
	ForbiddenFocusDirections,
	PressableFocusOptions,
} from '@flexn/sdk/lib/focusManager/types';
import { getResponsiveScaling, getScaledValue } from '../../utils/scale';
import React, { ComponentProps } from 'react';
import { ActivityIndicator, StyleSheet, Text, View } from 'react-native';

import colors from '../../../platformAssets/runtime/colors';
import fontnames from '../../../platformAssets/runtime/fontnames';
import Pressable from '../pressable';
import { ANIMATION_TYPES } from '@flexn/sdk';

const styles = StyleSheet.create({
	button: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingHorizontal: getScaledValue(12),
		borderRadius: getScaledValue(8),
		backgroundColor: '#000000',
		overflow: 'hidden',
		opacity: 0.8,
	},
	withBorder: {
		borderWidth: 2,
		borderColor: colors.primary,
		width: getScaledValue(200),
	},
	buttonText: {
		flex: 1,
		marginLeft: getScaledValue(4),
		marginRight: getScaledValue(12),
		marginTop: getScaledValue(12),
		marginBottom: getScaledValue(10),
		fontSize: getResponsiveScaling(1.5).width,
		fontFamily: fontnames.secondarySemiBold,
		color: 'white',
		height: getResponsiveScaling(2).width,
	},

	focusedButton: {
		backgroundColor: colors.primary,
	},
	leftIconContainer: {
		// backgroundColor: 'green',
	},
	rightIconContainer: {
		// backgroundColor: 'green',
	},
});

// switch out forbiddenFocusDirections with our extended version
type PressableProps = Omit<ComponentProps<typeof Pressable>, 'focusOptions'>;
type FocusOptions = Omit<PressableFocusOptions, 'forbiddenFocusDirections'>;
type ButtonForbiddenFocusDirections = ForbiddenFocusDirections | 'scroll';
type ButtonFocusOptions = FocusOptions & {
	forbiddenFocusDirections?: ButtonForbiddenFocusDirections[];
};
type ButtonProps = {
	parentContext?: any;
	label?: string;
	leftIcon?: React.ReactNode;
	rightIcon?: React.ReactNode;
	focused?: boolean;
	focusOptions?: ButtonFocusOptions;
	centeredText?: boolean;
	loadingPercentage?: number;
	processing?: boolean;
	exitFlow?: boolean;
	withBorder?: boolean;
} & PressableProps;

const Button: React.FC<ButtonProps> = ({
	parentContext,
	children,
	label,
	style = {},
	leftIcon,
	rightIcon,
	focused,
	centeredText,
	focusOptions = {},
	loadingPercentage,
	processing = false,
	exitFlow = false,
	withBorder = false,
	...props
}) => {
	let styling;

	if (Array.isArray(style)) {
		styling = [styles.button, withBorder && styles.withBorder, ...style];
	} else {
		styling = [styles.button, withBorder && styles.withBorder, style];
	}

	return (
		<>
			<Pressable
				parentContext={parentContext}
				style={styling}
				activeOpacity={1}
				focusOptions={
					{
						animatorOptions: {
							type: ANIMATION_TYPES.BACKGROUND,
							backgroundColorFocus: colors.primary, // Required. Color which will be applied when element gains focus
							duration: 50, // Optional. How long time border transition should take
						},
						...focusOptions,
					} as PressableFocusOptions // this is needed as 'scroll' isn't accepted by Flexn as a valid value
				}
				{...props}
			>
				{loadingPercentage && loadingPercentage > 0 && (
					<View
						style={{
							position: 'absolute',
							right: 0,
							left: 0,
							top: 0,
							bottom: 0,
							borderRadius: getScaledValue(8),
						}}
					>
						<View
							style={{
								backgroundColor: '#FFFFFF',
								height: '100%',
								width: `${loadingPercentage > 100 ? 100 : loadingPercentage}%`,
								borderBottomLeftRadius: getScaledValue(8),
								borderTopLeftRadius: getScaledValue(8),
								opacity: 0.3,
							}}
						/>
					</View>
				)}
				<>
					<View style={styles.leftIconContainer}>{leftIcon}</View>
					{label && (
						<Text
							style={[
								styles.buttonText,
								centeredText && {
									textAlign: 'center',
									marginRight: getScaledValue(4),
								},
							]}
							numberOfLines={1}
						>
							{processing ? <ActivityIndicator color="white" /> : label}
						</Text>
					)}
					{children}
					<View style={styles.rightIconContainer}>{rightIcon}</View>
				</>
			</Pressable>
		</>
	);
};

Button.displayName = 'Button';

export default Button;
