import { PlayerAPI } from 'bitmovin-player';
import React, {
	createContext,
	useState,
	useRef,
	ReactNode,
	Dispatch,
	SetStateAction,
	useEffect,
	useCallback,
} from 'react';

import usePrevious from '../hooks/usePrevious';
import { useVideo } from '../hooks/useVideo';
import { AssetType } from '../models/AssetType';
import { EpisodeDetails, MovieDetails } from '../models/Details';
import { EntitlementProvider } from '../models/EntitlementProvider';
import { AudioTrack } from 'bitmovin-player-react-native';

type Props = {
	children?: ReactNode;
};

export type Subtitle = {
	index?: number;
	title: string;
	language?: string;
	lang?: string;
	type: string;
	id?: string;
};

export type Subtitles = Array<Subtitle>;

export enum PlayerType {
	Video,
	Trailer,
}

const PlayerContext = createContext<
	| {
			player: any;
			playerWeb: PlayerAPI | null;
			setPlayerWeb: Dispatch<SetStateAction<PlayerAPI | null>>;
			showPlayer: boolean;
			setShowPlayer: Dispatch<SetStateAction<boolean>>;
			currentUrl: string;
			setCurrentUrl: Dispatch<SetStateAction<string>>;
			duration: number | undefined;
			setDuration: Dispatch<SetStateAction<number | undefined>>;
			seekTime: number | null;
			setSeekTime: Dispatch<SetStateAction<number | null>>;
			currentTime: number;
			setCurrentTime: Dispatch<SetStateAction<number>>;
			didSeek: {};
			fireDidSeek: Dispatch<SetStateAction<{}>>;
			buffering: boolean;
			setBuffering: Dispatch<SetStateAction<boolean>>;
			playing: boolean;
			setPlaying: Dispatch<SetStateAction<boolean>>;
			isEnd: boolean;
			setIsEnd: Dispatch<SetStateAction<boolean>>;
			showControls: boolean;
			setShowControls: Dispatch<SetStateAction<boolean>>;
			showDetails: boolean;
			setShowDetails: Dispatch<SetStateAction<boolean>>;
			showSkipIntro: boolean;
			setShowSkipIntro: Dispatch<SetStateAction<boolean>>;
			showNextEpisode: boolean;
			setShowNextEpisode: Dispatch<SetStateAction<boolean>>;
			playpause: () => void;
			asset: MovieDetails | EpisodeDetails | undefined;
			setAsset: Dispatch<
				SetStateAction<MovieDetails | EpisodeDetails | undefined>
			>;
			subtitles: Subtitles;
			setSubtitles: Dispatch<SetStateAction<Subtitles>>;
			selectedSubtitle: string;
			setSelectedSubtitle: Dispatch<SetStateAction<string>>;

			playerType: PlayerType;
			setPlayerType: Dispatch<SetStateAction<PlayerType>>;
			trailer: { url: string; drmToken: string };
			video: { url: string; drmToken: string; subscriptionNotFound: boolean };
			startOver: boolean;
			setStartOver: Dispatch<SetStateAction<boolean>>;
			clearPlayerState: () => void;

			audioTracks: AudioTrack[] | undefined;
			setAudioTracks: Dispatch<SetStateAction<AudioTrack[] | undefined>>;
			selectedAudioTrack: string;
			setSelectedAudioTrack: Dispatch<SetStateAction<string>>;
			showAudioDialog: boolean;
			setShowAudioDialog: Dispatch<SetStateAction<boolean>>;

			showSettingsDialog: boolean;
			setShowSettingsDialog: Dispatch<SetStateAction<boolean>>;
	  }
	| undefined
>(undefined);

const PlayerProvider = ({ children }: Props) => {
	const player = useRef<Video>(null);
	const [playerWeb, setPlayerWeb] = useState<PlayerAPI | null>(null);
	const [showPlayer, setShowPlayer] = useState<boolean>(false);

	// Player state
	const [currentUrl, setCurrentUrl] = useState('');
	const [duration, setDuration] = useState<number>();
	const [seekTime, setSeekTime] = useState<number | null>(0);
	const [currentTime, setCurrentTime] = useState<number>(0);
	const [playing, setPlaying] = useState(false);
	const [playerType, setPlayerType] = useState<PlayerType>(PlayerType.Trailer);
	const [isEnd, setIsEnd] = useState<boolean>(false);
	const [didSeek, fireDidSeek] = useState({});
	const [buffering, setBuffering] = useState<boolean>(false);
	const [startOver, setStartOver] = useState<boolean>(false);

	// Subtile state
	const [subtitles, setSubtitles] = useState<Subtitles>([]);
	const [selectedSubtitle, setSelectedSubtitle] = useState<string>('');

	// Audio state
	const [audioTracks, setAudioTracks] = useState<AudioTrack[]>();
	const [selectedAudioTrack, setSelectedAudioTrack] = useState<string>('en');
	const [showAudioDialog, setShowAudioDialog] = useState<boolean>(false);

	// UI state
	const [showControls, setShowControls] = useState(false);
	const [showDetails, setShowDetails] = useState(true);
	const [showSkipIntro, setShowSkipIntro] = useState(false);
	const [showNextEpisode, setShowNextEpisode] = useState(false);
	const [showSettingsDialog, setShowSettingsDialog] = useState<boolean>(false);

	// Asset state
	const [asset, setAsset] = useState<MovieDetails | EpisodeDetails>();
	const prevAsset = usePrevious(asset);

	// Video instances
	const trailer = useVideo(asset, EntitlementProvider.trailer);
	const video = useVideo(asset, EntitlementProvider.internal);

	const playpause = () => setPlaying(!playing);

	const clearState = useCallback(() => {
		setCurrentUrl('');
		setSeekTime(0);
		setCurrentTime(0);
		setPlaying(false);
		setShowControls(false);
		setShowDetails(true);
		setIsEnd(false);
		setPlayerType(PlayerType.Trailer);
		setShowPlayer(false);
		setShowSkipIntro(false);
		setShowNextEpisode(false);
		setStartOver(false);
	}, []);

	const clearPlayerState = useCallback(() => {
		setSeekTime(0);
		setCurrentTime(0);
		setPlaying(true);
		setShowSkipIntro(false);
		setShowNextEpisode(false);
		setBuffering(false);
		setDuration(0);
		setShowControls(false);
	}, []);

	//Asset change
	useEffect(() => {
		if (
			asset?.id !== prevAsset?.id &&
			asset?.asset_type !== AssetType.episode
		) {
			console.log('clear asset');
			clearState();
		}

		if (!asset) {
			console.log('clear asset');
			clearState();
		}
	}, [asset, clearState, prevAsset?.id]);

	return (
		<PlayerContext.Provider
			value={{
				player,
				showPlayer,
				setShowPlayer,
				currentUrl,
				setCurrentUrl,
				duration,
				setDuration,
				seekTime,
				setSeekTime,
				currentTime,
				setCurrentTime,
				didSeek,
				fireDidSeek,
				buffering,
				setBuffering,
				playing,
				setPlaying,
				isEnd,
				setIsEnd,
				showControls,
				setShowControls,
				showDetails,
				setShowDetails,
				showSkipIntro,
				setShowSkipIntro,
				showNextEpisode,
				setShowNextEpisode,
				playpause,
				asset,
				setAsset,
				subtitles,
				setSubtitles,
				selectedSubtitle,
				setSelectedSubtitle,

				playerType,
				setPlayerType,
				trailer,
				video,
				startOver,
				setStartOver,
				playerWeb,
				setPlayerWeb,
				clearPlayerState,

				audioTracks,
				setAudioTracks,
				selectedAudioTrack,
				setSelectedAudioTrack,
				showAudioDialog,
				setShowAudioDialog,

				showSettingsDialog,
				setShowSettingsDialog,
			}}
		>
			{children}
		</PlayerContext.Provider>
	);
};

export { PlayerContext, PlayerProvider };
