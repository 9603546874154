import * as Sentry from '@sentry/browser';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import { isAfter, parseISO } from 'date-fns';

import config from '../../platformAssets/runtime/config';
import { Asset, Collection, Season, ShelfAsset, TVShow } from '../models/Asset';
import { AssetType, assetTypeMap } from '../models/AssetType';
import { CollectionResponse } from '../models/Collection';
import {
	AssetDetails,
	Details,
	hasValidLicense,
	TVShowDetails,
} from '../models/Details';
import { Favorite } from '../models/Favorites';
import { History, HistoryResponse } from '../models/History';
import { Subscription, SubscriptionState } from '../models/Subscription';
import { useAuth } from '../providers/auth';

import { Genre, getImages, makeShelfAsset } from './shelves';
import { useI18n } from './useI18n';

export function useCollection({ id }: { id: string }) {
	console.log('useCollection', id);

	const { countryCode, language: countryLanguage } = useI18n();

	const country = countryCode?.toUpperCase() || 'NL';
	const language = countryLanguage || 'NL';

	return useQuery<CollectionResponse>(
		['collection', id],
		async () => {
			const url = `${config.catalogServiceUrl}/v1/collection/${id}?country=${country}&translation=${language}`;
			const res = await fetch(url);

			console.log('useCollection fetching', url);

			if (!res.ok) {
				Sentry.captureMessage(`Failed to fetch collection ${id}`);
				throw new Error('Failed to fetch collection', {
					cause: res.statusText,
				});
			}

			return res.json();
		},
		{
			enabled: true,
			refetchInterval: 86400000,
			refetchIntervalInBackground: true,
		}
	);
}

export function useMenu({ id }: { id: string }) {
	console.log('useMenu', id);

	const { data: menuCollection, isLoading } = useCollection({
		id,
	});

	return {
		isLoading,
		data: menuCollection?.extended?.custom?.menu
			? menuCollection?.extended?.custom?.menu?.filter(
					(m) => m.href && m?.type === 'collection'
			  )
			: ([] as Array<any>),
	};
}

export function useFavoritesCollection() {
	const { data, isLoading, error } = useFavorites();
	const { translate } = useI18n();

	const collection: Collection = {
		title: translate('shelves.favorites'),
		id: 'favorites',
		shelfId: 'favorites',
		asset_type: AssetType.collection,
		items: data ? data : [],
		tags: [],
		cover_image: '',
		list_image: '',
		image: {
			cover: '',
			clean_cover: '',
			list: '',
		},
		description: '',
		short_description: '',
	};

	return {
		data: collection,
		isLoading,
		error,
	};
}

export function useWatchHistoryCollection() {
	const { history, isLoading, error } = useWatchHistory();
	const { translate } = useI18n();

	const collection: Collection = {
		title: translate('shelves.continuewatching'),
		id: 'watch-history',
		shelfId: 'watch-history',
		asset_type: AssetType.collection,
		items: history ? history : [],
		tags: [],
		cover_image: '',
		list_image: '',
		image: {
			cover: '',
			clean_cover: '',
			list: '',
		},
		description: '',
		short_description: '',
	};

	return {
		data: collection,
		isLoading,
		error,
	};
}

export function useCurrentEpisode(tvshow: TVShow) {
	const client = useQueryClient();
	const { profile } = useAuth();

	const { data: episodes, status } = useQuery<ShelfAsset[] | undefined>(
		['tvshow-current-episode', profile?.id, tvshow.id],
		async () => {
			const eps = client.getQueryData<ShelfAsset>([
				'tvshow-current-episode',
				profile?.id,
				tvshow.id,
			]);

			if (!eps) {
				const firstSeason = client.getQueryData<Season>([
					'season',
					tvshow.seasons[0].id,
				]);

				if (!firstSeason) {
					throw new Error('Could not find first season of tvshow');
				}

				return firstSeason.episodes;
			}

			return eps;
		}
	);

	return episodes?.[0];
}

const historyKey = 'watch-history';
const historyShelfAssetsKey = 'watch-history-shelf-assets';

export function useDuration(
	asset: ShelfAsset | AssetDetails | null | undefined
) {
	const client = useQueryClient();
	const { token, profile } = useAuth();

	const { data: history } = useQuery<History | undefined>(
		[historyKey, profile?.id, asset?.id],
		async () => {
			return client.getQueryData<History>([historyKey, profile?.id, asset?.id]);
		},
		{
			enabled: !!asset,
		}
	);

	const updateDuration = useMutation(
		async ({ duration }: { duration: number }) => {
			if (!asset) return;

			const deleteReq = await fetch(
				`${config.userServiceUrl}/v1/watchhistory?id=${asset.id}&asset_type=${asset.asset_type}`,
				{
					method: 'DELETE',
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				}
			);

			if (!deleteReq.ok) {
				const { message } = await deleteReq.json();
				Sentry.captureMessage(`Deleting watch history item failed ${asset.id}`);
				throw new Error('Deleting watch history item failed. ' + message);
			}

			const now = new Date();
			const historyPayload = {
				id: asset.id,
				asset_type: assetTypeMap[asset.asset_type],
				duration: Math.floor(duration),
				date: now.toISOString(),
			};

			const addReq = await fetch(`${config.userServiceUrl}/v1/watchhistory`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(historyPayload),
			});

			if (!addReq.ok) {
				const { message } = await addReq.json();
				Sentry.captureMessage(`Adding watch history item failed ${asset.id}`);
				throw new Error('Adding watch history item failed. ' + message);
			}

			return historyPayload;
		},
		{
			onSuccess: (history) => {
				console.log('updateDuration onSuccess', history);

				if (history) {
					console.log('> set history', history.id);

					client.setQueryData([historyKey, profile?.id, history.id], history);
				}

				// update the tvshow current episode list
				if (asset && asset.asset_type === AssetType.episode) {
					client.setQueryData<any[]>(
						['tvshow-current-episode', profile?.id, asset.tvshow.id],
						(episodes) => (episodes ? [asset, ...episodes] : [asset])
					);
				}
			},
		}
	);

	return {
		duration: history?.duration || 0,
		progress:
			asset && history
				? Math.floor((history.duration / asset.duration) * 100)
				: 0,
		updateDuration: updateDuration.mutateAsync,
	};
}

export function useWatchHistory() {
	const client = useQueryClient();
	const { profile, token } = useAuth();
	const { countryCode, language: countryLanguage } = useI18n();

	const country = countryCode?.toUpperCase() || 'NL';
	const language = countryLanguage || 'NL';

	const reloadWatchHistory = () => {
		setTimeout(() => {
			client.invalidateQueries([historyShelfAssetsKey, profile?.id]);
		}, 2000);
	};

	const {
		data: history,
		isLoading,
		error,
	} = useQuery<(Details | TVShow)[]>(
		[historyShelfAssetsKey, profile?.id],
		async () => {
			if (!token || !profile?.id) return [];

			console.log(
				'fetching watch history',
				`${config.userServiceUrl}/v1/watchhistory`,
				token
			);

			const res = await fetch(`${config.userServiceUrl}/v1/watchhistory`, {
				method: 'GET',
				headers: {
					Authorization: token ? `Bearer ${token}` : '',
					'Content-Type': 'application/json',
				},
			});

			if (!res.ok) {
				const { message } = await res.json();
				Sentry.captureMessage(`Loading watch history failed ${profile?.id}`);
				throw new Error(message || 'Loading watch history failed.');
			}

			const historyIds: HistoryResponse = await res.json();
			const history = await Promise.all<Promise<Details | undefined>[]>(
				historyIds.slice(0, 20).map(async (history) => {
					const { id, asset_type } = history;

					const url = `${config.catalogServiceUrl}/v1/${assetTypeMap[asset_type]}/${id}?country=${country}&translation=${language}`;
					const res = await fetch(url);

					if (res.ok) {
						let resultAsset;
						const asset: Details = await res.json();

						if (asset.asset_type === AssetType.episode) {
							const tvshowUrl = `${config.catalogServiceUrl}/v1/tvshow/${asset.tvshow.id}?country=${country}&translation=${language}`;
							const tvshowRes = await fetch(tvshowUrl);

							if (tvshowRes.ok) {
								const tvshowAsset: Details = await tvshowRes.json();

								client.setQueryData<any>(
									['tvshow-current-episode', profile?.id, asset.tvshow.id],
									(episodes) => (episodes ? [...episodes, asset] : [asset])
								);

								resultAsset = makeShelfAsset(tvshowAsset, true);
							}
						} else {
							resultAsset = makeShelfAsset(asset, true);
						}

						resultAsset.shelfId = 'watch-history';

						client.setQueryData(
							['assets', 'detail', resultAsset.id],
							resultAsset
						);
						client.setQueryData([historyKey, profile?.id, id], history);

						return resultAsset;
					}
				})
			);

			const now = new Date();
			const uniqueAssetIdMap = {};

			return history.filter((historyAsset) => {
				if (historyAsset === undefined) {
					return false;
				}

				if (!hasValidLicense(historyAsset, country, now)) {
					return false;
				}

				// filter duplicate tvshows
				if (uniqueAssetIdMap[historyAsset.id]) {
					return false;
				}

				uniqueAssetIdMap[historyAsset.id] = historyAsset.id;

				return true;
			}) as Details[];
		}
	);

	return {
		history,
		isLoading,
		error,
		reloadWatchHistory,
	};
}

export function useSubscriptions() {
	const { user, token } = useAuth();

	const { data: subscriptions, refetch: refreshSubscriptions } = useQuery<
		Subscription[]
	>(['subscription', user?.id], async () => {
		if (!token) return null;

		const res = await fetch(
			`${config.subscriptionServiceUrl}/v1/subscription`,
			{
				method: 'GET',
				headers: {
					Authorization: token ? `Bearer ${token}` : '',
					'Content-Type': 'application/json',
				},
			}
		);

		if (!res.ok) {
			const { message } = await res.json();
			Sentry.captureMessage(`Loading subscription failed. ${user?.id}`);
			throw new Error(message || 'Loading subscription failed.');
		}

		return res.json();
	});

	const activeSubscription = () => {
		return subscriptions?.find((subscription: Subscription) => {
			if (subscription.state === SubscriptionState.PAUSED) {
				return true;
			}

			if (!subscription.subscription_end) {
				return false;
			}

			const date = new Date(subscription.subscription_end);
			// Set date a day after subscription end since the last day is inclusive
			date.setDate(date.getDate() + 1);
			const now = new Date();

			return date > now;
		});
	};

	const reactivatableSubscription = () => {
		return subscriptions?.reduce((acc: any, sub: Subscription) => {
			const { can_be_reactivated, subscription_end } = sub || {};

			if (
				can_be_reactivated &&
				subscription_end &&
				(!acc ||
					isAfter(
						parseISO(subscription_end?.toString()),
						parseISO(acc?.subscription_end.toString())
					))
			) {
				return sub;
			}

			return acc;
		}, null);
	};

	const reactivateSubscription = async (subscription: Subscription) => {
		const res = await fetch(
			`${config.subscriptionServiceUrl}/v1/subscription/${subscription.id}/reactivate`,
			{
				method: 'GET',
				headers: {
					Authorization: token ? `Bearer ${token}` : '',
					'Content-Type': 'application/json',
				},
			}
		);

		if (!res.ok) {
			const { message } = await res.json();
			throw new Error(message || 'Reactivate failed.');
		}

		await refreshSubscriptions();

		return res.json();
	};

	return {
		subscriptions,
		activeSubscription: activeSubscription(),
		reactivatableSubscription: reactivatableSubscription(),
		reactivateSubscription,
	};
}

export function useFavorites() {
	const client = useQueryClient();
	const { profile, token } = useAuth();
	const { countryCode, language: countryLanguage } = useI18n();

	const country = countryCode?.toLocaleLowerCase() || 'nl';
	const language = countryLanguage || 'NL';
	console.log('|||| useFavorites', profile?.id);

	return useQuery<Details[]>(
		['favorites', profile?.id],
		async () => {
			console.log('|||| useQuery');

			if (!token || !profile?.id) return [];

			const res = await fetch(`${config.userServiceUrl}/v1/favorites`, {
				method: 'GET',
				headers: {
					Authorization: token ? `Bearer ${token}` : '',
					'Content-Type': 'application/json',
				},
			});

			console.log('Fetching favorites from server');

			if (!res.ok) {
				const { message } = await res.json();
				Sentry.captureMessage(`Loading favorites failed. ${profile?.id}`);
				throw new Error(message || 'Loading favorites failed.');
			}

			const favoriteIds: Favorite[] = await res.json();

			const favorites = await Promise.all(
				favoriteIds.map(async (favorite) => {
					const { id, asset_type } = favorite;
					const url = `${config.catalogServiceUrl}/v1/${assetTypeMap[asset_type]}/${id}?country=${country}&translation=${language}`;
					const res = await fetch(url);

					if (res.ok) {
						let resultAsset;
						const asset: Details = await res.json();

						if (asset.asset_type === AssetType.episode) {
							const tvshowUrl = `${config.catalogServiceUrl}/v1/tvshow/${asset.tvshow.id}?country=${country}&translation=${language}`;
							const tvshowRes = await fetch(tvshowUrl);

							if (tvshowRes.ok) {
								const tvshowAsset: Details = await tvshowRes.json();

								resultAsset = makeShelfAsset(tvshowAsset);
							}
						} else {
							resultAsset = makeShelfAsset(asset);
						}

						resultAsset.shelfId = 'favorites';

						client.setQueryData(
							['assets', 'detail', resultAsset.id],
							resultAsset
						);

						client.setQueryData(
							['favorites', profile?.id, resultAsset.id],
							favorite
						);

						return resultAsset;
					}
				})
			);

			const now = new Date();
			const uniqueAssetIdMap = {};

			return favorites
				.filter((favoriteAsset) => {
					if (favoriteAsset === undefined) {
						return false;
					}

					if (!hasValidLicense(favoriteAsset, country, now)) {
						return false;
					}

					// filter duplicate tvshows
					if (uniqueAssetIdMap[favoriteAsset.id]) {
						return false;
					}

					uniqueAssetIdMap[favoriteAsset.id] = favoriteAsset.id;

					return true;
				})
				.reverse() as Details[];
		},
		{
			// enabled: !!profile?.id,
		}
	);
}

export function useToggleFavorite() {
	const queryClient = useQueryClient();
	const { profile, token } = useAuth();
	const { data: favorites } = useFavorites();
	const [loading, setLoading] = useState(false);

	const hasFavorite = (asset: ShelfAsset | Details) => {
		return !!favorites?.find((fav) => {
			return fav.id === asset.id;
		});
	};

	const removeFavorite = useMutation(
		async (asset: ShelfAsset | Details) => {
			setLoading(true);

			const favorite = queryClient.getQueryData<Favorite>([
				'favorites',
				profile?.id,
				asset.id,
			]);

			if (!favorite) {
				throw new Error('Local client favorite not found.');
			}

			const res = await fetch(
				`${config.userServiceUrl}/v1/favorites?id=${favorite.id}&asset_type=${favorite.asset_type}`,
				{
					method: 'DELETE',
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				}
			);

			setLoading(false);

			if (!res.ok) {
				const { message } = await res.json();
				Sentry.captureMessage(`Removing favorite failed. ${asset.id}`);
				throw new Error('Removing favorite failed.', { cause: message });
			}

			return res;
		},
		{
			onSettled: (res, error, asset) => {
				console.log('Favorite removed', asset);

				queryClient.invalidateQueries(['favorites', profile?.id]);
			},
		}
	);

	const addFavorite = useMutation(
		async (asset: ShelfAsset | AssetDetails) => {
			setLoading(true);

			const res = await fetch(`${config.userServiceUrl}/v1/favorites`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					id: asset.id,
					asset_type: assetTypeMap[asset.asset_type],
				}),
			});

			setLoading(false);

			if (!res.ok) {
				const { message } = await res.json();
				throw new Error('Adding favorite failed.', { cause: message });
			}

			return res.json();
		},
		{
			onSettled: (result, error, asset) => {
				if (error) {
					console.error(error);
					return;
				}

				console.log('Favorite added', asset, result);
				queryClient.invalidateQueries(['favorites', profile?.id]);
			},
		}
	);

	return {
		hasFavorite,
		addFavorite: addFavorite.mutate,
		removeFavorite: removeFavorite.mutateAsync,
		loading,
	};
}

export function useArrayMemo(array: any[]) {
	const ref = useRef<any[]>();
	const areArraysConsideredTheSame =
		ref.current && array.length === ref.current.length
			? array.every((element, i) => {
					return element === ref.current?.[i];
			  })
			: false;
	useEffect(() => {
		if (!areArraysConsideredTheSame) {
			ref.current = array;
		}
	}, [areArraysConsideredTheSame, array]);
	return areArraysConsideredTheSame ? ref.current : array;
}

export function useSeason({ id }) {
	const { countryCode, language: countryLanguage } = useI18n();

	const country = countryCode?.toLocaleLowerCase() || 'nl';
	const language = countryLanguage || 'NL';

	return useQuery(['season', id], async () => {
		const res = await fetch(
			`${config.catalogServiceUrl}/v1/season/${id}?country=${country}&translation=${language}`
		);

		if (!res.ok) {
			const { message } = await res.json();
			Sentry.captureMessage(
				`Fetching season failed: ${id} ${country} ${language}`
			);
			throw new Error(message || 'Fetching season failed.');
		}

		return res.json();
	});
}

export function useSeasons({ items }: { items: string[] }) {
	const { countryCode, language: countryLanguage } = useI18n();

	const country = countryCode?.toLocaleLowerCase() || 'nl';
	const language = countryLanguage || 'NL';

	const results = useQueries<Season[]>(
		items.map((id: string) => {
			return {
				queryKey: [`season`, id],
				queryFn: async () => {
					const res = await fetch(
						`${config.catalogServiceUrl}/v1/season/${id}?country=${country}&translation=${language}`
					);

					if (!res.ok) {
						const { message } = await res.json();
						Sentry.captureMessage(
							`Fetching season failed: ${id} ${country} ${language}`
						);
						throw new Error(message || 'Fetching season failed.');
					}

					return res.json();
				},
			};
		})
	);

	const dataSets = useArrayMemo(
		results.map((result) => {
			if (!result.data) return undefined;

			return result.data;
		})
	);

	const decoratedData = useMemo(() => {
		return dataSets?.map((set, i) => {
			return {
				isLoading: results[i].isLoading,
				error: results[i].error,
				data: set,
			};
		});
	}, [dataSets, results]);

	return { results: decoratedData };
}

type SearchResult = {
	_index: number;
};

export function useSearch({
	text,
	searchView,
}: {
	text: string;
	searchView?: boolean;
}) {
	console.log('useSearch', text);

	const { countryCode } = useI18n();
	const country = countryCode?.toLocaleLowerCase() || 'nl';

	return useQuery(
		['search', text],
		async () => {
			console.log('useQuery', text);

			const arrangedData: { [key: string]: SearchResult[] } = {
				[config.search.assetKeys.movie]: [],
				[config.search.assetKeys.series]: [],
				['results']: [],
			};

			const query = {
				query: {
					bool: {
						filter: [
							{
								match: {
									'licensing.license_countries': country,
								},
							},
							{
								range: {
									[`licensing.licensing_periods.${country}.license_from`]: {
										lte: 'now/d',
									},
								},
							},
							{
								range: {
									[`licensing.licensing_periods.${country}.license_until`]: {
										gte: 'now/d',
									},
								},
							},
						],
						must: {
							function_score: {
								functions: [
									{
										field_value_factor: {
											field: 'rating',
											factor: 1,
											modifier: 'ln2p',
											missing: 1,
										},
									},
								],
								query: {
									simple_query_string: {
										fields: ['title^5', 'description', 'actors', 'directors'],
										minimum_should_match: '80%',
										query: text,
									},
								},
								max_boost: 5,
								boost_mode: 'sum',
							},
						},
					},
				},

				sort: [
					'_score',
					{
						rating: 'desc',
					},
				],
				size: 50,
			};

			const res = await fetch(`${config.searchUrl}/_search`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(query),
			});

			const data = await res.json();

			if (!res.ok) {
				const { message } = data;
				Sentry.captureMessage(`Search request failed: ${text} ${country}`);
				throw new Error(message || 'Search request failed.');
			}

			if (data?.hits?.hits.length === 0) {
				return;
			}

			data?.hits?.hits.forEach((item: SearchResult) => {
				if (searchView) {
					arrangedData['results'] = [...arrangedData.results, item];
				} else if (item._index in arrangedData) {
					arrangedData[item._index] = [...arrangedData[item._index], item];
				}
			});

			return arrangedData;
		},
		{
			staleTime: 0,
			cacheTime: 0,
			enabled: !!text,
		}
	);
}

export function useGenreSearch({
	text,
	searchView,
}: {
	text: string;
	searchView?: boolean;
}) {
	console.log('useSearch', text);

	const { countryCode } = useI18n();
	const country = countryCode?.toLocaleLowerCase() || 'nl';

	return useQuery(
		['search', text],
		async () => {
			console.log('useQuery', text);

			const arrangedData: { [key: string]: SearchResult[] } = {
				[config.search.assetKeys.movie]: [],
				[config.search.assetKeys.series]: [],
				['results']: [],
			};

			const query = {
				query: {
					bool: {
						filter: [
							{
								match: {
									'licensing.license_countries': country,
								},
							},
							{
								range: {
									[`licensing.licensing_periods.${country}.license_from`]: {
										lte: 'now/d',
									},
								},
							},
							{
								range: {
									[`licensing.licensing_periods.${country}.license_until`]: {
										gte: 'now/d',
									},
								},
							},
						],
						must: {
							function_score: {
								functions: [
									{
										field_value_factor: {
											field: 'rating',
											factor: 1,
											modifier: 'ln2p',
											missing: 1,
										},
									},
								],
								query: {
									simple_query_string: {
										fields: ['genres.id'],
										minimum_should_match: '80%',
										query: text,
									},
								},
								max_boost: 5,
								boost_mode: 'sum',
							},
						},
					},
				},

				sort: [
					'_score',
					{
						rating: 'desc',
					},
				],
				size: 50,
			};

			const res = await fetch(`${config.searchUrl}/_search`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(query),
			});

			const data = await res.json();

			if (!res.ok) {
				const { message } = data;
				Sentry.captureMessage(`Search request failed: ${text} ${country}`);
				throw new Error(message || 'Search request failed.');
			}

			if (data?.hits?.hits.length === 0) {
				return;
			}

			data?.hits?.hits.forEach((item: SearchResult) => {
				if (searchView) {
					arrangedData['results'] = [...arrangedData.results, item];
				} else if (item._index in arrangedData) {
					arrangedData[item._index] = [...arrangedData[item._index], item];
				}
			});

			return arrangedData;
		},
		{
			staleTime: 0,
			cacheTime: 0,
			enabled: !!text,
		}
	);
}

export function useGenresList() {
	const { language } = useI18n();

	const query = `
  query GenreLists($locale: Locale!, $id: ID!) {
    genreList(where: { id: $id }, locales: [$locale]) {
      genres(first: 50) {
        image(locales: [$locale, en]) {
          alt
          url
          listUrl: url(
            transformation: {
              image: { resize: { width: 640, height: 360, fit: clip } }
            }
          )
        }
        titleImage(locales: [$locale, en]) {
          alt
          url
          listUrl: url(
            transformation: {
              image: { resize: { width: 640, height: 360, fit: clip } }
            }
          )
        }
        slug
        title
        backgroundColor {
          hex
        }
      }
    }
  }
`;

	return useQuery(
		['genresList', ''],
		async () => {
			const variables = {
				locale: config.hygraph.availableLocales.includes(language!)
					? language
					: 'en', // Replace with the desired locale
				id: config.hygraph.genreId, // Replace with the actual genre list ID
			};
			const token = config.hygraph.hygraphToken;

			const res = await fetch(config.hygraph.hygraphUrl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					authorization: token ? `Bearer ${token}` : '',
				},
				body: JSON.stringify({
					query,
					variables,
				}),
			});

			const data = await res.json();

			if (!res.ok) {
				const { message } = data;
				Sentry.captureMessage(`Search request failed: ${'text'} ${language}`);
				throw new Error(message || 'Search request failed.');
			}

			if (data.data.genreList.genres.length === 0) {
				return;
			}

			return data.data.genreList.genres as Genre[];
		},
		{
			staleTime: 0,
			cacheTime: 0,
			enabled: !!'text',
		}
	);
}

export function useRegistrationBackground() {
	const { language } = useI18n();
	const query = `
		query RegistrationBackground($id: String!) {
			asset(where: {internalId: $id }) {
				fileName
				url
			}
			}
		`;
	return useQuery(
		['registrationBackground', ''],
		async () => {
			const variables = {
				id: config.hygraph.registrationBackground,
			};
			const token = config.hygraph.hygraphToken;

			const res = await fetch(config.hygraph.hygraphUrl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					authorization: token ? `Bearer ${token}` : '',
				},
				body: JSON.stringify({
					query,
					variables,
				}),
			});

			const data = await res.json();

			if (!res.ok) {
				const { message } = data;
				Sentry.captureMessage(`Search request failed: ${'text'} ${language}`);
				throw new Error(message || 'Search request failed.');
			}

			if (!data.data.asset) {
				return;
			}

			return data.data.asset.url as string;
		},
		{
			staleTime: 0,
			cacheTime: 0,
			enabled: !!'text',
		}
	);
}

export function useGenres() {
	const { countryCode } = useI18n();
	const country = countryCode?.toLocaleLowerCase() || 'nl';

	return useQuery(
		['genres', ''],
		async () => {
			const arrangedData: string[] = [];

			const query = {
				query: {
					query_string: {
						query: '*',
					},
				},
				aggs: {
					genres: {
						terms: { field: 'genres.id.keyword', size: 1000 },
					},
				},
			};

			const res = await fetch(`${config.searchUrl}/_search`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(query),
			});

			const data = await res.json();

			if (!res.ok) {
				const { message } = data;
				Sentry.captureMessage(`Search request failed: ${'text'} ${country}`);
				throw new Error(message || 'Search request failed.');
			}

			if (data?.aggregations?.genres?.buckets.length === 0) {
				return;
			}

			data?.aggregations?.genres?.buckets.forEach((item) => {
				arrangedData.push(item.key);
			});

			return arrangedData;
		},
		{
			staleTime: 0,
			cacheTime: 0,
			enabled: !!'text',
		}
	);
}

export function useJumbotron() {
	console.log('useJumbotron');
	const client = useQueryClient();

	return useQuery<ShelfAsset | undefined>('jumbotron', async () => {
		console.log('> get jumbotron');

		return client.getQueryData<ShelfAsset>('jumbotron');
	});
}

export function useTVShow(id?: string) {
	console.log('useTVShow');

	const { countryCode, language: countryLanguage } = useI18n();
	const country = countryCode?.toLocaleLowerCase() || 'nl';
	const language = countryLanguage || 'NL';

	if (!id) return { data: null };

	return useQuery<TVShowDetails>(
		['tvShow/details', id],
		async () => {
			const uri = `/v1/tvshow/${id}?country=${country}&translation=${language}`;
			const res = await fetch(`${config.catalogServiceUrl}${uri}`);

			if (!res.ok) {
				return { data: null };
			}

			return res.json();
		},
		{
			enabled: !!id,
			// enabled,
		}
	);
}

export function useShelfAssets(id) {
	console.log('useShelfAssets');

	const client = useQueryClient();
	const key = ['assets', 'shelf', id];

	return useQuery<ShelfAsset[]>(key, async () => {
		console.log('> get assets for shelf', id);

		return client.getQueryData<ShelfAsset[]>(key) || [];
	});
}

export function useShelfAsset(id) {
	console.log('useShelfAsset');

	const client = useQueryClient();
	const key = ['assets', 'detail', id];

	return useQuery<ShelfAsset[]>(key, async () => {
		console.log('> get asset', id);

		return client.getQueryData<ShelfAsset[]>(key) || [];
	});
}

export function useAsset() {
	console.log('useAsset');

	const client = useQueryClient();

	return useQuery<ShelfAsset | undefined>('asset', async () => {
		console.log('> get asset');

		return client.getQueryData<ShelfAsset>('asset');
	});
}

export const useSetAsset = () => {
	const client = useQueryClient();
	return useMutation(
		async (asset: ShelfAsset) => {
			console.log('> mutate asset');
			return asset;
		},
		{
			onSuccess: (asset: ShelfAsset) => {
				console.log('> set asset');
				client.setQueryData('asset', asset);
			},
		}
	);
};

export function useAssetDetails({
	asset,
	enabled = false,
}: {
	asset: Asset | ShelfAsset | null | any;
	enabled?: boolean;
}) {
	const assetId = asset?.id;
	const { countryCode, language: countryLanguage } = useI18n();

	const country = countryCode?.toLocaleLowerCase() || 'nl';
	const language = countryLanguage || 'NL';

	return useQuery<Details>(
		['asset/details', assetId],
		async () => {
			const assetType =
				asset?.asset_subtype || (asset && assetTypeMap[asset.asset_type]);
			const uri = `/v1/${assetType}/${assetId}?country=${country}&translation=${language}`;
			const res = await fetch(`${config.catalogServiceUrl}${uri}`);

			if (!res.ok) {
				throw new Error(res.statusText);
			}

			const returnAsset = await res.json();

			return {
				...returnAsset,
				...getImages(returnAsset, false),
			};
		},
		{
			enabled,
			// enabled,
		}
	);
}
