import { View } from '@flexn/sdk';
import { isAndroidtv } from '@rnv/renative';
import React, { memo, useCallback } from 'react';
import { ActivityIndicator, Text } from 'react-native';

import colors from '../../../platformAssets/runtime/colors';
import { PlayerType } from '../../contexts/PlayerContext';
import { useCurrentEpisode, useDuration } from '../../hooks/api';
import { useSeasonsShelves } from '../../hooks/shelves';
import { useI18n } from '../../hooks/useI18n';
import { usePlayer } from '../../hooks/usePlayer';
import { ShelfAsset } from '../../models/Asset';
import { AssetType } from '../../models/AssetType';
import {
	Details,
	EpisodeDetails,
	MovieDetails,
	TVShowDetails,
} from '../../models/Details';
import { useAuth } from '../../providers/auth';
import ClientTracker from '../../services/event-tracker/ClientTracker';
import UserInterActionEvent, {
	USER_INTERACTION_CONTEXT,
	USER_INTERACTION_FEATURES,
	USER_INTERACTION_TYPE,
} from '../../services/event-tracker/UserInterActionEvent';
import isNative from '../../utils/isNative';
import { getResponsiveScaling, getScaledValue } from '../../utils/scale';
import ShelfList from '../shelf-list/shelf-list-flexn';
import { TILE_LANDSCAPE_HEIGHT } from '../tile/tileBase';

type EpisodeShelfProps = {
	parentContext?: any;
	asset: Details;
	hasFocus: boolean;
	onFocus: (asset: ShelfAsset | null) => void;
	onLoginPress: () => void;
};

const EpisodeShelfList = ({
	parentContext,
	asset,
	onFocus,
	onLoginPress,
}: EpisodeShelfProps) => {
	const { user } = useAuth();
	const { translate } = useI18n();

	const assetToPlay =
		(asset.asset_type === AssetType.tvshow && useCurrentEpisode(asset)) ||
		asset;
	const { duration: episodeDuration } = useDuration(assetToPlay);
	const {
		isEnd,
		setCurrentTime,
		setIsEnd,
		setAsset,
		setPlayerType,
		setPlaying,
		setShowDetails,
		setShowPlayer,
	} = usePlayer();

	const { shelvesLoading, shelvesError, shelves } = useSeasonsShelves(
		asset as TVShowDetails
	);

	const handleTileFocus = (asset: ShelfAsset) => {
		onFocus(asset);
	};

	const handlePress = useCallback(
		(pressAsset: MovieDetails | EpisodeDetails) => {
			console.log('press', pressAsset.id);

			const userInterActionEvent = new UserInterActionEvent();
			userInterActionEvent.setFeature(USER_INTERACTION_FEATURES.ASSET_DETAIL);
			userInterActionEvent.setContext(
				USER_INTERACTION_CONTEXT.SEASON_SHELF_LIST
			);
			userInterActionEvent.setType(USER_INTERACTION_TYPE.BUTTON);
			userInterActionEvent.setName(
				`shelf_${pressAsset.shelfId}_asset_${pressAsset.id}`
			);
			userInterActionEvent.setBody({
				shelf: pressAsset.shelfId,
				asset: pressAsset.id,
			});

			ClientTracker.sendUserInterActionEvent(userInterActionEvent);

			if (!user) {
				return onLoginPress();
			}

			if (isEnd) {
				setCurrentTime(0);
				setIsEnd(false);
			}

			setAsset(pressAsset);
			setPlayerType(PlayerType.Video);
			setPlaying(true);
			setShowDetails(false);
			setShowPlayer(true);
			setCurrentTime(0);
		},
		[
			onLoginPress,
			isEnd,
			setAsset,
			setCurrentTime,
			setIsEnd,
			setPlayerType,
			setPlaying,
			setShowDetails,
			setShowPlayer,
			user,
		]
	);

	const handleTileFocusCb = useCallback(handleTileFocus, [onFocus]);

	const shelvesList = useCallback(() => {
		if (assetToPlay && episodeDuration > 0) {
			const seasonShelf = shelves.find((s) => s.id === assetToPlay.season.id);

			if (seasonShelf) {
				return [
					{
						...seasonShelf,
						selectedAssetIndex: assetToPlay.index,
						titlePrefix: `${translate('continuewatching.button')}:`,
					},
					...shelves,
				];
			}
		}

		return shelves;
	}, [assetToPlay, episodeDuration, shelves, translate]);

	if (shelvesError) {
		console.error(shelvesError);

		return <Text>An error has occurred.</Text>;
	}

	if (shelves.length === 0) return null;

	return (
		<View parentContext={parentContext}>
			{shelvesLoading ? (
				<ActivityIndicator
					size="large"
					color={colors.primary}
				/>
			) : (
				<ShelfList
					initialXOffset={getScaledValue(isAndroidtv() ? 30 : 40)}
					items={shelvesList()}
					onFocus={handleTileFocusCb}
					onPress={handlePress}
					showDetail
					rowHeight={getResponsiveScaling(TILE_LANDSCAPE_HEIGHT).width}
					animatorOptions={{
						type: isNative ? 'scale_with_border' : 'border',
						scale: 1.1,
					}}
					focusOptions={{ forbiddenFocusDirections: ['right'] }}
					titleStyle={{
						paddingLeft: getScaledValue(100),
					}}
					cardStyle={{
						borderWidth: isNative ? getScaledValue(isAndroidtv() ? 4 : 2) : 0,
						borderColor: '#FFFFFF',
						borderRadius: getScaledValue(16),
					}}
				/>
			)}
		</View>
	);
};

export default memo(EpisodeShelfList, (p, n) => {
	return false;
});
