import { ScrollView, View } from '@flexn/sdk';
import { useFocusEffect } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { isAndroidtv, isTvos } from '@rnv/renative';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BackHandler, Dimensions, StyleSheet } from 'react-native';

import colors from '../../platformAssets/runtime/colors';
import fonts from '../../platformAssets/runtime/fontnames';
import gradients from '../../platformAssets/runtime/img/gradient.png';
import AppLoader from '../components/app-loader';
import AssetPreview from '../components/asset-preview';
import BackgroundPoster from '../components/background-poster';
import Button from '../components/button/button-flex';
import FavoriteButton from '../components/detail/favorite-button';
import MovieButton from '../components/detail/movie-button';
import StartOverButton from '../components/detail/startover-button';
import TrailerButton from '../components/detail/trailer-button';
import EpisodeShelfList from '../components/episode-shelf-list';
import Icon from '../components/icon';
import ImageBackground from '../components/image/background';
import Player from '../components/player';
import Screen from '../components/screen';
import { PlayerProvider, PlayerType } from '../contexts/PlayerContext';
import { useAssetDetails, useSetAsset, useWatchHistory } from '../hooks/api';
import { useI18n } from '../hooks/useI18n';
import { useKeyHandler } from '../hooks/useKeyHandler';
import { usePlayer } from '../hooks/usePlayer';
import { useVideoAnalytics } from '../hooks/useVideoAnalytics';
import { isPlayable, ShelfAsset } from '../models/Asset';
import { AssetType } from '../models/AssetType';
import { NavigatorParameterList } from '../navigators/main-screen-navigator';
import { useAuth } from '../providers/auth';
import isNative from '../utils/isNative';
import { KEY_BACK } from '../utils/keymap';
import TvEventControl from '../utils/TvEventControl';
import { getScaledValue, getResponsiveScaling } from '../utils/scale';
import { IMAGE_WIDTH } from '../components/tile/tileBase';

const styles = StyleSheet.create({
	screenContainer: {
		flex: 1,
		backgroundColor: 'black',
	},

	contentMovie: {
		paddingLeft: getScaledValue(100),
		paddingTop: getScaledValue(24),
		paddingBottom: 0,
		height:
			Dimensions.get('screen').height + getScaledValue(isAndroidtv() ? 80 : 0),
	},

	contentTvShow: {
		paddingTop: getScaledValue(24),
		paddingLeft: getScaledValue(100),
		paddingBottom: getScaledValue(120),
	},

	episodeShelf: {
		marginTop: -getScaledValue(80),
	},

	h1: {
		color: colors.primaryText,
		fontFamily: fonts.primary,
		fontSize: getScaledValue(45),
	},

	h2: {
		color: '#ffffff',
		fontFamily: fonts.secondary,
		width: '60%',
		fontSize: getScaledValue(16),
	},

	h3: {
		color: colors.muted,
		fontFamily: fonts.secondarySemiBold,
		fontSize: getScaledValue(16),
		marginRight: getScaledValue(20),
	},

	muted: {
		flexDirection: 'row',
	},

	ageRatingImage: {
		width: getScaledValue(24),
		height: getScaledValue(24),
	},

	button: {
		marginTop: getScaledValue(10),
		marginRight: getScaledValue(10),
		width: getResponsiveScaling((IMAGE_WIDTH + 2) * 2).width,
	},

	backgroundPosterContainer: {
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
	},

	gradient: {
		position: 'relative',
		flex: 1,
	},

	absoluteContainer: {
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
	},
});

type AssetDetailScreenProps = NativeStackScreenProps<
	NavigatorParameterList,
	'AssetDetail'
>;

const Content = ({ asset, navigation, play }) => {
	const timeoutRef = useRef<any | undefined>(undefined);
	const [episodeAsset, setEpisodeAsset] = useState<ShelfAsset | null>(null);
	const { mutate: setAssetMutate } = useSetAsset();

	useVideoAnalytics();

	const { user } = useAuth();
	const { translate } = useI18n();
	const {
		showDetails,
		setShowDetails,
		isEnd,
		setAsset,
		setPlayerType,
		playing,
		setPlaying,
		playerType,
		currentTime,
		setShowPlayer,
		showPlayer,

		showControls,
		setShowControls,
		showSettingsDialog,
		setShowSettingsDialog,
	} = usePlayer();

	const { data: selectedAssetDetails } = useAssetDetails({
		asset,
		enabled: !!asset?.id,
	});
	const { reloadWatchHistory } = useWatchHistory();

	const [episodeFocus, setEpisodeFocus] = useState(false);

	const handleLoginPress = useCallback(() => {
		navigation.navigate('Profile');
	}, [navigation]);

	const handleButtonsFocus = useCallback(() => {
		setEpisodeFocus(false);
	}, []);

	const handleEpisodeFocus = useCallback((asset: ShelfAsset | null) => {
		setEpisodeAsset(asset);
		setEpisodeFocus(true);
	}, []);

	useFocusEffect(
		useCallback(() => {
			timeoutRef.current && clearTimeout(timeoutRef.current);
			timeoutRef.current = setTimeout(() => {
				if (
					selectedAssetDetails?.trailer &&
					!playing &&
					asset.asset_type !== AssetType.tvshow &&
					playerType !== PlayerType.Video &&
					currentTime === 0
				) {
					setShowPlayer(true);
					setPlaying(true);
					setPlayerType(PlayerType.Trailer);
				}
			}, 3000);

			return () => {
				clearTimeout(timeoutRef.current);
			};
		}, [
			selectedAssetDetails?.trailer,
			playing,
			asset?.asset_type,
			playerType,
			currentTime,
			setShowPlayer,
			setPlaying,
			setPlayerType,
		])
	);

	useEffect(() => {
		if (selectedAssetDetails && isPlayable(selectedAssetDetails)) {
			setAsset(selectedAssetDetails);
			setAssetMutate(asset);
		}
	}, [asset, selectedAssetDetails, setAsset, setAssetMutate]);

	useEffect(() => {
		if (isTvos()) {
			TvEventControl.enableTVMenuKey();
		}

		const handleBackButtonClick = () => {
			if (showSettingsDialog) {
				setShowSettingsDialog(false);
				return true;
			}

			if (showControls) {
				setShowControls(false);
				return true;
			}

			if (!showDetails) {
				reloadWatchHistory();
				setShowDetails(true);
				setPlaying(false);
				return true;
			}

			return false;
		};

		BackHandler.addEventListener('hardwareBackPress', handleBackButtonClick);
		return () => {
			if (isTvos()) {
				TvEventControl.disableTVMenuKey();
			}

			BackHandler.removeEventListener(
				'hardwareBackPress',
				handleBackButtonClick
			);
		};
	}, [
		setPlaying,
		setShowDetails,
		showDetails,
		showSettingsDialog,
		showControls,
		setShowSettingsDialog,
		setShowControls,
		reloadWatchHistory,
	]);

	useEffect(() => {
		return () => {
			if (!navigation.isFocused()) {
				setAsset(undefined);
				timeoutRef.current && clearTimeout(timeoutRef.current);
			}
		};
	}, [navigation, asset, setAsset]);

	useKeyHandler([KEY_BACK], (key) => {
		if (!isNative) {
			if (key === KEY_BACK) {
				if (showSettingsDialog) {
					return setShowSettingsDialog(false);
				}

				if (showControls) {
					return setShowControls(false);
				}

				if (!showDetails) {
					reloadWatchHistory();

					setShowDetails(true);
					setPlaying(false);

					return true;
				}

				navigation.goBack();
			}
		}
	});

	// This fix addresses an issue with the focus manager when opening the detail screen via a deep link
	useEffect(() => {
		if (asset) {
			setShowDetails(false);

			setTimeout(() => {
				setShowDetails(true);
			}, 100);
		}
	}, [asset]);

	const swRef = useRef<ScrollView>() as React.MutableRefObject<ScrollView>;

	return (
		<>
			<Screen
				style={styles.screenContainer}
				focusOptions={{
					horizontalViewportOffset: getScaledValue(60),
					verticalViewportOffset: getScaledValue(20),
				}}
			>
				<BackgroundPoster
					style={styles.backgroundPosterContainer}
					poster={
						episodeAsset ? episodeAsset?.background.uri : asset?.background.uri
					}
				/>
				{showPlayer && !isEnd && <Player />}

				<Screen
					screenState={showDetails ? 'foreground' : 'background'}
					style={styles.absoluteContainer}
				>
					<ImageBackground
						fadeDuration={0}
						source={gradients}
						resizeMode="cover"
						style={[styles.gradient, { opacity: showDetails ? 1 : 0 }]}
					>
						{selectedAssetDetails && (
							<View
								style={{
									transform: [
										{
											translateY: -getResponsiveScaling(episodeFocus ? 55 : 0)
												.height,
										},
									],
								}}
							>
								<View style={{ position: 'relative' }}>
									<View
										style={[
											asset.asset_type === AssetType.movie
												? styles.contentMovie
												: styles.contentTvShow,
										]}
									>
										<AssetPreview />

										<View
											style={{
												flexDirection:
													asset.asset_type === AssetType.tvshow
														? 'row'
														: 'column',
											}}
										>
											{user ? (
												<View
													style={{
														flexDirection:
															asset.asset_type === AssetType.tvshow
																? 'row'
																: 'column',
													}}
												>
													<MovieButton
														asset={selectedAssetDetails}
														onFocus={() => handleButtonsFocus()}
														focusOptions={{
															forbiddenFocusDirections: ['scroll'],
														}}
													/>
													{asset.asset_type !== AssetType.tvshow && (
														<StartOverButton
															asset={selectedAssetDetails}
															onFocus={() => handleButtonsFocus()}
															focusOptions={{
																forbiddenFocusDirections: ['scroll'],
															}}
														/>
													)}
													<FavoriteButton
														asset={selectedAssetDetails}
														onFocus={() => handleButtonsFocus()}
														focusOptions={{
															forbiddenFocusDirections: ['scroll'],
														}}
													/>
												</View>
											) : (
												<Button
													testID={'asset-detail-button-profile'}
													style={styles.button}
													label={translate('login.button.text')}
													leftIcon={<Icon type="profile" />}
													onFocus={() => handleButtonsFocus()}
													onPress={handleLoginPress}
													focusOptions={{
														forbiddenFocusDirections: ['scroll'],
													}}
												/>
											)}
											{selectedAssetDetails.trailer && (
												<TrailerButton
													asset={selectedAssetDetails}
													onFocus={() => handleButtonsFocus()}
													focusOptions={{
														forbiddenFocusDirections: ['scroll'],
													}}
												/>
											)}
										</View>
									</View>
								</View>
								{showDetails && (
									<View style={[styles.episodeShelf]}>
										<EpisodeShelfList
											asset={selectedAssetDetails}
											hasFocus={navigation.isFocused() || showDetails}
											onFocus={handleEpisodeFocus}
											onLoginPress={handleLoginPress}
										/>
									</View>
								)}
							</View>
						)}
					</ImageBackground>
				</Screen>
			</Screen>
		</>
	);
};

const AssetDetailScreen: React.FC<AssetDetailScreenProps> = ({
	navigation,
	route: {
		params: { asset, id, type, play },
	},
}: AssetDetailScreenProps) => {
	const { data: selectedAssetDetails, isLoading } = useAssetDetails({
		asset: asset || { id, asset_type: type },
		enabled: true,
	});

	if (isLoading) {
		if (asset && asset?.title) {
			return (
				<AppLoader
					show
					hideLogo
				/>
			);
		}

		return null;
	}

	return (
		<PlayerProvider>
			<Content
				navigation={navigation}
				asset={selectedAssetDetails}
				play={play}
			/>
		</PlayerProvider>
	);
};

export default AssetDetailScreen;
