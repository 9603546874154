import { isAndroidtv } from '@rnv/renative';
import React from 'react';
import { StyleSheet } from 'react-native';

import colors from '../../../platformAssets/runtime/colors';
import Pressable from '../../components/pressable';
import { ShelfAsset } from '../../models/Asset';
import isNative from '../../utils/isNative';
import { getScaledValue, getResponsiveScaling } from '../../utils/scale';
import Image from '../image';
import { TILE_WIDTH, TILE_HEIGHT } from '../tile/tileBase';

type Props = {
	repeatContext?: any;
	asset: ShelfAsset;
	onPress: (asset: ShelfAsset) => void;
	onFocus: (asset: ShelfAsset) => void;
	focusOptions: any;
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		width: getResponsiveScaling(TILE_WIDTH - 1).width,
		height: getResponsiveScaling(TILE_HEIGHT - 1).width,
		padding: getScaledValue(16),
		margin: getScaledValue(8),
		justifyContent: 'center',
		alignItems: 'center',
		borderWidth: isNative ? getScaledValue(isAndroidtv() ? 2 : 2) : 0,
		borderColor: colors.primaryText,
		borderRadius: getScaledValue(12),
	},

	image: {
		position: 'absolute',
		zIndex: -1,
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		borderRadius: getScaledValue(12),
	},
});

export const SearchResult = ({
	repeatContext,
	asset,
	onFocus,
	onPress,
	focusOptions,
}: Props) => {
	return (
		<Pressable
			onPress={() => {
				onPress(asset);
			}}
			onFocus={() => {
				onFocus(asset);
			}}
			repeatContext={repeatContext}
			style={[styles.container]}
			focusOptions={focusOptions}
		>
			<Image
				style={styles.image}
				source={{ uri: asset.image.uri }}
			/>
		</Pressable>
	);
};

SearchResult.displayName = 'SearchResult';
