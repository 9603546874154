import * as Sentry from '@sentry/browser';
import React from 'react';

import packageFile from '../package.json';
import config from '../platformAssets/runtime/config';

import '../platformAssets/runtime/fontManager';
import AppLayout from './app-layout';

Sentry.init({
	dsn: config.sentryDNS,
});
Sentry.setContext('platform and version', {
	name: config.platformName,
	version: packageFile?.version,
});

export default function App() {
	return <AppLayout />;
}
