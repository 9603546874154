import * as Sentry from '@sentry/browser';
import { useContext } from 'react';

import { I18nContext } from '../contexts/I18nContext';

export function useI18n() {
	const context = useContext(I18nContext);

	if (context === undefined) {
		Sentry.captureMessage(`useI18n must be within I18nProvider`);
		throw new Error('useI18n must be within I18nProvider');
	}

	return context;
}
