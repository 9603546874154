import { App as FlexnApp } from '@flexn/sdk';

import React from 'react';
import { Dimensions, StyleSheet } from 'react-native';
import { QueryClient, QueryClientProvider } from 'react-query';

import AppNavigator from './navigators/app-navigator';
import isNative from './utils/isNative';
import { ConfigProvider } from './contexts/ConfigContext';

const styles = StyleSheet.create({
	app: {
		position: 'relative',
		height: isNative ? Dimensions.get('window').height : '100vh',
	},
});

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnMount: false,
			notifyOnChangeProps: 'tracked',
			cacheTime: Infinity,
			staleTime: Infinity,
		},
	},
});

const AppLayout = () => {
	return (
		<FlexnApp style={styles.app}>
			<QueryClientProvider client={queryClient}>
				<ConfigProvider>
					<AppNavigator />
				</ConfigProvider>
				{/* <Debugger /> */}
			</QueryClientProvider>
		</FlexnApp>
	);
};

export default AppLayout;
